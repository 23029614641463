import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBatchTestResultsRequest } from "redux/releases/action";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ReactComponent as DownloadIcon } from "assets/icons/export.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import ComparisonModal from "components/modals/release/ComparisonModal";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { getReleaseResultsFromDB } from "../../../utility/BatchTestDB";
import VirtualRow from "./VirtualRow";
import AnalyticsModal from "components/modals/release/AnalyticsModal/AnalyticsModal";

const BatchTestResults = ({ releaseId, decisionFlowTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux state (for triggering clear/analytics, etc.)
  const {
    isClearingBatchTestResults,
    isClearingBatchTestResultsSuccess,
    isClearingBatchTestResultsError,
    isBatchTestSuccess,
    batchTest, // used only for summary count (if still needed)
  } = useSelector((state) => state.releases);

  // Local state for DB-loaded results (refactored results)
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const PAGE_SIZE = 50;

  // Modal state
  const [selectedResult, setSelectedResult] = useState(null);
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [currentRequestData, setCurrentRequestData] = useState({});
  const [currentResponseData, setCurrentResponseData] = useState({});
  const [showClearConfirmModal, setShowClearConfirmModal] = useState(false);
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

  const prevIsClearingBatchTestResultsSuccess = usePrevious(
    isClearingBatchTestResultsSuccess
  );
  const prevIsClearingBatchTestResultsError = usePrevious(
    isClearingBatchTestResultsError
  );
  const prevIsBatchTestSuccess = usePrevious(isBatchTestSuccess);

  // Load paginated results from IndexedDB
  const loadResults = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const { results: newResults, totalCount } = await getReleaseResultsFromDB(
        releaseId,
        page,
        PAGE_SIZE
      );
      setResults((prev) => [...prev, ...newResults]);
      setTotalCount(totalCount);
      setPage((p) => p + 1);
    } catch (error) {
      toast.error("Error loading results.", ToastOptions);
    } finally {
      setLoading(false);
    }
  }, [releaseId, page, loading]);

  useEffect(() => {
    loadResults();
  }, [releaseId]);

  const onScroll = useCallback(
    ({ scrollOffset }) => {
      if (
        scrollOffset > results.length * 50 - 1000 &&
        results.length < totalCount
      ) {
        loadResults();
      }
    },
    [results.length, totalCount, loadResults]
  );

  // Handler for viewing a result's comparison
  const handleViewComparison = useCallback((result, index) => {
    setSelectedResult({ ...result, rowIndex: index + 1 }); // Add rowIndex
    setCurrentRequestData(result.input);
    setCurrentResponseData(result.output);
    setShowComparisonModal(true);
  }, []);

  // Download handler uses local results (refactored)
  const handleDownload = () => {
    if (results.length === 0) {
      toast.error(t("no_results_to_download"), ToastOptions);
      return;
    }
    const jsonLines = results
      .map((result) => JSON.stringify(result.output))
      .join("\n");
    const blob = new Blob([jsonLines], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    const filename = `batch_test_results_${
      new Date().toISOString().split("T")[0]
    }.jsonl`;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    toast.success(t("download_started"), ToastOptions);
  };

  // Clear results handler
  const handleClearResults = () => {
    setShowClearConfirmModal(true);
  };

  const handleConfirmClear = () => {
    dispatch(clearBatchTestResultsRequest({ releaseId }));
    setShowClearConfirmModal(false);
  };

  // Toast notifications for clear results
  useEffect(() => {
    if (
      prevIsClearingBatchTestResultsSuccess === false &&
      isClearingBatchTestResultsSuccess
    ) {
      setResults([]);
      setPage(0);
      setTotalCount(0);
      toast.success(t("batch_test_results_cleared"), ToastOptions);
    }
  }, [isClearingBatchTestResultsSuccess]);

  useEffect(() => {
    if (
      prevIsClearingBatchTestResultsError === false &&
      isClearingBatchTestResultsError
    ) {
      toast.error(t("error_clearing_batch_test_results"), ToastOptions);
    }
  }, [isClearingBatchTestResultsError]);

  useEffect(() => {
    if (prevIsBatchTestSuccess === false && isBatchTestSuccess === true) {
      loadResults();
    }
  }, [isBatchTestSuccess, prevIsBatchTestSuccess, loadResults]);

  const getItemData = useCallback(
    () => ({
      results,
      t,
      handleViewComparison,
      setSelectedResult,
    }),
    [results, t, handleViewComparison]
  );

  // If no results are loaded, you can return a placeholder or null
  if (!results.length && !loading)
    return <div className="alert alert-info">{t("no_results_available")}</div>;

  return (
    <>
      <div className="batch-test-results mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>{t("batch_testing_results")}</h4>
          <div className="d-flex">
            <button
              className="btn outline"
              onClick={handleDownload}
              title={t("download_results")}
              disabled={results.length === 0}
            >
              <DownloadIcon /> {t("download")}
            </button>
            <button
              className="btn outline option-delete d-flex align-items-center ml-2"
              onClick={handleClearResults}
              disabled={isClearingBatchTestResults || results.length === 0}
              data-tip
              data-for="clear-results-tooltip"
            >
              {isClearingBatchTestResults ? (
                t("clearing")
              ) : (
                <>
                  <TrashIcon className="pr-2" /> {t("clear_results")}
                </>
              )}
            </button>
            <button
              className="btn outline ml-2"
              onClick={() => setShowAnalyticsModal(true)}
              disabled={results.length === 0}
            >
              {t("analyze")}
            </button>
            {isClearingBatchTestResults && (
              <ReactTooltip
                id="clear-results-tooltip"
                effect="solid"
                place="top"
              >
                {t("please_wait")}
              </ReactTooltip>
            )}
          </div>
        </div>

        {/* Summary using totalCount from DB (or optionally batchTest.total if desired) */}
        <div className="mb-3">
          <strong>{t("total")}:</strong> {totalCount}
        </div>

        {/* Results Table */}
        <div className="virtual-table-container" style={{ height: "600px" }}>
          <div className="table-header">
            <div className="table-col col-number">#</div>
            <div className="table-col col-status">{t("status")}</div>
            <div className="table-col col-content">{t("input")}</div>
            <div className="table-col col-content">{t("output")}</div>
            <div className="table-col col-actions text-center">
              {t("compare")}
            </div>
          </div>

          {loading && results.length === 0 ? (
            <div className="loading-indicator">Loading...</div>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height - 40}
                  itemCount={totalCount}
                  itemSize={50}
                  width={width}
                  onScroll={onScroll}
                  itemData={getItemData()}
                  overscanCount={5}
                  className="batch-test-list"
                >
                  {VirtualRow}
                </List>
              )}
            </AutoSizer>
          )}
        </div>

        {/* Comparison Modal */}
        {showComparisonModal && selectedResult && (
          <ComparisonModal
            show={showComparisonModal}
            handleClose={() => {
              setShowComparisonModal(false);
              setSelectedResult(null);
            }}
            originalData={currentRequestData}
            resultData={currentResponseData}
            title={`Comparison #${selectedResult.rowIndex || ""}`}
          />
        )}

        {/* Analytics Modal */}
        <AnalyticsModal
          show={showAnalyticsModal}
          handleClose={() => setShowAnalyticsModal(false)}
          decisionFlowTitle={decisionFlowTitle}
          releaseId={releaseId}
        />
      </div>

      <DeleteConfirm
        open={showClearConfirmModal}
        handleClose={() => setShowClearConfirmModal(false)}
        handleConfirm={handleConfirmClear}
        message={t("do_you_want_to_clear_results")}
        title={t("clear_results")}
        confirm_button={t("clear_results")}
      />
    </>
  );
};

BatchTestResults.propTypes = {
  releaseId: PropTypes.string.isRequired,
  decisionFlowTitle: PropTypes.string,
};

export default BatchTestResults;
