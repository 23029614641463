import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getCountriesRequest,
  getSubscriptionRequest,
  updateSubscriptionRequest,
} from "redux/subscription/action";
import usePrevious from "utility/hooks/usePrevious";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import SubHeader from "components/SubHeader";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";
import "./SubscriptionSettings.css";

const SubscriptionSettings = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const [dataSourceRef] = useCtrlSHandler(onHandleUpdateSubscription);
  const { t } = useTranslation();

  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({
    title: "",
    primary_contact_email: "",
    company_code: "",
    primary_contact_name: "",
    primary_contact_phone: "",
    address: "",
    town: "",
    county: "",
    post_code: "",
    country_id: "",
  });

  const {
    isGetSubscriptionSuccess,
    isGetSubscriptionError,
    isGetCountriesError,
    subscriptions,
    countries,
    isUpdateSubscriptionsSuccess,
    isUpdateSubscriptionsError,
  } = useSelector((state) => state.subscriptions);

  const prevIsGetCountriesError = usePrevious(isGetCountriesError);
  const prevIsGetSubscriptionError = usePrevious(isGetSubscriptionError);
  const prevIsGetSubscriptionSuccess = usePrevious(isGetSubscriptionSuccess);
  const prevIsUpdateSubscriptionsSuccess = usePrevious(
    isUpdateSubscriptionsSuccess
  );
  const prevIsUpdateSubscriptionsError = usePrevious(
    isUpdateSubscriptionsError
  );

  useEffect(() => {
    document.title = t("subscriptions_settings_decisimo");
    setIsLoading(true);
    dispatch(getSubscriptionRequest());
    dispatch(getCountriesRequest());
  }, [dispatch, setIsLoading, t]);

  useEffect(() => {
    if (isGetSubscriptionSuccess && prevIsGetSubscriptionSuccess === false) {
      setIsLoading(false);
      const tenant = subscriptions?.tenant || {};
      setFormValues({
        title: tenant.title || "",
        primary_contact_email: tenant.primary_contact_email || "",
        company_code: tenant.company_code || "",
        primary_contact_name: tenant.primary_contact_name || "",
        primary_contact_phone: tenant.primary_contact_phone || "",
        address: tenant.address || "",
        town: tenant.town || "",
        county: tenant.county || "",
        post_code: tenant.post_code || "",
        country_id: tenant.country_id || "",
      });
    }
  }, [
    isGetSubscriptionSuccess,
    prevIsGetSubscriptionSuccess,
    setIsLoading,
    subscriptions,
  ]);

  useEffect(() => {
    if (
      (isGetSubscriptionError && prevIsGetSubscriptionError === false) ||
      (isGetCountriesError && prevIsGetCountriesError === false) ||
      (isUpdateSubscriptionsError && prevIsUpdateSubscriptionsError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetSubscriptionError,
    isGetCountriesError,
    isUpdateSubscriptionsError,
    prevIsGetCountriesError,
    prevIsGetSubscriptionError,
    prevIsUpdateSubscriptionsError,
    setIsLoading,
  ]);

  useEffect(() => {
    if (
      isUpdateSubscriptionsSuccess &&
      prevIsUpdateSubscriptionsSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("subscription_settings_changed"), ToastOptions);
    }
  }, [
    isUpdateSubscriptionsSuccess,
    prevIsUpdateSubscriptionsSuccess,
    setIsLoading,
    t,
  ]);

  const validateField = (name, value) => {
    let error = "";

    if (name === "title") {
      if (!value.trim()) {
        error = t("tenant_name_required");
      }
    }

    if (name === "primary_contact_email") {
      if (!value.trim()) {
        error = t("email_required");
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        error = t("invalid_email");
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form values
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Validate specific fields
    if (name === "title" || name === "primary_contact_email") {
      validateField(name, value);
    }

    // Handle country change separately if needed
    if (name === "country_id") {
      // If there are any specific actions on country change, handle here
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.title.trim()) {
      newErrors.title = t("tenant_name_required");
    }

    const email = formValues.primary_contact_email.trim();
    if (!email) {
      newErrors.primary_contact_email = t("email_required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.primary_contact_email = t("invalid_email");
    }

    setErrors(newErrors);

    return newErrors;
  };

  function onHandleUpdateSubscription(target) {
    const formData = new FormData(target);
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      toast.error(t("please_fix_errors"), ToastOptions);
      return;
    }

    localStorage.setItem("tenant", formData.get("title"));
    const data = new URLSearchParams(formData);
    setIsLoading(true);
    dispatch(updateSubscriptionRequest(data));
  }

  return (
    <>
      <SubHeader title={t("settings")} />
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          onHandleUpdateSubscription(e.target);
        }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 mb-2">
            <label className="mt-2">{t("company_name")}</label>
            <input
              type="text"
              name="title"
              value={formValues.title}
              onChange={handleChange}
              className={`form-control ${errors.title ? "is-invalid" : ""}`}
            />
            {errors.title && (
              <div className="invalid-feedback">{errors.title}</div>
            )}

            <label className="mt-2">{t("company_id_company_number")}</label>
            <input
              type="text"
              name="company_code"
              value={formValues.company_code}
              onChange={handleChange}
              className="form-control"
            />

            <hr />
            <h5>{t("primary_contact")}</h5>

            <label className="mt-2">{t("name")}:</label>
            <input
              type="text"
              name="primary_contact_name"
              value={formValues.primary_contact_name}
              onChange={handleChange}
              className="form-control"
            />

            <label className="mt-2">{t("email")}:</label>
            <input
              type="email"
              name="primary_contact_email"
              value={formValues.primary_contact_email}
              onChange={handleChange}
              className={`form-control ${
                errors.primary_contact_email ? "is-invalid" : ""
              }`}
            />
            {errors.primary_contact_email && (
              <div className="invalid-feedback">
                {errors.primary_contact_email}
              </div>
            )}

            <label className="mt-2">{t("phone")}:</label>
            <input
              type="text"
              name="primary_contact_phone"
              value={formValues.primary_contact_phone}
              onChange={handleChange}
              className="form-control"
            />
          </div>

          <div className="col-lg-4 col-md-6 col-12 mb-2">
            <h5>{t("address")}</h5>

            <label className="mt-2">{t("address_building_street")}</label>
            <textarea
              rows="3"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              className="form-control"
            />

            <label className="mt-2">{t("town")}</label>
            <input
              type="text"
              name="town"
              value={formValues.town}
              onChange={handleChange}
              className="form-control"
            />

            <label className="mt-2">{t("county")}</label>
            <input
              type="text"
              name="county"
              value={formValues.county}
              onChange={handleChange}
              className="form-control"
            />

            <label className="mt-2">{t("post_code")}</label>
            <input
              type="text"
              name="post_code"
              value={formValues.post_code}
              onChange={handleChange}
              className="form-control"
            />

            <label className="mt-2">{t("country")}</label>
            <select
              name="country_id"
              value={formValues.country_id}
              onChange={handleChange}
              className="form-control"
            >
              <option value="">{t("select_country")}</option>
              {countries?.length > 0 &&
                countries.map((country) => (
                  <option key={country.country_id} value={country.country_id}>
                    {country.title}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col">
            <button className="btn primary" type="submit">
              <SaveIcon /> <span className="ml-2">{t("update_settings")}</span>
            </button>
          </div>
        </div>
      </form>

      <h2 className="h3">{t("subscriptions")}</h2>
      {!subscriptions?.subscriptions ||
      !subscriptions.subscriptions.active_modules.length ? (
        <div>{t("no_active_subscriptions")}</div>
      ) : (
        <div className="subscriptions-container mb-4">
          <div className="subscription-info">
            <p>
              <strong>{t("active_subscription")}</strong>{" "}
              {subscriptions.subscriptions.title}
            </p>
            <p>
              <strong>{t("license_end_date")}</strong>{" "}
              {!subscriptions.subscriptions.end_date
                ? t("open_ended")
                : subscriptions.subscriptions.end_date}
            </p>
          </div>
          <div className="modules-container">
            <table className="modules-table">
              <thead>
                <tr>
                  <th>{t("module")}</th>
                  <th>{t("included_capitalized")}</th>
                  <th>{t("number_allowed")}</th>
                  <th>{t("overage_allowed")}</th>
                </tr>
              </thead>
              <tbody>
                {subscriptions.subscriptions.active_modules.map(
                  (module, index) => (
                    <tr key={index}>
                      <td>{module.module_name}</td>
                      <td>{module.included ? t("yes") : t("no")}</td>
                      <td>{module.number_allowed}</td>
                      <td>{module.overage_allowed ? t("yes") : t("no")}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionSettings;
