import { handleActions } from "redux-actions";
import {
  createImpactScenarioRequest,
  createImpactScenarioSuccess,
  createImpactScenarioFailure,
  executeImpactScenarioRequest,
  executeImpactScenarioSuccess,
  executeImpactScenarioFailure,
  addImpactScenarioResult,
  updateImpactScenarioProgress,
  resetImpactAnalyzerState,
} from "./action";

const initialState = {
  // Create Impact Scenario
  impactScenarioId: null,
  isCreatingImpactScenario: false,
  isCreateImpactScenarioSuccess: false,
  isCreateImpactScenarioError: false,
  createImpactScenarioError: null,

  // Execute Impact Scenario
  isExecutingImpactScenario: false,
  isExecuteImpactScenarioSuccess: false,
  isExecuteImpactScenarioError: false,
  executeImpactScenarioError: null,
  impactScenarioResults: {
    total: 0,
    completed: 0,
    results: [],
  },
};

const reducer = handleActions(
  {
    // Create Impact Scenario
    [createImpactScenarioRequest]: (state) => ({
      ...state,
      isCreatingImpactScenario: true,
      isCreateImpactScenarioSuccess: false,
      isCreateImpactScenarioError: false,
      createImpactScenarioError: null,
    }),
    [createImpactScenarioSuccess]: (state, { payload }) => ({
      ...state,
      impactScenarioId: payload,
      isCreatingImpactScenario: false,
      isCreateImpactScenarioSuccess: true,
      isCreateImpactScenarioError: false,
    }),
    [createImpactScenarioFailure]: (state, { payload }) => ({
      ...state,
      isCreatingImpactScenario: false,
      isCreateImpactScenarioSuccess: false,
      isCreateImpactScenarioError: true,
      createImpactScenarioError: payload,
    }),

    // Execute Impact Scenario
    [executeImpactScenarioRequest]: (state, { payload }) => ({
      ...state,
      isExecutingImpactScenario: true,
      isExecuteImpactScenarioSuccess: false,
      isExecuteImpactScenarioError: false,
      executeImpactScenarioError: null,
      impactScenarioResults: {
        total: payload.totalCount || 0,
        completed: 0,
        results: [],
      },
    }),
    [executeImpactScenarioSuccess]: (state) => ({
      ...state,
      isExecutingImpactScenario: false,
      isExecuteImpactScenarioSuccess: true,
      isExecuteImpactScenarioError: false,
    }),
    [executeImpactScenarioFailure]: (state, { payload }) => ({
      ...state,
      isExecutingImpactScenario: false,
      isExecuteImpactScenarioSuccess: false,
      isExecuteImpactScenarioError: true,
      executeImpactScenarioError: payload,
    }),
    [addImpactScenarioResult]: (state, { payload }) => ({
      ...state,
      impactScenarioResults: {
        ...state.impactScenarioResults,
        completed: state.impactScenarioResults.completed + 1,
        results: [...state.impactScenarioResults.results, payload],
      },
    }),
    [updateImpactScenarioProgress]: (state, { payload }) => ({
      ...state,
      impactScenarioResults: {
        ...state.impactScenarioResults,
        completed: payload.completed,
        total: payload.total,
      },
    }),

    // Reset State
    [resetImpactAnalyzerState]: () => initialState,
  },
  initialState
);

export default reducer;
