import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { useTranslation } from "react-i18next";
import ReleaseTreeTable from "components/tree-table/ReleaseTreeTable";

const ResultContentModal = ({ show, handleClose, data, title }) => {
  const [isJsonView, setIsJsonView] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsJsonView(false);
  }, [data]);

  const renderContent = () => {
    if (typeof data !== "object") {
      return <pre className="content-modal-pre">{data}</pre>;
    }

    return isJsonView ? (
      <CodeMirror
        key={`content-${JSON.stringify(data)}`}
        value={JSON.stringify(data, null, 2)}
        extensions={[json(), EditorView.lineWrapping]}
        cursor={{ line: 1, ch: 1 }}
        height="600px"
        readOnly="nocursor"
      />
    ) : (
      <ReleaseTreeTable type="output" testingData={{ current: data }} />
    );
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header>
        <h3 className="modal-title">{title}</h3>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {renderContent()}
        {typeof data === "object" && (
          <div className="convert-json btn-group-toggle" data-toggle="buttons">
            <label className="btn outline border-radius-left-4 active">
              <input
                type="radio"
                value="0"
                checked={!isJsonView}
                onChange={() => setIsJsonView(false)}
              />
              {t("tree")}
            </label>
            <label className="btn outline border-radius-right-4">
              <input
                type="radio"
                value="1"
                checked={isJsonView}
                onChange={() => setIsJsonView(true)}
              />
              JSON
            </label>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

ResultContentModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
};

export default ResultContentModal;
