import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const CustomSelect = ({
  defaultValue = null,
  options = null,
  selectKey = null,
  handleChange = null,
  name = null,
  getOptionLabel,
  classes = "",
  isSearchable = false,
  isDisabled = null,
  selectWidth = null,
  menuPosition = null,
  isFormula = false,
  disabled = null,
  selectStyle = {},
  components = {},
  isDataSourceSection = false,
}) => {
  const customStyles = {
    menu: (styles) => ({
      ...styles,
      background: "white",
      border: "1px solid #DEE2E6",
      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      marginTop: 0,
      marginBottom: 0,
      overflow: "hidden",
      zIndex: 9999,
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
      maxHeight: "250px",
      overflowY: "auto",
      zIndex: 9999,
    }),
    option: (styles) => ({
      ...styles,
      fontSize: "14px",
      cursor: "pointer",
      padding: "5px !important",
      "&:not(:last-child)": {
        borderBottom: "1px solid #ced4da",
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "#6C757D",
      marginRight: "10px",
    }),
  };

  return (
    <Select
      components={components}
      disabled={disabled}
      menuPosition={menuPosition}
      classNamePrefix="select"
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      getOptionLabel={getOptionLabel}
      name={name}
      defaultValue={defaultValue}
      options={options}
      key={selectKey}
      className={`react-select-container font-size-14 bg-white border-radius-left-4 ${classes} ${selectWidth} ${
        isFormula ? "mr-1 mb-1" : ""
      } ${isDataSourceSection ? "oauth2-tokens" : ""}`}
      onChange={handleChange}
      styles={{ ...customStyles, ...selectStyle }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#F8F9FA",
        },
      })}
    />
  );
};

CustomSelect.propTypes = {
  defaultValue: PropTypes.any,
  options: PropTypes.array,
  selectKey: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  getOptionLabel: PropTypes.any,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.any,
  selectWidth: PropTypes.string,
  menuPosition: PropTypes.string,
  classes: PropTypes.string,
  isFormula: PropTypes.bool,
  disabled: PropTypes.bool,
  selectStyle: PropTypes.object,
  components: PropTypes.object,
  isDataSourceSection: PropTypes.bool,
};

export default CustomSelect;
