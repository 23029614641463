import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import usePrevious from "utility/hooks/usePrevious";
import {
  getRuleSetRequest,
  getRunTestResultDetailsRequest,
  getRunTestResultStatusRequest,
  updateRuleSetRequest,
} from "redux/rulesets/action";
import { MainContext } from "context/contexts";
import RuleSetContent from "components/rulesets/RuleSetContent";
import TestingModal from "components/modals/Testing";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { ruleDataConvertJson, sortByOrderIndex } from "utility/utility";
import { buildTree } from "./utils";
import {
  useCtrlSHandler,
  useCtrlQHandler,
} from "utility/hooks/useCtrlSHandler";
import { useTranslation } from "react-i18next";

const RuleSet = () => {
  const { vectorsData, setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [dataSourceRef] = useCtrlSHandler(updateRuleSet);

  useCtrlQHandler(() => setIsTestRuleSetModalOpen(true));

  const {
    testRuleSet,
    isGetTestRuleSetSuccess,
    isGetTestRuleSetError,
    isGetRunTestResultDetailsSuccess,
    isGetRunTestResultDetailsError,
    isGetRunTestResultStatusSuccess,
    isGetRunTestResultStatusError,
    runTestResultDetails,
    runTestResultStatus,
    ruleSet,
    isGetRuleSetSuccess,
    isUpdateRuleSetSuccess,
  } = useSelector((state) => state.ruleSets);

  const prevIsGetRuleSetSuccess = usePrevious(isGetRuleSetSuccess);
  const prevIsUpdateRuleSetSuccess = usePrevious(isUpdateRuleSetSuccess);
  const prevIsGetTestRuleSetSuccess = usePrevious(isGetTestRuleSetSuccess);
  const prevIsGetTestRuleSetError = usePrevious(isGetTestRuleSetError);
  const prevIsGetRunTestResultDetailsSuccess = usePrevious(
    isGetRunTestResultDetailsSuccess
  );
  const prevIsGetRunTestResultDetailsError = usePrevious(
    isGetRunTestResultDetailsError
  );
  const prevIsGetRunTestResultStatusSuccess = usePrevious(
    isGetRunTestResultStatusSuccess
  );
  const prevIsGetRunTestResultStatusError = usePrevious(
    isGetRunTestResultStatusError
  );

  const [ruleSetData, setRuleSetData] = useState({});
  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [testRuleSetData, setTestRuleSetData] = useState([]);
  const [runTestResultDetailsData, setRunTestResultDetailsData] = useState();
  const [runTestResultStatusData, setRunTestResultStatusData] = useState();
  const [isTestRuleSetModalOpen, setIsTestRuleSetModalOpen] = useState(false);
  const [rulesFired, setRulesFired] = useState([]);
  const [rulesFiredDetails, setRulesFiredDetails] = useState([]);

  useEffect(() => {
    document.title = t("rule_set_decisimo");
    setIsLoading(true);
    dispatch(getRuleSetRequest({ id }));
  }, []);

  useEffect(() => {
    setVectorsDataClone([...vectorsData]);
  }, [vectorsData]);

  useEffect(() => {
    if (
      (isGetTestRuleSetError && prevIsGetTestRuleSetError === false) ||
      (isGetRunTestResultDetailsError &&
        prevIsGetRunTestResultDetailsError === false) ||
      (isGetRunTestResultStatusError &&
        prevIsGetRunTestResultStatusError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetTestRuleSetError,
    isGetRunTestResultDetailsError,
    isGetRunTestResultStatusError,
  ]);

  useEffect(() => {
    if (isGetTestRuleSetError && prevIsGetTestRuleSetError === false) {
      toast.error(t("rules_set_get_failure"), ToastOptions);
    }
  }, [isGetTestRuleSetError]);

  useEffect(() => {
    if (
      (isGetRuleSetSuccess && prevIsGetRuleSetSuccess === false) ||
      (isUpdateRuleSetSuccess && prevIsUpdateRuleSetSuccess === false)
    ) {
      const data = structuredClone(ruleSet);
      data.rules = data.rules?.map((rule) => {
        if (rule.condition) {
          rule.condition = buildTree(rule.condition);
        }
        return rule;
      });
      setIsLoading(false);
      if (ruleSet?.rules?.length === 1 && ruleSet?.rules[0]?.rule_id === 0) {
        setRuleSetData({ ...data, rules: [] });
      } else {
        data?.rules?.sort(sortByOrderIndex);
        setRuleSetData(data);
      }
      setTestRuleSetData([]);
    }
  }, [isGetRuleSetSuccess, isUpdateRuleSetSuccess]);

  useEffect(() => {
    if (isGetTestRuleSetSuccess && prevIsGetTestRuleSetSuccess === false) {
      setIsLoading(false);
      setTestRuleSetData(structuredClone(testRuleSet));
    }
  }, [isGetTestRuleSetSuccess]);

  useEffect(() => {
    if (
      isGetRunTestResultDetailsSuccess &&
      prevIsGetRunTestResultDetailsSuccess === false
    ) {
      setIsLoading(false);
      const testResultClone = structuredClone(runTestResultDetails);
      const ruleSetClone = structuredClone(ruleSet);
      setRunTestResultDetailsData(testResultClone);
      if (
        Object.keys(ruleSetClone).length > 0 &&
        Object.keys(testResultClone).length > 0
      ) {
        setRulesFiredDetails(
          ruleSetClone.rules
            .filter((rule) =>
              testResultClone?.output?.test?.rules_fired?.some(
                (fired) => fired === rule.name
              )
            )
            ?.map((rule) => rule.title)
        );
      }
    }
  }, [isGetRunTestResultDetailsSuccess]);

  useEffect(() => {
    if (
      isGetRunTestResultStatusSuccess &&
      prevIsGetRunTestResultStatusSuccess === false
    ) {
      setIsLoading(false);
      setRunTestResultStatusData({ ...runTestResultStatus });
      const testResultClone = structuredClone(runTestResultStatus);
      const ruleSetClone = structuredClone(ruleSet);
      setRunTestResultStatusData(testResultClone);
      if (
        Object.keys(ruleSetClone).length > 0 &&
        Object.keys(testResultClone).length > 0
      ) {
        setRulesFired(
          ruleSetClone.rules
            .filter((rule) =>
              testResultClone?.output?.test?.rules_fired?.some(
                (fired) => fired === rule.name
              )
            )
            ?.map((rule) => rule.title)
        );
      }
    }
  }, [isGetRunTestResultStatusSuccess]);

  function updateRuleSet(target) {
    if (target.querySelector(".is-invalid.react-autosuggest__input")) {
      return toast.error(t("data_attribute_path_is_invalid"), ToastOptions);
    } else if (target.querySelector(".is-invalid")) {
      return toast.error(t("invalid_json"), ToastOptions);
    }
    setIsEdited(false);
    const identifiers = [];
    ruleSetData?.rules?.forEach((rule) => {
      identifiers.push(
        target?.getElementsByClassName("form-control")[
          `rule[${rule.rule_id}][name]`
        ]?.value
      );
    });

    const hasDuplicate = identifiers?.some(
      (val, i) => identifiers.indexOf(val) !== i
    );

    if (!hasDuplicate) {
      const data = new URLSearchParams(new FormData(target));
      data.set("title", ruleSetData?.title);
      data.set("name", ruleSetData?.name);

      ruleSetData?.rules?.forEach((rule) => {
        if (
          (rule.rule_id !== 0 && data.get(`rule[${rule.rule_id}][active]`)) ===
          "on"
        ) {
          data.set(`rule[${rule.rule_id}][active]`, "1");
        }
      });
      data.set("verbose", ruleSetData.verbose === "1" ? "1" : "0");
      setIsLoading(true);
      const ruleJsonData = ruleDataConvertJson(data, ruleSetData);

      dispatch(updateRuleSetRequest({ ruleJsonData, id }));
    } else {
      toast.error(t("rule_identifier_unique_warning"), ToastOptions);
    }
  }

  const handleCloseTestRuleSet = () => {
    setRunTestResultDetailsData();
    setRunTestResultStatusData();
    setIsTestRuleSetModalOpen(false);
  };

  const handleRunTestResultDetailsConfirm = (e) => {
    e.preventDefault();
    const data = new URLSearchParams(new FormData(e.target));

    setIsLoading(true);
    dispatch(getRunTestResultDetailsRequest({ data, id }));
  };

  const handleRunTestResultSuccessConfirm = (vectorId) => {
    if (!vectorId) {
      vectorId = vectorsData.length > 1 ? vectorsData[1].vector_id : null;
    }
    if (!vectorId) {
      return toast.error(t("no_data_object_selected"), ToastOptions);
    }
    setIsLoading(true);
    dispatch(getRunTestResultStatusRequest({ id, vectorId }));
  };

  return (
    <>
      <form
        ref={dataSourceRef}
        onSubmit={(e) => {
          e.preventDefault();
          updateRuleSet(e.target);
        }}
        onChange={() => setIsEdited(true)}
      >
        <RuleSetContent
          ruleSetData={ruleSetData}
          setRuleSetData={setRuleSetData}
          setIsTestRuleSetModalOpen={setIsTestRuleSetModalOpen}
        />
      </form>

      <TestingModal
        title={t("testing_rule_set")}
        size={"lg"}
        handleClose={handleCloseTestRuleSet}
        handleRunTestResultDetailsConfirm={handleRunTestResultDetailsConfirm}
        handleRunTestResultSuccessConfirm={handleRunTestResultSuccessConfirm}
        runTestResultDetailsData={runTestResultDetailsData}
        runTestResultStatusData={runTestResultStatusData}
        testingData={testRuleSetData}
        vectorsData={vectorsDataClone}
        rulesFired={rulesFired}
        rulesFiredDetails={rulesFiredDetails}
        isRuleSet={true}
        open={isTestRuleSetModalOpen}
      />
    </>
  );
};

export default RuleSet;
