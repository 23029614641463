import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDecisionTreeRequest } from "redux/decisionTrees/action";
import { MainContext } from "context/contexts";
import DecisionTreeContent from "components/decisionTrees/DecisionTreeContent"; // Adjust the import path as needed
import { useTranslation } from "react-i18next";
import { DecisionTreeProvider } from "context/DecisionTreeContext";

const DecisionTree = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { setIsLoading } = useContext(MainContext);
  const { t } = useTranslation();
  const { decisionTree, isLoading, error, isFetchTreeSuccess } = useSelector(
    (state) => state.decisionTrees
  );

  document.title = t("decision_tree") + " - Decisimo";

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchDecisionTreeRequest(id));
  }, [dispatch, id, setIsLoading]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (isFetchTreeSuccess && decisionTree) {
      setIsLoading(false);
      if (decisionTree && decisionTree.name) {
        document.title = `${decisionTree.title} - Decisimo`;
      } else {
        document.title = t("decision_tree") + "Decisimo";
      }
    }
  }, [isFetchTreeSuccess, setIsLoading, decisionTree]);

  // Optionally handle error state
  useEffect(() => {
    if (error) {
      // Handle the error state, e.g., show a notification
      console.error("Failed to fetch decision tree:", error);
    }
  }, [error]);

  return (
    <>
      {decisionTree ? (
        <DecisionTreeProvider>
          <DecisionTreeContent decisionTreeId={id} hideFields={true} />
        </DecisionTreeProvider>
      ) : (
        <div>{t("loading")}</div>
      )}
    </>
  );
};

export default DecisionTree;
