import { handleActions } from "redux-actions";
import {
  createUserAccountFailure,
  createUserAccountRequest,
  createUserAccountSuccess,
  createUserAccountConflict,
  deleteUserFromAccountsFailure,
  deleteUserFromAccountsRequest,
  deleteUserFromAccountsSuccess,
  getUserAccountFailure,
  getUserAccountRequest,
  getUserAccountsFailure,
  getUserAccountsRequest,
  getUserAccountsSuccess,
  getUserAccountSuccess,
  updateUserAccountFailure,
  updateUserAccountPasswordFailure,
  updateUserAccountPasswordRequest,
  updateUserAccountPasswordSuccess,
  updateUserAccountRequest,
  updateUserAccountSuccess,
  checkUserSessionStatusRequest,
  checkUserSessionStatusSuccess,
  checkUserSessionStatusFailure,
  lockUserRequest,
  lockUserSuccess,
  lockUserFailure,
  unlockUserRequest,
  unlockUserSuccess,
  unlockUserFailure,
} from "redux/user/action";

const initialState = {
  isChangeSettingsSuccess: false,
  isChangeSettingsFailure: false,
  isChangeAccountPasswordSuccess: false,
  isChangeAccountPasswordFailure: false,
  isChangeAccountSuccess: false,
  isChangeAccountFailure: false,
  isCreatedAccountSuccess: false,
  isCreatedAccountFailure: false,
  isCreatedAccountConflict: false,
  removeUserFromAccountsSuccess: false,
  removeUserFromAccountsFailure: false,
  userAccounts: [],
  isGetUserAccountSuccess: false,
  isGetUserAccountError: false,
  isGetUserAccountsSuccess: false,
  isGetUserAccountsError: false,
  userAccount: {},
  newUserAccountId: "",
  sessionTimeToExpire: 0,
  sessionStatus: "unknown",
  isCheckUserSessionStatusSuccess: false,
  isCheckUserSessionStatusError: false,
  isLockUserSuccess: false,
  isLockUserFailure: false,
  isUnlockUserSuccess: false,
  isUnlockUserFailure: false,
};

const reducer = handleActions(
  {
    // change user accounts password
    [updateUserAccountPasswordRequest]: (state) => ({
      ...state,
      isChangeAccountPasswordSuccess: false,
      isChangeAccountPasswordFailure: false,
    }),
    [updateUserAccountPasswordSuccess]: (state) => ({
      ...state,
      isChangeAccountPasswordSuccess: true,
      isChangeAccountPasswordFailure: false,
    }),
    [updateUserAccountPasswordFailure]: (state) => ({
      ...state,
      isChangeAccountPasswordSuccess: false,
      isChangeAccountPasswordFailure: true,
    }),
    // remove user from user accounts
    [deleteUserFromAccountsRequest]: (state) => ({
      ...state,
      removeUserFromAccountsSuccess: false,
      removeUserFromAccountsFailure: false,
    }),
    [deleteUserFromAccountsSuccess]: (state) => ({
      ...state,
      removeUserFromAccountsSuccess: true,
      removeUserFromAccountsFailure: false,
    }),
    [deleteUserFromAccountsFailure]: (state) => ({
      ...state,
      removeUserFromAccountsSuccess: false,
      removeUserFromAccountsFailure: true,
    }),
    // get user accounts
    [getUserAccountsRequest]: (state) => ({
      ...state,
      isGetUserAccountsSuccess: false,
      isGetUserAccountsError: false,
    }),
    [getUserAccountsSuccess]: (state, { payload }) => ({
      ...state,
      isGetUserAccountsSuccess: true,
      isGetUserAccountsError: false,
      userAccounts: payload || [],
    }),
    [getUserAccountsFailure]: (state) => ({
      ...state,
      isGetUserAccountsSuccess: false,
      isGetUserAccountsError: true,
    }),
    // get user account
    [getUserAccountRequest]: (state) => ({
      ...state,
      isGetUserAccountSuccess: false,
      isGetUserAccountError: false,
    }),
    [getUserAccountSuccess]: (state, { payload }) => ({
      ...state,
      isGetUserAccountSuccess: true,
      isGetUserAccountError: false,
      userAccount: payload,
    }),
    [getUserAccountFailure]: (state) => ({
      ...state,
      isGetUserAccountSuccess: false,
      isGetUserAccountError: true,
    }),
    // create user account
    [createUserAccountRequest]: (state) => ({
      ...state,
      isCreatedAccountSuccess: false,
      isCreatedAccountFailure: false,
    }),
    [createUserAccountSuccess]: (state, { payload }) => {
      return {
        ...state,
        newUserAccountId: payload || "",
        isCreatedAccountSuccess: true,
        isCreatedAccountFailure: false,
      };
    },
    [createUserAccountFailure]: (state) => ({
      ...state,
      isCreatedAccountFailure: true,
      isCreatedAccountSuccess: false,
    }),
    [createUserAccountConflict]: (state) => ({
      ...state,
      isCreatedAccountConflict: true,
      isCreatedAccountSuccess: false,
    }),
    // save user account
    [updateUserAccountRequest]: (state) => ({
      ...state,
      isChangeAccountSuccess: false,
      isChangeAccountFailure: false,
    }),
    [updateUserAccountSuccess]: (state, { payload }) => ({
      ...state,
      isChangeAccountSuccess: true,
      isChangeAccountFailure: false,
      userAccount: payload,
    }),
    [updateUserAccountFailure]: (state) => ({
      ...state,
      isChangeAccountSuccess: false,
      isChangeAccountFailure: true,
    }),
    // check user session status
    [checkUserSessionStatusRequest]: (state) => ({
      ...state,
      isCheckUserSessionStatusSuccess: false,
      isCheckUserSessionStatusError: false,
    }),
    [checkUserSessionStatusSuccess]: (state, { payload }) => ({
      ...state,
      sessionTimeToExpire: payload.time_to_expiry,
      sessionStatus: payload.status,
      isCheckUserSessionStatusSuccess: true,
      isCheckUserSessionStatusError: false,
    }),
    [checkUserSessionStatusFailure]: (state) => ({
      ...state,
      isCheckUserSessionStatusSuccess: false,
      isCheckUserSessionStatusError: true,
    }),
    // Lock user
    [lockUserRequest]: (state) => ({
      ...state,
      isLockUserSuccess: false,
      isLockUserFailure: false,
    }),
    [lockUserSuccess]: (state) => ({
      ...state,
      isLockUserSuccess: true,
      isLockUserFailure: false,
    }),
    [lockUserFailure]: (state) => ({
      ...state,
      isLockUserSuccess: false,
      isLockUserFailure: true,
    }),
    // Unlock user
    [unlockUserRequest]: (state) => ({
      ...state,
      isUnlockUserSuccess: false,
      isUnlockUserFailure: false,
    }),
    [unlockUserSuccess]: (state) => ({
      ...state,
      isUnlockUserSuccess: true,
      isUnlockUserFailure: false,
    }),
    [unlockUserFailure]: (state) => ({
      ...state,
      isUnlockUserSuccess: false,
      isUnlockUserFailure: true,
    }),
  },
  initialState
);

export default reducer;
