import { JSONPath } from "jsonpath-plus";

/**
 * Extract values from data using JSONPath
 * @param {Array} data Array of objects to extract from
 * @param {String} jsonPath JSONPath expression
 * @returns {Array} Extracted values
 */
export const extractJSONPathValues = (data, jsonPath) => {
  return data
    .map((item) => {
      try {
        return JSONPath({ path: jsonPath, json: item, wrap: false });
      } catch {
        return null;
      }
    })
    .filter((val) => val !== null && val !== undefined);
};

/**
 * Aggregate values based on selected aggregation type
 * @param {Array} values Array of numeric values
 * @param {String} aggregation Aggregation type (sum, average, min, max, count, quartile, decile)
 * @returns {*} Aggregated value or array based on aggregation type
 */
export const aggregateValues = (values, aggregation) => {
  switch (aggregation) {
    case "count":
      return values.length;
    case "sum":
      return values.reduce((acc, val) => acc + val, 0);
    case "average":
      return values.length > 0
        ? values.reduce((acc, val) => acc + val, 0) / values.length
        : 0;
    case "min":
      return values.length > 0 ? Math.min(...values) : 0;
    case "max":
      return values.length > 0 ? Math.max(...values) : 0;
    case "quartile":
      return calculateQuartiles(values.map(Number));
    case "decile":
      return calculateDeciles(values.map(Number));
    case "auc":
    case "gini":
    case "roc":
    case "distribution_shift":
      // This is handled specially in generateComparisonCharts
      return values;
    default:
      return null;
  }
};

/**
 * Calculate quartiles for statistics
 * @param {Array} values Array of numeric values
 * @returns {Array} Array containing [Q1, Q2, Q3]
 */
export const calculateQuartiles = (values) => {
  if (!values.length) return [0, 0, 0];
  const sorted = [...values].sort((a, b) => a - b);
  const q1Index = Math.floor(sorted.length * 0.25);
  const q2Index = Math.floor(sorted.length * 0.5);
  const q3Index = Math.floor(sorted.length * 0.75);
  return [sorted[q1Index], sorted[q2Index], sorted[q3Index]];
};

/**
 * Calculate deciles for statistics
 * @param {Array} values Array of numeric values
 * @returns {Array} Array containing the 9 decile values
 */
export const calculateDeciles = (values) => {
  if (!values.length) return Array(9).fill(0);
  const sorted = [...values].sort((a, b) => a - b);
  const deciles = [];
  for (let i = 1; i <= 9; i++) {
    const index = Math.floor((i / 10) * sorted.length);
    deciles.push(sorted[index]);
  }
  return deciles;
};

/**
 * Calculate ROC curve points for binary classification
 * @param {Array} scores Array of predicted scores/probabilities
 * @param {Array} labels Array of actual binary labels (0/1)
 * @returns {Object} Object containing points, AUC and Gini coefficient
 */
export const calculateROCPoints = (scores, labels) => {
  if (!scores || !labels || scores.length !== labels.length || !scores.length) {
    return { points: [], auc: 0, gini: 0 };
  }

  // Convert all values to numbers
  const numericScores = scores.map(Number);
  const numericLabels = labels.map(Number);

  // Create array of score-label pairs and sort by score in descending order
  const pairs = numericScores
    .map((score, idx) => ({ score, label: numericLabels[idx] }))
    .sort((a, b) => b.score - a.score);

  // Calculate total positive and negative cases
  const totalPositives = numericLabels.filter((label) => label === 1).length;
  const totalNegatives = numericLabels.length - totalPositives;

  if (totalPositives === 0 || totalNegatives === 0) {
    return { points: [], auc: 0, gini: 0 };
  }

  // Initialize counters
  let truePositives = 0;
  let falsePositives = 0;

  // Calculate ROC points (including 0,0 and 1,1)
  const points = [{ x: 0, y: 0 }]; // Start with (0,0)

  let prevScore = null;
  let tempTP = 0;
  let tempFP = 0;

  // Process each prediction
  for (let i = 0; i < pairs.length; i++) {
    const { score, label } = pairs[i];

    // If score changes or we're at the end, add the accumulated point
    if (prevScore !== null && (score !== prevScore || i === pairs.length - 1)) {
      const tpr = truePositives / totalPositives;
      const fpr = falsePositives / totalNegatives;
      points.push({ x: fpr, y: tpr });
    }

    // Update counters
    if (label === 1) {
      truePositives++;
    } else {
      falsePositives++;
    }

    prevScore = score;
  }

  // Add final point (1,1)
  points.push({ x: 1, y: 1 });

  // Calculate AUC using trapezoidal rule
  let auc = 0;
  for (let i = 1; i < points.length; i++) {
    auc +=
      ((points[i].x - points[i - 1].x) * (points[i].y + points[i - 1].y)) / 2;
  }

  // Calculate Gini coefficient
  const gini = 2 * auc - 1;

  return { points, auc, gini };
};

/**
 * Calculate AUC (Area Under the ROC Curve)
 * @param {Array} scores Array of predicted scores/probabilities
 * @param {Array} labels Array of actual binary labels (0/1)
 * @returns {Number} AUC value between 0 and 1
 */
export const calculateAUC = (scores, labels) => {
  const { auc } = calculateROCPoints(scores, labels);
  return auc;
};

/**
 * Calculate Gini coefficient from AUC
 * @param {Array} scores Array of predicted scores/probabilities
 * @param {Array} labels Array of actual binary labels (0/1)
 * @returns {Number} Gini coefficient between -1 and 1
 */
export const calculateGini = (scores, labels) => {
  const { gini } = calculateROCPoints(scores, labels);
  return gini;
};

/**
 * Group data by dimension
 * @param {Array} metricValues Array of metric values
 * @param {Array} dimensionValues Array of dimension values
 * @returns {Object} Object with dimensions as keys and arrays of values as values
 */
export const groupDataByDimension = (metricValues, dimensionValues) => {
  const groupedData = {};
  for (let i = 0; i < metricValues.length; i++) {
    const dim = dimensionValues[i];
    if (dim === null || dim === undefined) continue;
    const value = metricValues[i];
    if (!groupedData[dim]) groupedData[dim] = [];
    groupedData[dim].push(Number(value));
  }
  return groupedData;
};

/**
 * Generate chart options based on data and configuration
 * @param {Object} config Chart configuration
 * @param {Array} data Data array
 * @param {String} title Chart title
 * @param {String} colorTheme Chart color
 * @returns {Object} ECharts option object
 */
export const generateChartOptions = (
  config,
  data,
  title,
  colorTheme = "#003057"
) => {
  const { metricPath, dimensionPath, aggregation } = config;

  // Extract metric values
  const metricValues = extractJSONPathValues(data, metricPath);
  if (metricValues.length === 0) return null;

  // Handle special case for AUC, Gini, and ROC aggregations
  if (
    aggregation === "auc" ||
    aggregation === "gini" ||
    aggregation === "roc"
  ) {
    // For these metrics, we need both prediction scores (metric) and actual labels (dimension)
    if (!dimensionPath) {
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {},
        series: [
          {
            type: "gauge",
            data: [{ value: 0, name: aggregation }],
            detail: { formatter: "{value}" },
            color: [colorTheme],
          },
        ],
      };
    }

    // Extract dimension values (actual labels)
    const dimensionValues = extractJSONPathValues(data, dimensionPath);

    // If we don't have matching pairs, return null
    if (
      metricValues.length !== dimensionValues.length ||
      metricValues.length === 0
    ) {
      return null;
    }

    // Calculate ROC points, AUC, and Gini
    const { points, auc, gini } = calculateROCPoints(
      metricValues,
      dimensionValues
    );

    if (aggregation === "roc") {
      // Generate ROC curve chart
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            // Handle array data points (from line series)
            if (
              params.data &&
              Array.isArray(params.data) &&
              params.data.length >= 2
            ) {
              return (
                `False Positive Rate: ${params.data[0].toFixed(3)}<br/>` +
                `True Positive Rate: ${params.data[1].toFixed(3)}`
              );
            }

            // Handle marker area or line (special elements)
            if (params.name && params.name.includes("AUC")) {
              return params.name;
            }

            // Generic fallback
            return params.seriesName || "ROC Point";
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          name: "False Positive Rate",
          min: 0,
          max: 1,
          nameLocation: "middle",
          nameGap: 30,
        },
        yAxis: {
          type: "value",
          name: "True Positive Rate",
          min: 0,
          max: 1,
          nameLocation: "middle",
          nameGap: 30,
        },
        series: [
          {
            name: "ROC Curve",
            type: "line",
            smooth: true,
            symbol: "none",
            itemStyle: {
              color: colorTheme,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: colorTheme,
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 255, 255, 0.1)",
                  },
                ],
              },
              opacity: 0.3,
            },
            data: points.map((point) => [point.x, point.y]),
            markLine: {
              silent: true,
              lineStyle: {
                type: "dashed",
                color: "#999",
              },
              data: [
                [
                  {
                    coord: [0, 0],
                  },
                  {
                    coord: [1, 1],
                  },
                ],
              ],
            },
            markArea: {
              itemStyle: {
                color: "rgba(0, 0, 0, 0.05)",
              },
              data: [
                [
                  {
                    name: `AUC: ${auc.toFixed(3)}`,
                    coord: [1, 0],
                  },
                  {
                    coord: [0, 1],
                  },
                ],
              ],
            },
          },
        ],
      };
    } else if (aggregation === "auc") {
      // Display AUC as gauge
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {
          formatter: function (params) {
            return `${params.name}: ${params.value.toFixed(3)}`;
          },
        },
        series: [
          {
            type: "gauge",
            min: 0,
            max: 1,
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.5, "#ff4d4f"], // 0-0.5: Red
                  [0.7, "#faad14"], // 0.5-0.7: Yellow
                  [0.9, "#52c41a"], // 0.7-0.9: Green
                  [1, "#1890ff"], // 0.9-1.0: Blue
                ],
              },
            },
            pointer: {
              itemStyle: {
                color: "auto",
              },
            },
            data: [{ value: auc, name: "AUC" }],
            detail: {
              // Replace string template with function formatter
              formatter: function (value) {
                return value.toFixed(3);
              },
              valueAnimation: true,
              fontSize: 16,
            },
          },
        ],
      };
    } else if (aggregation === "gini") {
      // Display Gini as gauge
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {
          formatter: function (params) {
            return `${params.name}: ${params.value.toFixed(3)}`;
          },
        },
        series: [
          {
            type: "gauge",
            min: -1,
            max: 1,
            axisLine: {
              lineStyle: {
                width: 30,
                color: [
                  [0.0, "#ff4d4f"], // -1.0-0.0: Red
                  [0.5, "#faad14"], // 0.0-0.5: Yellow
                  [0.8, "#52c41a"], // 0.5-0.8: Green
                  [1.0, "#1890ff"], // 0.8-1.0: Blue
                ],
              },
            },
            pointer: {
              itemStyle: {
                color: "auto",
              },
            },
            data: [{ value: gini, name: "Gini" }],
            detail: {
              // Replace string template with function formatter
              formatter: function (value) {
                return value.toFixed(3);
              },
              fontSize: 16,
            },
          },
        ],
      };
    }
  }

  // If no dimension, calculate global aggregation
  if (!dimensionPath) {
    const aggregatedValue = aggregateValues(
      metricValues.map(Number),
      aggregation
    );

    // If quartile or decile, generate different chart type
    if (aggregation === "quartile") {
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: ["Q1", "Q2 (Median)", "Q3"],
        },
        yAxis: { type: "value" },
        series: [
          {
            data: aggregatedValue,
            type: "bar",
            color: colorTheme,
          },
        ],
      };
    } else if (aggregation === "decile") {
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {},
        xAxis: {
          type: "category",
          data: Array.from({ length: 9 }, (_, i) => `D${i + 1}`),
        },
        yAxis: { type: "value" },
        series: [
          {
            data: aggregatedValue,
            type: "line",
            smooth: true,
            color: colorTheme,
          },
        ],
      };
    } else {
      return {
        title: {
          text: title,
          left: "center",
          textStyle: { fontSize: 14 },
        },
        tooltip: {},
        series: [
          {
            type: "gauge",
            data: [{ value: aggregatedValue, name: aggregation }],
            detail: { formatter: "{value}" },
            color: [colorTheme],
          },
        ],
      };
    }
  }

  // If dimension provided, group by dimension
  const dimensionValues = extractJSONPathValues(data, dimensionPath);

  // Group data by dimension
  const groupedData = groupDataByDimension(metricValues, dimensionValues);

  // Aggregate values for each dimension
  const categories = Object.keys(groupedData);

  if (aggregation === "quartile" || aggregation === "decile") {
    const series = categories.map((cat) => {
      const values = aggregateValues(groupedData[cat], aggregation);
      return {
        name: cat,
        type: aggregation === "quartile" ? "bar" : "line",
        data: values,
        smooth: aggregation === "decile",
      };
    });

    return {
      title: {
        text: title,
        left: "center",
        textStyle: { fontSize: 14 },
      },
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          return params
            .map((param) => `${param.seriesName}: ${param.value.toFixed(2)}`)
            .join("<br/>");
        },
      },
      legend: {
        data: categories,
        top: 30,
      },
      xAxis: {
        type: "category",
        data:
          aggregation === "quartile"
            ? ["Q1", "Q2 (Median)", "Q3"]
            : Array.from({ length: 9 }, (_, i) => `D${i + 1}`),
        name: aggregation === "quartile" ? "Quartile" : "Decile",
      },
      yAxis: {
        type: "value",
        name: "Value",
      },
      series: series,
      color: [colorTheme, colorTheme === "#003057" ? "#005C42" : "#003057"],
    };
  } else {
    const values = categories.map((cat) =>
      aggregateValues(groupedData[cat], aggregation)
    );

    return {
      title: {
        text: title,
        left: "center",
        textStyle: { fontSize: 14 },
      },
      tooltip: {},
      xAxis: {
        type: "category",
        data: categories,
        axisLabel: {
          rotate: 45,
          interval: 0,
        },
      },
      yAxis: {
        type: "value",
      },
      grid: {
        bottom: 100,
      },
      series: [
        {
          data: values,
          type: "bar",
          color: colorTheme,
        },
      ],
    };
  }
};

/**
 * Generate comparison charts for impact analysis
 * @param {Object} slice Analytics slice configuration
 * @param {Array} baselineData Baseline data array
 * @param {Array} comparisonData Comparison data array
 * @param {Function} t Translation function
 * @returns {Object} Object with baseline and comparison chart options
 */
export const generateComparisonCharts = (
  slice,
  baselineData,
  comparisonData,
  t
) => {
  const { metric: path, dimension, aggregation, name } = slice;

  if (!path) return null;

  // Special handling for distribution shift
  if (aggregation === "distribution_shift") {
    // Extract metric values from both datasets
    const baselineScores = extractJSONPathValues(baselineData, path);
    const comparisonScores = extractJSONPathValues(comparisonData, path);

    // Make sure we have matching data
    if (
      baselineScores.length !== comparisonScores.length ||
      baselineScores.length === 0
    ) {
      return null;
    }

    // Calculate distribution shift
    const { nodes, links } = calculateDistributionShift(
      baselineScores,
      comparisonScores
    );

    // Create a single Sankey diagram
    const shiftChart = {
      title: {
        text: `${name || path} - ${t("distribution_shift")}`,
        left: "center",
        textStyle: { fontSize: 14 },
      },
      tooltip: {
        trigger: "item",
        formatter: "{c0}",
      },
      series: [
        {
          type: "sankey",
          data: nodes,
          links: links,
          emphasis: {
            focus: "adjacency",
          },
          lineStyle: {
            color: "gradient",
            curveness: 0.5,
          },
        },
      ],
    };

    return {
      name: `${name || path} - ${t("distribution_shift")}`,
      isFullWidth: true, // Signal that this should take full width
      chart: shiftChart,
    };
  }

  // Process regular charts as before
  const baselineChart = generateChartOptions(
    { metricPath: path, dimensionPath: dimension, aggregation },
    baselineData,
    `${t("baseline")} - ${name || path}`,
    "#003057" // Blue for baseline
  );

  const comparisonChart = generateChartOptions(
    { metricPath: path, dimensionPath: dimension, aggregation },
    comparisonData,
    `${t("comparison")} - ${name || path}`,
    "#005C42" // Green for comparison
  );

  if (baselineChart && comparisonChart) {
    return {
      name: name || path,
      baseline: baselineChart,
      comparison: comparisonChart,
    };
  }

  return null;
};

/**
 * Calculate distribution shift between baseline and comparison scores
 * @param {Array} baselineScores Array of scores from baseline data
 * @param {Array} comparisonScores Array of corresponding scores from comparison data
 * @returns {Object} Object containing migration data for visualization
 */
export const calculateDistributionShift = (
  baselineScores,
  comparisonScores
) => {
  if (
    !baselineScores ||
    !comparisonScores ||
    baselineScores.length !== comparisonScores.length
  ) {
    return { nodes: [], links: [] };
  }

  // Helper to determine decile bucket (0-9) for a score
  const getDecileBucket = (score) => {
    const normalizedScore = Math.max(0, Math.min(0.999999, score)); // Ensure it's between 0 and <1
    return Math.floor(normalizedScore * 10); // 0 to 9
  };

  // Initialize migration matrix (10x10 for decile transitions)
  const migrationMatrix = Array(10)
    .fill()
    .map(() => Array(10).fill(0));

  // Count transitions
  for (let i = 0; i < baselineScores.length; i++) {
    const baselineBucket = getDecileBucket(Number(baselineScores[i]));
    const comparisonBucket = getDecileBucket(Number(comparisonScores[i]));
    migrationMatrix[baselineBucket][comparisonBucket]++;
  }

  // Prepare data for Sankey diagram
  const nodes = [];
  // Add source nodes (baseline deciles)
  for (let i = 0; i < 10; i++) {
    nodes.push({
      name: `Baseline ${i / 10}-${(i + 1) / 10}`,
      itemStyle: { color: "#003057" },
    });
  }
  // Add target nodes (comparison deciles)
  for (let i = 0; i < 10; i++) {
    nodes.push({
      name: `Comparison ${i / 10}-${(i + 1) / 10}`,
      itemStyle: { color: "#005C42" },
    });
  }

  // Create links between nodes based on migration data
  const links = [];
  for (let source = 0; source < 10; source++) {
    for (let target = 0; target < 10; target++) {
      if (migrationMatrix[source][target] > 0) {
        links.push({
          source: source,
          target: target + 10, // Offset for comparison nodes
          value: migrationMatrix[source][target],
        });
      }
    }
  }

  return { nodes, links };
};
