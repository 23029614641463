import { createAction } from "redux-actions";

export const getScorecardsRequest = createAction("GET_SCORECARDS_REQUEST");
export const getScorecardsSuccess = createAction("GET_SCORECARDS_SUCCESS");
export const getScorecardsFailure = createAction("GET_SCORECARDS_FAILURE");

export const getScorecardByIdRequest = createAction(
  "GET_SCORECARD_BY_ID_REQUEST"
);
export const getScorecardByIdSuccess = createAction(
  "GET_SCORECARD_BY_ID_SUCCESS"
);
export const getScorecardByIdFailure = createAction(
  "GET_SCORECARD_BY_ID_FAILURE"
);

export const createScorecardRequest = createAction("CREATE_SCORECARD_REQUEST");
export const createScorecardSuccess = createAction("CREATE_SCORECARD_SUCCESS");
export const createScorecardFailure = createAction("CREATE_SCORECARD_FAILURE");

export const updateScorecardRequest = createAction("UPDATE_SCORECARD_REQUEST");
export const updateScorecardSuccess = createAction("UPDATE_SCORECARD_SUCCESS");
export const updateScorecardFailure = createAction("UPDATE_SCORECARD_FAILURE");

export const deleteScorecardRequest = createAction("DELETE_SCORECARD_REQUEST");
export const deleteScorecardSuccess = createAction("DELETE_SCORECARD_SUCCESS");
export const deleteScorecardFailure = createAction("DELETE_SCORECARD_FAILURE");

export const getRunTestResultRequest = createAction(
  "GET_RUN_TEST_SCORECARD_RESULT_REQUEST"
);
export const getRunTestResultSuccess = createAction(
  "GET_RUN_TEST_SCORECARD_RESULT_SUCCESS"
);
export const getRunTestResultFailure = createAction(
  "GET_RUN_TEST_SCORECARD_RESULT_FAILURE"
);

export const getTestScorecardRequest = createAction(
  "GET_TEST_SCORECARD_REQUEST"
);
export const getTestScorecardSuccess = createAction(
  "GET_TEST_SCORECARD_SUCCESS"
);
export const getTestScorecardFailure = createAction(
  "GET_TEST_SCORECARD_FAILURE"
);

export const getScorecardRevisionsRequest = createAction(
  "GET_SCORECARD_REVISIONS_REQUEST"
);
export const getScorecardRevisionsSuccess = createAction(
  "GET_SCORECARD_REVISIONS_SUCCESS"
);
export const getScorecardRevisionsFailure = createAction(
  "GET_SCORECARD_REVISIONS_FAILURE"
);

export const getScorecardRevisionRequest = createAction(
  "GET_SCORECARD_REVISION_REQUEST"
);
export const getScorecardRevisionSuccess = createAction(
  "GET_SCORECARD_REVISION_SUCCESS"
);
export const getScorecardRevisionFailure = createAction(
  "GET_SCORECARD_REVISION_FAILURE"
);

export const getScorecardRevision2Request = createAction(
  "GET_SCORECARD_REVISION2_REQUEST"
);
export const getScorecardRevision2Success = createAction(
  "GET_SCORECARD_REVISION2_SUCCESS"
);
export const getScorecardRevision2Failure = createAction(
  "GET_SCORECARD_REVISION2_FAILURE"
);

export const getRunTestResultStatusRequest = createAction(
  "GET_RUN_TEST_SCORECARD_RESULT_STATUS_REQUEST"
);
export const getRunTestResultStatusSuccess = createAction(
  "GET_RUN_TEST_SCORECARD_RESULT_STATUS_SUCCESS"
);
export const getRunTestResultStatusFailure = createAction(
  "GET_RUN_TEST_SCORECARD_RESULT_STATUS_FAILURE"
);

export const getScorecardExplanationRequest = createAction(
  "GET_SCORECARD_EXPLANATION_REQUEST"
);

export const getScorecardExplanationSuccess = createAction(
  "GET_SCORECARD_EXPLANATION_SUCCESS"
);

export const getScorecardExplanationFailure = createAction(
  "GET_SCORECARD_EXPLANATION_FAILURE"
);
//workflows
export const getScorecardWorkflowsRequest = createAction(
  "GET_SCORECARD_WORKFLOWS_REQUEST"
);
export const getScorecardWorkflowsSuccess = createAction(
  "GET_SCORECARD_WORKFLOWS_SUCCESS"
);
export const getScorecardWorkflowsFailure = createAction(
  "GET_SCORECARD_WORKFLOWS_FAILURE"
);
