import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { DecisionTreeContext } from "context/DecisionTreeContext";

const NodeSplitCondition = ({
  nodeId,
  condition,
  handleConditionChange,
  handleConditionDelete,
  isReadOnly = false,
}) => {
  const [valueA, setValueA] = useState(condition.value_a);
  const [valueB, setValueB] = useState(condition.value_b);

  const { t } = useTranslation();

  // Get operators and operator symbols from context
  const { operators, operatorSymbols } = useContext(DecisionTreeContext);

  useEffect(() => {
    setValueA(condition.value_a);
    setValueB(condition.value_b);
  }, [condition]);

  const handleValueChange = (newValue, key) => {
    const isNumberRequired = [
      "greater_than",
      "greater_than_or_equal_to",
      "less_than",
      "less_than_or_equal_to",
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(condition.condition_type);

    if (isNumberRequired) {
      const reg = /^-?\d*(\.\d*)?$/;
      if (!reg.test(newValue) && newValue !== "") {
        return; // Prevent non-numeric input
      }
    }

    if (key === "value_a") {
      setValueA(newValue);
    } else if (key === "value_b") {
      setValueB(newValue);
    }

    handleConditionChange(nodeId, condition.decision_tree_node_condition_id, {
      ...condition,
      [key]: newValue,
    });
  };

  const handleBlur = (e, key) => {
    const value = e.target.value;
    const isNumberRequired = [
      "greater_than",
      "greater_than_or_equal_to",
      "less_than",
      "less_than_or_equal_to",
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(condition.condition_type);

    if (isNumberRequired) {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        handleValueChange(numericValue.toString(), key);
      } else {
        handleValueChange("", key);
      }
    } else {
      handleValueChange(value, key);
    }
  };

  const handleOperatorChange = (newOperator) => {
    handleConditionChange(nodeId, condition.decision_tree_node_condition_id, {
      ...condition,
      condition_type: newOperator,
    });
  };

  const renderCondition = (text) => (
    <ContextMenuTrigger
      id={`condition-${condition.decision_tree_node_condition_id}`}
      mouseButton={2}
    >
      <span className="input-group-text border-0 rounded-0 m-0">{text}</span>
    </ContextMenuTrigger>
  );

  const renderConditionInput = () => {
    const isInterval = [
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(condition.condition_type);

    const operatorSymbols = {
      greater_than: ">",
      greater_than_or_equal_to: "≥",
      less_than: "<",
      less_than_or_equal_to: "≤",
      equal_to: "=",
      not_equal_to: "≠",
      closed_interval: "[",
      open_interval: "(",
      left_closed_right_open: "[",
      left_open_right_closed: "(",
      is_null: "∅",
      starts_with: "^",
      ends_with: "$",
    };

    const isNumberInput = [
      "greater_than",
      "greater_than_or_equal_to",
      "less_than",
      "less_than_or_equal_to",
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(condition.condition_type);

    const renderInputField = (value, key) => (
      <input
        type="text"
        className="form-control border-0 m-0 border-0 rounded-0"
        value={value || ""}
        disabled={isReadOnly}
        onChange={(e) => handleValueChange(e.target.value, key)}
        onBlur={(e) => handleBlur(e, key)}
        inputMode={isNumberInput ? "numeric" : "text"}
      />
    );

    return condition.condition_type === "any_value" ||
      condition.condition_type === "is_null" ? (
      <div className="text-center align-middle any_value w-100 outline-none border-0 rounded-0">
        {t(condition.condition_type)}
      </div>
    ) : condition.condition_type === "ends_with" ? (
      <div className="input-group d-flex justify-content-between border-0 rounded-0">
        {renderInputField(valueA, "value_a")}
        {renderCondition(operatorSymbols[condition.condition_type] || "")}
      </div>
    ) : (
      <div className="input-group d-flex justify-content-between border-0 rounded-0">
        {renderCondition(operatorSymbols[condition.condition_type] || "")}
        {renderInputField(valueA, "value_a")}
        {isInterval && (
          <>
            <span className="input-group-text border-0 rounded-0">,</span>
            {renderInputField(valueB, "value_b")}
            <span className="input-group-text border-0 rounded-0">
              {condition.condition_type === "open_interval" ||
              condition.condition_type === "left_closed_right_open"
                ? ")"
                : "]"}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderContextMenu = () =>
    !isReadOnly && (
      <ContextMenu
        id={`condition-${condition.decision_tree_node_condition_id}`}
      >
        {operators.map((op) => (
          <MenuItem key={op} onClick={() => handleOperatorChange(op)}>
            {t(op)}
          </MenuItem>
        ))}
      </ContextMenu>
    );

  return (
    <>
      <div className="row align-items-center">
        <div className="col-10">
          <ContextMenuTrigger
            id={`condition-${condition.decision_tree_node_condition_id}`}
            holdToDisplay={-1}
          >
            {renderConditionInput()}
          </ContextMenuTrigger>
          {renderContextMenu()}
        </div>
        <div className="col-2 text-right">
          {!isReadOnly && (
            <button
              className="border-radius-4 border-0 btn btn-outline-light mr-3 option-delete"
              type="button"
              title={t("Delete")}
              onClick={() =>
                handleConditionDelete(
                  nodeId,
                  condition.decision_tree_node_condition_id
                )
              }
            >
              <TrashIcon
                style={{
                  color: "#6C757D",
                }}
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

NodeSplitCondition.propTypes = {
  condition: PropTypes.shape({
    decision_tree_node_condition_id: PropTypes.any,
    value_a: PropTypes.string,
    value_b: PropTypes.string,
    condition_type: PropTypes.string.isRequired,
  }).isRequired,
  nodeId: PropTypes.any.isRequired,
  handleConditionChange: PropTypes.func.isRequired,
  handleConditionDelete: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default NodeSplitCondition;
