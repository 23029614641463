import { createAction } from "redux-actions";

// Fetching a list of decision trees
export const fetchDecisionTreesRequest = createAction(
  "FETCH_DECISION_TREES_REQUEST"
);
export const fetchDecisionTreesSuccess = createAction(
  "FETCH_DECISION_TREES_SUCCESS"
);
export const fetchDecisionTreesFailure = createAction(
  "FETCH_DECISION_TREES_FAILURE"
);

// Fetching a single decision tree by its ID
export const fetchDecisionTreeRequest = createAction(
  "FETCH_DECISION_TREE_REQUEST"
);
export const fetchDecisionTreeSuccess = createAction(
  "FETCH_DECISION_TREE_SUCCESS"
);
export const fetchDecisionTreeFailure = createAction(
  "FETCH_DECISION_TREE_FAILURE"
);

// Creating a new decision tree
export const createDecisionTreeRequest = createAction(
  "CREATE_DECISION_TREE_REQUEST"
);
export const createDecisionTreeSuccess = createAction(
  "CREATE_DECISION_TREE_SUCCESS"
);
export const createDecisionTreeFailure = createAction(
  "CREATE_DECISION_TREE_FAILURE"
);

// Updating an existing decision tree
export const updateDecisionTreeRequest = createAction(
  "UPDATE_DECISION_TREE_REQUEST"
);
export const updateDecisionTreeSuccess = createAction(
  "UPDATE_DECISION_TREE_SUCCESS"
);
export const updateDecisionTreeFailure = createAction(
  "UPDATE_DECISION_TREE_FAILURE"
);

// Deleting an existing decision tree
export const deleteDecisionTreeRequest = createAction(
  "DELETE_DECISION_TREE_REQUEST"
);
export const deleteDecisionTreeSuccess = createAction(
  "DELETE_DECISION_TREE_SUCCESS"
);
export const deleteDecisionTreeFailure = createAction(
  "DELETE_DECISION_TREE_FAILURE"
);

export const getRunTestResultRequest = createAction(
  "GET_RUN_TEST_DECISION_TREE_RESULT_REQUEST"
);
export const getRunTestResultSuccess = createAction(
  "GET_RUN_TEST_DECISION_TREE_RESULT_SUCCESS"
);
export const getRunTestResultFailure = createAction(
  "GET_RUN_TEST_DECISION_TREE_RESULT_FAILURE"
);

export const getTestDecisionTreeRequest = createAction(
  "GET_TEST_DECISION_TREE_REQUEST"
);
export const getTestDecisionTreeSuccess = createAction(
  "GET_TEST_DECISION_TREE_SUCCESS"
);
export const getTestDecisionTreeFailure = createAction(
  "GET_TEST_DECISION_TREE_FAILURE"
);

export const getDecisionTreeRevisionsRequest = createAction(
  "GET_DECISION_TREE_REVISIONS_REQUEST"
);
export const getDecisionTreeRevisionsSuccess = createAction(
  "GET_DECISION_TREE_REVISIONS_SUCCESS"
);
export const getDecisionTreeRevisionsFailure = createAction(
  "GET_DECISION_TREE_REVISIONS_FAILURE"
);

export const getDecisionTreeRevisionRequest = createAction(
  "GET_DECISION_TREE_REVISION_REQUEST"
);
export const getDecisionTreeRevisionSuccess = createAction(
  "GET_DECISION_TREE_REVISION_SUCCESS"
);
export const getDecisionTreeRevisionFailure = createAction(
  "GET_DECISION_TREE_REVISION_FAILURE"
);

export const getRunTestResultStatusRequest = createAction(
  "GET_RUN_TEST_DECISION_TREE_RESULT_STATUS_REQUEST"
);
export const getRunTestResultStatusSuccess = createAction(
  "GET_RUN_TEST_DECISION_TREE_RESULT_STATUS_SUCCESS"
);
export const getRunTestResultStatusFailure = createAction(
  "GET_RUN_TEST_DECISION_TREE_RESULT_STATUS_FAILURE"
);

export const getRunTestObjectResultRequest = createAction(
  "GET_RUN_TEST_OBJECT_RESULT_REQUEST"
);
export const getRunTestObjectResultSuccess = createAction(
  "GET_RUN_TEST_OBJECT_RESULT_SUCCESS"
);
export const getRunTestObjectResultFailure = createAction(
  "GET_RUN_TEST_OBJECT_RESULT_FAILURE"
);
//workflows
export const getDecisionTreeWorkflowsRequest = createAction(
  "GET_DECISION_TREE_WORKFLOWS_REQUEST"
);
export const getDecisionTreeWorkflowsSuccess = createAction(
  "GET_DECISION_TREE_WORKFLOWS_SUCCESS"
);
export const getDecisionTreeWorkflowsFailure = createAction(
  "GET_DECISION_TREE_WORKFLOWS_FAILURE"
);
