import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import PropTypes from "prop-types";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import {
  createRuleSetRequest,
  deleteRuleActionRequest,
  deleteRuleConditionRequest,
  deleteRuleRequest,
  deleteRuleSetRequest,
  getConditionTypesRequest,
  getRuleRevisionsRequest,
  getRuleSetsRequest,
  getTestRuleSetRequest,
  updateRuleSetRequest,
} from "redux/rulesets/action";
import DeleteConfirm from "components/modals/DeleteConfirm";
import Rule from "components/rulesets/rule";
import RuleSetComponent from "components/rulesets/rule/RuleSet";
import Revisions from "components/modals/ruleSet/Revisions";
import { MainContext } from "context/contexts";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import RuleSetsModal from "components/modals/ruleSet/Rulesets";
import CreateNewItem from "components/modals/CreateNewItem";
import RuleSetOptions from "components/modals/ruleSet/Options";
import { ruleDataConvertJson } from "utility/utility";
import { useTranslation } from "react-i18next";
import ChatModal from "components/modals/ruleSet/ChatModal";

const newRule = {
  action: [],
  active: null,
  condition: [],
  name: null,
  rule_id: 0,
  title: null,
  type: null,
  order_index: null,
};

const newCondition = {
  attribute1_path: null,
  attribute1_value: "",
  attribute1_vector: 0,
  attribute2_path: null,
  attribute2_value: "",
  attribute2_vector: 0,
  condition_type_name: "greater_than",
  children: [],
};

const newAction = {
  action_path: "",
  action_type: "",
  action_value: null,
  action_vector: 0,
  attribute_path: "",
};

const RuleSetContent = ({
  ruleSetData,
  setRuleSetData,
  setIsTestRuleSetModalOpen,
  revisionMode = false,
}) => {
  const { setIsLoading, setIsEdited, isEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    ruleRevisions,
    conditionTypes,
    isGetConditionTypesSuccess,
    isGetConditionTypesError,
    isGetRuleRevisionsSuccess,
    isGetRuleRevisionsError,
    isRemovedRuleSetSuccess,
    isRemovedRuleSetError,
    isUpdateRuleSetSuccess,
    isUpdateRuleSetError,
    isRemovedRuleSuccess,
    isRemovedRuleError,
    isRemovedRuleConditionSuccess,
    isRemovedRuleConditionError,
    isRemovedRuleActionSuccess,
    isRemovedRuleActionError,
    isCreatedRuleSetSuccess,
    isCreatedRuleSetError,
    newRuleSetId,
  } = useSelector((state) => state.ruleSets);

  const prevIsUpdateRuleSetSuccess = usePrevious(isUpdateRuleSetSuccess);
  const prevIsRemovedRuleSuccess = usePrevious(isRemovedRuleSuccess);
  const prevIsRemovedRuleSetSuccess = usePrevious(isRemovedRuleSetSuccess);
  const prevIsGetRuleRevisionsSuccess = usePrevious(isGetRuleRevisionsSuccess);
  const prevIsGetConditionTypesError = usePrevious(isGetConditionTypesError);
  const prevIsGetRuleRevisionsError = usePrevious(isGetRuleRevisionsError);
  const prevIsRemovedRuleSetError = usePrevious(isRemovedRuleSetError);
  const prevIsUpdateRuleSetError = usePrevious(isUpdateRuleSetError);
  const prevIsRemovedRuleError = usePrevious(isRemovedRuleError);
  const prevIsRemovedRuleConditionError = usePrevious(
    isRemovedRuleConditionError
  );
  const prevIsRemovedRuleConditionSuccess = usePrevious(
    isRemovedRuleConditionSuccess
  );
  const prevIsRemovedRuleActionError = usePrevious(isRemovedRuleActionError);
  const prevIsRemovedRuleActionSuccess = usePrevious(
    isRemovedRuleActionSuccess
  );
  const prevIsGetConditionTypesSuccess = usePrevious(
    isGetConditionTypesSuccess
  );
  const prevIsCreatedRuleSetSuccess = usePrevious(isCreatedRuleSetSuccess);
  const prevIsCreatedRuleSetError = usePrevious(isCreatedRuleSetError);

  const [ruleRevisionsData, setRuleRevisionsData] = useState([]);
  const [activeConditionId, setActiveConditionId] = useState({});
  const [isRuleRevisionsModalOpen, setIsRuleRevisionsModalOpen] =
    useState(false);
  const [selectedRule, setSelectedRule] = useState({});
  const [conditionTypesData, setConditionTypesData] = useState([]);
  const [activeRuleId, setActiveRuleId] = useState({});
  const [activeActionId, setActiveActionId] = useState({});
  const [isRuleDeleteModalOpen, setIsRuleDeleteModalOpen] = useState(false);
  const [rulesData, setRulesData] = useState([]);
  const [isRuleConditionDeleteModalOpen, setIsRuleConditionDeleteModalOpen] =
    useState(false);
  const [isRuleActionDeleteModalOpen, setIsRuleActionDeleteModalOpen] =
    useState(false);
  const [isRuleSetDeleteModalOpen, setIsRuleSetDeleteModalOpen] =
    useState(false);
  const [isRuleSetsModalOpen, setIsRuleSetsModalOpen] = useState(false);
  const [isCreateNewRevisionOpen, setIsCreateNewRevisionOpen] = useState(false);
  const [isRenderedRules, setIsRenderedRules] = useState(false);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);

  const [isChatModalOpen, setIsChatModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setIsRenderedRules(true);
    dispatch(getConditionTypesRequest(id));

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (!id && ruleSetData.rules.length < 1) {
      addRule();
    }
  }, []);

  useEffect(() => {
    if (ruleSetData && Object.keys(ruleSetData).length > 0) {
      setIsRenderedRules(false);
      if (!revisionMode) {
        setRulesData(structuredClone(ruleSetData.rules));
      }
    }
  }, [ruleSetData]);

  useEffect(() => {
    if (isCreatedRuleSetSuccess && prevIsCreatedRuleSetSuccess === false) {
      navigate(`/rule-sets/${newRuleSetId}`);
    }
  }, [isCreatedRuleSetSuccess]);

  useEffect(() => {
    if (
      (isGetConditionTypesError && prevIsGetConditionTypesError === false) ||
      (isGetRuleRevisionsError && prevIsGetRuleRevisionsError === false) ||
      (isRemovedRuleSetError && prevIsRemovedRuleSetError === false) ||
      (isUpdateRuleSetError && prevIsUpdateRuleSetError === false) ||
      (isRemovedRuleError && prevIsRemovedRuleError === false) ||
      (isRemovedRuleConditionError &&
        prevIsRemovedRuleConditionError === false) ||
      (isRemovedRuleActionError && prevIsRemovedRuleActionError === false) ||
      (isCreatedRuleSetError && prevIsCreatedRuleSetError === false)
    ) {
      if (isRemovedRuleError) {
        handleClose();
      } else if (isRemovedRuleConditionError) {
        handleCloseCondition();
      } else if (isRemovedRuleActionError) {
        handleCloseAction();
      }
      setIsLoading(false);
      setIsCreateNewRevisionOpen(false);
      setIsOptionsModalOpen(false);
    }
  }, [
    isCreatedRuleSetError,
    isGetConditionTypesError,
    isGetRuleRevisionsError,
    isRemovedRuleSetError,
    isUpdateRuleSetError,
    isRemovedRuleError,
    isRemovedRuleConditionError,
    isRemovedRuleActionError,
  ]);

  useEffect(() => {
    if (
      isGetConditionTypesSuccess &&
      prevIsGetConditionTypesSuccess === false
    ) {
      setIsLoading(false);
      let data = structuredClone(Object.values(conditionTypes));
      data = data.filter((item) => {
        return (item.title = item.title.replace("&ne;", "≠"));
      });

      setConditionTypesData(data);
    }
  }, [isGetConditionTypesSuccess]);

  useEffect(() => {
    if (isGetRuleRevisionsSuccess && prevIsGetRuleRevisionsSuccess === false) {
      setIsLoading(false);
      setRuleRevisionsData(structuredClone(ruleRevisions));
    }
  }, [isGetRuleRevisionsSuccess]);

  useEffect(() => {
    if (isUpdateRuleSetSuccess && prevIsUpdateRuleSetSuccess === false) {
      setIsLoading(false);
      toast.success(t("rule_changes_saved"), ToastOptions);
    }
  }, [isUpdateRuleSetSuccess]);

  useEffect(() => {
    if (isRemovedRuleSuccess && prevIsRemovedRuleSuccess === false) {
      removeRule();
      handleClose();
      setIsLoading(false);
      toast.warning(t("rule_deleted"), ToastOptions);
    }
  }, [isRemovedRuleSuccess]);

  useEffect(() => {
    if (isRemovedRuleSetSuccess && prevIsRemovedRuleSetSuccess === false) {
      setIsLoading(false);
      removeRule(true);
      handleClose();
    }
  }, [isRemovedRuleSetSuccess]);

  useEffect(() => {
    if (
      isRemovedRuleConditionSuccess &&
      prevIsRemovedRuleConditionSuccess === false
    ) {
      toast.warning(t("rule_condition_deleted"), ToastOptions);
      setIsLoading(false);
      removeRuleCondition();
      handleCloseCondition();
    }
  }, [isRemovedRuleConditionSuccess]);

  useEffect(() => {
    if (
      isRemovedRuleActionSuccess &&
      prevIsRemovedRuleActionSuccess === false
    ) {
      toast.warning(t("rule_action_deleted"), ToastOptions);
      setIsLoading(false);
      handleCloseAction();
    }
  }, [isRemovedRuleActionSuccess]);

  const addRule = () => {
    const newData = [...ruleSetData.rules];
    const newRuleObject = { ...newRule };
    newRuleObject.rule_id = `new_${Math.round(Math.random() * 10000)}`;
    newRuleObject.is_new = true;
    newRuleObject.order_index = newData.length + 1;
    newData.push(newRuleObject);
    ruleSetData.rules = [...newData];
    setIsEdited(true);
    setRuleSetData({ ...ruleSetData });
  };

  const removeRule = (isRuleSet) => {
    if (!isRuleSet) {
      const ruleSetRulesData = [...ruleSetData.rules];
      const rulesData = ruleSetRulesData.filter(
        (rule) => rule.rule_id !== selectedRule.rule_id
      );
      setIsEdited(true);
      setRuleSetData({ ...ruleSetData, rules: rulesData });
    } else {
      toast.warning(t("ruleset_deleted"), ToastOptions);
      navigate("/rule-sets");
    }

    handleClose();
  };

  const handleShowDeleteModal = (e, selectedRuleInfo) => {
    if (selectedRuleInfo) {
      setSelectedRule(selectedRuleInfo);
      setIsRuleDeleteModalOpen(true);
    } else {
      setIsRuleSetDeleteModalOpen(true);
    }
  };

  const handleClose = () => {
    setSelectedRule({});
    setIsRuleDeleteModalOpen(false);
    setIsRuleSetDeleteModalOpen(false);
  };

  const handleConfirm = (isRuleSet = false) => {
    if (isRuleSet) {
      setIsEdited(false);
      setIsLoading(true);
      dispatch(deleteRuleSetRequest(parseInt(id)));
      setIsRuleSetDeleteModalOpen(false);
    } else {
      if (!selectedRule.is_new) {
        setIsLoading(true);
        dispatch(deleteRuleRequest([parseInt(id), selectedRule.rule_id]));
      } else {
        removeRule();
      }
    }
  };

  const addCondition = ({ rule_id, type, condition }) => {
    const newConditionData = { ...newCondition };
    newConditionData.rule_condition_id = `new_${Math.round(
      Math.random() * 10000
    )}`;
    const rulesCopy = structuredClone(ruleSetData);

    const ruleIndex = rulesCopy.rules.findIndex(
      (rule) => rule.rule_id === rule_id
    );

    if (!condition) {
      if (ruleIndex !== -1 && type !== "group") {
        if (!Array.isArray(rulesCopy.rules[ruleIndex].condition)) {
          rulesCopy.rules[ruleIndex].condition = [];
        }
        rulesCopy.rules[ruleIndex].condition = [
          ...rulesCopy.rules[ruleIndex].condition,
          newConditionData,
        ];
      } else if (ruleIndex !== -1 && type === "group") {
        if (!Array.isArray(rulesCopy.rules[ruleIndex].condition)) {
          rulesCopy.rules[ruleIndex].condition = [];
        }
        rulesCopy.rules[ruleIndex].condition = [
          ...rulesCopy.rules[ruleIndex].condition,
          {
            ...newConditionData,
            condition_type_name: type,
            children: [
              {
                ...newCondition,
                rule_condition_id: `new_${Math.round(Math.random() * 10000)}`,
                parent_condition_id: newConditionData.rule_condition_id,
                condition_type_name: "greater_than",
              },
            ],
          },
        ];
      }
    } else {
      const getCondition = rulesCopy.rules[ruleIndex].condition?.find(
        (cond) => cond.rule_condition_id === condition.rule_condition_id
      );
      if (getCondition) {
        if (!Array.isArray(getCondition.children)) {
          getCondition.children = [];
        }
        getCondition.children.push({
          ...newConditionData,
          parent_condition_id: getCondition.rule_condition_id,
        });
      } else {
        rulesCopy.rules[ruleIndex].condition.push(newConditionData);
      }
    }

    setIsEdited(true);
    setRuleSetData(rulesCopy);
  };

  const removeRuleCondition = () => {
    const rulesData = structuredClone(ruleSetData);
    const rule = rulesData.rules.find((rule) => rule.rule_id === activeRuleId);

    rule.condition.map((item) => {
      if (item.children?.length) {
        item.children = item.children.filter(
          (con) => con.rule_condition_id !== activeConditionId
        );
      }
    });
    rule.condition = rule.condition.filter(
      (condition) => condition.rule_condition_id !== activeConditionId
    );
    setIsEdited(true);
    setRuleSetData(rulesData);
  };

  const handleShowConditionDeleteModal = (ruleId, conditionId) => {
    setActiveRuleId(ruleId);
    setActiveConditionId(conditionId);
    setIsRuleConditionDeleteModalOpen(true);
  };

  const handleCloseCondition = () => {
    setIsRuleConditionDeleteModalOpen(false);
  };

  const handleConditionDeleteConfirm = () => {
    if (typeof activeConditionId === "number") {
      setIsLoading(true);
      dispatch(deleteRuleConditionRequest([parseInt(id), activeConditionId]));
    }
    removeRuleCondition();
    handleCloseCondition();
  };

  const addAction = (ruleId) => {
    const newActionData = { ...newAction };
    newActionData.rule_action_id = `new_${Math.round(Math.random() * 10000)}`;
    const rulesData = structuredClone(ruleSetData);
    const rule = rulesData.rules.find((rule) => rule.rule_id === ruleId);
    if (rule.action === null) {
      rule.action = [];
      rule.action.push(newActionData);
    } else {
      rule.action.push(newActionData);
    }
    setIsEdited(true);
    setRuleSetData(rulesData);
  };

  const removeRuleAction = () => {
    const rulesData = structuredClone(ruleSetData);
    const rule = rulesData.rules.find((rule) => rule.rule_id === activeRuleId);
    rule.action = rule.action.filter(
      (action) => action.rule_action_id !== activeActionId
    );
    setIsEdited(true);
    setRuleSetData(rulesData);
  };

  const handleShowActionDeleteModal = (ruleId, actionId) => {
    setActiveRuleId(ruleId);
    setActiveActionId(actionId);
    setIsRuleActionDeleteModalOpen(true);
  };

  const handleCloseAction = () => {
    setIsRuleActionDeleteModalOpen(false);
  };

  const handleActionDeleteConfirm = () => {
    if (typeof activeActionId === "number") {
      setIsLoading(true);
      dispatch(deleteRuleActionRequest([parseInt(id), activeActionId]));
    }
    removeRuleAction();
    handleCloseAction();
  };

  const handleShowRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getRuleRevisionsRequest(parseInt(id)));
    setIsRuleRevisionsModalOpen(true);
  };

  const handleShowTestRuleSetModal = () => {
    //if is edited, we will ask to save first
    if (isEdited) {
      return toast.error(t("please_save_ruleset_first"), ToastOptions);
    }

    setIsLoading(true);
    dispatch(getTestRuleSetRequest(parseInt(id)));
    setIsTestRuleSetModalOpen(true);
  };

  const handleCloseRevisions = () => {
    setIsRuleRevisionsModalOpen(false);
  };

  const handleShowRuleSetsModal = () => {
    dispatch(getRuleSetsRequest());
    setIsRuleSetsModalOpen(true);
  };

  const handleCloseRuleSets = () => {
    setIsRuleSetsModalOpen(false);
  };

  const handleImportRulesConfirm = (selectedRules) => {
    if (selectedRules.length > 0) {
      selectedRules.map((selectedRule) => {
        selectedRule.rule_id = `new_${Math.round(Math.random() * 10000)}`;
        selectedRule.is_new = true;
      });
      const cloneOfRuleSet = structuredClone(ruleSetData);
      cloneOfRuleSet.rules = cloneOfRuleSet.rules.concat(selectedRules);
      setIsEdited(true);
      setRuleSetData(cloneOfRuleSet);
      setIsRuleSetsModalOpen(false);
      toast.success(t("imported_success"), ToastOptions);
    } else {
      toast.error(t("select_rule"), ToastOptions);
    }
  };

  const updateRuleSetOptions = (target) => {
    const name = target.name.value;
    const title = target.title.value;

    if (name.length === 0 || title.length === 0) {
      return toast.error(
        t("please_fill_out_the_title_or_identifier"),
        ToastOptions
      );
    }

    // Update local state only
    setRuleSetData({
      ...ruleSetData,
      name: name,
      title: title,
      verbose: target.verbose?.checked ? "1" : "0",
    });

    setIsEdited(true);
    setIsOptionsModalOpen(false);
    toast.success(t("options_updated_save_to_preserver_changes"), ToastOptions);
  };

  const renderRule = (rule, index) => {
    return (
      <Rule
        key={rule.rule_id}
        index={index}
        id={rule.rule_id}
        rule={rule}
        setRuleSet={setRuleSetData}
        conditionTypesData={conditionTypesData}
        handleShowConditionDeleteModal={handleShowConditionDeleteModal}
        handleShowActionDeleteModal={handleShowActionDeleteModal}
        handleShowDeleteModal={handleShowDeleteModal}
        addCondition={addCondition}
        addAction={addAction}
        revisionMode={revisionMode}
        ruleSetId={ruleSetData.rule_set_id}
      />
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const data = () => {
        const oldIndex = rulesData.findIndex(
          (rule) => rule.rule_id === active.id
        );
        const newIndex = rulesData.findIndex(
          (rule) => rule.rule_id === over.id
        );
        const newItemsArray = arrayMove(rulesData, oldIndex, newIndex);

        // Update the order_index for each rule in the main ruleSetData
        ruleSetData?.rules?.forEach((rule) => {
          const index = newItemsArray.findIndex(
            (item) => item.rule_id === rule.rule_id
          );
          if (index !== -1) {
            rule.order_index = index;
          }
        });

        return newItemsArray;
      };

      setIsEdited(true);
      setRulesData(data());
    }
  };

  const handleShowCreateNewFromRevisionModal = () => {
    setIsCreateNewRevisionOpen(true);
  };

  const handleShowRuleSetOptionsModal = () => {
    setIsOptionsModalOpen(true);
  };

  //copy & pasting
  const [copiedItemId, setCopiedItemId] = useState(null);

  const [selectedItemId, setSelectedItemId] = useState(null);

  //if a new rule is selected, give it rule-selected class and remove the class from any other classes
  useEffect(() => {
    if (selectedItemId) {
      const selectedRule = document.getElementById(`rules-${selectedItemId}`);
      const allRules = document.querySelectorAll(".accordion");
      allRules.forEach((rule) => rule.classList.remove("rule-selected"));
      selectedRule.classList.add("rule-selected");
    }
  }, [selectedItemId]);

  const handleCopy = (itemId) => {
    // Store the ID of the item to copy
    setCopiedItemId(itemId);
    toast.success(t("rule_copied"), ToastOptions);
  };

  const handlePaste = () => {
    if (!copiedItemId) return;

    const itemToCopy = ruleSetData.rules.find(
      (rule) => rule.rule_id === copiedItemId
    );
    if (itemToCopy) {
      const pastedItem = {
        ...itemToCopy,
        rule_id: `new_${Math.round(Math.random() * 10000)}`, // Ensure a unique ID for the new item
        name: `${itemToCopy.name}_copy`, // Ensure a unique name for the new item
        is_new: true, // Mark the new item as new
        order_index: ruleSetData.rules.length + 1, // Add the new item to the end of the list
      };

      // Update using setRuleSetData, assuming ruleSetData contains a rules property
      const updatedRules = [...ruleSetData.rules, pastedItem];
      setRuleSetData({ ...ruleSetData, rules: updatedRules });
      setIsEdited(true);
      toast.success(t("rule_pasted"), ToastOptions);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for selected item and that the event target is not an input or textarea
      if (
        selectedItemId &&
        !["INPUT", "TEXTAREA"].includes(document.activeElement.tagName) &&
        !window.getSelection().toString()
      ) {
        if (event.ctrlKey && event.key === "c") {
          // Copy logic
          handleCopy(selectedItemId);
        } else if (event.ctrlKey && event.key === "v") {
          // Paste logic
          handlePaste();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [selectedItemId, handleCopy, handlePaste]);

  // Callback to add a new rule from the chat modal
  const handleAddRuleFromChat = (rule) => {
    // First, reorganize conditions to create proper nesting
    if (rule.condition) {
      // Find all group conditions
      const groupConditions = rule.condition.filter(
        (c) => c.condition_type_name === "group"
      );

      // For each group condition, find its children and nest them
      groupConditions.forEach((groupCond) => {
        // Find all conditions that have this group as parent
        const children = rule.condition.filter(
          (c) => c.parent_condition_id === groupCond.rule_condition_id
        );

        // Add children to the group condition
        groupCond.children = children;
      });

      // Filter out the child conditions from the top level
      rule.condition = rule.condition.filter((c) => !c.parent_condition_id);
    }

    const existingRuleIndex = ruleSetData.rules.findIndex(
      (existingRule) => existingRule.rule_id === rule.rule_id
    );

    if (existingRuleIndex !== -1) {
      // Update existing rule
      const updatedRules = structuredClone(ruleSetData.rules);
      updatedRules[existingRuleIndex] = {
        ...updatedRules[existingRuleIndex],
        ...rule,
        order_index: updatedRules[existingRuleIndex].order_index,
        is_new: false,
      };

      setRuleSetData({
        ...ruleSetData,
        rules: updatedRules,
      });

      setIsRenderedRules(true);
      toast.success(t("rule_updated"), ToastOptions);
    } else {
      // Create new rule
      const newRuleObject = {
        ...rule,
        rule_id: `new_${Math.round(Math.random() * 10000)}`,
        is_new: true,
        order_index: ruleSetData.rules.length + 1,
      };

      setRuleSetData({
        ...ruleSetData,
        rules: [...ruleSetData.rules, newRuleObject],
      });

      setIsRenderedRules(true);
      toast.success(t("rule_added"), ToastOptions);
    }

    setIsEdited(true);
  };

  return (
    <>
      <RuleSetComponent
        ruleSetData={ruleSetData}
        setRuleSetData={setRuleSetData}
        handleShowRuleSetOptionsModal={handleShowRuleSetOptionsModal}
        handleShowRuleSetsModal={handleShowRuleSetsModal}
        handleShowRevisionsModal={handleShowRevisionsModal}
        handleShowTestRuleSetModal={handleShowTestRuleSetModal}
        handleShowDeleteModal={handleShowDeleteModal}
        handleShowCreateNewFromRevisionModal={
          handleShowCreateNewFromRevisionModal
        }
        revisionMode={revisionMode}
      />
      {isRenderedRules ? (
        <div className="w-100 text-center">
          <div className="spinner-border border-2" role="status">
            <span className="sr-only">{t("loading")}</span>
          </div>
        </div>
      ) : !revisionMode ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={rulesData?.map((rule) => rule.rule_id)}
            strategy={verticalListSortingStrategy}
          >
            {rulesData?.length > 0 &&
              rulesData.map((rule, i) => {
                return (
                  <div
                    key={rule.rule_id}
                    onClick={() => setSelectedItemId(rule.rule_id)}
                    className={`accordion ui-sortable ${
                      i === 0
                        ? "first-row"
                        : i === rulesData?.length - 1
                        ? "last-row"
                        : ""
                    }`}
                    id={`rules-${rule.rule_id}`}
                  >
                    <div className="cursor-auto">{renderRule(rule, i)}</div>
                  </div>
                );
              })}
          </SortableContext>
        </DndContext>
      ) : (
        ruleSetData?.content?.rules?.length > 0 &&
        ruleSetData?.content?.rules[0].rule_id !== 0 &&
        ruleSetData.content.rules.map((rule, i) => {
          return (
            <div
              key={rule.rule_id}
              className={`accordion ${!id ? "pt-5" : ""} ui-sortable`}
              id={`rules-${rule.rule_id}`}
            >
              <div className="cursor-auto" key={rule.rule_id}>
                {renderRule(rule, i)}
              </div>
            </div>
          );
        })
      )}

      {!revisionMode && (
        <>
          <div
            className={`add-rule-container ${
              ruleSetData?.rules?.length === 1 ? "container-border" : ""
            }`}
          >
            <div className="btn-group">
              <button
                className="btn outline border-radius-left-4"
                type="button"
                onClick={addRule}
                title={t("add_rule")}
              >
                <PlusIcon style={{ filter: "brightness(0.5)" }} />
                <span className="ml-2">{t("add_rule")}</span>
              </button>
              <button
                className="btn outline dropdown-toggle dropdown-toggle-split border-radius-right-4"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle Dropdown</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => setIsChatModalOpen(true)}
                >
                  <PlusIcon style={{ filter: "brightness(0.5)" }} />
                  <span className="ml-2">Add using AI chat</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={() => handleConfirm()}
        title={t("delete_rule")}
        message={t("delete_rule_message")}
        open={isRuleDeleteModalOpen}
      />

      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={() => handleConfirm(true)}
        title={t("delete_rule_set")}
        message={t("delete_rule_set_message")}
        open={isRuleSetDeleteModalOpen}
      />

      <DeleteConfirm
        handleClose={handleCloseCondition}
        handleConfirm={handleConditionDeleteConfirm}
        title={t("delete_rule_condition")}
        message={t("delete_rule_condition_message")}
        open={isRuleConditionDeleteModalOpen}
      />

      <DeleteConfirm
        handleClose={handleCloseAction}
        handleConfirm={handleActionDeleteConfirm}
        title={t("delete_rule_action")}
        message={t("delete_rule_action_message")}
        open={isRuleActionDeleteModalOpen}
      />

      <RuleSetsModal
        handleClose={handleCloseRuleSets}
        handleConfirm={handleImportRulesConfirm}
        conditionTypesData={conditionTypesData}
        title={t("import_rules")}
        open={isRuleSetsModalOpen}
      />

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={ruleRevisionsData}
        open={isRuleRevisionsModalOpen}
        linkTo={"rule-sets"}
        elemId={parseInt(id)}
      />

      <CreateNewItem
        handleClose={() => setIsCreateNewRevisionOpen(false)}
        handleConfirm={() => {
          const data = new URLSearchParams(new FormData());

          const ruleJsonData = ruleDataConvertJson(
            data,
            ruleSetData.content,
            true,
            true
          );

          dispatch(createRuleSetRequest(ruleJsonData));
        }}
        open={isCreateNewRevisionOpen}
      />

      <RuleSetOptions
        handleClose={() => setIsOptionsModalOpen(false)}
        handleConfirm={updateRuleSetOptions}
        open={isOptionsModalOpen}
        ruleSetData={ruleSetData}
        setRuleSetData={setRuleSetData}
        revisionMode={revisionMode}
      />

      {/* Chat Modal */}
      {isChatModalOpen && (
        <ChatModal
          open={isChatModalOpen}
          handleClose={() => setIsChatModalOpen(false)}
          onAddRule={handleAddRuleFromChat}
          conditionTypesData={conditionTypesData}
          ruleSetId={ruleSetData.rule_set_id}
          ruleSet={ruleSetData}
        />
      )}
    </>
  );
};

RuleSetContent.propTypes = {
  ruleSetData: PropTypes.object,
  setRuleSetData: PropTypes.func,
  revisionMode: PropTypes.bool,
  setIsTestRuleSetModalOpen: PropTypes.func,
};

export default RuleSetContent;
