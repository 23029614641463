import * as SRD from "react-js-diagrams/lib/main";
import CustomNodeWidget from "./CustomNodeWidget";
import React from "react";

export class CustomWidgetFactory extends SRD.NodeWidgetFactory {
  constructor() {
    super("custom");
  }

  generateReactWidget(diagramEngine, node) {
    return <CustomNodeWidget node={node} />;
  }
}
