import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  getScorecardsFailure,
  getScorecardsRequest,
  getScorecardsSuccess,
  getScorecardByIdFailure,
  getScorecardByIdRequest,
  getScorecardByIdSuccess,
  createScorecardFailure,
  createScorecardRequest,
  createScorecardSuccess,
  updateScorecardFailure,
  updateScorecardRequest,
  updateScorecardSuccess,
  deleteScorecardFailure,
  deleteScorecardRequest,
  deleteScorecardSuccess,
  getScorecardRevisionSuccess,
  getScorecardRevisionFailure,
  getScorecardRevisionRequest,
  getScorecardRevision2Success,
  getScorecardRevision2Failure,
  getScorecardRevision2Request,
  getScorecardRevisionsSuccess,
  getScorecardRevisionsFailure,
  getScorecardRevisionsRequest,
  getTestScorecardFailure,
  getTestScorecardRequest,
  getTestScorecardSuccess,
  getRunTestResultFailure,
  getRunTestResultRequest,
  getRunTestResultSuccess,
  getRunTestResultStatusFailure,
  getRunTestResultStatusRequest,
  getRunTestResultStatusSuccess,
  getScorecardExplanationRequest,
  getScorecardExplanationSuccess,
  getScorecardExplanationFailure,
  getScorecardWorkflowsFailure,
  getScorecardWorkflowsRequest,
  getScorecardWorkflowsSuccess,
} from "redux/scorecard/action";

function* getScorecards() {
  try {
    const response = yield call(axios.get, "/scorecards");
    if (response.status === 200) {
      yield put(getScorecardsSuccess(response.data));
    }
  } catch (e) {
    yield put(getScorecardsFailure(e.message));
  }
}

function* getScorecardById({ payload }) {
  try {
    const url = `/scorecards/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getScorecardByIdSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getScorecardByIdFailure(e.message));
  }
}

function* createScorecard({ payload }) {
  try {
    const response = yield call(axios.post, "/scorecards", payload);
    if (response.status === 201) {
      yield put(createScorecardSuccess(response.headers.etag));
    }
  } catch (e) {
    yield put(createScorecardFailure(e.message));
  }
}

function* updateScorecard({ payload }) {
  try {
    const url = `/scorecards/${payload.id}`;
    const response = yield call(axios.put, url, payload.scorecardData);
    if (response.status === 200) {
      yield put(updateScorecardSuccess(response.data));
    }
  } catch (e) {
    yield put(updateScorecardFailure(e.message));
  }
}

function* deleteScorecard({ payload }) {
  try {
    const url = `/scorecards/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteScorecardSuccess());
    }
  } catch (e) {
    yield put(deleteScorecardFailure(e.message));
  }
}

function* getScorecardRevisions({ payload }) {
  try {
    const url = `scorecards/${payload}/revisions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getScorecardRevisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getScorecardRevisionsFailure("e.message"));
  }
}

function* getScorecardRevision({ payload, revisionId }) {
  try {
    const url = `scorecards/${payload.id}/revisions/${payload.revisionId}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getScorecardRevisionSuccess(response.data));
    }
  } catch (e) {
    yield put(getScorecardRevisionFailure("e.message"));
  }
}

function* getScorecardRevision2({ payload }) {
  try {
    const url = `/scorecards/${payload.id}/revisions/${payload.revisionId}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getScorecardRevision2Success(response.data));
    }
  } catch (e) {
    yield put(getScorecardRevision2Failure(e.message));
  }
}

function* getTestScorecard({ payload }) {
  try {
    const url = `/scorecards/${payload}/test`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getTestScorecardSuccess(response.data));
    }
  } catch (e) {
    yield put(getTestScorecardFailure(e.message));
  }
}

function* getRunTestResult({ payload }) {
  try {
    const url = `/scorecards/${payload.id}/test`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultFailure(e.message));
  }
}

function* getRunTestResultStatus({ payload }) {
  try {
    const url = `/scorecards/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultStatusFailure(e.message));
  }
}

//get scorecard explanation
function* getScorecardExplanation({ payload }) {
  try {
    const url = `/scorecards/${payload.scorecard_id}/explain`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getScorecardExplanationSuccess(response.data));
    }
  } catch (e) {
    yield put(getScorecardExplanationFailure(e.message));
  }
}

//workflows
function* getScorecardWorkflows({ payload }) {
  try {
    const url = `/scorecards/${payload}/workflows`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getScorecardWorkflowsSuccess(response.data));
    }
  } catch (e) {
    yield put(getScorecardWorkflowsFailure(e.message));
  }
}

export default function* scorecardSaga() {
  yield takeLatest(getScorecardsRequest, getScorecards);
  yield takeLatest(getScorecardByIdRequest, getScorecardById);
  yield takeEvery(createScorecardRequest, createScorecard);
  yield takeLatest(updateScorecardRequest, updateScorecard);
  yield takeLatest(deleteScorecardRequest, deleteScorecard);
  yield takeLatest(getScorecardRevisionsRequest, getScorecardRevisions);
  yield takeLatest(getScorecardRevisionRequest, getScorecardRevision);
  yield takeLatest(getScorecardRevision2Request, getScorecardRevision2);
  yield takeLatest(getTestScorecardRequest, getTestScorecard);
  yield takeLatest(getRunTestResultRequest, getRunTestResult);
  yield takeLatest(getRunTestResultStatusRequest, getRunTestResultStatus);
  yield takeLatest(getScorecardExplanationRequest, getScorecardExplanation);
  yield takeLatest(getScorecardWorkflowsRequest, getScorecardWorkflows);
}
