import React, { useEffect, useRef, useState } from "react";
import { TreeState, TreeTable } from "cp-react-tree-table";
import "./table.css";
import PropTypes from "prop-types";

function ReleaseTreeTable({ testingData, type, height = 558 }) {
  const [treeState, setTreeState] = useState([]);
  const treeTableRef = useRef(null);

  useEffect(() => {
    if (testingData.current) {
      const result = objectToArray(testingData.current);
      setTreeState(TreeState.expandAll(TreeState.create(result)));
    }
  }, [testingData, testingData.current]);

  const updateValueAtIndex = (index, newValue) => {
    let currentIndex = 0;
    const treeStateCopy = structuredClone(testingData.current);

    function recursiveUpdate(data, isFinish = false) {
      if (isFinish) {
        testingData.current = treeStateCopy;
        return;
      }
      if (data && typeof data === "object" && !Array.isArray(data)) {
        for (const key in data) {
          if (currentIndex === index) {
            data[key] = newValue;
            currentIndex++;
            recursiveUpdate(data[key], true);
          }
          currentIndex++;
          recursiveUpdate(data[key], isFinish);
        }
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          if (currentIndex === index) {
            data[i] = newValue;
            currentIndex++;
            recursiveUpdate("", true);
            return;
          }
          currentIndex++;
          recursiveUpdate(data[i], isFinish);
        }
      }
    }

    recursiveUpdate(treeStateCopy);
  };

  const objectToArray = (obj) => {
    const result = [];

    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        const item = {
          data: {
            key: key,
            value:
              typeof obj[key] === "string" ||
              typeof obj[key] === "number" ||
              typeof obj[key] === "boolean"
                ? obj[key]
                : "",
          },
          height: 32,
        };

        if (Array.isArray(obj[key])) {
          item.children = obj[key].map((arrayItem, index) => {
            const arrayChild = {
              data: {
                key: (index + 1).toString(),
                value:
                  typeof obj[key] === "string" ||
                  typeof obj[key] === "number" ||
                  typeof obj[key] === "boolean"
                    ? obj[key]
                    : arrayItem,
              },
              height: 32,
            };

            if (typeof arrayItem !== "string") {
              arrayChild.children = objectToArray(arrayItem);
            }

            return arrayChild;
          });
        } else if (
          obj[key] &&
          typeof obj[key] === "object" &&
          Object.keys(obj[key]).length > 0
        ) {
          item.children = objectToArray(obj[key]);
        }

        result.push(item);
      }
    }

    return result;
  };

  const renderHeaderCell = (name, alignLeft = true) => (
    <span className={alignLeft ? "align-left" : "align-right"}>{name}</span>
  );

  const renderIndexCell = (row) => (
    <div style={{ paddingLeft: row.metadata.depth * 15 + "px" }}>
      <button
        className={`toggle-button ellipsis-text ${
          row.$state.isExpanded ? "expanded" : ""
        }`}
        onClick={row.toggleChildren}
        disabled={!row.metadata.hasChildren}
        title={row.data.key}
      >
        {row.data.key}
      </button>
    </div>
  );

  const renderEditableCell = (row) => {
    return row.data && !row.metadata.hasChildren ? (
      <input
        disabled={type}
        type="text"
        value={row.data.value}
        onChange={(event) => {
          row.updateData({
            ...row.data,
            value: event.target.value,
          });
          updateValueAtIndex(row.metadata.index, event.target.value);
        }}
      />
    ) : null;
  };

  return (
    <TreeTable
      className={`demo-tree-table ${type === "output" ? "output-tree" : ""}`}
      height={height}
      headerHeight={32}
      ref={treeTableRef}
      value={treeState}
      onChange={(newVal) => {
        setTreeState(newVal);
      }}
    >
      <TreeTable.Column
        renderCell={renderIndexCell}
        renderHeaderCell={() => renderHeaderCell("Key")}
        basis="180px"
        grow={0}
      />
      <TreeTable.Column
        renderCell={renderEditableCell}
        renderHeaderCell={() => renderHeaderCell("Value")}
      />
    </TreeTable>
  );
}

ReleaseTreeTable.propTypes = {
  testingData: PropTypes.object,
  type: PropTypes.string,
  height: PropTypes.number,
};

export default ReleaseTreeTable;
