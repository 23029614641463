import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as KeyIcon } from "assets/icons/key.svg";
import ReactTooltip from "react-tooltip";

const Header = ({
  header,
  setHeaders,
  handleShowHeaderDeleteModal,
  isLast,
}) => {
  const { t } = useTranslation();

  const [content, setContent] = useState(header.content);
  const [isSecret, setIsSecret] = useState(header.is_secret);

  const handleChange = (e, id) => {
    if (isLast) {
      setHeaders((prevState) => {
        const prevStateHeader = prevState.find(
          (param) => param.external_data_header_id === id
        );
        prevStateHeader.isDefault = false;

        return [...prevState, getNewHeader()];
      });
    }

    setContent(e.target.value);
  };

  const handleSecretToggle = (id) => {
    const newIsSecret = !isSecret;
    setIsSecret(newIsSecret);

    if (isLast) {
      setHeaders((prevState) => {
        const prevStateHeader = prevState.find(
          (param) => param.external_data_header_id === id
        );
        prevStateHeader.isDefault = false;

        return [...prevState, getNewHeader()];
      });
    }
  };

  // Modified function to show header name before colon
  const getMaskedContent = (content) => {
    if (!content || content.length === 0) return "";

    // If there's a colon, keep everything before it visible
    const colonIndex = content.indexOf(":");
    if (colonIndex !== -1) {
      const headerName = content.substring(0, colonIndex + 1);
      const headerValue = content.substring(colonIndex + 1);

      // If value is empty or just whitespace, don't mask anything
      if (!headerValue.trim()) return headerName;

      // Mask the value part
      return (
        headerName + " " + "*".repeat(Math.min(6, headerValue.trim().length))
      );
    }

    // If no colon, just show first character and mask the rest
    if (content.length === 1) return content;
    return (
      content.substring(0, 1) + "*".repeat(Math.min(6, content.length - 1))
    );
  };

  return (
    <tr className={header.isDefault ? "bg-gray" : ""}>
      <td>
        {isSecret ? (
          <div className="d-flex flex-column">
            <input
              type="hidden"
              name={
                !header.isDefault
                  ? `headers[${header.external_data_header_id}][content]`
                  : ""
              }
              value={content}
            />
            <input
              className="form-control form-control-sm form-control-plaintext"
              type="text"
              readOnly={true}
              value={getMaskedContent(content)}
              placeholder={t("header")}
              data-tip={true}
              data-for={`secret-header-${header.external_data_header_id}`}
            />
            {content && (
              <ReactTooltip
                id={`secret-header-${header.external_data_header_id}`}
                type="dark"
                place="top"
                effect="solid"
              >
                {t("editing_disabled_for_secret")}
              </ReactTooltip>
            )}
          </div>
        ) : (
          <input
            className="form-control form-control-sm form-control-plaintext"
            type="text"
            placeholder={t("header")}
            name={
              !header.isDefault
                ? `headers[${header.external_data_header_id}][content]`
                : ""
            }
            onChange={(e) => handleChange(e, header.external_data_header_id)}
            defaultValue={content}
          />
        )}
      </td>
      <td className="text-center">
        {content && (
          <>
            <KeyIcon
              className="cursor-pointer"
              title={t("mark_as_key")}
              style={{
                width: "16px",
                height: "16px",
                opacity: isSecret ? 1 : 0.3,
                fill: isSecret ? "#0F3356" : "none",
                stroke: "#0F3356 ",
              }}
              onClick={() => handleSecretToggle(header.external_data_header_id)}
            />
            <input
              type="hidden"
              name={`headers[${header.external_data_header_id}][is_secret]`}
              value={isSecret ? 1 : 0}
            />
          </>
        )}
      </td>
      <td className="text-center">
        {!header.isDefault ? (
          <DeleteIcon
            className="cursor-pointer"
            title={t("delete_header")}
            style={{
              width: "15px",
              height: "16px",
            }}
            onClick={() =>
              handleShowHeaderDeleteModal(header.external_data_header_id)
            }
          />
        ) : null}
      </td>
    </tr>
  );
};

const newHeader = {
  content: "",
  is_secret: 0,
  isDefault: true,
};

export function getNewHeader() {
  const newHeaderClone = structuredClone(newHeader);
  newHeaderClone.external_data_header_id = `new_${parseInt(
    Date.now() * Math.random()
  )}`;

  return newHeaderClone;
}

Header.propTypes = {
  header: PropTypes.object,
  handleShowHeaderDeleteModal: PropTypes.func,
  setHeaders: PropTypes.func,
  isLast: PropTypes.bool,
};

export default Header;
