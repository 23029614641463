import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as KeyIcon } from "assets/icons/key.svg";
import ReactTooltip from "react-tooltip";

const Parameter = ({
  param,
  setParams,
  handleShowParamDeleteModal,
  isLast,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState(param.name);
  const [value, setValue] = useState(param.value);
  const [isKey, setIsKey] = useState(param.is_key);

  const handleChange = (e, setData, id) => {
    if (isLast) {
      setParams((prevState) => {
        const prevStateParam = prevState.find(
          (param) => param.external_data_param_id === id
        );
        prevStateParam.isDefault = false;

        return [...prevState, getNewParam()];
      });
    }

    setData(e.target.value);
  };

  const handleKeyToggle = (id) => {
    const newIsKey = !isKey;
    setIsKey(newIsKey);

    if (isLast) {
      setParams((prevState) => {
        const prevStateParam = prevState.find(
          (param) => param.external_data_param_id === id
        );
        prevStateParam.isDefault = false;

        return [...prevState, getNewParam()];
      });
    }
  };

  // Function to mask the value when it's a key
  const getMaskedValue = (value) => {
    if (!value || value.length === 0) return "";
    if (value.length === 1) return value;
    return "*".repeat(Math.min(8, value.length));
  };

  return (
    <tr className={param.isDefault ? "bg-gray" : ""}>
      <td>
        <input
          className="form-control form-control-sm field form-control-plaintext"
          type="text"
          placeholder={t("parameter_name")}
          onChange={(e) =>
            handleChange(e, setName, param.external_data_param_id)
          }
          name={
            !param.isDefault
              ? `params[${param.external_data_param_id}][name]`
              : null
          }
          defaultValue={name}
        />
      </td>
      <td>
        {isKey ? (
          <div className="d-flex flex-column">
            <input
              type="hidden"
              name={
                !param.isDefault
                  ? `params[${param.external_data_param_id}][value]`
                  : null
              }
              value={value}
            />
            <input
              className="form-control form-control-sm form-control-plaintext"
              placeholder={t("value")}
              type="text"
              readOnly={true}
              value={getMaskedValue(value)}
              data-tip={true}
              data-for={`secret-param-${param.external_data_param_id}`}
            />
            {value && (
              <ReactTooltip
                id={`secret-param-${param.external_data_param_id}`}
                type="dark"
                place="top"
                effect="solid"
              >
                {t("editing_disabled_for_secret")}
              </ReactTooltip>
            )}
          </div>
        ) : (
          <input
            className="form-control form-control-sm form-control-plaintext"
            placeholder={t("value")}
            type="text"
            onChange={(e) =>
              handleChange(e, setValue, param.external_data_param_id)
            }
            name={
              !param.isDefault
                ? `params[${param.external_data_param_id}][value]`
                : null
            }
            defaultValue={param.value}
          />
        )}
      </td>
      <td className="text-center">
        {(value || name) && (
          <>
            <KeyIcon
              className="cursor-pointer"
              title={t("mark_as_key")}
              style={{
                width: "16px",
                height: "16px",
                opacity: isKey ? 1 : 0.3,
                fill: isKey ? "#0F3356" : "none",
                stroke: "#0F3356",
              }}
              onClick={() => handleKeyToggle(param.external_data_param_id)}
            />
            <input
              type="hidden"
              name={`params[${param.external_data_param_id}][is_key]`}
              value={isKey ? 1 : 0}
            />
          </>
        )}
      </td>
      <td>
        {value || name ? (
          <div className="custom-control custom-switch float-right align-middle text-center">
            <input
              type="checkbox"
              className="custom-control-input w-100 z-index-100 cursor-pointer"
              name={
                !param.isDefault
                  ? `params[${param.external_data_param_id}][push_to_flow]`
                  : null
              }
              defaultChecked={param.push_to_flow}
            />
            <label className="custom-control-label" />
          </div>
        ) : null}
      </td>
      <td className="text-center">
        {value || name ? (
          <DeleteIcon
            className="cursor-pointer"
            title="Delete parameter"
            style={{
              width: "15px",
              height: "16px",
            }}
            onClick={() =>
              handleShowParamDeleteModal(param.external_data_param_id)
            }
          />
        ) : null}
      </td>
    </tr>
  );
};

export const newParameter = {
  name: "",
  value: "",
  push_to_flow: 0,
  isDefault: true,
};

export function getNewParam() {
  const newParameterClone = structuredClone(newParameter);
  newParameterClone.external_data_param_id = `new_${parseInt(
    Date.now() * Math.random()
  )}`;

  return newParameterClone;
}

Parameter.propTypes = {
  param: PropTypes.object,
  handleShowParamDeleteModal: PropTypes.func,
  setParams: PropTypes.func,
  isLast: PropTypes.bool,
};

export default Parameter;
