import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import * as actions from "./action";

import {
  getDecisionTreeRevisionsSuccess,
  getDecisionTreeRevisionsFailure,
  getDecisionTreeRevisionSuccess,
  getDecisionTreeRevisionFailure,
  getTestDecisionTreeSuccess,
  getTestDecisionTreeFailure,
  getRunTestResultSuccess,
  getRunTestResultFailure,
  getRunTestResultStatusSuccess,
  getRunTestResultStatusFailure,
  fetchDecisionTreeFailure,
  fetchDecisionTreeSuccess,
  getDecisionTreeWorkflowsRequest,
  getDecisionTreeWorkflowsSuccess,
  getDecisionTreeWorkflowsFailure,
} from "./action";

const fetchDataFromAPI = async (url, method = "GET", data = null) => {
  const response = await axios({ url, method, data });
  return response.data;
};

// Saga to fetch all decision trees
function* fetchDecisionTrees() {
  try {
    const data = yield call(fetchDataFromAPI, "/decision-trees");
    yield put(actions.fetchDecisionTreesSuccess(data));
  } catch (error) {
    yield put(actions.fetchDecisionTreesFailure(error.toString()));
  }
}

// Saga to fetch a single decision tree
function* fetchDecisionTree({ payload }) {
  try {
    const data = yield call(fetchDataFromAPI, `/decision-trees/${payload}`);
    yield put(actions.fetchDecisionTreeSuccess(data));
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(fetchDecisionTreeFailure("e.message"));
  }
}

// Saga to create a decision tree
function* createDecisionTree({ payload }) {
  try {
    //example: const response = yield call(axios.post, "/external-data", payload.data);
    //"/decision-trees",
    const response = yield call(axios.post, "/decision-trees", payload);
    if (response.status === 201) {
      yield put(
        actions.createDecisionTreeSuccess({
          data: response.data,
          id: response.headers.etag,
        })
      );
    } else {
      yield put(actions.createDecisionTreeFailure("e.message"));
    }
  } catch (error) {
    yield put(actions.createDecisionTreeFailure(error.toString()));
  }
}

// Saga to update a decision tree
function* updateDecisionTree({ payload }) {
  try {
    const data = yield call(
      fetchDataFromAPI,
      `/decision-trees/${payload.decision_tree_id}`,
      "PUT",
      payload
    );
    yield put(actions.updateDecisionTreeSuccess(data));
  } catch (error) {
    yield put(actions.updateDecisionTreeFailure(error.toString()));
  }
}

// Saga to delete a decision tree
function* deleteDecisionTree({ payload }) {
  try {
    const url = `/decision-trees/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(actions.deleteDecisionTreeSuccess(payload));
    } else {
      yield put(actions.deleteDecisionTreeFailure("e.message"));
    }
  } catch (error) {
    yield put(actions.deleteDecisionTreeFailure(error.toString()));
  }
}

function* getDecisionTreeRevisions({ payload }) {
  try {
    const url = `decision-trees/${payload}/revisions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTreeRevisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTreeRevisionsFailure("e.message"));
  }
}

function* getDecisionTreeRevision({ payload, revisionId }) {
  try {
    const url = `decision-trees/${payload.id}/revisions/${payload.revisionId}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTreeRevisionSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTreeRevisionFailure("e.message"));
  }
}

function* getTestDecisionTree({ payload }) {
  try {
    const url = `/decision-trees/${payload}/test`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getTestDecisionTreeSuccess(response.data));
    }
  } catch (e) {
    yield put(getTestDecisionTreeFailure(e.message));
  }
}

function* getRunTestResult({ payload }) {
  try {
    const url = `/decision-trees/${payload.id}/test`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultFailure(e.message));
  }
}

function* getRunTestResultStatus({ payload }) {
  try {
    const url = `/decision-trees/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultStatusFailure(e.message));
  }
}

//workflows
function* getDecisionTreeWorkflows({ payload }) {
  try {
    const url = `/decision-trees/${payload}/workflows`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDecisionTreeWorkflowsSuccess(response.data));
    }
  } catch (e) {
    yield put(getDecisionTreeWorkflowsFailure(e.message));
  }
}

// Watcher saga for decision trees operations
export default function* decisionTrees() {
  yield takeLatest(
    actions.fetchDecisionTreesRequest().type,
    fetchDecisionTrees
  );
  yield takeLatest(actions.fetchDecisionTreeRequest().type, fetchDecisionTree);
  yield takeLatest(
    actions.createDecisionTreeRequest().type,
    createDecisionTree
  );
  yield takeLatest(
    actions.updateDecisionTreeRequest().type,
    updateDecisionTree
  );
  yield takeLatest(
    actions.deleteDecisionTreeRequest().type,
    deleteDecisionTree
  );

  yield takeLatest(
    actions.getDecisionTreeRevisionsRequest().type,
    getDecisionTreeRevisions
  );

  yield takeLatest(
    actions.getDecisionTreeRevisionRequest().type,
    getDecisionTreeRevision
  );

  yield takeLatest(
    actions.getTestDecisionTreeRequest().type,
    getTestDecisionTree
  );

  yield takeLatest(actions.getRunTestResultRequest().type, getRunTestResult);

  yield takeLatest(
    actions.getRunTestResultStatusRequest().type,
    getRunTestResultStatus
  );

  //workflows
  yield takeLatest(
    actions.getDecisionTreeWorkflowsRequest().type,
    getDecisionTreeWorkflows
  );
}
