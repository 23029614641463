import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDecisionTableRequest,
  deleteTableColumnRequest,
  getDecisionTableRequest,
} from "redux/decisionTables/action";
import PropTypes from "prop-types";
import usePrevious from "utility/hooks/usePrevious";
import DecisionTableColumns from "components/decisionTables/TableColumns";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as LayoutIcon } from "assets/icons/layout.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { compareColumns, generateCloudInstanceName } from "utility/utility";
import { MainContext } from "context/contexts";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const column = {
  attribute_path: "",
  title: "",
};

const DecisionTableContent = ({ vectorsDataClone, editMode = false }) => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    decisionTable,
    isGetDecisionTableSuccess,
    isGetDecisionTableError,
    isRemovedDecisionTableSuccess,
    isRemovedTableColumnSuccess,
  } = useSelector((state) => state.decisionTables);

  const [selectedColumn, setSelectedColumn] = useState({});
  const [cols, setCols] = useState([]);
  const [isColumnDeleteModalOpen, setIsColumnDeleteModalOpen] = useState(false);
  const [isDecisionTableDeleteModalOpen, setIsDecisionTableDeleteModalOpen] =
    useState(false);
  const [decisionTableData, setDecisionTableData] = useState({});

  const prevIsGetDecisionTableSuccess = usePrevious(isGetDecisionTableSuccess);
  const prevIsRemovedTableColumnSuccess = usePrevious(
    isRemovedTableColumnSuccess
  );
  const prevIsRemovedDecisionTableSuccess = usePrevious(
    isRemovedDecisionTableSuccess
  );
  const prevIsGetDecisionTableError = usePrevious(isGetDecisionTableError);

  useEffect(() => {
    if (editMode) {
      setIsLoading(true);
      dispatch(getDecisionTableRequest(parseInt(id)));
    } else {
      addColumn(["condition", "result", "condition"]);
    }

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (
      isRemovedDecisionTableSuccess &&
      prevIsRemovedDecisionTableSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("decision_table_deleted"), ToastOptions);
      navigate("/decision-tables");
    }
  }, [isRemovedDecisionTableSuccess]);

  useEffect(() => {
    if (
      isRemovedTableColumnSuccess &&
      prevIsRemovedTableColumnSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("table_column_deleted"), ToastOptions);
    }
  }, [isRemovedTableColumnSuccess]);

  useEffect(() => {
    if (isGetDecisionTableSuccess && prevIsGetDecisionTableSuccess === false) {
      setIsLoading(false);
      setDecisionTableData(decisionTable);
      if (
        decisionTable?.cols?.length === 1 &&
        !decisionTable?.cols[0].decision_table_column_id
      ) {
        addColumn();
      } else {
        const cols = structuredClone(decisionTable?.cols);
        const data = cols?.map((col) => ({
          title: col.title,
          attribute_path: col.attribute_path,
          decision_table_column_id: col.decision_table_column_id,
          role: col.role,
        }));
        const sortedColumns = data.sort(compareColumns);

        const columnCopy = structuredClone(column);
        columnCopy.decision_table_column_id = `new_${Math.round(
          Math.random() * 10000
        )}`;
        columnCopy.role = "condition";
        columnCopy.is_new = true;
        columnCopy.is_default = true;
        sortedColumns.push(columnCopy);

        setCols(sortedColumns);
      }
    }
  }, [isGetDecisionTableSuccess]);

  useEffect(() => {
    if (isGetDecisionTableError && prevIsGetDecisionTableError === false) {
      setIsLoading(false);
    }
  }, [isGetDecisionTableError]);

  const handleConfirmDeleteColumnModal = () => {
    if (!selectedColumn.is_new) {
      const columnId = selectedColumn.decision_table_column_id;
      setIsLoading(true);
      dispatch(deleteTableColumnRequest({ id, columnId }));
    } else {
      setIsEdited(true);
    }
    setCols(
      cols.filter(
        (column) =>
          column.decision_table_column_id !==
          selectedColumn.decision_table_column_id
      )
    );

    handleCloseDeleteColumnModal();
  };

  const handleShowDeleteColumnModal = (selectedColumn) => {
    setSelectedColumn(selectedColumn);
    setIsColumnDeleteModalOpen(true);
  };

  const handleCloseDeleteColumnModal = () => {
    setIsColumnDeleteModalOpen(false);
  };

  const handleToggleDeleteDecisionTableModal = (isShow = true) => {
    setIsDecisionTableDeleteModalOpen(isShow);
  };

  const handleConfirmDecisionTableModal = () => {
    setIsLoading(true);
    setIsEdited(false);
    dispatch(deleteDecisionTableRequest(id));
    handleToggleDeleteDecisionTableModal(false);
  };

  const addColumn = (types = ["condition"]) => {
    setIsEdited(true);
    const columnsCopy = structuredClone(cols);
    types.map((type) => {
      const columnCopy = structuredClone(column);
      columnCopy.decision_table_column_id = `new_${Math.round(
        Math.random() * 10000
      )}`;
      columnCopy.role = type;
      if (type === "result") {
        columnCopy.attribute_path = "$.decision";
      }
      columnCopy.is_new = true;
      columnsCopy.push(columnCopy);
    });

    setCols(columnsCopy);
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleToggleDeleteDecisionTableModal}
        >
          <DeleteIcon /> {t("delete")}
        </button>
      ),
      divider: false,
    },
  ];

  return (
    <>
      <SubHeader
        alt={t("decision_table")}
        title={t("decision_table_settings")}
        actions={
          <>
            {editMode && (
              <Link to={`/decision-tables/${id}`}>
                <button className="h-100 btn outline mr-2">
                  <LayoutIcon /> <span className="ml-2">{t("edit_table")}</span>
                </button>
              </Link>
            )}

            <button
              className="btn primary mr-2"
              type="submit"
              title={t("save_table")}
            >
              <SaveIcon />
            </button>

            {editMode && (
              <div className="btn-group mr-1">
                <button
                  type="button"
                  className="btn primary"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  data-tip={true}
                  data-for="delete-table"
                >
                  <DeleteIcon />
                </button>
                <ReactTooltip
                  type="dark"
                  place="top"
                  effect="solid"
                  id="delete-table"
                >
                  {t("delete_table")}
                </ReactTooltip>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                  {settings.length > 0 &&
                    settings.map((setting) => {
                      return (
                        <span key={setting.id}>
                          {setting.content}
                          {setting.divider && (
                            <div className="dropdown-divider" />
                          )}
                        </span>
                      );
                    })}
                </div>
              </div>
            )}
          </>
        }
      />
      <div className="row">
        <div className="col-md col-12">
          <label>{t("table_title")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="title"
            value={decisionTableData ? decisionTableData.title : ""}
            onChange={(e) => {
              setDecisionTableData({
                ...decisionTableData,
                title: e.target.value,
              });
            }}
            required
          />
        </div>
        <div className="col-md-5 col-12">
          <label>{t("identifier")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="name"
            value={
              Object.keys(decisionTableData).length > 0
                ? decisionTableData.name
                : !id
                ? generateCloudInstanceName()
                : ""
            }
            onChange={(e) => {
              setDecisionTableData({
                ...decisionTableData,
                name: e.target.value,
              });
            }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9_-]/g, "");
            }}
            pattern="[a-zA-Z0-9_\-]+"
            title={t("only_letters_numbers_underscore_hyphen")}
            required
          />
        </div>
      </div>
      <DecisionTableColumns
        cols={cols}
        setCols={setCols}
        vectorsData={vectorsDataClone}
        handleShowDeleteColumnModal={handleShowDeleteColumnModal}
      />
      <DeleteConfirm
        handleClose={handleCloseDeleteColumnModal}
        handleConfirm={handleConfirmDeleteColumnModal}
        title={t("delete_column")}
        message={t("delete_column_message")}
        open={isColumnDeleteModalOpen}
      />
      <DeleteConfirm
        handleClose={() => handleToggleDeleteDecisionTableModal(false)}
        handleConfirm={handleConfirmDecisionTableModal}
        title={t("delete_decision_table")}
        message={t("delete_decision_table_message")}
        open={isDecisionTableDeleteModalOpen}
      />
    </>
  );
};

DecisionTableContent.propTypes = {
  cols: PropTypes.array,
  vectorsDataClone: PropTypes.array,
  handleShowDeleteColumnModal: PropTypes.func,
  handleCloseDeleteColumnModal: PropTypes.func,
  handleConfirmDeleteColumnModal: PropTypes.func,
  isColumnDeleteModalOpen: PropTypes.bool,
  addColumn: PropTypes.func,
  setCols: PropTypes.func,
  editMode: PropTypes.bool,
};

export default DecisionTableContent;
