import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";

const DatasetDropdown = ({
  datasets = [],
  onSelect,
  onManageDatasets,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  // Calculate dropdown position when opened
  const calculatePosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  };

  // Handle button click to toggle dropdown
  const handleToggle = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (!isOpen) {
      calculatePosition();
    }
    setIsOpen(!isOpen);
  };

  // Handle clicking outside to close dropdown
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        isOpen &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  // Render dropdown using portal
  const renderDropdown = () => {
    if (!isOpen) return null;

    // Show different content based on whether we have datasets or not
    const dropdownContent =
      datasets.length === 0 ? (
        <div className="dropdown-empty-state">
          <p style={{ padding: "10px 12px", textAlign: "center", margin: "0" }}>
            {t("no_datasets_available")}
          </p>
        </div>
      ) : (
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          {datasets.map((dataset) => (
            <div
              key={dataset.id}
              className="dataset-item"
              onClick={() => {
                onSelect(dataset);
                setIsOpen(false);
              }}
              style={{
                padding: "8px 12px",
                cursor: "pointer",
                borderBottom: "1px solid #f0f0f0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontWeight: 500 }}>{dataset.name}</span>
              <span
                style={{
                  backgroundColor: "#e9ecef",
                  padding: "2px 6px",
                  borderRadius: "10px",
                  fontSize: "0.75rem",
                }}
              >
                {dataset.itemCount}
              </span>
            </div>
          ))}
        </div>
      );

    return createPortal(
      <div
        ref={dropdownRef}
        className="dataset-list batch-test-dropdown"
        style={{
          position: "absolute",
          zIndex: 9999,
          top: `${position.top}px`,
          left: `${position.left}px`,
          width: `${position.width}px`,
          background: "#fff",
          border: "1px solid #dee2e6",
          borderRadius: "3px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
        }}
      >
        <div className="dataset-list-header">
          <h6 style={{ margin: 0 }}>{t("available_datasets")}</h6>
          <button
            type="button"
            className="btn-close"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
            style={{
              background: "none",
              border: "none",
              fontSize: "1.2rem",
              padding: "0 6px",
              cursor: "pointer",
            }}
            aria-label="Close"
          >
            ×
          </button>
        </div>

        {dropdownContent}

        <div
          className="dataset-list-footer"
          style={{ borderTop: "1px solid #dee2e6", padding: "8px 12px" }}
        >
          <button
            type="button" // Add this
            className="btn outline w-100 btn-sm"
            onClick={(e) => {
              e.preventDefault();
              onManageDatasets(e);
              setIsOpen(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              viewBox="0 0 16 16"
              style={{ marginRight: "5px" }}
            >
              <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
              <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319z" />
            </svg>
            {t("manage_datasets")}
          </button>
        </div>
      </div>,
      document.body
    );
  };

  return (
    <div className="dataset-dropdown mb-3">
      {datasets.length === 0 ? (
        <button
          type="button" // Add this to explicitly make it not submit forms
          className="btn outline w-100"
          onClick={(e) => {
            e.preventDefault();
            onManageDatasets(e);
          }}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            viewBox="0 0 16 16"
            className="me-2"
          >
            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319z" />
          </svg>
          {t("manage_datasets")}
        </button>
      ) : (
        <button
          ref={buttonRef}
          type="button" // Add this to explicitly make it not submit forms
          className="btn outline w-100"
          onClick={handleToggle}
          disabled={disabled}
        >
          {t("select_dataset")} ({datasets.length})
        </button>
      )}
      {renderDropdown()}
    </div>
  );
};

DatasetDropdown.propTypes = {
  datasets: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onManageDatasets: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DatasetDropdown;
