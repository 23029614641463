import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  createEndPointLimitedFlowRequest,
  createEndPointUserNotificationRequest,
  deleteEndPointLimitedFlowRequest,
  deleteEndPointUserNotificationRequest,
} from "redux/endpoints/action";
import { generateEndpointApiName, sortByTitle } from "utility/utility";
import { ToastOptions } from "components/toastify";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import ReleaseEndPoint from "components/modals/endpoint/ReleaseEndPoint";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as PlusIcon } from "assets/icons/custom_plus.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { useTranslation } from "react-i18next";
import { toInteger } from "lodash";

const randomApiKey =
  Math.random().toString(36).substring(2) +
  Math.random().toString(36).substring(2) +
  Math.random().toString(36).substring(2);

const EndPointContent = ({
  instances,
  editMode = false,
  data,
  workflows,
  users,
  approvalSequences,
}) => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const [endPointURL, setEndPointURL] = useState("");
  const [secret, setSecret] = useState(1);
  const [active, setActive] = useState(false);
  const [workflowsClone, setWorkflowsClone] = useState([]);
  const [limitedDecisions, setLimitedDecisions] = useState([]);
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [isAddFlowModalOpen, setIsAddFlowModalOpen] = useState(false);
  const [isFlowLimitationDeleteModalOpen, setIsFlowLimitationDeleteModalOpen] =
    useState(false);
  const [
    isUserNotificationDeleteModalOpen,
    setIsUserNotificationDeleteModalOpen,
  ] = useState(false);
  const [blockDeletion, setBlockDeletion] = useState(false);
  const [selectedApprovalSequence, setSelectedApprovalSequence] =
    useState(null);
  const [showApprovalSequenceSelect, setShowApprovalSequenceSelect] =
    useState(false);

  useEffect(() => {
    if (data && workflows.length > 0) {
      const cloneOfData = structuredClone(data);
      setEndPointURL(`${cloneOfData.api_url}${cloneOfData.name}`);
      setWorkflowsClone(workflows);
      setNotificationUsers(
        !cloneOfData.notification_users ? [] : cloneOfData.notification_users
      );
      setActive(parseInt(cloneOfData.notification));
      setBlockDeletion(toInteger(cloneOfData.block_deletion) || false);
      setSelectedApprovalSequence(cloneOfData.approval_sequence_id || null);

      if (cloneOfData.workflows) {
        const limitedFlows = cloneOfData.workflows.map((workflow) => {
          return workflows.find(
            (workflowClone) => workflowClone.workflow_id === workflow.toString()
          );
        });
        const workflowsClone = workflows.filter(
          ({ workflow_id }) =>
            !cloneOfData.workflows.includes(parseInt(workflow_id))
        );
        setWorkflowsClone(workflowsClone);
        setLimitedDecisions(limitedFlows.sort(sortByTitle));
      }
    }
  }, [data, workflows]);

  const handleOpenFlowLimitationModal = (id) => {
    setSelectedWorkflowId(id);
    setIsFlowLimitationDeleteModalOpen(true);
  };

  const handleClose = () => {
    setSelectedWorkflowId(null);
    setIsFlowLimitationDeleteModalOpen(false);
  };

  const handleConfirm = () => {
    const limitId = selectedWorkflowId;
    const filtered = limitedDecisions.filter(
      (workflow) => workflow.workflow_id !== selectedWorkflowId.toString()
    );
    const limitedDecisionsData = workflows.filter(
      ({ workflow_id: id }) =>
        !filtered.some(({ workflow_id }) => id === workflow_id)
    );

    setWorkflowsClone(limitedDecisionsData);
    setLimitedDecisions(filtered);
    setIsLoading(true);
    dispatch(deleteEndPointLimitedFlowRequest({ id, limitId }));
    handleClose();
  };

  const handleShowAddModal = () => {
    setIsAddFlowModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddFlowModalOpen(false);
  };

  const handleConfirmAdding = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const limited = structuredClone(limitedDecisions);
    const workflow = workflows.find(
      (workflow) => workflow.workflow_id === e.target.workflow_id.value
    );
    limited.push(workflow);
    setLimitedDecisions(limited.sort(sortByTitle));
    const limitedDecisionsData = workflows.filter(
      ({ workflow_id: id }) =>
        !limited.some(({ workflow_id }) => id === workflow_id)
    );

    setWorkflowsClone(limitedDecisionsData);
    const data = new FormData(e.target);

    setIsLoading(true);
    dispatch(createEndPointLimitedFlowRequest({ id, data }));
    handleCloseAddModal();
  };

  const handleShowAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };

  const handleCloseAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };

  const handleConfirmUserAdding = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const user = users.find((user) => user.user_id === e.target.user_id.value);
    const clone = structuredClone(notificationUsers);
    const index = clone.findIndex(
      (item) => item.user_id == e.target.user_id.value
    );
    if (index !== -1) {
      return toast.error(
        t("user_already_in_the_notification_list"),
        ToastOptions
      );
    }
    setIsLoading(true);
    clone.push(user);
    setNotificationUsers(clone);
    const data = new FormData(e.target);
    dispatch(createEndPointUserNotificationRequest({ id, data }));
  };

  const handleOpenUserNotificationModal = (id) => {
    setSelectedUserId(id);
    setIsUserNotificationDeleteModalOpen(true);
  };

  const handleUserNotificationClose = () => {
    setSelectedUserId(null);
    setIsUserNotificationDeleteModalOpen(false);
  };

  const handleUserNotificationConfirm = () => {
    const userId = selectedUserId;
    const filtered = notificationUsers.filter(
      (user) => user.user_id !== selectedUserId
    );
    setNotificationUsers(filtered);
    setIsLoading(true);
    dispatch(deleteEndPointUserNotificationRequest({ id, userId }));
    handleUserNotificationClose();
  };

  const handleBlockDeletionToggle = () => {
    setBlockDeletion(!blockDeletion);
    const updatedData = structuredClone(data);
    updatedData.block_deletion = !blockDeletion;
  };

  const handleApprovalSequenceChange = (e) => {
    setSelectedApprovalSequence(e.target.value);
    setShowApprovalSequenceSelect(false); // Hide the select after choosing
  };

  const copyEndpointURL = () => {
    navigator.clipboard.writeText(endPointURL);
    toast.success(`${t("copied")}!`, ToastOptions);
  };

  const togglePassword = () => {
    setSecret((prevSecret) => (prevSecret ? 0 : 1));
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(`${t("copied")}!`, ToastOptions);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <>
      {!editMode && (
        <div className="row my-md-5 mt-3">
          <div className="col-md col-12 mb-md-0 mb-3">
            <label htmlFor="api_name">{t("api_name")}</label>
            <input
              type="text"
              className="form-control"
              defaultValue={editMode ? data?.name : generateEndpointApiName()}
              key={data?.name}
              name="name"
              required
              disabled={data ? "disabled" : ""}
            />
          </div>
          <div className="col" />
        </div>
      )}
      {editMode && (
        <>
          <input
            type="hidden"
            className="form-control"
            defaultValue={editMode ? data?.name : generateEndpointApiName()}
            key={data?.name}
            name="name"
            disabled={data ? "disabled" : ""}
          />
        </>
      )}
      <div className="row my-md-2 mt-3">
        <div className="col-md col-12 mb-md-0">
          <div className="mb-md-3 mb-0">
            <label htmlFor="api_key">{t("api_key")}</label>
            <br />
            <div className="d-flex">
              <input
                type={secret ? "password" : "text"}
                className="form-control border-radius-none-right-top-bottom"
                name="api_key"
                defaultValue={!editMode ? randomApiKey : data?.api_key}
                key={data?.api_key}
                required
              />

              <div className="input-group-append">
                <button
                  title={secret ? t("show_api_key") : t("show_api_key")}
                  className="btn outline border-radius-none-right-top-bottom border-radius-none-left-top-bottom border-left-0"
                  type="button"
                  onClick={togglePassword}
                >
                  {secret ? <EyeIcon /> : <EyeOffIcon />}
                </button>
                <button
                  title={t("copy_api_key")}
                  className="btn outline border-radius-none-left-top-bottom"
                  type="button"
                  onClick={() => copyToClipboard(data?.api_key)}
                >
                  <CopyIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col" />
      </div>
      <div className="row my-md-5 mt-1">
        <div className="col-md col-12 instance-selection mb-md-0 mb-3">
          <label htmlFor="endpoint_instance_id">{t("endpoint_region")}</label>
          {editMode && (
            <div>
              <strong>
                {
                  instances.find(
                    (instance) =>
                      instance.endpoint_instance_id ===
                      data?.endpoint_instance_id
                  )?.title
                }
              </strong>
            </div>
          )}
          {!editMode && (
            <select
              className="form-control"
              name="endpoint_instance_id"
              defaultValue={data?.endpoint_instance_id}
              disabled={data && "disabled"}
              key={data?.endpoint_instance_id}
            >
              {instances?.length > 0 &&
                instances.map((instance) => (
                  <option
                    key={instance.endpoint_instance_id}
                    value={instance.endpoint_instance_id}
                  >
                    {instance.title}
                  </option>
                ))}
            </select>
          )}
        </div>
        <div className="col" />
      </div>
      {editMode && (
        <>
          <div className="row endpoint-info">
            <div className="col">
              <label>{t("api_endpoint_url")}</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  defaultValue={data?.api_url && `${data.api_url}${data.name}`}
                  onChange={(e) => setEndPointURL(e.target.value)}
                  className="form-control"
                  id="api_endpoint_full_url"
                  key={data?.name}
                />
                <div className="input-group-append">
                  <label
                    className="input-group-text clickable"
                    onClick={copyEndpointURL}
                    title={t("copy_endpoint_url")}
                  >
                    <CopyIcon />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <hr />
      {editMode && (
        <>
          <div className="card workflow-limitation">
            <div className="card-body">
              <label>{t("specifically_decision")}</label>
              <div className="row">
                <div className="col">
                  <span id="limited-workflows">
                    {limitedDecisions.length > 0 &&
                      limitedDecisions.map((workflow) => (
                        <button
                          key={workflow.workflow_id}
                          type="button"
                          className="btn mr-2"
                        >
                          {workflow.title}
                          <span
                            className="text-danger"
                            onClick={() =>
                              handleOpenFlowLimitationModal(
                                workflow.workflow_id
                              )
                            }
                          >
                            <TrashIcon />
                          </span>
                        </button>
                      ))}
                  </span>
                  {workflowsClone && workflowsClone.length > 0 ? (
                    <button
                      className="btn outline btn-sm text-success"
                      onClick={handleShowAddModal}
                      type="button"
                    >
                      <PlusIcon /> <span className="ml-2">{t("add")}</span>
                    </button>
                  ) : (
                    <>
                      {workflows.length === 0 && (
                        <p className="text-dark m-0">
                          {t("no_decision_flows_sentence")}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <div className="card mt-2">
                <div className="card-body">
                  <label>
                    {t("notifications")}: <br />
                    {t("active")}?
                    <div className="custom-control custom-switch float-right align-middle">
                      <input
                        type="checkbox"
                        className="custom-control-input w-100 z-index-100"
                        defaultValue="1"
                        onChange={(e) => setActive(e.target.checked)}
                        defaultChecked={
                          data
                            ? parseInt(data.notification)
                              ? "checked"
                              : ""
                            : ""
                        }
                        key={data.notification}
                        name="notifications"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="notifications"
                      />
                    </div>
                  </label>
                  {data && active ? (
                    <div className="row">
                      <div className="col">
                        <span>
                          {notificationUsers?.length > 0 &&
                            notificationUsers.map((user) => (
                              <button
                                key={user?.user_id}
                                type="button"
                                className="btn mr-2"
                              >
                                {user?.full_name}
                                <span
                                  className="text-danger"
                                  onClick={() =>
                                    handleOpenUserNotificationModal(
                                      user.user_id
                                    )
                                  }
                                >
                                  <TrashIcon />
                                </span>
                              </button>
                            ))}
                        </span>
                        <button
                          className="btn outline btn-sm text-success"
                          type="button"
                          onClick={handleShowAddUserModal}
                        >
                          <PlusIcon /> <span className="ml-2">{t("add")}</span>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mt-2">
                <div className="card-body">
                  {!showApprovalSequenceSelect && selectedApprovalSequence ? (
                    <div>
                      <label>{t("approval_sequence")}</label>
                      <div className="d-flex align-items-center">
                        <p className="m-0">
                          {
                            approvalSequences.find(
                              (seq) =>
                                seq.approval_sequence_id ==
                                selectedApprovalSequence
                            )?.title
                          }
                        </p>
                        <button
                          className="btn btn-link ml-2 p-0"
                          onClick={() => setShowApprovalSequenceSelect(true)}
                        >
                          {t("change")}
                        </button>
                      </div>
                    </div>
                  ) : (
                    !showApprovalSequenceSelect && (
                      <button
                        className="btn outline btn-sm text-success"
                        onClick={() => setShowApprovalSequenceSelect(true)}
                      >
                        {t("set_approval_sequence")}
                      </button>
                    )
                  )}
                  {showApprovalSequenceSelect && (
                    <>
                      <label>{t("approval_sequence")}</label>
                      <select
                        className="form-control"
                        value={selectedApprovalSequence || ""}
                        onChange={handleApprovalSequenceChange}
                        name="approval_sequence_id"
                      >
                        <option value="">
                          {t("select_approval_sequence")}
                        </option>
                        {approvalSequences.map((sequence) => (
                          <option
                            key={sequence.approval_sequence_id}
                            value={sequence.approval_sequence_id}
                          >
                            {sequence.title}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  <p className="mt-2 small text-muted">
                    {t(
                      "if_you_define_approval_sequence_every_release_will_have_to_be_approved_first"
                    )}
                    <input
                      type="hidden"
                      name="approval_sequence_id"
                      value={selectedApprovalSequence || ""}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-2 mb-4">
            <div className="card-body">
              <label>
                {t("block_deletion")}
                <div className="custom-control custom-switch float-right align-middle ml-2">
                  <input
                    type="checkbox"
                    className="custom-control-input w-100 z-index-100"
                    onChange={handleBlockDeletionToggle}
                    checked={blockDeletion}
                    key={blockDeletion}
                    name="block_deletion"
                    value={1}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="block_deletion"
                  />
                </div>
              </label>
              <p className="mt-2 small text-muted">
                {t(
                  "prevents_this_endpoint_from_being_deleted_until_manually_unlocked"
                )}
              </p>
            </div>
          </div>
        </>
      )}
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_limit_decision")}
        message={
          <>
            <p>{t("delete_confirmation_question")}</p>
            <p>{t("delete_warning_text")}</p>
          </>
        }
        icon=""
        open={isFlowLimitationDeleteModalOpen}
      />
      <DeleteConfirm
        handleClose={handleUserNotificationClose}
        handleConfirm={handleUserNotificationConfirm}
        title={t("delete_notification_user")}
        message={<p>{t("receiving_notifications")}</p>}
        icon=""
        open={isUserNotificationDeleteModalOpen}
      />
      {workflowsClone && workflowsClone.length > 0 && (
        <ReleaseEndPoint
          open={isAddFlowModalOpen}
          handleClose={handleCloseAddModal}
          handleConfirm={handleConfirmAdding}
          action={
            <div>
              <label>{t("nav_bar_decision_flow")}</label>
              <select className="form-control" name="workflow_id">
                {workflowsClone &&
                  workflowsClone.map((workflow) => (
                    <option
                      key={workflow.workflow_id}
                      value={workflow.workflow_id}
                    >
                      {workflow.title}
                    </option>
                  ))}
              </select>
            </div>
          }
        />
      )}

      <ReleaseEndPoint
        open={isAddUserModalOpen}
        title={t("select_user")}
        handleClose={handleCloseAddUserModal}
        handleConfirm={handleConfirmUserAdding}
        action={
          <div>
            <label>{t("user_camelcase")}</label>
            <select className="form-control" name="user_id">
              {users?.length > 0 &&
                users.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.full_name}
                  </option>
                ))}
            </select>
          </div>
        }
      />
    </>
  );
};

EndPointContent.propTypes = {
  instances: PropTypes.array,
  editMode: PropTypes.bool,
  data: PropTypes.object,
  workflows: PropTypes.array,
  users: PropTypes.array,
  approvalSequences: PropTypes.array,
};

export default EndPointContent;
