import { handleActions } from "redux-actions";
import {
  createDecisionTableFailure,
  createDecisionTableRequest,
  createDecisionTableSuccess,
  deleteDecisionTableFailure,
  deleteDecisionTableRequest,
  deleteDecisionTableRowFailure,
  deleteDecisionTableRowRequest,
  deleteDecisionTableRowSuccess,
  deleteDecisionTableSuccess,
  deleteTableColumnFailure,
  deleteTableColumnRequest,
  deleteTableColumnSuccess,
  exportTableDataFailure,
  exportTableDataRequest,
  exportTableDataSuccess,
  getConditionsFailure,
  getConditionsRequest,
  getConditionsSuccess,
  getDecisionTableFailure,
  getDecisionTableRequest,
  getDecisionTableRevisionFailure,
  getDecisionTableRevisionRequest,
  getDecisionTableRevisionsFailure,
  getDecisionTableRevisionsRequest,
  getDecisionTableRevisionsSuccess,
  getDecisionTableRevisionSuccess,
  getDecisionTableRevision2Failure,
  getDecisionTableRevision2Request,
  getDecisionTableRevision2Success,
  getDecisionTableRowsFailure,
  getDecisionTableRowsRequest,
  getDecisionTableRowsSuccess,
  getDecisionTablesFailure,
  getDecisionTablesRequest,
  getDecisionTablesSuccess,
  getDecisionTableSuccess,
  getRunTestObjectResultFailure,
  getRunTestObjectResultRequest,
  getRunTestObjectResultSuccess,
  getRunTestResultStatusTableFailure,
  getRunTestResultStatusTableRequest,
  getRunTestResultStatusTableSuccess,
  getRunTestSimulationResultFailure,
  getRunTestSimulationResultRequest,
  getRunTestSimulationResultSuccess,
  importTableDataFailure,
  importTableDataRequest,
  importTableDataSuccess,
  updateDecisionTableDefinitionFailure,
  updateDecisionTableDefinitionRequest,
  updateDecisionTableDefinitionSuccess,
  updateDecisionTableFailure,
  updateDecisionTableRequest,
  updateDecisionTableSuccess,
  //getOverlapValidationRequest
  getOverlapValidationRequest,
  getOverlapValidationSuccess,
  getOverlapValidationFailure,
  deleteDecisionTableConflict,
  //workflows
  getDecisionTableWorkflowsFailure,
  getDecisionTableWorkflowsRequest,
  getDecisionTableWorkflowsSuccess,
} from "redux/decisionTables/action";
import { sortByOrder } from "utility/utility";

const initialState = {
  decisionTables: [],
  decisionTablesCount: 0,
  decisionTable: {},
  decisionTableRows: [],
  decisionTableRevisions: [],
  revision: {},
  revision2: {},
  runTestResultStatus: {},
  runTestObjectResult: {},
  runTestSimulationResult: {},
  conditions: [],
  overlaps: [],
  exportedTableData: "",
  isGetDecisionTablesSuccess: false,
  isGetDecisionTablesError: false,
  isCreatedDecisionTableSuccess: false,
  isCreatedDecisionTableError: false,
  isUpdatedDecisionTableDefinitionSuccess: false,
  isUpdatedDecisionTableDefinitionError: false,
  isGetDecisionTableSuccess: false,
  isGetDecisionTableError: false,
  isUpdatedDecisionTableSuccess: false,
  isUpdatedDecisionTableError: false,
  isGetDecisionTableRowsSuccess: false,
  isGetDecisionTableRowsError: false,
  isRemovedDecisionTableRowSuccess: false,
  isRemovedDecisionTableRowError: false,
  isGetDecisionTableRevisionsSuccess: false,
  isGetDecisionTableRevisionsError: false,
  isGetRevisionSuccess: false,
  isGetRevisionError: false,
  isGetRevision2Success: false,
  isGetRevision2Error: false,
  isImportTableDataSuccess: false,
  isImportTableDataError: false,
  isExportTableDataSuccess: false,
  isExportTableDataError: false,
  isGetTestResultStatusSuccess: false,
  isGetTestResultStatusError: false,
  isGetTestObjectResultSuccess: false,
  isGetTestObjectResultError: false,
  isGetTestSimulationResultSuccess: false,
  isGetTestSimulationResultError: false,
  isRemovedDecisionTableSuccess: false,
  isRemovedDecisionTableError: false,
  isRemovedTableColumnSuccess: false,
  isRemovedTableColumnError: false,
  newDecisionTableId: "",
  isGetConditionsSuccess: false,
  isGetConditionsError: false,
  //overlap
  isGetOverlapValidationSuccess: false,
  isGetOverlapValidationError: false,
  isDeleteDecisionTableConflict: false,
  //workflows
  isGetDecisionTableWorkflowsSuccess: false,
  isGetDecisionTableWorkflowsError: false,
  decisionTableWorkflows: [],
};

const reducer = handleActions(
  {
    [getDecisionTablesRequest]: (state) => ({
      ...state,
      isGetDecisionTablesSuccess: false,
      isGetDecisionTablesError: false,
    }),
    [getDecisionTablesSuccess]: (state, { payload }) => ({
      ...state,
      decisionTables: payload.decision_tables || [],
      decisionTablesCount: payload.total_count || 0,
      isGetDecisionTablesSuccess: true,
      isGetDecisionTablesError: false,
    }),
    [getDecisionTablesFailure]: (state) => ({
      ...state,
      isGetDecisionTablesSuccess: false,
      isGetDecisionTablesError: true,
    }),
    // create decision table
    [createDecisionTableRequest]: (state) => ({
      ...state,
      isCreatedDecisionTableSuccess: false,
      isCreatedDecisionTableError: false,
    }),
    [createDecisionTableSuccess]: (state, { payload }) => ({
      ...state,
      newDecisionTableId: payload ?? "",
      isCreatedDecisionTableSuccess: true,
      isCreatedDecisionTableError: false,
    }),
    [createDecisionTableFailure]: (state) => ({
      ...state,
      isCreatedDecisionTableSuccess: false,
      isCreatedDecisionTableError: true,
    }),
    // update decision table columns
    [updateDecisionTableDefinitionRequest]: (state) => ({
      ...state,
      isUpdatedDecisionTableDefinitionSuccess: false,
      isUpdatedDecisionTableDefinitionError: false,
    }),
    [updateDecisionTableDefinitionSuccess]: (state) => ({
      ...state,
      isUpdatedDecisionTableDefinitionSuccess: true,
      isUpdatedDecisionTableDefinitionError: false,
    }),
    [updateDecisionTableDefinitionFailure]: (state) => ({
      ...state,
      isUpdatedDecisionTableDefinitionSuccess: false,
      isUpdatedDecisionTableDefinitionError: true,
    }),
    // get decision table
    [getDecisionTableRequest]: (state) => ({
      ...state,
      isGetDecisionTableSuccess: false,
      isGetDecisionTableError: false,
    }),
    [getDecisionTableSuccess]: (state, { payload }) => {
      let decisionTable = {};
      if (payload) {
        decisionTable = structuredClone(payload);
        decisionTable.cols = decisionTable.cols.sort(sortByOrder);
      }
      return {
        ...state,
        decisionTable,
        isGetDecisionTableSuccess: true,
        isGetDecisionTableError: false,
      };
    },
    [getDecisionTableFailure]: (state) => ({
      ...state,
      isGetDecisionTableSuccess: false,
      isGetDecisionTableError: true,
    }),
    // get conditions
    [getConditionsRequest]: (state) => ({
      ...state,
      isGetConditionsSuccess: false,
      isGetConditionsError: false,
    }),
    [getConditionsSuccess]: (state, { payload }) => ({
      ...state,
      conditions: payload ?? [],
      isGetConditionsSuccess: true,
      isGetConditionsError: false,
    }),
    [getConditionsFailure]: (state) => ({
      ...state,
      isGetConditionsSuccess: false,
      isGetConditionsError: true,
    }),
    // update decision table
    [updateDecisionTableRequest]: (state) => ({
      ...state,
      isUpdatedDecisionTableSuccess: false,
      isUpdatedDecisionTableError: false,
    }),
    [updateDecisionTableSuccess]: (state) => ({
      ...state,
      isUpdatedDecisionTableSuccess: true,
      isUpdatedDecisionTableError: false,
    }),
    [updateDecisionTableFailure]: (state) => ({
      ...state,
      isUpdatedDecisionTableSuccess: false,
      isUpdatedDecisionTableError: true,
    }),
    // get decision table rows
    [getDecisionTableRowsRequest]: (state) => ({
      ...state,
      isGetDecisionTableRowsSuccess: false,
      isGetDecisionTableRowsError: false,
    }),
    [getDecisionTableRowsSuccess]: (state, { payload }) => ({
      ...state,
      decisionTableRows: payload ?? [],
      isGetDecisionTableRowsSuccess: true,
      isGetDecisionTableRowsError: false,
    }),
    [getDecisionTableRowsFailure]: (state) => ({
      ...state,
      isGetDecisionTableRowsSuccess: false,
      isGetDecisionTableRowsError: true,
    }),
    // get decision table rows
    [deleteDecisionTableRowRequest]: (state) => ({
      ...state,
      isRemovedDecisionTableRowSuccess: false,
      isRemovedDecisionTableRowError: false,
    }),
    [deleteDecisionTableRowSuccess]: (state) => ({
      ...state,
      isRemovedDecisionTableRowSuccess: true,
      isRemovedDecisionTableRowError: false,
    }),
    [deleteDecisionTableRowFailure]: (state) => ({
      ...state,
      isRemovedDecisionTableRowSuccess: false,
      isRemovedDecisionTableRowError: true,
    }),
    // get decision table revisions
    [getDecisionTableRevisionsRequest]: (state) => ({
      ...state,
      isGetDecisionTableRevisionsSuccess: false,
      isGetDecisionTableRevisionsError: false,
    }),
    [getDecisionTableRevisionsSuccess]: (state, { payload }) => ({
      ...state,
      decisionTableRevisions: payload ?? [],
      isGetDecisionTableRevisionsSuccess: true,
      isGetDecisionTableRevisionsError: false,
    }),
    [getDecisionTableRevisionsFailure]: (state) => ({
      ...state,
      isGetDecisionTableRevisionsSuccess: false,
      isGetDecisionTableRevisionsError: true,
    }),
    // get decision table revision
    [getDecisionTableRevisionRequest]: (state) => ({
      ...state,
      isGetRevisionSuccess: false,
      isGetRevisionError: false,
    }),
    [getDecisionTableRevisionSuccess]: (state, { payload }) => ({
      ...state,
      revision: payload ?? {},
      isGetRevisionSuccess: true,
      isGetRevisionError: false,
    }),
    [getDecisionTableRevisionFailure]: (state) => ({
      ...state,
      isGetRevisionSuccess: false,
      isGetRevisionError: true,
    }),
    // get decision table revision2
    [getDecisionTableRevision2Request]: (state) => ({
      ...state,
      isGetRevision2Success: false,
      isGetRevision2Error: false,
    }),
    [getDecisionTableRevision2Success]: (state, { payload }) => ({
      ...state,
      revision2: payload ?? {},
      isGetRevision2Success: true,
      isGetRevision2Error: false,
    }),
    [getDecisionTableRevision2Failure]: (state) => ({
      ...state,
      isGetRevision2Success: false,
      isGetRevision2Error: true,
    }),
    // import decision table data
    [importTableDataRequest]: (state) => ({
      ...state,
      isImportTableDataSuccess: false,
      isImportTableDataError: false,
    }),
    [importTableDataSuccess]: (state) => ({
      ...state,
      isImportTableDataSuccess: true,
      isImportTableDataError: false,
    }),
    [importTableDataFailure]: (state) => ({
      ...state,
      isImportTableDataSuccess: false,
      isImportTableDataError: true,
    }),
    // export decision table data
    [exportTableDataRequest]: (state) => ({
      ...state,
      isExportTableDataSuccess: false,
      isExportTableDataError: false,
    }),
    [exportTableDataSuccess]: (state, { payload }) => ({
      ...state,
      exportedTableData: payload,
      isExportTableDataSuccess: true,
      isExportTableDataError: false,
    }),
    [exportTableDataFailure]: (state) => ({
      ...state,
      isExportTableDataSuccess: false,
      isExportTableDataError: true,
    }),
    // get decision table test
    [getRunTestResultStatusTableRequest]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusTableSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultStatus: payload,
      isGetTestResultStatusSuccess: true,
      isGetTestResultStatusError: false,
    }),
    [getRunTestResultStatusTableFailure]: (state) => ({
      ...state,
      isGetTestResultStatusSuccess: false,
      isGetTestResultStatusError: true,
    }),
    // get decision table test object result
    [getRunTestObjectResultRequest]: (state) => ({
      ...state,
      isGetTestObjectResultSuccess: false,
      isGetTestObjectResultError: false,
    }),
    [getRunTestObjectResultSuccess]: (state, { payload }) => ({
      ...state,
      runTestObjectResult: payload,
      isGetTestObjectResultSuccess: true,
      isGetTestObjectResultError: false,
    }),
    [getRunTestObjectResultFailure]: (state) => ({
      ...state,
      isGetTestObjectResultSuccess: false,
      isGetTestObjectResultError: true,
    }),
    // get decision table test object result
    [getRunTestSimulationResultRequest]: (state) => ({
      ...state,
      isGetTestSimulationResultSuccess: false,
      isGetTestSimulationResultError: false,
    }),
    [getRunTestSimulationResultSuccess]: (state, { payload }) => ({
      ...state,
      runTestSimulationResult: payload ?? {},
      isGetTestSimulationResultSuccess: true,
      isGetTestSimulationResultError: false,
    }),
    [getRunTestSimulationResultFailure]: (state) => ({
      ...state,
      isGetTestSimulationResultSuccess: false,
      isGetTestSimulationResultError: true,
    }),
    // remove decision table
    [deleteDecisionTableRequest]: (state) => ({
      ...state,
      isRemovedDecisionTableSuccess: false,
      isRemovedDecisionTableError: false,
      isDeleteDecisionTableConflict: false,
    }),
    [deleteDecisionTableSuccess]: (state) => ({
      ...state,
      isRemovedDecisionTableSuccess: true,
      isRemovedDecisionTableError: false,
    }),
    [deleteDecisionTableFailure]: (state) => ({
      ...state,
      isRemovedDecisionTableSuccess: false,
      isRemovedDecisionTableError: true,
    }),
    [deleteDecisionTableConflict]: (state) => ({
      ...state,
      isDeleteDecisionTableConflict: true,
      isRemovedDecisionTableError: false,
      isRemovedDecisionTableSuccess: false,
    }),
    // remove decision table column
    [deleteTableColumnRequest]: (state) => ({
      ...state,
      isRemovedTableColumnSuccess: false,
      isRemovedTableColumnError: false,
    }),
    [deleteTableColumnSuccess]: (state) => ({
      ...state,
      isRemovedTableColumnSuccess: true,
      isRemovedTableColumnError: false,
    }),
    [deleteTableColumnFailure]: (state) => ({
      ...state,
      isRemovedTableColumnSuccess: false,
      isRemovedTableColumnError: true,
    }),
    //overlap
    [getOverlapValidationRequest]: (state) => ({
      ...state,
      isGetOverlapValidationSuccess: false,
      isGetOverlapValidationError: false,
    }),
    [getOverlapValidationSuccess]: (state, { payload }) => ({
      ...state,
      overlaps: payload ?? [],
      isGetOverlapValidationSuccess: true,
      isGetOverlapValidationError: false,
    }),
    [getOverlapValidationFailure]: (state) => ({
      ...state,
      isGetOverlapValidationSuccess: false,
      isGetOverlapValidationError: true,
    }),
    //workflows
    [getDecisionTableWorkflowsRequest]: (state) => ({
      ...state,
      isGetDecisionTableWorkflowsSuccess: false,
      isGetDecisionTableWorkflowsError: false,
    }),
    [getDecisionTableWorkflowsSuccess]: (state, { payload }) => ({
      ...state,
      decisionTableWorkflows: payload ?? [],
      isGetDecisionTableWorkflowsSuccess: true,
      isGetDecisionTableWorkflowsError: false,
    }),
    [getDecisionTableWorkflowsFailure]: (state) => ({
      ...state,
      isGetDecisionTableWorkflowsSuccess: false,
      isGetDecisionTableWorkflowsError: true,
    }),
  },
  initialState
);

export default reducer;
