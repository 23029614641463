import React, { useState } from "react";
import PropTypes from "prop-types";
import ConstraintTableRow from "./ConstraintTableRow";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { t } from "i18next";

const defaultConstraint = {
  type: "min_max", // options: "min_max", "list", "regex"
  min_value: "",
  max_value: "",
  values: "",
  pattern: "",
};

const ConstraintManager = ({
  constraints: initialConstraints = [],
  onChange,
  t,
}) => {
  const [constraints, setConstraints] = useState(initialConstraints);
  // newConstraint is stored as a row as well (at index = constraints.length)
  const [newConstraint, setNewConstraint] = useState({ ...defaultConstraint });

  const updateConstraints = (updated) => {
    setConstraints(updated);
    onChange && onChange(updated);
  };

  const handleRowChange = (index, updatedConstraint) => {
    const updated = [...constraints];
    updated[index] = updatedConstraint;
    updateConstraints(updated);
  };

  const handleDeleteRow = (index) => {
    const updated = constraints.filter((_, i) => i !== index);
    updateConstraints(updated);
  };

  // For the new row changes, update newConstraint state.
  const handleNewRowChange = (index, updatedConstraint) => {
    setNewConstraint(updatedConstraint);
  };

  // When clicking "Add" on the new row, push newConstraint to the list.
  const handleAddNewRow = () => {
    updateConstraints([...constraints, newConstraint]);
    setNewConstraint({ ...defaultConstraint });
  };

  return (
    <div>
      <h5 className="mb-3">{t("constraints")}</h5>
      <div className="table-responsive">
        <table className="table table-pretty">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Type</th>
              <th>{t("value")}</th>
              <th style={{ width: "10%" }} className="text-end">
                {t("action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {constraints.map((con, index) => (
              <ConstraintTableRow
                key={index}
                index={index}
                constraint={con}
                onChange={handleRowChange}
                onDelete={handleDeleteRow}
                isNew={false}
                t={t}
              />
            ))}

            <tr>
              <td colSpan="4" className="p-0">
                <div className="d-flex justify-content-center add-row-bg p-1 m-0">
                  <button
                    onClick={() => handleAddNewRow()}
                    className="btn outline m-0"
                    type="button"
                    title={t("add_bin")}
                  >
                    <PlusIcon
                      style={{
                        filter: "brightness(0.5)",
                      }}
                    />
                    <span className="ml-2">{t("add_constraint")}</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ConstraintManager.propTypes = {
  constraints: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      min_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      max_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      values: PropTypes.string,
      pattern: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  t: PropTypes.func,
};

export default ConstraintManager;
