import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./Pagination.css";
import { ReactComponent as BackIcon } from "assets/icons/back-pagination.svg";
import { ReactComponent as NextIcon } from "assets/icons/next-pagination.svg";

export const Pagination = ({
  currentPage,
  completeItemsCount = 0,
  setCurrentPage,
  itemsPerPage = 10,
}) => {
  const { t } = useTranslation();

  const totalPages = Math.ceil(completeItemsCount / itemsPerPage);
  const currentPageNumber = currentPage + 1;

  // Calculate page ranges to show
  const getPageNumbers = () => {
    const delta = 2; // Number of pages to show before and after current page
    const range = [];
    for (
      let i = Math.max(2, currentPageNumber - delta);
      i <= Math.min(totalPages - 1, currentPageNumber + delta);
      i++
    ) {
      range.push(i);
    }

    if (currentPageNumber - delta > 2) {
      range.unshift("...");
    }
    if (currentPageNumber + delta < totalPages - 1) {
      range.push("...");
    }

    if (totalPages > 1) {
      range.unshift(1);
    }
    if (totalPages > 2) {
      range.push(totalPages);
    }

    return range;
  };

  return (
    <div className="pagination-container mb-4 mt-4">
      <div className="pagination-info">
        {t("showing_x_to_y_of_z_items", {
          from: Math.min(currentPage * itemsPerPage + 1, completeItemsCount),
          to: Math.min((currentPage + 1) * itemsPerPage, completeItemsCount),
          total: completeItemsCount,
        })}
      </div>
      {totalPages > 1 && (
        <div className="pagination">
          {currentPage > 0 && (
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              aria-label="Previous page"
            >
              <BackIcon />
            </button>
          )}

          {getPageNumbers().map((pageNum, idx) => (
            <button
              key={idx}
              className={pageNum === currentPageNumber ? "active" : ""}
              onClick={() => pageNum !== "..." && setCurrentPage(pageNum - 1)}
              disabled={pageNum === "..."}
            >
              {pageNum}
            </button>
          ))}

          {currentPageNumber < totalPages && (
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              aria-label="Next page"
            >
              <NextIcon />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  completeItemsCount: PropTypes.number,
  itemsPerPage: PropTypes.number,
};

export default Pagination;
