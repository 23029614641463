import React from "react";
import PropTypes from "prop-types";
import CustomSelect from "components/CustomSelect";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

const constraintOptions = [
  { value: "min_max", label: "Min/Max" },
  { value: "list", label: "List" },
  { value: "regex", label: "Regex" },
];

const ConstraintValueInput = ({ constraint, onChange, t }) => {
  if (constraint.type === "min_max") {
    const handleMinChange = (e) => onChange("min_value", e.target.value);
    const handleMaxChange = (e) => onChange("max_value", e.target.value);
    return (
      <div className="input-group d-flex justify-content-between border-0 rounded-0">
        <span className="input-group-text border-0 rounded-0">[</span>
        <input
          type="number"
          className="form-control border-0"
          placeholder={t("min")}
          value={constraint.min_value ?? ""}
          onChange={handleMinChange}
        />
        <span className="input-group-text border-0 rounded-0">,</span>
        <input
          type="number"
          className="form-control border-0"
          placeholder={t("max")}
          value={constraint.max_value ?? ""}
          onChange={handleMaxChange}
        />
        <span className="input-group-text border-0 rounded-0">]</span>
      </div>
    );
  }

  if (constraint.type === "list") {
    return (
      <input
        type="text"
        className="form-control border-0 rounded-0"
        placeholder={t("comma_separated_values")}
        value={constraint.values ?? ""}
        onChange={(e) => onChange("values", e.target.value)}
      />
    );
  }

  if (constraint.type === "regex") {
    return (
      <input
        type="text"
        className="form-control border-0 rounded-0"
        placeholder={t("regex_pattern")}
        value={constraint.pattern ?? ""}
        onChange={(e) => onChange("pattern", e.target.value)}
      />
    );
  }

  return null;
};

ConstraintValueInput.propTypes = {
  constraint: PropTypes.shape({
    type: PropTypes.string.isRequired,
    min_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    values: PropTypes.string,
    pattern: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const ConstraintTableRow = ({
  index,
  constraint,
  onChange,
  onDelete, // for existing rows
  onAdd, // for new row
  isNew,
  t,
}) => {
  const handleTypeChange = (selectedOption) => {
    const newType = selectedOption.value;
    let newFields = {};
    if (newType === "min_max") {
      newFields = { min_value: "", max_value: "" };
    } else if (newType === "list") {
      newFields = { values: "" };
    } else if (newType === "regex") {
      newFields = { pattern: "" };
    }
    onChange(index, { type: newType, ...newFields });
  };

  const handleValueChange = (field, value) => {
    onChange(index, { ...constraint, [field]: value });
  };

  const currentTypeOption = constraintOptions.find(
    (option) => option.value === constraint.type
  );

  return (
    <tr>
      <td
        style={{ verticalAlign: "middle", minWidth: "150px" }}
        className="m-0 p-0"
      >
        <CustomSelect
          defaultValue={currentTypeOption}
          options={constraintOptions}
          selectKey={constraint.type}
          handleChange={(option) => handleTypeChange(option)}
          selectStyle={{
            dropdownIndicator: (styles) => ({
              ...styles,
              marginRight: "0",
            }),
            control: (styles) => ({
              ...styles,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              border: 0,
            }),
          }}
          menuPortalTarget={document.body} // Render menu at the document root
          menuPosition="fixed" // Position menu fixed
        />
      </td>
      <td style={{ verticalAlign: "middle" }} className="m-0 p-0">
        <ConstraintValueInput
          constraint={constraint}
          onChange={handleValueChange}
          t={t}
        />
      </td>
      <td
        style={{ verticalAlign: "middle" }}
        className="text-end m-0 p-0 text-center"
      >
        {isNew ? (
          <button className="btn btn-outline" onClick={() => onAdd(index)}>
            <PlusIcon />
          </button>
        ) : (
          <button className="btn btn-outline" onClick={() => onDelete(index)}>
            <TrashIcon />
          </button>
        )}
      </td>
    </tr>
  );
};

ConstraintTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  constraint: PropTypes.shape({
    type: PropTypes.string.isRequired,
    min_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    values: PropTypes.string,
    pattern: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
  isNew: PropTypes.bool,
  t: PropTypes.func,
};

ConstraintTableRow.defaultProps = {
  isNew: false,
};

export default ConstraintTableRow;
