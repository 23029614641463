import { createAction } from "redux-actions";

// Create Impact Scenario
export const createImpactScenarioRequest = createAction(
  "CREATE_IMPACT_SCENARIO_REQUEST"
);
export const createImpactScenarioSuccess = createAction(
  "CREATE_IMPACT_SCENARIO_SUCCESS"
);
export const createImpactScenarioFailure = createAction(
  "CREATE_IMPACT_SCENARIO_FAILURE"
);

// Execute Impact Scenario
export const executeImpactScenarioRequest = createAction(
  "EXECUTE_IMPACT_SCENARIO_REQUEST"
);
export const executeImpactScenarioSuccess = createAction(
  "EXECUTE_IMPACT_SCENARIO_SUCCESS"
);
export const executeImpactScenarioFailure = createAction(
  "EXECUTE_IMPACT_SCENARIO_FAILURE"
);
export const addImpactScenarioResult = createAction(
  "ADD_IMPACT_SCENARIO_RESULT"
);
export const updateImpactScenarioProgress = createAction(
  "UPDATE_IMPACT_SCENARIO_PROGRESS"
);

// Reset Impact Analyzer State
export const resetImpactAnalyzerState = createAction(
  "RESET_IMPACT_ANALYZER_STATE"
);
