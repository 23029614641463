import { handleActions } from "redux-actions";
import {
  createDataSourceFailure,
  createDataSourceRequest,
  createDataSourceSuccess,
  createOAuth2TokenFailure,
  createOAuth2TokenRequest,
  createOAuth2TokenSuccess,
  deleteDataSourceFailure,
  deleteDataSourceConflict,
  deleteDataSourceHeaderFailure,
  deleteDataSourceHeaderRequest,
  deleteDataSourceHeaderSuccess,
  deleteDataSourceParamFailure,
  deleteDataSourceParamRequest,
  deleteDataSourceParamSuccess,
  deleteDataSourceRequest,
  deleteDataSourceSuccess,
  deleteOAuth2TokenFailure,
  deleteOAuth2TokenRequest,
  deleteOAuth2TokenSuccess,
  getDataSourceFailure,
  getDataSourceRequest,
  getDataSourcesFailure,
  getDataSourcesRequest,
  getDataSourcesSuccess,
  getDataSourceSuccess,
  getExternalDataTestingFailure,
  getExternalDataTestingRequest,
  getExternalDataTestingSuccess,
  getOAuth2TokenFailure,
  getOAuth2TokenRequest,
  getOAuth2TokensFailure,
  getOAuth2TokensRequest,
  getOAuth2TokensSuccess,
  getOAuth2TokenSuccess,
  getRunTestResultFailure,
  getRunTestResultRequest,
  getRunTestResultSuccess,
  updateDataSourceFailure,
  updateDataSourceRequest,
  updateDataSourceSuccess,
  updateOAuth2TokenFailure,
  updateOAuth2TokenRequest,
  updateOAuth2TokenSuccess,
  getExternalDataResponseRequest,
  getExternalDataResponseSuccess,
  getExternalDataResponseFailure,
  getExternalDataResponsesRequest,
  getExternalDataResponsesSuccess,
  getExternalDataResponsesFailure,
  createExternalDataResponseFailure,
  createExternalDataResponseRequest,
  createExternalDataResponseSuccess,
  updateExternalDataResponseFailure,
  updateExternalDataResponseRequest,
  updateExternalDataResponseSuccess,
  deleteExternalDataResponseFailure,
  deleteExternalDataResponseRequest,
  deleteExternalDataResponseSuccess,
} from "redux/dataSources/action";

const initialState = {
  dataSources: [],
  dataSource: {},
  dataSourcesCount: 0,
  newDataSourceId: "",
  runTestResult: {},
  oauth2Tokens: [],
  oauth2Token: {},
  externalDataTestingResult: {},
  dataResponse: {},
  dataResponses: [],
  isGetOAuth2TokensSuccess: false,
  isGetOAuth2TokensError: false,
  isGetOAuth2TokenSuccess: false,
  isGetOAuth2TokenError: false,
  isUpdatedOAuth2TokenSuccess: false,
  isUpdatedOAuth2TokenError: false,
  isCreatedOAuth2TokenSuccess: false,
  isCreatedOAuth2TokenError: false,
  isDeletedOAuth2TokenSuccess: false,
  isDeletedOAuth2TokenError: false,
  isGetDataSourcesSuccess: false,
  isGetDataSourcesError: false,
  isGetDataSourceSuccess: false,
  isGetDataSourceError: false,
  isGetRunTestResultSuccess: false,
  isGetRunTestResultError: false,
  isGetExternalDataTestingSuccess: false,
  isGetExternalDataTestingError: false,
  isUpdatedDataSourceSuccess: false,
  isUpdatedDataSourceError: false,
  isDeletedDataSourceSuccess: false,
  isDeletedDataSourceError: false,
  isDeletedDataSourceConflict: false,
  isDeletedDataSourceParamSuccess: false,
  isDeletedDataSourceParamError: false,
  isDeletedDataSourceHeaderSuccess: false,
  isDeletedDataSourceHeaderError: false,
  isCreatedDataSourceSuccess: false,
  isCreatedDataSourceError: false,
  //Response
  isGetResponseSuccess: false,
  isGetResponseError: false,
  isGetResponsesSuccess: false,
  isGetResponsesError: false,
  isCreatedResponseSuccess: false,
  isCreatedResponseError: false,
  isUpdatedResponseSuccess: false,
  isUpdatedResponseError: false,
  isDeletedResponseSuccess: false,
  isDeletedResponseError: false,
};

const reducer = handleActions(
  {
    // get data sources
    [getDataSourcesRequest]: (state) => ({
      ...state,
      isGetDataSourcesSuccess: false,
      isGetDataSourcesError: false,
    }),
    [getDataSourcesSuccess]: (state, { payload }) => ({
      ...state,
      dataSources: payload.external_data || [],
      dataSourcesCount: payload.total_count || 0,
      isGetDataSourcesSuccess: true,
      isGetDataSourcesError: false,
    }),
    [getDataSourcesFailure]: (state) => ({
      ...state,
      isGetDataSourcesSuccess: false,
      isGetDataSourcesError: true,
    }),
    // get data source
    [getDataSourceRequest]: (state) => ({
      ...state,
      isGetDataSourceSuccess: false,
      isGetDataSourceError: false,
    }),
    [getDataSourceSuccess]: (state, { payload }) => ({
      ...state,
      dataSource: payload,
      isGetDataSourceSuccess: true,
      isGetDataSourceError: false,
    }),
    [getDataSourceFailure]: (state) => ({
      ...state,
      isGetDataSourceSuccess: false,
      isGetDataSourceError: true,
    }),
    // get run test result
    [getRunTestResultRequest]: (state) => ({
      ...state,
      isGetRunTestResultSuccess: false,
      isGetRunTestResultError: false,
    }),
    [getRunTestResultSuccess]: (state, { payload }) => ({
      ...state,
      runTestResult: payload || {},
      isGetRunTestResultSuccess: true,
      isGetRunTestResultError: false,
    }),
    [getRunTestResultFailure]: (state) => ({
      ...state,
      isGetRunTestResultSuccess: false,
      isGetRunTestResultError: true,
    }),
    // get run test result
    [getExternalDataTestingRequest]: (state) => ({
      ...state,
      isGetExternalDataTestingSuccess: false,
      isGetExternalDataTestingError: false,
    }),
    [getExternalDataTestingSuccess]: (state, { payload }) => ({
      ...state,
      externalDataTestingResult: payload || {},
      isGetExternalDataTestingSuccess: true,
      isGetExternalDataTestingError: false,
    }),
    [getExternalDataTestingFailure]: (state) => ({
      ...state,
      isGetExternalDataTestingSuccess: false,
      isGetExternalDataTestingError: true,
    }),
    // update data source
    [updateDataSourceRequest]: (state) => ({
      ...state,
      isUpdatedDataSourceSuccess: false,
      isUpdatedDataSourceError: false,
    }),
    [updateDataSourceSuccess]: (state) => ({
      ...state,
      isUpdatedDataSourceSuccess: true,
      isUpdatedDataSourceError: false,
    }),
    [updateDataSourceFailure]: (state) => ({
      ...state,
      isUpdatedDataSourceSuccess: false,
      isUpdatedDataSourceError: true,
    }),
    // delete data sources
    [deleteDataSourceRequest]: (state) => ({
      ...state,
      isDeletedDataSourceSuccess: false,
      isDeletedDataSourceError: false,
    }),
    [deleteDataSourceSuccess]: (state) => ({
      ...state,
      isDeletedDataSourceSuccess: true,
      isDeletedDataSourceError: false,
    }),
    [deleteDataSourceFailure]: (state) => ({
      ...state,
      isDeletedDataSourceSuccess: false,
      isDeletedDataSourceError: true,
    }),
    [deleteDataSourceConflict]: (state) => ({
      ...state,
      isDeletedDataSourceSuccess: false,
      isDeletedDataSourceError: false,
      isDeletedDataSourceConflict: true,
    }),
    // delete data source param
    [deleteDataSourceParamRequest]: (state) => ({
      ...state,
      isDeletedDataSourceParamSuccess: false,
      isDeletedDataSourceParamError: false,
    }),
    [deleteDataSourceParamSuccess]: (state) => ({
      ...state,
      isDeletedDataSourceParamSuccess: true,
      isDeletedDataSourceParamError: false,
    }),
    [deleteDataSourceParamFailure]: (state) => ({
      ...state,
      isDeletedDataSourceParamSuccess: false,
      isDeletedDataSourceParamError: true,
    }),
    // delete data source header
    [deleteDataSourceHeaderRequest]: (state) => ({
      ...state,
      isDeletedDataSourceHeaderSuccess: false,
      isDeletedDataSourceHeaderError: false,
    }),
    [deleteDataSourceHeaderSuccess]: (state) => ({
      ...state,
      isDeletedDataSourceHeaderSuccess: true,
      isDeletedDataSourceHeaderError: false,
    }),
    [deleteDataSourceHeaderFailure]: (state) => ({
      ...state,
      isDeletedDataSourceHeaderSuccess: false,
      isDeletedDataSourceHeaderError: true,
    }),
    // create data sources
    [createDataSourceRequest]: (state) => ({
      ...state,
      isCreatedDataSourceSuccess: false,
      isCreatedDataSourceError: false,
    }),
    [createDataSourceSuccess]: (state, { payload }) => ({
      ...state,
      newDataSourceId: payload ?? "",
      isCreatedDataSourceSuccess: true,
      isCreatedDataSourceError: false,
    }),
    [createDataSourceFailure]: (state) => ({
      ...state,
      isCreatedDataSourceSuccess: false,
      isCreatedDataSourceError: true,
    }),
    // get oauth 2 tokens
    [getOAuth2TokensRequest]: (state) => ({
      ...state,
      isGetOAuth2TokensSuccess: false,
      isGetOAuth2TokensError: false,
    }),
    [getOAuth2TokensSuccess]: (state, { payload }) => ({
      ...state,
      oauth2Tokens: payload || [],
      isGetOAuth2TokensSuccess: true,
      isGetOAuth2TokensError: false,
    }),
    [getOAuth2TokensFailure]: (state) => ({
      ...state,
      isGetOAuth2TokensSuccess: false,
      isGetOAuth2TokensError: true,
    }),
    // get oauth 2 token
    [getOAuth2TokenRequest]: (state) => ({
      ...state,
      isGetOAuth2TokenSuccess: false,
      isGetOAuth2TokenError: false,
    }),
    [getOAuth2TokenSuccess]: (state, { payload }) => {
      return {
        ...state,
        oauth2Token: payload || {},
        isGetOAuth2TokenSuccess: true,
        isGetOAuth2TokenError: false,
      };
    },
    [getOAuth2TokenFailure]: (state) => ({
      ...state,
      isGetOAuth2TokenSuccess: false,
      isGetOAuth2TokenError: true,
    }),
    // create oauth 2
    [createOAuth2TokenRequest]: (state) => ({
      ...state,
      isCreatedOAuth2TokenSuccess: false,
      isCreatedOAuth2TokenError: false,
    }),
    [createOAuth2TokenSuccess]: (state, { payload }) => ({
      ...state,
      oauth2Tokens: payload || [],
      isCreatedOAuth2TokenSuccess: true,
      isCreatedOAuth2TokenError: false,
    }),
    [createOAuth2TokenFailure]: (state) => ({
      ...state,
      isCreatedOAuth2TokenSuccess: false,
      isCreatedOAuth2TokenError: true,
    }),
    // update oauth 2
    [updateOAuth2TokenRequest]: (state) => ({
      ...state,
      isUpdatedOAuth2TokenSuccess: false,
      isUpdatedOAuth2TokenError: false,
    }),
    [updateOAuth2TokenSuccess]: (state, { payload }) => ({
      ...state,
      oauth2Tokens: payload || [],
      isUpdatedOAuth2TokenSuccess: true,
      isUpdatedOAuth2TokenError: false,
    }),
    [updateOAuth2TokenFailure]: (state) => ({
      ...state,
      isUpdatedOAuth2TokenSuccess: false,
      isUpdatedOAuth2TokenError: true,
    }),
    [deleteOAuth2TokenRequest]: (state) => ({
      ...state,
      isDeletedOAuth2TokenSuccess: false,
      isDeletedOAuth2TokenError: false,
    }),
    [deleteOAuth2TokenSuccess]: (state, { payload }) => {
      const endData = structuredClone(state.oauth2Tokens);
      const data = endData.filter(
        (item) => item.external_data_oauth2_id !== payload.deleted_id
      );
      return {
        ...state,
        oauth2Tokens: data || [],
        isDeletedOAuth2TokenSuccess: true,
        isDeletedOAuth2TokenError: false,
      };
    },
    [deleteOAuth2TokenFailure]: (state) => ({
      ...state,
      isDeletedOAuth2TokenSuccess: false,
      isDeletedOAuth2TokenError: true,
    }),
    // get response
    [getExternalDataResponseRequest]: (state) => ({
      ...state,
      isGetResponseSuccess: false,
      isGetResponseError: false,
    }),
    [getExternalDataResponseSuccess]: (state, { payload }) => ({
      ...state,
      dataResponse: payload || {},
      isGetResponseSuccess: true,
      isGetResponseError: false,
    }),
    [getExternalDataResponseFailure]: (state) => ({
      ...state,
      isGetResponseSuccess: false,
      isGetResponseError: true,
    }),
    // get responses
    [getExternalDataResponsesRequest]: (state) => ({
      ...state,
      isGetResponsesSuccess: false,
      isGetResponsesError: false,
    }),
    [getExternalDataResponsesSuccess]: (state, { payload }) => ({
      ...state,
      dataResponses: payload || [],
      isGetResponsesSuccess: true,
      isGetResponsesError: false,
    }),
    [getExternalDataResponsesFailure]: (state) => ({
      ...state,
      isGetResponsesSuccess: false,
      isGetResponsesError: true,
    }),
    // create response
    [createExternalDataResponseRequest]: (state) => ({
      ...state,
      isCreatedResponseSuccess: false,
      isCreatedResponseError: false,
    }),
    [createExternalDataResponseSuccess]: (state, { payload }) => ({
      ...state,
      dataResponses: payload || [],
      isCreatedResponseSuccess: true,
      isCreatedResponseError: false,
    }),
    [createExternalDataResponseFailure]: (state) => ({
      ...state,
      isCreatedResponseSuccess: false,
      isCreatedResponseError: true,
    }),
    // update response
    [updateExternalDataResponseRequest]: (state) => ({
      ...state,
      isUpdatedResponseSuccess: false,
      isUpdatedResponseError: false,
    }),
    [updateExternalDataResponseSuccess]: (state, { payload }) => ({
      ...state,
      dataResponse: payload || {},
      isUpdatedResponseSuccess: true,
      isUpdatedResponseError: false,
    }),
    [updateExternalDataResponseFailure]: (state) => ({
      ...state,
      isUpdatedResponseSuccess: false,
      isUpdatedResponseError: true,
    }),
    // delete response
    [deleteExternalDataResponseRequest]: (state) => ({
      ...state,
      isDeletedResponseSuccess: false,
      isDeletedResponseError: false,
    }),
    [deleteExternalDataResponseSuccess]: (state, { payload }) => {
      const endData = structuredClone(state.dataResponses);
      const data = endData.filter(
        (item) => item.external_data_response_id !== payload.deleted_id
      );
      return {
        ...state,
        dataResponses: data || [],
        isDeletedResponseSuccess: true,
        isDeletedResponseError: false,
      };
    },
    [deleteExternalDataResponseFailure]: (state) => ({
      ...state,
      isDeletedResponseSuccess: false,
      isDeletedResponseError: true,
    }),
  },
  initialState
);

export default reducer;
