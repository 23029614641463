import { call, put, takeLatest, all, fork, delay } from "redux-saga/effects";
import axios from "api/axios";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";

// Redux actions for impact scenarios
import {
  createImpactScenarioRequest,
  createImpactScenarioSuccess,
  createImpactScenarioFailure,
  executeImpactScenarioRequest,
  executeImpactScenarioSuccess,
  executeImpactScenarioFailure,
  addImpactScenarioResult,
  updateImpactScenarioProgress,
} from "./action";

// Dexie helper methods (using separate pending & results tables for scenarios)
import {
  getPendingScenarioTests,
  addScenarioResultToDB,
  clearScenarioResultsFromDB,
  clearPendingScenarioTests,
  markScenarioTestAsQueued,
  markScenarioTestAsProcessed,
  startScenarioTestWithDataset,
  getScenarioResultsCount,
} from "utility/BatchTestDB";

/**
 * Worker Saga: Create Impact Scenario
 */
function* createImpactScenario({ payload }) {
  try {
    const response = yield call(axios.post, "/impact-scenarios", payload);
    if (response.status === 201) {
      const impactScenarioId = response.headers.etag;
      yield put(createImpactScenarioSuccess(impactScenarioId));

      // Automatically start execution using dataset items
      yield put(
        executeImpactScenarioRequest({
          scenarioId: impactScenarioId,
          datasetId: payload.dataset_id,
        })
      );
    } else {
      throw new Error("Failed to create impact scenario");
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Failed to create impact scenario";
    yield put(createImpactScenarioFailure(errorMessage));
    toast.error(errorMessage, ToastOptions);
  }
}

/**
 * Worker Saga: Handle an individual pending scenario test.
 * Marks the test as queued, executes it, saves the result, then marks it as processed.
 */
function* handleIndividualScenarioTest(test, datasetId, scenarioId) {
  try {
    // Mark the test as queued to avoid reprocessing
    yield call(markScenarioTestAsQueued, test.id);
    const url = `impact-scenarios/${scenarioId}/execute`;
    const response = yield call(axios.post, url, test.input);
    if (response.status === 200) {
      const result = {
        input: test.input,
        baselineOutput: response.data.baseline,
        comparisonOutput: response.data.comparison,
      };
      yield put(addImpactScenarioResult(result));
      yield call(addScenarioResultToDB, scenarioId, datasetId, result);
    }
    // Mark the test as processed once done
    yield call(markScenarioTestAsProcessed, test.id);
  } catch (error) {
    const result = {
      input: test.input,
      error: error.message || "Execution failed",
    };
    yield put(addImpactScenarioResult(result));
    yield call(markScenarioTestAsProcessed, test.id);
  }
}

/**
 * Worker Saga: Execute Impact Scenario.
 *
 * This saga clears old results and pending tests for the scenario,
 * copies the dataset items into pendingScenarioTests, then processes
 * each pending test—marking them as queued and then as processed.
 */
function* executeImpactScenario({ payload }) {
  const { scenarioId, datasetId } = payload;
  try {
    if (!scenarioId) throw new Error("Missing scenario ID");
    if (!datasetId || isNaN(Number(datasetId))) {
      throw new Error("Invalid dataset ID");
    }

    // Clear old results and any existing pending tests for a clean slate
    yield call(clearScenarioResultsFromDB, scenarioId);
    yield call(clearPendingScenarioTests, scenarioId);

    // Copy dataset items into pending tests and get the total count
    const totalCount = yield call(
      startScenarioTestWithDataset,
      scenarioId,
      datasetId
    );
    if (!totalCount || totalCount <= 0) {
      throw new Error("No test items found in the selected dataset");
    }
    yield put(
      updateImpactScenarioProgress({ total: totalCount, completed: 0 })
    );

    let processed = 0;
    const batchSize = 100;
    const concurrency = 5;
    let pendingTests;

    // Loop until no pending tests remain
    while (true) {
      pendingTests = yield call(
        getPendingScenarioTests,
        scenarioId,
        0,
        batchSize
      );
      if (!pendingTests.length) break;

      // Process tests in sub-batches concurrently
      for (let i = 0; i < pendingTests.length; i += concurrency) {
        const subset = pendingTests.slice(i, i + concurrency);
        yield all(
          subset.map((test) =>
            fork(handleIndividualScenarioTest, test, datasetId, scenarioId)
          )
        );
        processed += subset.length;
        yield put(
          updateImpactScenarioProgress({
            completed: Math.min(processed, totalCount),
            total: totalCount,
          })
        );
        yield delay(100); // Prevent server overload
      }
    }

    // Wait for pending async operations to complete
    yield delay(1000);

    // Verify all tests were processed by checking results in DB
    const { totalCount: savedCount } = yield call(
      getScenarioResultsCount,
      scenarioId
    );

    if (savedCount < totalCount) {
      console.log(
        `Found discrepancy: ${
          totalCount - savedCount
        } scenario tests missing. Processing remaining tests...`
      );

      // Process any remaining tests - use call instead of fork to ensure completion
      const remainingTests = yield call(
        getPendingScenarioTests,
        scenarioId,
        0,
        totalCount - savedCount
      );

      if (remainingTests.length > 0) {
        // Process remaining tests synchronously to ensure completion
        for (let i = 0; i < remainingTests.length; i++) {
          yield call(
            handleIndividualScenarioTest,
            remainingTests[i],
            datasetId,
            scenarioId
          );

          // Update progress
          yield put(
            updateImpactScenarioProgress({
              completed: Math.min(savedCount + i + 1, totalCount),
              total: totalCount,
            })
          );
        }
      }

      // Final verification
      const { totalCount: finalCount } = yield call(
        getScenarioResultsCount,
        scenarioId
      );

      if (finalCount < totalCount) {
        console.warn(
          `After retries, still missing ${
            totalCount - finalCount
          } scenario test results`
        );
      }
    }

    yield put(executeImpactScenarioSuccess());
    toast.success("Impact scenario execution completed", ToastOptions);
  } catch (error) {
    console.error("Impact scenario execution error:", error);
    yield put(
      executeImpactScenarioFailure(error.message || "Execution failed")
    );
    toast.error(
      error.message || "Failed to execute impact scenario",
      ToastOptions
    );
  }
}

/**
 * Watcher Saga
 */
export default function* saga() {
  yield all([
    takeLatest(createImpactScenarioRequest, createImpactScenario),
    takeLatest(executeImpactScenarioRequest, executeImpactScenario),
  ]);
}
