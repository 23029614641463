import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteScorecardRequest,
  getScorecardsRequest,
  deleteScorecardSuccess,
} from "redux/scorecard/action";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SubHeader from "components/SubHeader";
import { ToastOptions } from "components/toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { sortByTitle, TableNoItems } from "utility/utility";
import Pagination from "utility/Pagination";
import { ReactComponent as HelpCircle } from "assets/icons/help-circle.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

const Scorecards = () => {
  const { setIsLoading, isMobile } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    scorecards,
    scorecardsCount,
    isGetScorecardsSuccess,
    isDeleteScorecardSuccess,
    isDeletingScorecard,
    isGetScorecardsError,
  } = useSelector((state) => state.scorecard);

  const [selectedScorecard, setSelectedScorecard] = useState({});
  const [isScorecardDeleteModalOpen, setIsScorecardDeleteModalOpen] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [scorecardsCopy, setScorecardsCopy] = useState([]);

  const prevIsRemovedScorecardSuccess = usePrevious(isDeleteScorecardSuccess);

  useEffect(() => {
    document.title = `${t("scorecards")} - Decisimo`;
    setIsLoading(true);
    dispatch(getScorecardsRequest(currentPage));
  }, [currentPage, dispatch, setIsLoading]);

  useEffect(() => {
    if (isGetScorecardsSuccess) {
      // run custom sort function of sort by title

      const scorecardsClone = structuredClone(scorecards);
      const sortedScorecards = scorecardsClone?.sort(sortByTitle);
      setScorecardsCopy(sortedScorecards);
      setIsLoading(false);
    }
  }, [isGetScorecardsSuccess, scorecards]);

  useEffect(() => {
    if (isGetScorecardsError) {
      setIsLoading(false);
    }
  }, [isGetScorecardsError]);

  useEffect(() => {
    if (
      !isDeletingScorecard &&
      isDeleteScorecardSuccess &&
      prevIsRemovedScorecardSuccess === false
    ) {
      setScorecardsCopy(
        scorecardsCopy.filter(
          (item) => item.scorecard_id !== selectedScorecard.scorecard_id
        )
      );
      setIsLoading(false);
      setIsScorecardDeleteModalOpen(false);
      toast.warning(t("scorecard_deleted"), ToastOptions);
    }
  }, [isDeleteScorecardSuccess]);

  const handleShowDeleteModal = (scorecard) => {
    setSelectedScorecard(scorecard);
    setIsScorecardDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsLoading(true);
    dispatch(deleteScorecardRequest(selectedScorecard.scorecard_id));
  };

  return (
    <>
      <SubHeader
        alt={t("nav_bar_scorecards")}
        title={t("nav_bar_scorecards")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/scorecard"
              title={t("create_scorecard")}
              className="btn primary-header border-radius-left-4"
            >
              <PlusIcon /> <span className="ml-2">{t("scorecard")}</span>
            </Link>
          </div>
        }
      />
      <div className="mb-4">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-4 py-3">
                {t("table_title")}
              </th>
              {!isMobile && (
                <>
                  <th scope="col" className="th-flow col-lg-4 py-3">
                    {t("identifier")}
                  </th>
                  <th scope="col" className="th-flow col-lg-4 py-3">
                    {t("last_change")}
                  </th>
                </>
              )}
              <th scope="col" className="th-flow col-lg-4 py-3" />
            </tr>
          </thead>
          <tbody>
            {scorecardsCopy.length > 0
              ? scorecardsCopy.map((scorecard) => (
                  <tr key={scorecard.scorecard_id} className="td-flow">
                    <td>
                      <Link to={`/scorecards/${scorecard.scorecard_id}`}>
                        {scorecard.title}
                      </Link>
                    </td>
                    {!isMobile && (
                      <>
                        <td>{scorecard.name}</td>

                        <td>
                          {scorecard.dtime_modified}
                          <HelpCircle
                            data-tip
                            data-for={`modified-${scorecard.scorecard_id}`}
                            className="ml-2 cursor-pointer"
                          />
                          <ReactTooltip
                            id={`modified-${scorecard.scorecard_id}`}
                            type="dark"
                            place="right"
                            effect="solid"
                          >
                            {t("modified_by")} {scorecard.full_name}
                          </ReactTooltip>
                        </td>
                      </>
                    )}
                    <td className="d-flex align-items-center justify-content-end border-0">
                      <div>
                        <button
                          title={t("copy_or_delete_scorecard")}
                          type="button"
                          className="btn primary-text border-0 p-0 pb-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <MoreVertical
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </button>

                        <div
                          className="dropdown-menu table-dropdown"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleShowDeleteModal(scorecard)}
                          >
                            <TrashIcon /> {t("delete")}
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              : isGetScorecardsSuccess &&
                scorecards.length === 0 && <TableNoItems />}
          </tbody>
        </table>
      </div>

      <Pagination
        currentPage={currentPage}
        completeItemsCount={scorecardsCount}
        itemsPerPage={20}
        setCurrentPage={(page) => setCurrentPage(page)}
      />

      <DeleteConfirm
        open={isScorecardDeleteModalOpen}
        handleClose={() => setIsScorecardDeleteModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        title={t("delete_scorecard")}
        message={`${t("do_you_want_delete")} ${selectedScorecard.title}?`}
      />
    </>
  );
};

export default Scorecards;
