import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./ServiceCard.css";
import dataSourceDefault from "assets/images/data-source-default.png";
import { ReactComponent as ConnectedIcon } from "assets/icons/connected.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";

const ServiceCard = ({
  service,
  onActivate,
  onDeactivate,
  handleGetServiceDetails,
}) => {
  const { t } = useTranslation();

  const handleActivateClick = () => {
    onActivate(); // Call the parent's onActivate function
  };

  const handleEditClick = () => {
    handleGetServiceDetails(service.dama_service_id); // Call the parent's handleGetServiceDetails function
  };

  const serviceTypeBadgeColor = (type) => {
    switch (type) {
      case "managed":
        return "bg-blue-500";
      case "free":
        return "bg-green-500";
      case "byok":
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  const formatPrice = (price) => {
    if (price === 0 || price === "free") {
      return t("Free");
    }
    if (typeof price === "number") {
      return `${price} $/call`;
    }
    return "";
  };

  // Determine status based on tenant_service_status
  const isActive =
    service.dama_service_tenant_id && service.tenant_service_status === "a";

  return (
    <div className="card service-card">
      <div className="card-header">
        <div className="header-title">{service.title}</div>
        <div className="header-status pt-3">
          {isActive && (
            <>
              <ConnectedIcon className="status-indicator" />
            </>
          )}
        </div>
      </div>
      <div className="card-content">
        <div className="content-image">
          <img src={dataSourceDefault} alt="Data Source Default" />
        </div>
        <div className="content-text">
          {service.description || "Data service description is not available."}
        </div>
      </div>
      <div className="card-meta">
        <div className="meta-left">
          <span>By {service.provider_title}</span>
          <span
            className={`tag ${serviceTypeBadgeColor(service.service_type)}`}
          >
            {t(service.service_type)}
          </span>
        </div>
        <div>{formatPrice(service.price)}</div>
      </div>
      <div className="card-actions">
        {isActive ? (
          <button className="btn" onClick={handleEditClick}>
            <span className="btn-icon">
              <EditIcon />
            </span>
            {t("edit")}
          </button>
        ) : (
          <button className="btn" onClick={handleActivateClick}>
            <span className="btn-icon connect"></span>
            {t("activate")}
          </button>
        )}
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    dama_service_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    dama_service_tenant_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    external_data: PropTypes.array,
    isActive: PropTypes.bool,
    secret_type: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    providerName: PropTypes.string,
    provider_title: PropTypes.string,
    providerUrl: PropTypes.string,
    service_type: PropTypes.string,
    tenant_service_status: PropTypes.string,
  }).isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  handleGetServiceDetails: PropTypes.func.isRequired,
};

export default ServiceCard;
