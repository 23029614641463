// New file: components/modals/scorecard/ScorecardOptions.js

import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { useTranslation } from "react-i18next";

const ScorecardOptions = ({
  open,
  handleClose,
  scorecardData = {},
  updateScorecardOptions,
  revisionMode = false,
}) => {
  const ref = useRef();
  const { t } = useTranslation();

  const handleConfirm = (form) => {
    if (form.checkValidity()) {
      const formData = new FormData(form);
      updateScorecardOptions(
        formData.get("name"),
        formData.get("title"),
        formData.get("attribute_path"),
        formData.get("calculation_type")
      );
      handleClose();
    } else {
      form.reportValidity();
    }
  };

  return (
    <Modal size="lg" show={open} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title">{t("edit_scorecard")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <form ref={ref}>
        <Modal.Body className="row">
          <div className="col-12 col-lg-12 mb-2">
            <label>{t("title_capitalized")}</label>
            <input
              type="text"
              className="form-control"
              name="title"
              defaultValue={scorecardData?.title || ""}
              disabled={revisionMode}
              required
            />
          </div>
          <div className="col-12 col-lg-12 mb-2">
            <label>{t("identifier")}</label>
            <input
              type="text"
              className="form-control border-radius-4"
              name="name"
              defaultValue={scorecardData?.name || ""}
              disabled={revisionMode}
              required
              pattern="[a-zA-Z0-9_\-]+"
              title={t("only_letters_numbers_underscore_hyphen")}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^a-zA-Z0-9_-]/g, "");
              }}
            />
          </div>
          <div className="col-12 col-lg-12 mb-2">
            <label>{t("attribute_path")}</label>
            <input
              type="text"
              className="form-control"
              name="attribute_path"
              defaultValue={scorecardData?.attribute_path || ""}
              disabled={revisionMode}
              required
            />
          </div>
          <div className="col-12 col-lg-12 mb-2">
            <label>{t("calculation_type")}</label>
            <select
              className="form-control"
              name="calculation_type"
              defaultValue={scorecardData?.calculation_type || "sum"}
              disabled={revisionMode}
              required
            >
              <option value="sum">{t("scorecard_calculation_sum")}</option>
              <option value="logistic">
                {t("scorecard_calculation_logistic_regression")}
              </option>
            </select>
          </div>
        </Modal.Body>
        {!revisionMode && (
          <Modal.Footer>
            <Button variant="" className="outline" onClick={handleClose}>
              {t("close")}
            </Button>
            <Button
              variant=""
              className="primary d-flex align-items-center"
              onClick={() => handleConfirm(ref?.current)}
            >
              <SaveIcon /> <span className="ml-2">{t("save")}</span>
            </Button>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
};

ScorecardOptions.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  scorecardData: PropTypes.object,
  updateScorecardOptions: PropTypes.func,
  revisionMode: PropTypes.bool,
};

export default ScorecardOptions;
