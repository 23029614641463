import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Spinner, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getAnalysisRecipesRequest } from "redux/analysisRecipes/actions";
import {
  getRuleRevisionsRequest,
  getRuleSetWorkflowsRequest,
} from "redux/rulesets/action";
import {
  getDecisionTreeRevisionsRequest,
  getDecisionTreeWorkflowsRequest,
} from "redux/decisionTrees/action";
import {
  getDecisionTableRevisionsRequest,
  getDecisionTableWorkflowsRequest,
} from "redux/decisionTables/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import {
  createImpactScenarioRequest,
  resetImpactAnalyzerState,
  executeImpactScenarioRequest,
} from "redux/impactAnalyzer/action";

import usePrevious from "utility/hooks/usePrevious";
import DatasetDropdown from "components/releases/batchTest/DatasetDropdown";
import DatasetManager from "components/releases/batchTest/DatasetManager";
import { getAllDatasets } from "utility/BatchTestDB";
import ImpactAnalyzerResults from "./ImpactAnalyzerResults";
import ImpactAnalyzerProgress from "./ImpactAnalyzerProgress";
import {
  getFunctionRevisionsRequest,
  getFunctionWorkflowsRequest,
} from "redux/functions/action";
import {
  getScorecardRevisionsRequest,
  getScorecardWorkflowsRequest,
} from "redux/scorecard/action";

const ImpactAnalyzerSidebar = ({
  sidebarWidth,
  setSidebarWidth,
  entityId,
  entityType,
  setIsImpactAnalyzerOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const [resizeHandleLeft, setResizeHandleLeft] = useState(0);
  const [isResizeHandleHovered, setIsResizeHandleHovered] = useState(false);

  // Add a ref to track previous entityId
  const prevEntityIdRef = useRef(entityId);

  // Helper function to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      date.toLocaleDateString() +
      " " +
      date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
  };

  // Local state
  const [decisionFlow, setDecisionFlow] = useState("");
  const [dataset, setDataset] = useState(null);
  const [dataSourceRevision1, setDataSourceRevision1] = useState("");
  const [analyticsRecipe, setAnalyticsRecipe] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [decisionFlows, setDecisionFlows] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [entityRevision1, setEntityRevision1] = useState("");
  const [entityRevision2, setEntityRevision2] = useState("");
  const [entityRevisions, setEntityRevisions] = useState([]);
  const [showDatasetModal, setShowDatasetModal] = useState(false);
  const [isLoadingDatasets, setIsLoadingDatasets] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [localScenarioId, setLocalScenarioId] = useState(null);

  // Get analysis recipes from Redux
  const { analysisRecipes } = useSelector((state) => state.analysisRecipes);

  const {
    ruleRevisions,
    isGetRuleRevisionsSuccess,
    ruleSetWorkflows,
    isGetRuleSetWorkflowsSuccess,
  } = useSelector((state) => state.ruleSets);

  const {
    revisions: decisionTreeRevisions,
    isGetDecisionTreeRevisionsSuccess,
    decisionTreeWorkflows,
    isGetDecisionTreeWorkflowsSuccess,
  } = useSelector((state) => state.decisionTrees);

  const {
    decisionTableRevisions,
    isGetDecisionTableRevisionsSuccess,
    decisionTableWorkflows,
    isGetDecisionTableWorkflowsSuccess,
  } = useSelector((state) => state.decisionTables);

  const {
    isCreatingImpactScenario,
    isCreateImpactScenarioSuccess,
    impactScenarioId,
    impactScenarioResults,
  } = useSelector((state) => state.impactAnalyzer);

  const {
    revisions: scorecardRevisions,
    scorecardWorkflows,
    isGetScorecardRevisionsSuccess,
    isGetScorecardWorkflowsSuccess,
  } = useSelector((state) => state.scorecard);

  const {
    isGetFunctionRevisionsSuccess,
    revisions: functionRevisions,
    functionWorkflows,
    isGetFunctionWorkflowsSuccess,
  } = useSelector((state) => state.functions);

  const prevIsGetRuleRevisionsSuccess = usePrevious(isGetRuleRevisionsSuccess);
  const prevIsGetDecisionTreeRevisionsSuccess = usePrevious(
    isGetDecisionTreeRevisionsSuccess
  );
  const prevIsGetDecisionTableRevisionsSuccess = usePrevious(
    isGetDecisionTableRevisionsSuccess
  );

  const prevIsGetScorecardRevisionsSuccess = usePrevious(
    isGetScorecardRevisionsSuccess
  );
  const prevIsGetFunctionRevisionsSuccess = usePrevious(
    isGetFunctionRevisionsSuccess
  );

  // Fetch decision flows and analysis recipes on component mount
  useEffect(() => {
    // Fetch analysis recipes from Redux
    dispatch(getAnalysisRecipesRequest());

    // Load datasets from IndexedDB
    loadDatasets();
  }, [dispatch]);

  // Function to load datasets from IndexedDB
  const loadDatasets = async () => {
    setIsLoadingDatasets(true);
    try {
      const data = await getAllDatasets();
      // Map the datasets to the format expected by DatasetDropdown
      const formattedDatasets = data.map((dataset) => ({
        id: dataset.id,
        name: dataset.name,
        itemCount: dataset.itemCount || 0,
      }));
      setDatasets(formattedDatasets);

      // Auto-select the first dataset if there's only one
      if (formattedDatasets.length === 1) {
        const singleDataset = formattedDatasets[0];
        setDataset(singleDataset);
        setDataSourceRevision1(singleDataset.id);
      }
    } catch (error) {
      console.error("Error loading datasets", error);
      toast.error(t("error_loading_datasets"), ToastOptions);
    } finally {
      setIsLoadingDatasets(false);
    }
  };

  const handleResizeStart = (e) => {
    document.addEventListener("mousemove", handleResize);
    document.addEventListener("mouseup", stopResize);
  };

  const handleResize = (e) => {
    const maxWidth = Math.floor(window.innerWidth * 0.9); // 60% of window width
    const newWidth = Math.min(
      Math.max(400, window.innerWidth - e.clientX),
      maxWidth
    );
    setSidebarWidth(newWidth);
  };

  const stopResize = () => {
    document.removeEventListener("mousemove", handleResize);
    document.removeEventListener("mouseup", stopResize);
  };

  const handleDatasetSelect = (selectedDataset) => {
    setDataset(selectedDataset);
    setDataSourceRevision1(selectedDataset.id);
    setShowDatasetModal(false);
  };

  // Update handleAnalyze function
  const handleAnalyze = () => {
    if (
      decisionFlow &&
      dataSourceRevision1 &&
      entityRevision1 &&
      entityRevision2 &&
      (analyticsRecipe || analysisRecipes.length === 0)
    ) {
      const impactScenarioData = {
        workflow_id: parseInt(decisionFlow),
        entity_type: entityType,
        entity_id: parseInt(entityId),
        revision_id_baseline: parseInt(entityRevision1),
        revision_id_comparison: parseInt(entityRevision2),
        dataset_id: dataSourceRevision1,
        analysis_recipe_id: parseInt(analyticsRecipe),
      };

      setIsAnalyzing(true);
      dispatch(createImpactScenarioRequest(impactScenarioData));
    } else {
      toast.error(t("please_select_all_required_fields"), ToastOptions);
    }
  };

  useEffect(() => {
    // Check if entityId has changed
    if (prevEntityIdRef.current !== entityId) {
      // Reset state when entity changes
      setDecisionFlows([]);
      setDecisionFlow("");
      setEntityRevisions([]);
      setEntityRevision1("");
      setEntityRevision2("");

      // Update the ref
      prevEntityIdRef.current = entityId;
    }

    if (entityId && entityType && entityType === "rule_set") {
      // Always fetch workflows first
      dispatch(getRuleSetWorkflowsRequest(parseInt(entityId)));
      dispatch(getRuleRevisionsRequest(parseInt(entityId)));
    }

    if (entityId && entityType && entityType === "decision_tree") {
      // Always fetch workflows first
      dispatch(getDecisionTreeWorkflowsRequest(parseInt(entityId)));
      dispatch(getDecisionTreeRevisionsRequest(parseInt(entityId)));
    }

    if (entityId && entityType && entityType === "decision_table") {
      // Always fetch workflows first
      dispatch(getDecisionTableWorkflowsRequest(parseInt(entityId)));
      dispatch(getDecisionTableRevisionsRequest(parseInt(entityId)));
    }

    if (entityId && entityType && entityType === "scorecard") {
      dispatch(getScorecardRevisionsRequest(parseInt(entityId)));
      dispatch(getScorecardWorkflowsRequest(parseInt(entityId)));
    }

    if (entityId && entityType && entityType === "fce") {
      dispatch(getFunctionRevisionsRequest(parseInt(entityId)));
      dispatch(getFunctionWorkflowsRequest(parseInt(entityId)));
    }
  }, [entityId, entityType, dispatch]);

  // Add this new method before the component's return statement
  const handleRevisionUpdate = (revisions, isSuccess, prevIsSuccess) => {
    if (isSuccess && prevIsSuccess === false) {
      setEntityRevisions(revisions);

      // If revisions exist, automatically select the latest one for comparison
      if (revisions && revisions.length > 0) {
        const latestRevision = revisions[revisions.length - 1];
        setEntityRevision2(latestRevision.revision_id.toString());

        // Optionally, if there's a revision before the latest, set it as baseline
        if (revisions.length > 1) {
          const secondLatestRevision = revisions[revisions.length - 2];
          setEntityRevision1(secondLatestRevision.revision_id.toString());
        }
      }
    }
  };

  // Replace all four useEffect blocks with this single one
  useEffect(() => {
    // Handle rule revisions
    if (entityType === "rule_set") {
      handleRevisionUpdate(
        ruleRevisions,
        isGetRuleRevisionsSuccess,
        prevIsGetRuleRevisionsSuccess
      );
    }

    // Handle decision tree revisions
    if (entityType === "decision_tree") {
      handleRevisionUpdate(
        decisionTreeRevisions,
        isGetDecisionTreeRevisionsSuccess,
        prevIsGetDecisionTreeRevisionsSuccess
      );
    }

    // Handle decision table revisions
    if (entityType === "decision_table") {
      handleRevisionUpdate(
        decisionTableRevisions,
        isGetDecisionTableRevisionsSuccess,
        prevIsGetDecisionTableRevisionsSuccess
      );
    }

    // Handle scorecard revisions
    if (entityType === "scorecard") {
      handleRevisionUpdate(
        scorecardRevisions,
        isGetScorecardRevisionsSuccess,
        prevIsGetScorecardRevisionsSuccess
      );
    }

    // Handle function revisions
    if (entityType === "fce") {
      handleRevisionUpdate(
        functionRevisions,
        isGetFunctionRevisionsSuccess,
        prevIsGetFunctionRevisionsSuccess
      );
    }
  }, [
    ruleRevisions,
    isGetRuleRevisionsSuccess,
    prevIsGetRuleRevisionsSuccess,
    decisionTreeRevisions,
    isGetDecisionTreeRevisionsSuccess,
    prevIsGetDecisionTreeRevisionsSuccess,
    decisionTableRevisions,
    isGetDecisionTableRevisionsSuccess,
    prevIsGetDecisionTableRevisionsSuccess,
    scorecardRevisions,
    isGetScorecardRevisionsSuccess,
    prevIsGetScorecardRevisionsSuccess,
    functionRevisions,
    isGetFunctionRevisionsSuccess,
    prevIsGetFunctionRevisionsSuccess,
  ]);

  // Handle successful impact scenario creation
  useEffect(() => {
    if (isCreateImpactScenarioSuccess && impactScenarioId) {
      setIsAnalyzing(false);
      toast.success(t("impact_scenario_created_successfully"), ToastOptions);

      // Store scenario ID locally as a backup
      setLocalScenarioId(impactScenarioId);

      // Switch to results view
      setShowResults(true);

      // Dispatch action to execute the impact scenario with the selected dataset
      dispatch(
        executeImpactScenarioRequest({
          scenarioId: impactScenarioId,
          datasetId: dataSourceRevision1,
        })
      );
    }
  }, [
    isCreateImpactScenarioSuccess,
    impactScenarioId,
    t,
    dispatch,
    dataSourceRevision1,
  ]);

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      dispatch(resetImpactAnalyzerState());
    };
  }, [dispatch]);

  // Add this helper function
  const handleWorkflowsUpdate = (workflowData, isSuccess) => {
    if (isSuccess) {
      if (workflowData && workflowData.length > 0) {
        setDecisionFlows(
          workflowData.map((workflow) => ({
            id: workflow.workflow_id,
            name: workflow.title || workflow.name,
          }))
        );

        // Auto-select the first workflow if there's only one
        if (workflowData.length === 1) {
          setDecisionFlow(workflowData[0].workflow_id.toString());
        }
      } else {
        // Reset decision flow if no workflows are available
        setDecisionFlow("");
      }
    }
  };

  // Replace all separate useEffect blocks with a single one
  useEffect(() => {
    switch (entityType) {
      case "rule_set":
        handleWorkflowsUpdate(ruleSetWorkflows, isGetRuleSetWorkflowsSuccess);
        break;
      case "decision_tree":
        handleWorkflowsUpdate(
          decisionTreeWorkflows,
          isGetDecisionTreeWorkflowsSuccess
        );
        break;
      case "decision_table":
        handleWorkflowsUpdate(
          decisionTableWorkflows,
          isGetDecisionTableWorkflowsSuccess
        );
        break;
      case "scorecard":
        handleWorkflowsUpdate(
          scorecardWorkflows,
          isGetScorecardWorkflowsSuccess
        );
        break;
      case "fce":
        handleWorkflowsUpdate(functionWorkflows, isGetFunctionWorkflowsSuccess);
        break;
      default:
        break;
    }
  }, [
    entityType,
    ruleSetWorkflows,
    isGetRuleSetWorkflowsSuccess,
    decisionTreeWorkflows,
    isGetDecisionTreeWorkflowsSuccess,
    decisionTableWorkflows,
    isGetDecisionTableWorkflowsSuccess,
    scorecardWorkflows,
    isGetScorecardWorkflowsSuccess,
    functionWorkflows,
    isGetFunctionWorkflowsSuccess,
  ]);

  // Auto-select analytics recipe if there's only one
  useEffect(() => {
    if (analysisRecipes && analysisRecipes.length === 1) {
      setAnalyticsRecipe(
        analysisRecipes[0].release_analysis_recipe_id.toString()
      );
    }
  }, [analysisRecipes]);

  // Handle dataset creation in the modal
  const handleDatasetCreated = () => {
    loadDatasets();
    // Don't close the modal automatically, let the user select the newly created dataset
  };

  // Update the resize handle position when sidebar width changes
  useEffect(() => {
    if (sidebarRef.current) {
      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      setResizeHandleLeft(sidebarRect.left);
    }
  }, [sidebarWidth]);

  // Handle window resize events
  useEffect(() => {
    const updateResizeHandlePosition = () => {
      if (sidebarRef.current) {
        const sidebarRect = sidebarRef.current.getBoundingClientRect();
        setResizeHandleLeft(sidebarRect.left);
      }
    };

    window.addEventListener("resize", updateResizeHandlePosition);
    return () =>
      window.removeEventListener("resize", updateResizeHandlePosition);
  }, []);

  return (
    <div
      ref={sidebarRef}
      className="impact-analyzer-sidebar"
      style={{
        width: sidebarWidth,
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #ddd",
        padding: "1.5rem 1.5rem 1.5rem 0", // Remove left padding
        paddingLeft: "20px", // Small left padding after the resize handle
        height: "100%",
        overflow: "auto",
        boxShadow: "-3px 0 10px rgba(0, 0, 0, 0.1)",
        position: "relative", // Ensure position context for absolute positioning
      }}
    >
      <div
        className="resize-handle"
        onMouseDown={handleResizeStart}
        onMouseEnter={() => setIsResizeHandleHovered(true)}
        onMouseLeave={() => setIsResizeHandleHovered(false)}
        style={{
          position: "absolute",
          left: "0px", // At the very edge
          top: 0,
          width: isResizeHandleHovered ? "5px" : "1px",
          height: "100%",
          cursor: "ew-resize",
          backgroundColor: "#ccc",
          zIndex: 1002,
          transition: "width 0.15s ease",
        }}
      ></div>

      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="mb-0">
          {showResults ? t("impact_analysis_results") : t("impact_analyzer")}
        </h3>
        <div>
          {showResults && (
            <button
              type="button"
              className="btn outline me-2 mr-4"
              onClick={() => setShowResults(false)}
            >
              {t("back_to_form")}
            </button>
          )}
          <button
            type="button"
            className="close"
            onClick={() => setIsImpactAnalyzerOpen(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>

      {showResults ? (
        <ImpactAnalyzerResults
          scenarioId={impactScenarioId || localScenarioId}
          initialRecipeId={analyticsRecipe} // Pass the selected recipe ID
        />
      ) : decisionFlows.length === 0 ? (
        <div className="text-center p-4">
          <div className="alert alert-info">
            {t(
              "impact_analysis_requires_the_artifact_being_used_in_a_decision_flow"
            )}
          </div>
          <p className="mt-3">{t("no_decision_flows_found")}</p>
          <Button
            variant="outline-secondary"
            onClick={() => setIsImpactAnalyzerOpen(false)}
            className="mt-2"
          >
            {t("close")}
          </Button>
        </div>
      ) : (
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>{t("decision_flow")}</Form.Label>
            <Form.Control
              as="select"
              value={decisionFlow}
              onChange={(e) => setDecisionFlow(e.target.value)}
              className="rounded"
            >
              <option value="">{t("select_decision_flow")}</option>
              {decisionFlows.map((flow) => (
                <option key={flow.id} value={flow.id}>
                  {flow.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <div className="mb-4">
            <h5 className="h5">{t("comparison_data")}</h5>
            <hr className="mt-2 mb-3" />

            <Form.Group className="mb-3">
              <Form.Label>{t("dataset")}</Form.Label>
              {isLoadingDatasets ? (
                <div className="text-center py-2">
                  <Spinner animation="border" size="sm" />
                  <span className="ms-2">{t("loading_datasets")}</span>
                </div>
              ) : (
                <div>
                  <DatasetDropdown
                    datasets={datasets}
                    onSelect={handleDatasetSelect}
                    onManageDatasets={(e) => {
                      e && e.preventDefault();
                      setShowDatasetModal(true);
                    }}
                  />
                </div>
              )}
              {dataset && (
                <div className="mt-2">
                  <small className="text-muted">
                    {t("selected")}: <strong>{dataset.name}</strong> (
                    {dataset.itemCount} {t("items")})
                  </small>
                </div>
              )}
            </Form.Group>

            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>{t("baseline_revision")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={entityRevision1}
                    onChange={(e) => setEntityRevision1(e.target.value)}
                    className="rounded"
                    disabled={!decisionFlow}
                  >
                    <option value="">{t("select_baseline")}</option>
                    {entityRevisions.map((revision) => (
                      <option
                        key={revision.revision_id}
                        value={revision.revision_id}
                      >
                        {`Rev ${revision.revision_index}: ${formatDate(
                          revision.dtime_inserted
                        )} (${revision.full_name})`}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>{t("comparison_revision")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={entityRevision2}
                    onChange={(e) => setEntityRevision2(e.target.value)}
                    className="border-radius-4"
                    disabled={!decisionFlow}
                  >
                    <option value="">{t("select_comparison")}</option>
                    {entityRevisions.map((revision) => (
                      <option
                        key={revision.revision_id}
                        value={revision.revision_id}
                      >
                        {`Rev ${revision.revision_index}: ${formatDate(
                          revision.dtime_inserted
                        )} (${revision.full_name})`}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
            </div>
          </div>
          {analysisRecipes.length > 0 && (
            <Form.Group className="mb-4">
              <Form.Label>{t("analytics_recipe")}</Form.Label>

              <Form.Control
                as="select"
                value={analyticsRecipe}
                onChange={(e) => setAnalyticsRecipe(e.target.value)}
                className="border-radius-4"
              >
                <option value="">{t("select_analytics_recipe")}</option>
                {analysisRecipes.map((recipe) => (
                  <option
                    key={recipe.release_analysis_recipe_id}
                    value={recipe.release_analysis_recipe_id}
                  >
                    {recipe.title}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          <Button
            variant=""
            className="btn primary w-100 mt-3"
            onClick={handleAnalyze}
            disabled={
              isCreatingImpactScenario ||
              isAnalyzing ||
              !decisionFlow ||
              !dataSourceRevision1 ||
              !entityRevision1 ||
              !entityRevision2 ||
              (analysisRecipes.length > 0 && !analyticsRecipe)
            }
          >
            {isCreatingImpactScenario || isAnalyzing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
                {t("analyzing")}
              </>
            ) : (
              t("analyze")
            )}
          </Button>
          {(isCreatingImpactScenario || isAnalyzing) && (
            <ImpactAnalyzerProgress />
          )}
        </Form>
      )}

      {/* Dataset Manager Modal */}
      <Modal
        show={showDatasetModal}
        onHide={() => setShowDatasetModal(false)}
        size="lg"
      >
        <Modal.Header>
          <h5 className="modal-title">{t("manage_datasets")}</h5>
          <button
            type="button"
            className="close"
            onClick={() => setShowDatasetModal(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <DatasetManager
            onSelectDataset={handleDatasetSelect}
            onDatasetCreated={handleDatasetCreated}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="outline"
            onClick={() => setShowDatasetModal(false)}
          >
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ImpactAnalyzerSidebar.propTypes = {
  sidebarWidth: PropTypes.number.isRequired,
  setSidebarWidth: PropTypes.func.isRequired,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  setIsImpactAnalyzerOpen: PropTypes.func.isRequired,
};

export default ImpactAnalyzerSidebar;
