import { handleActions } from "redux-actions";
import {
  deleteDeploymentFailure,
  deleteDeploymentRequest,
  deleteDeploymentSuccess,
  getDeploymentByIdFailure,
  getDeploymentByIdRequest,
  getDeploymentByIdSuccess,
  getDeploymentsFailure,
  getDeploymentsRequest,
  getDeploymentsSuccess,
} from "redux/deployments/action";

const initialState = {
  deployments: [],
  deploymentsCount: 0,
  deployment: {},
  isGetDeploymentsSuccess: false,
  isGetDeploymentsError: false,
  isDeletedDeploymentSuccess: false,
  isDeletedDeploymentError: false,
  isGetDeploymentByIdSuccess: false,
  isGetDeploymentByIRequest: false,
  isGetDeploymentByIdError: false,
};

const reducer = handleActions(
  {
    // get deployments
    [getDeploymentsRequest]: (state) => ({
      ...state,
      isGetDeploymentsSuccess: false,
      isGetDeploymentsError: false,
    }),
    [getDeploymentsSuccess]: (state, { payload }) => ({
      ...state,
      deployments: payload.deployments || [],
      deploymentsCount: payload.total_count || 0,
      isGetDeploymentsSuccess: true,
      isGetDeploymentsError: false,
    }),
    [getDeploymentsFailure]: (state) => ({
      ...state,
      isGetDeploymentsSuccess: false,
      isGetDeploymentsError: true,
    }),
    // get By Id
    [getDeploymentByIdRequest]: (state) => ({
      ...state,
      isGetDeploymentByIdSuccess: false,
      isGetDeploymentByIdError: false,
    }),
    [getDeploymentByIdSuccess]: (state, { payload }) => ({
      ...state,
      deployment: payload,
      isGetDeploymentByIdSuccess: true,
      isGetDeploymentByIdError: false,
    }),
    [getDeploymentByIdFailure]: (state) => ({
      ...state,
      isGetDeploymentByIdSuccess: false,
      isGetDeploymentByIdError: true,
    }),
    // delete deployment
    [deleteDeploymentRequest]: (state) => ({
      ...state,
      isDeletedDeploymentSuccess: false,
      isDeletedDeploymentError: false,
    }),
    [deleteDeploymentSuccess]: (state) => ({
      ...state,
      isDeletedDeploymentSuccess: true,
      isDeletedDeploymentError: false,
    }),
    [deleteDeploymentFailure]: (state) => ({
      ...state,
      isDeletedDeploymentSuccess: false,
      isDeletedDeploymentError: true,
    }),
  },
  initialState
);

export default reducer;
