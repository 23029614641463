import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import {
  getAllDatasets,
  createDataset,
  deleteDataset,
} from "utility/BatchTestDB";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";

const DatasetManager = ({
  onSelectDataset,
  onDatasetCreated,
  hideList = false,
  autoShowForm = false,
}) => {
  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(autoShowForm);
  const [newDatasetName, setNewDatasetName] = useState("");
  const [newDatasetDescription, setNewDatasetDescription] = useState("");
  const [uploadFile, setUploadFile] = useState(null);
  const [deletingDataset, setDeletingDataset] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [datasetToDelete, setDatasetToDelete] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!hideList) {
      loadDatasets();
    }
  }, [hideList]);

  const loadDatasets = async () => {
    setLoading(true);
    try {
      const data = await getAllDatasets();
      setDatasets(data);
    } catch (error) {
      toast.error(t("error_loading_datasets"), ToastOptions);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateDataset = async (e) => {
    e.preventDefault();

    if (!uploadFile) {
      toast.error(t("please_select_file"), ToastOptions);
      return;
    }

    if (!newDatasetName.trim()) {
      toast.error(t("please_enter_dataset_name"), ToastOptions);
      return;
    }

    setLoading(true);

    try {
      // Read file content
      const content = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsText(uploadFile);
      });

      // Parse and validate the content
      const lines = content.split("\n").filter((line) => line.trim() !== "");
      const validItems = lines
        .map((line, index) => {
          try {
            return JSON.parse(line);
          } catch (err) {
            toast.error(
              t("invalid_json_on_line_x", { line: index + 1 }),
              ToastOptions
            );
            return null;
          }
        })
        .filter((item) => item !== null);

      if (validItems.length === 0) {
        toast.error(t("no_valid_items_found"), ToastOptions);
        setLoading(false);
        return;
      }

      // Create the dataset
      const datasetId = await createDataset(
        newDatasetName,
        newDatasetDescription,
        validItems
      );

      // Reset form
      setNewDatasetName("");
      setNewDatasetDescription("");
      setUploadFile(null);

      if (!autoShowForm) {
        setShowCreateForm(false);
      }

      // Always refresh the datasets list after successful creation
      loadDatasets();

      // Notify parent of successful creation if handler provided
      if (onDatasetCreated) {
        onDatasetCreated();
      }

      toast.success(
        t("dataset_created_successfully", { count: validItems.length }),
        ToastOptions
      );
    } catch (error) {
      toast.error(t("error_creating_dataset"), ToastOptions);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDataset = async (datasetId) => {
    setDeletingDataset(datasetId);
    try {
      await deleteDataset(datasetId);
      toast.success(t("dataset_deleted_successfully"), ToastOptions);
      // Reload datasets after deletion
      loadDatasets();
    } catch (error) {
      toast.error(t("error_deleting_dataset"), ToastOptions);
      console.error(error);
    } finally {
      setDeletingDataset(null);
    }
  };

  const openDeleteModal = (dataset) => {
    setDatasetToDelete(dataset);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (datasetToDelete) {
      await handleDeleteDataset(datasetToDelete.id);
      setIsDeleteModalOpen(false);
      setDatasetToDelete(null);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString() + " " + date.toLocaleTimeString();
  };

  return (
    <div className="dataset-manager">
      {!hideList && (
        <div className="d-flex justify-content-end mb-3">
          <button
            className="btn primary"
            onClick={() => setShowCreateForm(!showCreateForm)}
          >
            {showCreateForm ? t("cancel") : t("add_dataset")}
          </button>
        </div>
      )}

      {(showCreateForm || autoShowForm) && (
        <div className="card mb-4 p-3">
          <form onSubmit={handleCreateDataset}>
            <div className="form-group mb-3">
              <label htmlFor="datasetName">{t("dataset_name")}*</label>
              <input
                type="text"
                id="datasetName"
                className="form-control"
                value={newDatasetName}
                onChange={(e) => setNewDatasetName(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="datasetDescription">{t("description")}</label>
              <textarea
                id="datasetDescription"
                className="form-control"
                value={newDatasetDescription}
                onChange={(e) => setNewDatasetDescription(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="datasetFile">{t("dataset_file")}*</label>
              <input
                type="file"
                id="datasetFile"
                className="form-control"
                accept=".json,.jsonl,.txt"
                onChange={(e) => setUploadFile(e.target.files[0])}
                required
              />
              <small className="form-text text-muted">
                {t("file_format_help")}
              </small>
            </div>
            <button type="submit" className="btn primary" disabled={loading}>
              {loading ? t("creating") : t("add_dataset")}
            </button>
          </form>
        </div>
      )}

      {!hideList &&
        (loading && !showCreateForm ? (
          <p>{t("loading_datasets")}</p>
        ) : datasets.length === 0 ? (
          <div className="empty-state-container">
            <p>{t("no_datasets_found")}</p>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>{t("name")}</th>
                  <th>{t("description")}</th>
                  <th>{t("items")}</th>
                  <th>{t("created")}</th>
                  <th className="text-right">{t("actions")}</th>
                </tr>
              </thead>
              <tbody>
                {datasets.map((dataset) => (
                  <tr key={dataset.id}>
                    <td>{dataset.name}</td>
                    <td>{dataset.description || "-"}</td>
                    <td>{dataset.itemCount}</td>
                    <td>{formatDate(dataset.createdAt)}</td>
                    <td className="text-right">
                      <div className="w-100 d-flex gap-2 justify-content-end">
                        <button
                          className="btn outline"
                          onClick={() => onSelectDataset(dataset)}
                        >
                          {t("use_dataset")}
                        </button>
                        <button
                          className="btn danger outline ml-2"
                          onClick={() => openDeleteModal(dataset)}
                          disabled={deletingDataset === dataset.id}
                        >
                          {deletingDataset === dataset.id ? (
                            <span>{t("deleting")}</span>
                          ) : (
                            <>
                              <TrashIcon />
                            </>
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}

      <DeleteConfirm
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        title={t("delete_dataset")}
        message={t("are_you_sure_delete_dataset", {
          name: datasetToDelete?.name || "",
        })}
      />
    </div>
  );
};

DatasetManager.propTypes = {
  onSelectDataset: PropTypes.func.isRequired,
  onDatasetCreated: PropTypes.func,
  hideList: PropTypes.bool,
  autoShowForm: PropTypes.bool,
};

export default DatasetManager;
