import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../assets/css/AdminCenter.css";
import SubHeader from "components/SubHeader";

// Import the relevant icons
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { ReactComponent as RolesIcon } from "assets/icons/shield.svg"; // Assume shield.svg represents roles
import { ReactComponent as SubscriptionIcon } from "assets/icons/settings.svg";

const AdminCenter = () => {
  const { t } = useTranslation();

  const cards = [
    {
      title: t("users"),
      subTitle: t("Manage user accounts and access"),
      link: "/user-accounts",
      icon: <UsersIcon className="icon" />,
    },
    {
      title: t("roles"),
      subTitle: t("Define and assign roles"),
      link: "/user-roles",
      icon: <RolesIcon className="icon" />,
    },
    {
      title: t("subscription"),
      subTitle: t("Manage subscription settings"),
      link: "/subscriptions",
      icon: <SubscriptionIcon className="icon" />,
    },
    //approval sequences
    {
      title: t("approval_sequences"),
      subTitle: t("Manage approval sequences"),
      link: "/approval-sequences",
      icon: <SubscriptionIcon className="icon" />,
    },
  ];

  return (
    <>
      <SubHeader title={t("admin_center")} />
      <div className="container-fluid">
        <div className="dashboard-row">
          {cards.map((card) => (
            <Link to={card.link} className="dashboard-card" key={card.title}>
              <h2>{t(card.title)}</h2>
              <div className="dashboard-card__row">
                <span className="dashboard-card__subtitle">
                  {card.subTitle}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default AdminCenter;
