import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import ResultContentModal from "../../modals/release/ResultContentModal";

const VirtualRow = React.memo(({ index, style, data }) => {
  const { results, t, handleViewComparison } = data;
  const result = results[index];

  // Early return if result is undefined (prevents errors)
  if (!result) return null;

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const handleRowClick = (content, type) => {
    setModalContent(content);
    setModalTitle(`${type} #${index + 1}`);
    setShowModal(true);
  };

  const handleCompareClick = () => {
    data.handleViewComparison(result, index); // Pass the index
  };

  return (
    <>
      <div
        style={{
          ...style,
          display: "flex",
          width: "100%", // Add this line
          boxSizing: "border-box", // Add this line
        }}
        className={`virtual-row  border-bottom`}
      >
        <div className="table-col col-number">
          <small>{index + 1}.</small>
        </div>
        <div className="table-col col-status">
          <small>{t(result.status)}</small>
        </div>
        <div
          className="table-col col-content"
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(result.input, "Input")}
        >
          <pre className="result-pre">
            {JSON.stringify(result.input).substring(0, 50)}...
          </pre>
        </div>
        <div
          className="table-col col-content"
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(result.output, "Output")}
        >
          <pre className="result-pre">
            {JSON.stringify(result.output).substring(0, 50)}...
          </pre>
        </div>
        <div className="table-col col-actions d-flex justify-content-center align-items-center">
          <button className="btn" onClick={handleCompareClick}>
            <EyeIcon />
          </button>
        </div>
      </div>

      <ResultContentModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={modalContent}
        title={modalTitle}
      />
    </>
  );
});

VirtualRow.displayName = "VirtualRow";

VirtualRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string.isRequired,
        input: PropTypes.any.isRequired,
        output: PropTypes.any.isRequired,
      })
    ).isRequired,
    t: PropTypes.func.isRequired,
    handleViewComparison: PropTypes.func.isRequired,
    setSelectedResult: PropTypes.func.isRequired,
  }).isRequired,
};

export default VirtualRow;
