import React from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { useTranslation } from "react-i18next";
import { types } from "../utils";

/**
 * Diff-aware input component.
 */
const DiffInput = ({ originalValue, newValue, className, ...props }) => {
  const orig = originalValue || "";
  const neu = newValue || "";
  const changed = String(orig) !== String(neu);
  return (
    <input
      {...props}
      value={neu}
      disabled
      className={`${className || ""} ${changed ? "changed-field" : ""}`}
    />
  );
};

DiffInput.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string,
  className: PropTypes.string,
};

/**
 * DiffDropdownButton compares two text values (the type labels) and shows both if different.
 */
const DiffDropdownButton = ({ originalText, newText }) => {
  if (String(originalText) === String(newText)) {
    return <span>{newText}</span>;
  }
  return (
    <span>
      <span className="diff-old">{originalText}</span>
      <span className="diff-new">{newText}</span>
    </span>
  );
};

DiffDropdownButton.propTypes = {
  originalText: PropTypes.string,
  newText: PropTypes.string,
};

/**
 * Diff-aware select component for read-only operator select.
 */
const DiffSelect = ({
  originalValue,
  newValue,
  options,
  className,
  ...props
}) => {
  const changed = String(originalValue || "") !== String(newValue || "");
  return (
    <select
      {...props}
      value={newValue || ""}
      disabled
      className={`${className || ""} ${changed ? "changed-field" : ""}`}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

DiffSelect.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
};

/**
 * ActionCompared renders an action with diff-aware controls.
 * It preserves the layout of the normal Action component while highlighting changes.
 */
const ActionCompared = ({
  originalAction = {},
  newAction,
  vectorsData,
  revisionMode = true,
}) => {
  const { t } = useTranslation();

  // Convert the action_vector values to strings.
  const originalVector =
    originalAction.action_vector !== undefined &&
    originalAction.action_vector !== null
      ? originalAction.action_vector.toString()
      : "";
  const newVector =
    newAction.action_vector !== undefined && newAction.action_vector !== null
      ? newAction.action_vector.toString()
      : "";

  // Find the type objects from the types array.
  const originalType = types.find((type) => type.value === originalVector);
  const newType = types.find((type) => type.value === newVector);

  return (
    <div
      className="action-compared border-md ruleset-row pl-0"
      id={`action-${newAction.rule_action_id}`}
    >
      {/* Attribute 1 Section */}
      <div className="attribute1 large-item">
        <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
          <div className="input-group-prepend">
            <button
              className="btn outline font-size-14 w-100 border-radius-left-4 bg-white"
              type="button"
            >
              {t("attribute")}:
            </button>
          </div>
          <DiffInput
            originalValue={originalAction.attribute_path}
            newValue={newAction.attribute_path}
            className="form-control w-100 border-radius-right-4"
          />
        </div>
      </div>

      {/* Operator Select (diff-aware, read-only) */}
      <DiffSelect
        originalValue={originalAction.action_type}
        newValue={newAction.action_type}
        options={[
          { value: "s", label: "=" },
          { value: "p", label: "+" },
          { value: "m", label: "-" },
          { value: "x", label: "*" },
          { value: "h", label: "max([array])" },
          { value: "l", label: "min([array])" },
          { value: "c", label: "count([array])" },
          { value: "g", label: "sum([array])" },
          { value: "v", label: "avg([array])" },
          { value: "a", label: "add to array" },
          { value: "t", label: "add text" },
        ]}
        className="form-control outline font-size-16 condition-operation math-symbols text-center"
      />

      {/* Attribute 2 Section */}
      <div className="attribute2 large-item">
        <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
          <div className="input-group-prepend">
            <button
              className="btn outline font-size-14 dropdown-toggle text-left border-radius-left-4 bg-white rule-set-btn"
              type="button"
              disabled
            >
              <DiffDropdownButton
                originalText={originalType ? originalType.label : ""}
                newText={newType ? newType.label : ""}
              />
              :
            </button>
          </div>
          {newVector == "1" ? (
            <DiffInput
              originalValue={originalAction.action_path}
              newValue={newAction.action_path}
              className="form-control w-100 border-radius-right-4"
            />
          ) : (
            <DiffInput
              originalValue={originalAction.action_value}
              newValue={newAction.action_value}
              className="form-control w-100 border-radius-right-4"
            />
          )}
        </div>
      </div>
    </div>
  );
};

ActionCompared.propTypes = {
  originalAction: PropTypes.object,
  newAction: PropTypes.object.isRequired,
  vectorsData: PropTypes.array,
  revisionMode: PropTypes.bool,
};

export default ActionCompared;
