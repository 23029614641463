import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createOAuth2TokenRequest,
  deleteDataSourceHeaderRequest,
  deleteDataSourceParamRequest,
  deleteOAuth2TokenRequest,
  getOAuth2TokenRequest,
  getOAuth2TokensRequest,
  updateOAuth2TokenRequest,
  getExternalDataResponsesRequest,
  getExternalDataResponseRequest,
  createExternalDataResponseRequest,
  updateExternalDataResponseRequest,
  deleteExternalDataResponseRequest,
} from "redux/dataSources/action";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { MainContext } from "context/contexts";
import { generateCloudInstanceName } from "utility/utility";
import Parameter, { getNewParam } from "./Parameter";
import Header, { getNewHeader } from "./Header";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";
import { ReactComponent as CirclePlusIcon } from "assets/icons/circle-plus.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "assets/icons/templateIcons/edit_small.svg";
import { ReactComponent as WatchIcon } from "assets/icons/watch.svg";
import { ReactComponent as JSONIcon } from "assets/icons/json.svg";
import { ReactComponent as XMLIcon } from "assets/icons/xml.svg";
import ServiceParameterTable from "./ServiceParameterTable";
import Token from "../modals/Token";
import CustomSelect from "../CustomSelect";
import ResponseCard from "./ResponseCard";
import ResponseModal from "./ResponseModal";

const DataSourceContent = ({ data, isNew = false, dama_service_id = null }) => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    isDeletedDataSourceParamSuccess,
    isDeletedDataSourceHeaderSuccess,
    isGetOAuth2TokensSuccess,
    isGetOAuth2TokenSuccess,
    isCreatedOAuth2TokenSuccess,
    isUpdatedOAuth2TokenSuccess,
    isDeletedOAuth2TokenSuccess,
    oauth2Tokens,
    oauth2Token,
    dataResponses,
    dataResponse,
    isGetResponsesSuccess,
    isGetResponseSuccess,
    isCreatedResponseSuccess,
    isUpdatedResponseSuccess,
    isDeletedResponseSuccess,
  } = useSelector((state) => state.dataSources);

  const { dataServiceDetails, isDataMarketGetServiceDetailsSuccess } =
    useSelector((state) => state.dataMarket);

  const prevIsDeletedDataSourceParamSuccess = usePrevious(
    isDeletedDataSourceParamSuccess
  );
  const prevIsDeletedDataSourceHeaderSuccess = usePrevious(
    isDeletedDataSourceHeaderSuccess
  );

  const [method, setMethod] = useState("get");
  const [oauth2Authentication, setOauth2Authentication] = useState(false);

  const [authMethod, setAuthMethod] = useState("none"); // 'none' | 'http' | 'oauth2'

  const [selectedOauth2, setSelectedOauth2] = useState({
    type: null,
    value: null,
    label: null,
  });
  const [selectedOauth2Token, setSelectedOauth2Token] = useState(null);
  const [oauth2TokensData, setOauth2TokensData] = useState([]);
  const [isAddTokenModalOpen, setIsAddTokenModalOpen] = useState(false);

  const [dataClone, setDataClone] = useState({});
  const [params, setParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedParamId, setSelectedParamId] = useState({});
  const [selectedHeaderId, setSelectedHeaderId] = useState({});
  const [isDeleteTokenModalOpen, setIsDeleteTokenModalOpen] = useState(false);
  const [isParameterDeleteModalOpen, setIsParameterDeleteModalOpen] =
    useState(false);
  const [isHeaderDeleteModalOpen, setIsHeaderDeleteModalOpen] = useState(false);
  const [modalOauth2Token, setModalOauth2Token] = useState({});

  const [isAddResponseModalOpen, setIsAddResponseModalOpen] = useState(false);
  const [isEditResponseModalOpen, setIsEditResponseModalOpen] = useState(false);
  const [isDeleteResponseModalOpen, setIsDeleteResponseModalOpen] =
    useState(false);
  const [currentResponse, setCurrentResponse] = useState(null);
  const [selectedResponseId, setSelectedResponseId] = useState(null);

  const [activeTab, setActiveTab] = useState("params");

  const prevIsGetOAuth2TokensSuccess = usePrevious(isGetOAuth2TokensSuccess);
  const prevIsGetOAuth2TokenSuccess = usePrevious(isGetOAuth2TokenSuccess);
  const prevIsCreatedOAuth2TokenSuccess = usePrevious(
    isCreatedOAuth2TokenSuccess
  );
  const prevIsUpdatedOAuth2TokenSuccess = usePrevious(
    isUpdatedOAuth2TokenSuccess
  );
  const prevIsDeletedOAuth2TokenSuccess = usePrevious(
    isDeletedOAuth2TokenSuccess
  );

  const prevIsGetResponsesSuccess = usePrevious(isGetResponsesSuccess);
  const prevIsGetResponseSuccess = usePrevious(isGetResponseSuccess);
  const prevIsCreatedResponseSuccess = usePrevious(isCreatedResponseSuccess);
  const prevIsUpdatedResponseSuccess = usePrevious(isUpdatedResponseSuccess);
  const prevIsDeletedResponseSuccess = usePrevious(isDeletedResponseSuccess);

  const authOptions = [
    { value: "none", label: "None" },
    { value: "http", label: "HTTP Basic" },
    { value: "oauth2", label: "OAuth2" },
  ];

  const selectedAuthOption = authOptions.find(
    (opt) => opt.value === authMethod
  );

  useEffect(() => {
    dispatch(getOAuth2TokensRequest());

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      setOauth2Authentication(!!data?.external_data_oauth2_id);
    }
  }, [data]);

  useEffect(() => {
    if (isGetOAuth2TokenSuccess && prevIsGetOAuth2TokenSuccess === false) {
      setModalOauth2Token(oauth2Token);
      setIsAddTokenModalOpen(true);
    }
  }, [isGetOAuth2TokenSuccess]);

  useEffect(() => {
    if (
      (isUpdatedOAuth2TokenSuccess &&
        prevIsUpdatedOAuth2TokenSuccess === false) ||
      (isCreatedOAuth2TokenSuccess && prevIsCreatedOAuth2TokenSuccess === false)
    ) {
      dispatch(getOAuth2TokensRequest());
      setIsAddTokenModalOpen(false);
      setIsDeleteTokenModalOpen(false);
    }
    if (
      isUpdatedOAuth2TokenSuccess &&
      prevIsUpdatedOAuth2TokenSuccess === false
    ) {
      toast.success(t("token_updated"), ToastOptions);
    } else if (
      isCreatedOAuth2TokenSuccess &&
      prevIsCreatedOAuth2TokenSuccess === false
    ) {
      toast.success(t("token_created"), ToastOptions);
    }
  }, [
    isUpdatedOAuth2TokenSuccess,
    prevIsUpdatedOAuth2TokenSuccess,
    isCreatedOAuth2TokenSuccess,
    prevIsCreatedOAuth2TokenSuccess,
  ]);

  useEffect(() => {
    if (
      isDeletedOAuth2TokenSuccess &&
      prevIsDeletedOAuth2TokenSuccess === false
    ) {
      const clone = structuredClone(oauth2Tokens);
      setOauth2TokensData(
        clone.map((data) => {
          return {
            label: data.name,
            value: data.external_data_oauth2_id,
          };
        })
      );
      setIsAddTokenModalOpen(false);
      setIsDeleteTokenModalOpen(false);
      toast.success(t("token_deleted"), ToastOptions);
    }
  }, [isDeletedOAuth2TokenSuccess, prevIsDeletedOAuth2TokenSuccess]);

  useEffect(() => {
    if (
      (isGetOAuth2TokensSuccess && prevIsGetOAuth2TokensSuccess === false) ||
      oauth2Tokens.length > 0
    ) {
      const clone = structuredClone(oauth2Tokens);
      setOauth2TokensData(
        clone.map((data) => {
          return {
            label: data.name,
            value: data.external_data_oauth2_id,
          };
        })
      );
    }
  }, [isGetOAuth2TokensSuccess]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && !isNew) {
      // Data loaded, check auth method
      if (data.http_auth === 1 || data.http_auth === "1") {
        setAuthMethod("http");
      } else if (data.external_data_oauth2_id) {
        setAuthMethod("oauth2");

        // Load the OAuth2 tokens if they're not loaded yet
        if (oauth2TokensData.length === 0) {
          dispatch(getOAuth2TokensRequest());
        }
      } else {
        setAuthMethod("none");
      }

      // Rest of your existing code for loading data
      setDataClone(structuredClone(data));
      setMethod(data.method ? data.method : "");
      setParams(
        data.params ? [...data.params, getNewParam()] : [getNewParam()]
      );
      setHeaders(
        data.headers ? [...data.headers, getNewHeader()] : [getNewHeader()]
      );
    }
  }, [data, isNew]);

  useEffect(() => {
    if (isNew) {
      setParams([getNewParam()]);
      setHeaders([getNewHeader()]);
    }
  }, [isNew]);

  useEffect(() => {
    if (
      isDeletedDataSourceParamSuccess &&
      prevIsDeletedDataSourceParamSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(`${t("parameter")} ${t("deleted")}.`, ToastOptions);
    }
  }, [isDeletedDataSourceParamSuccess]);

  useEffect(() => {
    if (
      isDeletedDataSourceHeaderSuccess &&
      prevIsDeletedDataSourceHeaderSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(`${t("header")} ${t("deleted")}.`, ToastOptions);
    }
  }, [isDeletedDataSourceHeaderSuccess]);

  useEffect(() => {
    if (id && !isNew) {
      dispatch(getExternalDataResponsesRequest({ external_data_id: id }));
    }
  }, [id, isNew, dispatch]);

  useEffect(() => {
    if (isGetResponsesSuccess && prevIsGetResponsesSuccess === false) {
      // Responses loaded successfully
    }
  }, [isGetResponsesSuccess, prevIsGetResponsesSuccess]);

  useEffect(() => {
    if (isCreatedResponseSuccess && prevIsCreatedResponseSuccess === false) {
      toast.success(t("response_created_successfully"), ToastOptions);
      if (id && !isNew) {
        dispatch(getExternalDataResponsesRequest({ external_data_id: id }));
      }
    }
  }, [isCreatedResponseSuccess, prevIsCreatedResponseSuccess, id, isNew]);

  useEffect(() => {
    if (isUpdatedResponseSuccess && prevIsUpdatedResponseSuccess === false) {
      toast.success(t("response_updated_successfully"), ToastOptions);
      if (id && !isNew) {
        dispatch(getExternalDataResponsesRequest({ external_data_id: id }));
      }
    }
  }, [isUpdatedResponseSuccess, prevIsUpdatedResponseSuccess, id, isNew]);

  useEffect(() => {
    console.log("Response deletion effect triggered");
    if (isDeletedResponseSuccess && prevIsDeletedResponseSuccess === false) {
      toast.success(t("response_deleted_successfully"), ToastOptions);
      if (id && !isNew) {
        dispatch(getExternalDataResponsesRequest({ external_data_id: id }));
      }
    }
  }, [isDeletedResponseSuccess, prevIsDeletedResponseSuccess]);

  useEffect(() => {
    console.log("Effect triggered - OAuth2 ID:", data?.external_data_oauth2_id);
    console.log("Available tokens:", oauth2TokensData);

    if (data?.external_data_oauth2_id && oauth2TokensData?.length > 0) {
      // Convert IDs to numbers to ensure consistent comparison
      const externalDataOauth2Id = Number(data.external_data_oauth2_id);

      const foundToken = oauth2TokensData.find(
        (token) => Number(token.value) === externalDataOauth2Id
      );

      console.log("Found token:", foundToken);

      if (foundToken) {
        setSelectedOauth2Token(foundToken);
      } else {
        console.log("No matching token found!");
      }
    }
  }, [data, oauth2TokensData]);

  const deleteParam = (paramId) => {
    const paramsClone = structuredClone(params);
    const filteredParams = paramsClone.filter(
      (param) => param.external_data_param_id !== paramId
    );
    setParams(filteredParams);
    if (typeof paramId === "number") {
      setIsLoading(true);
      dispatch(deleteDataSourceParamRequest({ id, paramId }));
    } else {
      setIsEdited(true);
    }
  };

  const deleteHeader = (headerId) => {
    setIsEdited(true);
    const headersClone = structuredClone(headers);
    const filteredHeaders = headersClone.filter(
      (header) => header.external_data_header_id !== headerId
    );
    setHeaders(filteredHeaders);
    if (typeof headerId === "number") {
      setIsLoading(true);
      dispatch(deleteDataSourceHeaderRequest({ id, headerId }));
    }
  };

  const handleShowParamDeleteModal = (selectedData) => {
    setSelectedParamId(selectedData);
    setIsParameterDeleteModalOpen(true);
  };

  const handleClose = () => {
    setSelectedParamId({});
    setIsParameterDeleteModalOpen(false);
    setIsDeleteTokenModalOpen(false);
  };

  const handleConfirm = () => {
    setIsParameterDeleteModalOpen(false);
    deleteParam(selectedParamId);
  };

  const handleConfirmDeleteToken = () => {
    dispatch(deleteOAuth2TokenRequest(selectedOauth2.value));
  };

  const handleShowHeaderDeleteModal = (selectedData) => {
    setSelectedHeaderId(selectedData);
    setIsHeaderDeleteModalOpen(true);
  };

  const handleHeaderDeleteModalClose = () => {
    setSelectedHeaderId({});
    setIsHeaderDeleteModalOpen(false);
  };

  const handleHeaderDeleteConfirm = () => {
    setIsHeaderDeleteModalOpen(false);
    deleteHeader(selectedHeaderId);
  };

  const handleChangeMethod = (e) => {
    setIsEdited(true);
    setMethod(e.value);
  };

  const methods = [
    {
      value: "get",
      label: "GET",
    },
    {
      value: "post",
      label: "POST",
    },
    {
      value: "put",
      label: "PUT",
    },
  ];

  const getSelectValue = () => {
    return methods.find((data) => data.value == method);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isSelected || state.isFocused ? "#0094E5" : "#DEE2E6",
    }),
    option: (provided, state) => ({
      ...provided,
      height: 37,
      minHeight: 37,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#DEE2E6",
      borderBottom: "2px solid #f8f9fa",
      color: "#6C757D",
      paddingLeft: "10px !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "100%",
      color: "#6C757D",
      marginLeft: "10px",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? "#0094E5" : "#6C757D", // Custom colour
      marginRight: "10px",
    }),
  };

  const TableColumns = ({ tfoot = false, isHeaders = false }) => {
    let columns = (
      <tr className="border-0">
        <th>{t("parameter_name")}</th>
        <th>{t("value")}</th>
        <th>{t("key")}</th>
        <th className="text-center">
          {!tfoot && (
            <span data-tip={true} data-for="add-decision-flow-step">
              {t("flow")}
            </span>
          )}

          <ReactTooltip
            type="dark"
            place="top"
            effect="solid"
            id="add-decision-flow-step"
          >
            {t("add_to_decision_flow_step")}
          </ReactTooltip>
        </th>
        <th className="function-col" />
      </tr>
    );
    if (isHeaders) {
      columns = (
        <tr className="border-0">
          <th>{t("header")}</th>
          <th>{t("key")}</th>
          <th className="function-col" />
        </tr>
      );
    }
    return columns;
  };

  TableColumns.propTypes = {
    tfoot: PropTypes.bool,
    isHeaders: PropTypes.bool,
  };

  const handleToggle = (id, label, type = "edit") => {
    if (type === "edit") {
      dispatch(getOAuth2TokenRequest(id));
      setIsEdited(true);
    } else {
      setIsDeleteTokenModalOpen(true);
    }

    setSelectedOauth2({
      type,
      value: id,
      label,
    });
  };

  const handleSubmit = (target, tokenId) => {
    setIsEdited(false);
    const data = new URLSearchParams(new FormData(target));
    const url = target.token_url.value;
    const grantType = target.grant_type.value;

    const urlRegex =
      /^(https?:\/\/)([\w\d][-\w\d]*)(\.[\w\d][-\w\d]*)*(\.\w{2,})(:\d{1,5})?(\/[^\s]*)?$/i;
    if (!urlRegex.test(url)) {
      return toast.error(t("http_invalid"), ToastOptions);
    }

    for (const [key, value] of data) {
      data.set(key, value);
      if (key.includes("use_basic_auth")) {
        if (value === "true" || value === "1") {
          data.set(key, 1);
        } else {
          data.set(key, 0);
        }
      }
    }

    if (tokenId) {
      dispatch(
        updateOAuth2TokenRequest({
          data,
          id: tokenId,
        })
      );
    } else {
      dispatch(
        createOAuth2TokenRequest({
          data,
        })
      );
    }
  };

  const setToken = () => {
    setIsAddTokenModalOpen(false);
    setSelectedOauth2({ ...selectedOauth2, type: "" });
    setModalOauth2Token({});
  };

  const handleAddResponse = (responseData) => {
    dispatch(
      createExternalDataResponseRequest({
        external_data_id: id,
        ...responseData,
      })
    );
    setIsAddResponseModalOpen(false);
  };

  const handleEditResponse = (response) => {
    setCurrentResponse(response);
    setIsEditResponseModalOpen(true);
  };

  const handleUpdateResponse = (responseData) => {
    dispatch(
      updateExternalDataResponseRequest({
        external_data_id: id,
        ext_data_response_id: currentResponse.ext_data_response_id,
        ...responseData,
      })
    );
    setIsEditResponseModalOpen(false);
  };

  const handleDeleteResponse = (responseId) => {
    setSelectedResponseId(responseId);
    setIsDeleteResponseModalOpen(true);
  };

  const handleConfirmDeleteResponse = () => {
    dispatch(
      deleteExternalDataResponseRequest({
        external_data_id: id,
        ext_data_response_id: selectedResponseId,
      })
    );
    setIsDeleteResponseModalOpen(false);
  };

  // Add this helper function at component level
  const handleResponseTypeChange = (type) => {
    // Update hidden input value
    document.querySelector('input[name="response_type"]').value = type;
    // Update data clone for UI refresh
    setDataClone({ ...dataClone, response_type: type });
    // Close dropdown
    document.getElementById("responseTypeDropdown").classList.remove("show");
  };

  // Then in the JSX, replace the buttons with:
  const ResponseTypeButton = ({ type, Icon }) => (
    <button
      className="dropdown-item d-flex align-items-center"
      type="button"
      onClick={() => handleResponseTypeChange(type.toLowerCase())}
    >
      <Icon className="mr-2" /> {type}
    </button>
  );

  ResponseTypeButton.propTypes = {
    type: PropTypes.string.isRequired,
    Icon: PropTypes.elementType.isRequired,
  };

  return (
    <>
      <input type="hidden" name="is_show_token" value={oauth2Authentication} />
      <div className="row">
        <div className="col-md col-12">
          <label>{t("table_title")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="title"
            defaultValue={dataClone.title ? dataClone.title : ""}
            required
          />
        </div>
        <div className="col-md col-12">
          <label>{t("identifier")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="name"
            defaultValue={
              dataClone.name
                ? dataClone.name
                : !id
                ? generateCloudInstanceName()
                : ""
            }
            pattern="[a-zA-Z0-9_\-]+"
            title={t("only_letters_numbers_underscore_hyphen")}
            required
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9_-]/g, "");
            }}
          />
        </div>
      </div>
      {!dama_service_id && (
        <>
          <div className="align-items-center row my-md-5">
            <div className="col-lg-2 col-md-3 pb-3 pb-md-0 pr-md-0">
              <label>{t("method")}</label>
              <br />
              <CustomSelect
                defaultValue={getSelectValue}
                options={methods}
                selectKey={method}
                handleChange={handleChangeMethod}
                components={{
                  IndicatorSeparator: () => null,
                }}
                selectStyle={{
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    marginRight: "0",
                  }),
                  control: (styles) => ({
                    ...styles,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }),
                }}
              />
              <input type="hidden" name="method" value={method} />
            </div>
            <div className="col-12 col-md pl-md-0 border-left-0 ">
              <label htmlFor="endpoint_url">{t("url_endpoint")}</label>
              <div className="input-group border-left-0 border-radius-left-0">
                <input
                  type="text"
                  className="form-control border-radius-0 border-left-0 border-right-1 border-radius-right-0"
                  name="endpoint_url"
                  placeholder="https://"
                  defaultValue={
                    dataClone.endpoint_url ? dataClone.endpoint_url : ""
                  }
                  style={{
                    borderRadius: "0",
                    WebkitBorderRadius: "0",
                    height: "38px",
                  }}
                />
                {/* Hidden input for response_type value */}
                <input
                  type="hidden"
                  name="response_type"
                  value={dataClone.response_type || (isNew ? "json" : "")}
                />
                <div
                  className="input-group-append border-1 p-0 border-radius-right-0 border-left-0"
                  data-tip
                  data-for="response-type-tooltip"
                  style={{ height: "38px" }}
                >
                  <div className="dropdown">
                    <span
                      className="input-group-text border-1 border-right-0 p-0 m-0 d-flex align-items-center justify-content-center cursor-pointer"
                      style={{
                        height: "100%",
                        width: "38px",
                        textAlign: "center",
                        borderRadius: "0",
                        WebkitBorderRadius: "0",
                      }}
                      onClick={() => {
                        const dropdown = document.getElementById(
                          "responseTypeDropdown"
                        );
                        dropdown.classList.toggle("show");
                      }}
                    >
                      {dataClone.response_type === "xml" ? (
                        <XMLIcon />
                      ) : (
                        <JSONIcon />
                      )}
                    </span>
                    <div
                      id="responseTypeDropdown"
                      className="dropdown-menu dropdown-menu-right p-0"
                    >
                      <ResponseTypeButton type="JSON" Icon={JSONIcon} />
                      <ResponseTypeButton type="XML" Icon={XMLIcon} />
                    </div>
                  </div>
                  <ReactTooltip
                    id="response-type-tooltip"
                    place="top"
                    effect="solid"
                  >
                    {t("response_format_click_to_change", {
                      format: t(dataClone.response_type) || "json",
                    })}
                  </ReactTooltip>
                </div>
                <div
                  className="input-group-append border-1 p-0"
                  data-tip
                  data-for="timeout-tooltip"
                  style={{ height: "38px" }}
                >
                  <span
                    className="input-group-text border-radius-right-4 border-1 p-0 pl-2 m-0 d-flex align-items-center"
                    style={{ height: "100%" }}
                  >
                    <WatchIcon />
                    <input
                      type="number"
                      className="form-control border-0"
                      name="timeout"
                      placeholder="60"
                      defaultValue={dataClone.timeout ? dataClone.timeout : 5}
                      style={{
                        width: "40px",
                        height: "100%",
                        padding: "0 0 0 4px",
                        backgroundColor: "transparent",
                      }}
                    />
                  </span>
                </div>
                <ReactTooltip id="timeout-tooltip" place="top" effect="solid">
                  {t("timeout_sec")}
                </ReactTooltip>
              </div>
            </div>
          </div>

          <div className="api-tabs-container mt-4 mb-5">
            {/* Tab Navigation */}
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "params" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("params")}
                  role="tab"
                  type="button"
                >
                  {t("parameters")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "headers" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("headers")}
                  role="tab"
                  type="button"
                >
                  {t("headers")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === "auth" ? "active" : ""}`}
                  onClick={() => setActiveTab("auth")}
                  role="tab"
                  type="button"
                >
                  {t("authentication")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "responses" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("responses")}
                  role="tab"
                  type="button"
                >
                  {t("response_variants")}
                </button>
              </li>
            </ul>

            {/* Tab Content */}
            <div className="tab-content border-top-0 pt-3">
              {/* Authentication Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "auth" ? "show active" : ""
                }`}
              >
                <div className="row">
                  <div className="col-md col-12 pb-md-0 pb-3">
                    <label>{t("authentication_method")}</label>
                    <CustomSelect
                      options={authOptions}
                      value={selectedAuthOption}
                      defaultValue={selectedAuthOption}
                      handleChange={(e) => {
                        setAuthMethod(e.value);
                        setIsEdited(true);
                      }}
                      isSearchable={false}
                      name="auth_method"
                      key={`auth-method-${authMethod}`}
                    />
                    <input
                      type="hidden"
                      name="http_authentication"
                      value={authMethod === "http" ? 1 : 0}
                    />
                    <input
                      type="hidden"
                      name="use_basic_auth"
                      value={authMethod === "oauth2" ? 1 : 0}
                    />
                  </div>
                </div>

                {authMethod === "http" && (
                  <div className="row mt-3">
                    <div className="col-md col-12 pb-md-0 pb-3">
                      <label>{t("authentication_username")}</label>
                      <input
                        type="text"
                        className="form-control border-radius-4"
                        name="auth_username"
                        defaultValue={
                          dataClone.auth_username ? dataClone.auth_username : ""
                        }
                      />
                    </div>
                    <div className="col-md col-12 pb-md-0 pb-3">
                      <label>{t("http_password")}</label>
                      <input
                        type="text"
                        className="form-control border-radius-4"
                        name="auth_password"
                      />
                    </div>
                  </div>
                )}

                {authMethod === "oauth2" && (
                  <div className="row mt-3">
                    <div className="col-md col-12 pb-md-0 pb-3">
                      <ButtonGroup className="w-100 h-fit-content">
                        <CustomSelect
                          defaultValue={
                            data &&
                            oauth2TokensData?.find(
                              (token) =>
                                token.value == data.external_data_oauth2_id
                            )
                          }
                          key={
                            data &&
                            oauth2TokensData?.find(
                              (token) =>
                                token.value == data.external_data_oauth2_id
                            )?.label
                          }
                          classes="z-index-100"
                          options={oauth2TokensData}
                          name="external_data_oauth2_id"
                          isSearchable={false}
                          isDataSourceSection={true}
                          onChange={(selected) => {
                            console.log("Token selection changed:", selected);
                            setSelectedOauth2Token(selected);
                            setIsEdited(true);
                          }}
                          components={{
                            Option: ({ innerProps, label, value }) => {
                              return (
                                <div
                                  {...innerProps}
                                  className="d-flex justify-content-between align-items-center"
                                  style={{ padding: "8px", cursor: "pointer" }}
                                >
                                  <p className="m-0">{label}</p>
                                  <div>
                                    <button
                                      title={t("edit_token")}
                                      className="border-radius-4 border-0 btn btn-outline-light mr-3"
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleToggle(value, label, "edit");
                                      }}
                                    >
                                      <EditIcon />
                                    </button>
                                    <button
                                      title={t("token_delete")}
                                      className="border-radius-4 border-0 btn btn-outline-light mr-3 option-delete"
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleToggle(value, label, "delete");
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{
                                          color: "#6C757D",
                                        }}
                                      />
                                    </button>
                                  </div>
                                </div>
                              );
                            },
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <button
                          title={t("add_token")}
                          className="btn outline w-25 h-38 mr-2"
                          type="button"
                          onClick={() => setIsAddTokenModalOpen(true)}
                        >
                          <CirclePlusIcon />
                          <span className="ml-2">{t("add_token")}</span>
                        </button>
                      </ButtonGroup>
                    </div>
                  </div>
                )}
              </div>

              {/* Parameters Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "params" ? "show active" : ""
                }`}
              >
                {method === "post" && (
                  <div className="col col-12 pb-md-0 pb-3 mb-2">
                    <label>{t("post_data_type")}:</label>

                    <div
                      className="btn-group btn-group-toggle border-radius-left-4 ml-2"
                      data-toggle="buttons"
                    >
                      <label
                        className={`btn outline border-radius-left-4 py-2 ${
                          (dataClone.post_json !== undefined &&
                            Number(dataClone.post_json) === 0) ||
                          !Object.keys(dataClone).length
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="post_json"
                          defaultValue="0"
                          defaultChecked={
                            (dataClone.post_json !== undefined &&
                              Number(dataClone.post_json) === 0) ||
                            !Object.keys(dataClone).length
                          }
                          onChange={() => {
                            setDataClone({ ...dataClone, post_json: 0 });
                            setIsEdited(true);
                          }}
                        />
                        Plain
                      </label>
                      <label
                        className={`btn outline border-radius-right-4 py-2 ${
                          dataClone.post_json !== undefined &&
                          Number(dataClone.post_json) === 1
                            ? "active"
                            : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="post_json"
                          defaultValue="1"
                          defaultChecked={
                            dataClone.post_json !== undefined &&
                            Number(dataClone.post_json) === 1
                          }
                          onChange={() => {
                            setDataClone({ ...dataClone, post_json: 1 });
                            setIsEdited(true);
                          }}
                        />
                        JSON
                      </label>
                    </div>
                  </div>
                )}

                <div className="border-radius-4  table-responsive">
                  <table className="table data-source-table">
                    <colgroup>
                      <col span="1" style={{ width: "45%" }} />
                      <col span="1" style={{ width: "45%" }} />
                      <col span="1" style={{ width: "3%" }} />
                      <col span="1" style={{ width: "4%" }} />
                      <col span="1" style={{ width: "3%" }} />
                    </colgroup>
                    <thead className="bg-transparent border-0">
                      <TableColumns />
                    </thead>
                    <tbody>
                      {params.length > 0 &&
                        params.map((param, index) => (
                          <Parameter
                            key={param.external_data_param_id}
                            param={param}
                            setParams={setParams}
                            isLast={!params[index + 1]}
                            handleShowParamDeleteModal={
                              handleShowParamDeleteModal
                            }
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Headers Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "headers" ? "show active" : ""
                }`}
              >
                <div className="mb-5 border-radius-4 table-responsive">
                  <table className="table data-source-table">
                    <colgroup>
                      <col span="1" style={{ width: "97%" }} />
                      <col span="1" style={{ width: "3%" }} />
                    </colgroup>
                    <thead className="bg-transparent border-0">
                      <TableColumns isHeaders={true} />
                    </thead>
                    <tbody>
                      {headers.length > 0 &&
                        headers.map((header, index) => (
                          <Header
                            key={header.external_data_header_id}
                            setHeaders={setHeaders}
                            header={header}
                            handleShowHeaderDeleteModal={
                              handleShowHeaderDeleteModal
                            }
                            isLast={!headers[index + 1]}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Responses Tab */}
              <div
                className={`tab-pane fade ${
                  activeTab === "responses" ? "show active" : ""
                }`}
              >
                <div className="d-flex justify-content-end mb-3">
                  <button
                    className="btn outline"
                    type="button"
                    onClick={() => setIsAddResponseModalOpen(true)}
                  >
                    <CirclePlusIcon />
                    <span className="ml-2">{t("add_response")}</span>
                  </button>
                </div>

                {dataResponses && dataResponses.length > 0 ? (
                  <div className="responses-container">
                    {dataResponses.map((response) => (
                      <ResponseCard
                        key={response.ext_data_response_id}
                        response={response}
                        onEdit={() => handleEditResponse(response)}
                        onDelete={() =>
                          handleDeleteResponse(response.ext_data_response_id)
                        }
                      />
                    ))}
                  </div>
                ) : (
                  <div className="p-4 border rounded">
                    <p className="text-center text-muted">
                      {t("no_responses_available")}
                    </p>
                    <p className="text-center">
                      {t("test_data_source_to_capture_responses")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {dama_service_id && (
        <>
          <div className="align-items-center row my-md-5">
            <div className="col-md col-12">
              <label>{t("data_hub_service")}</label>
              <br />
              {dataServiceDetails.title} by {dataServiceDetails.provider_title}
            </div>
          </div>

          <ServiceParameterTable
            serviceParams={data?.service_params || []} // Add null check and default
            params={params}
            setParams={setParams}
          />
        </>
      )}

      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_parameter")}
        message={t("delete_parameter_message")}
        open={isParameterDeleteModalOpen}
      />
      <DeleteConfirm
        handleClose={handleHeaderDeleteModalClose}
        handleConfirm={handleHeaderDeleteConfirm}
        title={t("delete_header")}
        message={t("delete_header_message")}
        open={isHeaderDeleteModalOpen}
      />
      {isAddTokenModalOpen && (
        <Token
          open={isAddTokenModalOpen}
          type={selectedOauth2.type}
          oauth2Token={modalOauth2Token}
          handleClose={setToken}
          handleSubmit={handleSubmit}
        />
      )}
      {isDeleteTokenModalOpen && (
        <DeleteConfirm
          handleClose={handleClose}
          handleConfirm={handleConfirmDeleteToken}
          title={t("token_delete")}
          message={
            <span>
              {t("do_you_want_to_delete")} <b>{selectedOauth2?.label}</b>{" "}
              {t("token")}?
            </span>
          }
          open={isDeleteTokenModalOpen}
        />
      )}
      {isAddResponseModalOpen && (
        <ResponseModal
          show={isAddResponseModalOpen}
          onClose={() => setIsAddResponseModalOpen(false)}
          onSave={handleAddResponse}
        />
      )}
      {isEditResponseModalOpen && (
        <ResponseModal
          open={isEditResponseModalOpen}
          response={currentResponse}
          handleClose={() => setIsEditResponseModalOpen(false)}
          handleSubmit={handleUpdateResponse}
        />
      )}
      {isDeleteResponseModalOpen && (
        <DeleteConfirm
          handleClose={() => setIsDeleteResponseModalOpen(false)}
          handleConfirm={handleConfirmDeleteResponse}
          title={t("delete_response")}
          message={t("do_you_want_to_delete_response")}
          open={isDeleteResponseModalOpen}
        />
      )}
    </>
  );
};

DataSourceContent.propTypes = {
  data: PropTypes.object,
  isNew: PropTypes.bool,
  innerProps: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  dama_service_id: PropTypes.any,
};

export default DataSourceContent;
