import Dexie from "dexie";

const BatchTestDB = new Dexie("BatchTestDB");

BatchTestDB.version(5).stores({
  // Basic dataset info
  datasets: "++id, name, description, createdAt, updatedAt, itemCount",
  // Actual items for each dataset
  datasetItems: "++id, datasetId, input, createdAt",

  // Release test tracking
  pendingReleaseTests: "++id, releaseId, datasetId, input, status, timestamp",
  releaseResults:
    "++id, releaseId, datasetId, input, output, status, timestamp",

  // Scenario test tracking
  pendingScenarioTests: "++id, scenarioId, datasetId, input, status, timestamp",
  scenarioResults:
    "++id, scenarioId, datasetId, input, baselineOutput, comparisonOutput, timestamp",
});

/* ------------------------------------------
   DATASET CRUD
   ------------------------------------------ */
export const createDataset = async (name, description, items) => {
  // Insert the dataset record
  const datasetId = await BatchTestDB.datasets.add({
    name,
    description,
    createdAt: new Date(),
    updatedAt: new Date(),
    itemCount: items.length,
  });

  // Insert all items into datasetItems
  const datasetItemRecords = items.map((inputValue) => ({
    datasetId,
    input: inputValue,
    createdAt: new Date(),
  }));

  await BatchTestDB.datasetItems.bulkAdd(datasetItemRecords);

  return datasetId;
};

export const getAllDatasets = async () => {
  return BatchTestDB.datasets.toArray();
};

export const getDatasetById = async (datasetId) => {
  return BatchTestDB.datasets.get(Number(datasetId));
};

export const deleteDataset = async (datasetId) => {
  const id = Number(datasetId);
  // Delete dataset
  await BatchTestDB.datasets.delete(id);
  // Delete all dataset items
  await BatchTestDB.datasetItems.where("datasetId").equals(id).delete();
  // If you want to also remove them from ANY pending tests or results, do so:
  await BatchTestDB.pendingReleaseTests.where("datasetId").equals(id).delete();
  await BatchTestDB.releaseResults.where("datasetId").equals(id).delete();
  await BatchTestDB.pendingScenarioTests.where("datasetId").equals(id).delete();
  await BatchTestDB.scenarioResults.where("datasetId").equals(id).delete();
};

/* ------------------------------------------
   DATASET ITEMS
   ------------------------------------------ */
export const getDatasetItems = async (datasetId, offset = 0, limit = 50) => {
  const numDatasetId = Number(datasetId);
  const items = await BatchTestDB.datasetItems
    .where("datasetId")
    .equals(numDatasetId)
    .offset(offset)
    .limit(limit)
    .toArray();

  const totalCount = await BatchTestDB.datasetItems
    .where("datasetId")
    .equals(numDatasetId)
    .count();

  return { items, totalCount };
};

/**
 * Returns only the raw inputs (not the entire row).
 */
export const getDatasetItemsByDatasetId = async (
  datasetId,
  offset = 0,
  limit = 50
) => {
  const numDatasetId = Number(datasetId);
  const items = await BatchTestDB.datasetItems
    .where("datasetId")
    .equals(numDatasetId)
    .offset(offset)
    .limit(limit)
    .toArray();

  return items.map((item) => item.input);
};

/* ------------------------------------------
   RELEASE TESTING
   ------------------------------------------ */
// Add pending release tests
export const addPendingReleaseTests = async (releaseId, datasetId, tests) => {
  const toAdd = tests.map((test) => ({
    releaseId: String(releaseId),
    datasetId: Number(datasetId),
    // If test already has an "input" property, use it; otherwise, wrap test in input.
    input: test && test.input ? test.input : test,
    status: "pending",
    timestamp: new Date(),
  }));
  await BatchTestDB.pendingReleaseTests.bulkAdd(toAdd);
  return toAdd.length;
};

// Retrieve pending release tests
export const getPendingReleaseTests = async (releaseId, offset, limit) => {
  return BatchTestDB.pendingReleaseTests
    .where("releaseId")
    .equals(String(releaseId))
    .and((item) => item.status === "pending")
    .offset(offset)
    .limit(limit)
    .toArray();
};

// Mark a single pending release test as queued or processed
export const markReleaseTestAsQueued = async (testId) => {
  await BatchTestDB.pendingReleaseTests.update(testId, { status: "queued" });
};

export const markReleaseTestAsProcessed = async (testId) => {
  await BatchTestDB.pendingReleaseTests.update(testId, { status: "processed" });
};

// Clear all release test entries for a given release
export const clearPendingReleaseTests = async (releaseId) => {
  return BatchTestDB.pendingReleaseTests
    .where("releaseId")
    .equals(String(releaseId))
    .delete();
};

// Add a release test result
export const addReleaseResultToDB = async (releaseId, datasetId, result) => {
  await BatchTestDB.releaseResults.add({
    releaseId: String(releaseId),
    datasetId: datasetId ? Number(datasetId) : null,
    input: result.input,
    output: result.output,
    status: result.status,
    timestamp: new Date(),
  });
};

// Get all release test results
export const getReleaseResultsFromDB = async (
  releaseId,
  page = 0,
  pageSize = 50
) => {
  const offset = page * pageSize;
  const results = await BatchTestDB.releaseResults
    .where("releaseId")
    .equals(String(releaseId))
    .offset(offset)
    .limit(pageSize)
    .toArray();
  const totalCount = await BatchTestDB.releaseResults
    .where("releaseId")
    .equals(String(releaseId))
    .count();
  return { results, totalCount };
};

// Get ALL release test results without pagination (specifically for analytics)
export const getAllReleaseResultsForAnalytics = async (releaseId) => {
  const results = await BatchTestDB.releaseResults
    .where("releaseId")
    .equals(String(releaseId))
    .toArray();

  return { results, totalCount: results.length };
};

// Clear release test results for a particular release
export const clearReleaseResultsFromDB = async (releaseId) => {
  return BatchTestDB.releaseResults
    .where("releaseId")
    .equals(String(releaseId))
    .delete();
};

// Example: start a batch test for a release by copying dataset items
export const startReleaseTestWithDataset = async (releaseId, datasetId) => {
  const { items } = await getDatasetItems(datasetId, 0, 100000);
  const inputs = items.map((record) => record.input);
  const count = await addPendingReleaseTests(releaseId, datasetId, inputs);
  return count;
};

/* ------------------------------------------
   SCENARIO TESTING
   ------------------------------------------ */
// Add pending scenario tests
export const addPendingScenarioTests = async (scenarioId, datasetId, items) => {
  const sId = String(scenarioId);
  const dId = Number(datasetId);

  const toAdd = items.map((inputValue) => ({
    scenarioId: sId,
    datasetId: dId,
    input: inputValue,
    status: "pending",
    timestamp: new Date(),
  }));

  await BatchTestDB.pendingScenarioTests.bulkAdd(toAdd);
  return toAdd.length;
};

// Retrieve pending scenario tests
export const getPendingScenarioTests = async (scenarioId, offset, limit) => {
  return BatchTestDB.pendingScenarioTests
    .where("scenarioId")
    .equals(String(scenarioId))
    .and((item) => item.status === "pending")
    .offset(offset)
    .limit(limit)
    .toArray();
};

// Mark scenario test as queued or processed
export const markScenarioTestAsQueued = async (testId) => {
  await BatchTestDB.pendingScenarioTests.update(testId, { status: "queued" });
};

export const markScenarioTestAsProcessed = async (testId) => {
  await BatchTestDB.pendingScenarioTests.update(testId, {
    status: "processed",
  });
};

// Clear scenario pending tests
export const clearPendingScenarioTests = async (scenarioId) => {
  return BatchTestDB.pendingScenarioTests
    .where("scenarioId")
    .equals(String(scenarioId))
    .delete();
};

// Add scenario result
export const addScenarioResultToDB = async (scenarioId, datasetId, result) => {
  await BatchTestDB.scenarioResults.add({
    scenarioId: String(scenarioId),
    datasetId: datasetId ? Number(datasetId) : null,
    input: result.input,
    baselineOutput: result.baselineOutput,
    comparisonOutput: result.comparisonOutput,
    timestamp: new Date(),
  });
};

export const getScenarioResultsFromDB = async (scenarioId) => {
  return BatchTestDB.scenarioResults
    .where("scenarioId")
    .equals(String(scenarioId))
    .toArray();
};

export const clearScenarioResultsFromDB = async (scenarioId) => {
  return BatchTestDB.scenarioResults
    .where("scenarioId")
    .equals(String(scenarioId))
    .delete();
};

// Example: start a scenario test by copying dataset items
export const startScenarioTestWithDataset = async (scenarioId, datasetId) => {
  const { items } = await getDatasetItems(datasetId, 0, 100000);
  const inputs = items.map((record) => record.input);
  const count = await addPendingScenarioTests(scenarioId, datasetId, inputs);
  return count;
};

// Helper function to generate JSON paths from an object
const generateJsonPaths = (obj, parentPath = "$") => {
  let paths = [];
  const traverse = (obj, currentPath) => {
    if (Array.isArray(obj)) {
      // Include both array itself and first element access
      paths.push(currentPath);
      paths.push(`${currentPath}[0]`);
      if (obj.length > 0 && typeof obj[0] === "object") {
        traverse(obj[0], `${currentPath}[0]`);
      }
    } else if (obj !== null && typeof obj === "object") {
      paths.push(currentPath);
      Object.keys(obj).forEach((key) => {
        const newPath = `${currentPath}.${key}`;
        traverse(obj[key], newPath);
      });
    } else {
      paths.push(currentPath);
    }
  };
  traverse(obj, parentPath);
  return [...new Set(paths)]; // Remove duplicates
};

export const getJsonPathSuggestions = async (id, type = "release") => {
  try {
    let resultsArray;
    if (type === "scenario") {
      resultsArray = await getScenarioResultsFromDB(id);
    } else {
      // getReleaseResultsFromDB returns an object { results, totalCount }
      const dbRes = await getReleaseResultsFromDB(id);
      resultsArray = dbRes.results;
    }
    if (!resultsArray || resultsArray.length === 0) {
      throw new Error("No results found");
    }
    // Use output for release tests and baselineOutput for scenario tests
    const exampleOutput =
      type === "scenario"
        ? resultsArray[0].baselineOutput
        : resultsArray[0].output;
    if (!exampleOutput) {
      throw new Error("No output data found in the first result");
    }
    return generateJsonPaths(exampleOutput);
  } catch (error) {
    console.error("Error generating JSONPath suggestions:", error);
    return [];
  }
};

/**
 * Get the count of scenario test results
 * @param {string} scenarioId - The scenario ID
 * @returns {Promise<{totalCount: number}>} - Object with totalCount property
 */
export async function getScenarioResultsCount(scenarioId) {
  try {
    const count = await BatchTestDB.scenarioResults
      .where("scenarioId")
      .equals(scenarioId)
      .count();

    return { totalCount: count };
  } catch (error) {
    console.error("Error getting scenario results count:", error);
    return { totalCount: 0 };
  }
}

// Finally, export the Dexie instance
export { BatchTestDB };
export default BatchTestDB;
