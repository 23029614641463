import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MainContext } from "context/contexts";
import { ReactComponent as GripVertical } from "assets/icons/grip-vertical.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as PlusIcon } from "assets/icons/circle-plus.svg";
import CustomSelect from "components/CustomSelect";
import AutoSuggestInput from "components/autosuggest";
import { Button } from "react-bootstrap";

// Import our diff-aware condition and action components:
import ConditionCompared from "./ConditionCompared";
import ActionCompared from "./ActionCompared";

import "./RuleCompared.css";

/** Diff-aware Input Control */
const DiffInput = ({ originalValue, newValue, className, ...props }) => {
  const changed = String(originalValue) !== String(newValue);
  return (
    <input
      {...props}
      value={newValue}
      disabled
      className={`${className || ""} ${changed ? "changed-field" : ""}`}
    />
  );
};

DiffInput.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/** Diff-aware Text Display */
const DiffText = ({ originalValue, newValue, className }) => {
  const changed = String(originalValue) !== String(newValue);
  return (
    <span className={`${className || ""} ${changed ? "changed-field" : ""}`}>
      {newValue}
    </span>
  );
};

DiffText.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string.isRequired,
  className: PropTypes.string,
};

/** Diff-aware Checkbox */
const DiffCheckbox = ({ originalChecked, newChecked, ...props }) => {
  const changed = originalChecked !== newChecked;
  return (
    <input
      {...props}
      type="checkbox"
      checked={newChecked}
      disabled
      className={changed ? "changed-field" : ""}
    />
  );
};

DiffCheckbox.propTypes = {
  originalChecked: PropTypes.bool,
  newChecked: PropTypes.bool.isRequired,
};

/** Diff-aware Select */
const DiffSelect = ({ originalValue, newValue, options, ...props }) => {
  const changed = String(originalValue) !== String(newValue);
  return (
    <select
      {...props}
      value={newValue}
      disabled
      className={changed ? "changed-field" : ""}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

DiffSelect.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

/**
 * RuleCompared renders a rule exactly as in your current view,
 * but every input/control is replaced with a diff-aware version.
 * For each field it compares newRule to originalRule. The condition
 * and action sections are rendered via ConditionCompared and ActionCompared.
 */
const RuleCompared = ({
  originalRule,
  newRule,
  conditionTypesData,
  revisionMode = true,
  importMode = false,
  index = "",
  handleShowConditionDeleteModal,
  handleShowActionDeleteModal,
  handleShowDeleteModal,
  addCondition,
  addAction,
  id,
  ruleSetId,
}) => {
  const { t } = useTranslation();
  const { vectorsData } = useContext(MainContext);

  return (
    <div
      className={`card cursor-auto rule-compared-box-shadow ${
        revisionMode ? "revision-mode" : ""
      }`}
      id={`rule-compared-${newRule.rule_id}`}
    >
      <div className="card-header rule-set-header">
        <h2 className="mb-0">
          <GripVertical className="cursor-move" />
          <Button
            variant=""
            type="button"
            className="ml-2 new-rule-btn"
            title={newRule.title || t("new_rule")}
            data-toggle="collapse"
            data-target={`#rule-compared-content-${newRule.rule_id}${
              importMode ? `-${index}` : ""
            }`}
            aria-controls={`rule-compared-content-${newRule.rule_id}${
              importMode ? `-${index}` : ""
            }`}
          >
            <DiffText
              originalValue={originalRule.title}
              newValue={newRule.title}
            />
          </Button>
        </h2>
        <div className="d-flex justify-content-between">
          <div className="custom-control custom-switch float-right align-middle">
            {/* Diff-aware checkbox for the "active" flag */}
            <DiffCheckbox
              originalChecked={originalRule.active}
              newChecked={newRule.active}
            />
          </div>
          {!revisionMode && (
            <div>
              <button
                type="button"
                className="btn primary-text border-0 p-0 pb-1 pt-1"
                title={t("delete_or_explain_rule")}
                onClick={() => handleShowDeleteModal(null, newRule)}
              >
                <DeleteIcon style={{ cursor: "pointer" }} />
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        id={`rule-compared-content-${newRule.rule_id}${
          importMode ? `-${index}` : ""
        }`}
        className={`collapse ${newRule?.defaultExpanded ? "show" : ""}`}
        data-parent={`#rules-${newRule.rule_id}`}
      >
        <div className="card-body card-border">
          <div className="card-body-content">
            {/* Rule basic fields */}
            <div className="row new-rule m-0">
              <div className="col-12 col-lg-6 pl-0">
                <div className="form-group">
                  <label>{t("rule_title")}</label>
                  <DiffInput
                    originalValue={originalRule.title}
                    newValue={newRule.title}
                    className="form-control border-radius-4"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 pr-0">
                <div className="form-group">
                  <label>{t("identifier")}</label>
                  <DiffInput
                    originalValue={originalRule.name}
                    newValue={newRule.name}
                    className="form-control border-radius-4"
                  />
                </div>
              </div>
            </div>
            {/* Execution type row */}
            <div className="row">
              <div className="col">
                <div className="d-flex">
                  <span className="pt-2 mr-2">
                    <DiffSelect
                      originalValue={originalRule.execution_type}
                      newValue={newRule.execution_type}
                      options={[
                        { value: "standard", label: t("apply_once") },
                        { value: "array", label: t("apply_on_all_in_list") },
                      ]}
                    />
                  </span>
                  {newRule?.execution_type === "array" && (
                    <>
                      <span className="mr-2 pl-1" style={{ width: "500px" }}>
                        <AutoSuggestInput
                          vectorsData={vectorsData}
                          defaultValue={newRule?.attribute_path || ""}
                          inputName={`rule[${newRule.rule_id}][attribute_path]`}
                          revisionMode={true}
                          isRuleArray={true}
                        />
                      </span>
                      <span className="pt-2 mr-2">{t("that")}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Conditions section */}
            <div className="section-divider">
              <label className="font-size-18">{t("conditions")}</label>
            </div>
            <div className="conditions">
              {newRule.condition &&
                newRule.condition.map((condition, idx) => (
                  <ConditionCompared
                    key={condition.rule_condition_id}
                    originalCondition={
                      originalRule.condition ? originalRule.condition[idx] : {}
                    }
                    newCondition={condition}
                    vectorsData={vectorsData}
                    conditionTypesData={conditionTypesData}
                    revisionMode={true}
                  />
                ))}
              {!revisionMode && (
                <div className="ruleset-row">
                  <div className="d-flex">
                    <span className="condition-label">{t("and/or")}</span>
                    <button
                      className="btn outline"
                      type="button"
                      title={t("add_condition")}
                      onClick={() => addCondition({ rule_id: newRule.rule_id })}
                    >
                      <PlusIcon style={{ filter: "brightness(0.5)" }} />
                      <span className="ml-2">{t("add_condition")}</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
            {/* Actions section */}
            <div className="section-divider">
              <label className="font-size-18">{t("actions")}</label>
            </div>
            <div className="actions">
              {newRule.action &&
                newRule.action.map((action, idx) => (
                  <ActionCompared
                    key={action.rule_action_id}
                    originalAction={
                      originalRule.action ? originalRule.action[idx] : {}
                    }
                    newAction={action}
                    vectorsData={vectorsData}
                    revisionMode={true}
                  />
                ))}
            </div>
            {!revisionMode && (
              <div className="my-3 d-flex justify-content-center">
                <button
                  className="btn outline"
                  type="button"
                  title={t("add_action")}
                  onClick={() => addAction(newRule.rule_id)}
                >
                  <PlusIcon style={{ filter: "brightness(0.5)" }} />
                  <span className="ml-2">{t("add_action")}</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

RuleCompared.propTypes = {
  originalRule: PropTypes.object.isRequired,
  newRule: PropTypes.object.isRequired,
  conditionTypesData: PropTypes.array,
  revisionMode: PropTypes.bool,
  importMode: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleShowConditionDeleteModal: PropTypes.func,
  handleShowActionDeleteModal: PropTypes.func,
  handleShowDeleteModal: PropTypes.func,
  addCondition: PropTypes.func,
  addAction: PropTypes.func,
  id: PropTypes.any,
  ruleSetId: PropTypes.any,
};

export default RuleCompared;
