import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import _ from "lodash";

import ReleaseTreeTable from "components/tree-table/ReleaseTreeTable";

const ComparisonModal = ({
  show,
  handleClose,
  originalData,
  resultData,
  title,
}) => {
  const [deltaData, setDeltaData] = useState({});
  const [outputViewMode, setOutputViewMode] = useState("tree"); // Manage view mode in a single state variable
  const [isInputJsonView, setIsInputJsonView] = useState(false);

  const { t } = useTranslation();

  const produceDeltaBetweenTesting = (obj1, obj2) => {
    function changes(newObj, baseObj) {
      const combinedKeys = _.union(_.keys(newObj), _.keys(baseObj)); // Get all keys from both objects
      return _.transform(
        combinedKeys,
        (result, key) => {
          if (!_.has(baseObj, key)) {
            result[key] = newObj[key]; // Key only in newObj (added)
          } else if (_.isObject(newObj[key]) && _.isObject(baseObj[key])) {
            const deeperChanges = changes(newObj[key], baseObj[key]);
            if (!_.isEmpty(deeperChanges)) {
              result[key] = deeperChanges; // Recurse into nested objects
            }
          } else if (!_.isEqual(newObj[key], baseObj[key])) {
            result[key] = newObj[key]; // Changed values
          }
        },
        {}
      );
    }
    return changes(obj2, obj1);
  };

  useEffect(() => {
    const delta = produceDeltaBetweenTesting(originalData, resultData);
    setDeltaData(delta);
  }, [originalData, resultData]);

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        <h3 className="modal-title">{title || "Delta Comparison"}</h3>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div className="row">
          {/* Original Data */}
          <div className="col-md-6">
            <h5>Original Data</h5>

            {(!originalData || Object.keys(originalData || {}).length === 0) &&
            !isInputJsonView ? (
              <div style={{ margin: "auto" }}>
                {t("please_select_and_load_an_object_to_begin")}
              </div>
            ) : isInputJsonView ? (
              <CodeMirror
                key={`output-${JSON.stringify(originalData)}`}
                value={JSON.stringify(originalData, null, 2)}
                extensions={[json(), EditorView.lineWrapping]}
                cursor={{ line: 1, ch: 1 }}
                height="600px"
                readOnly="nocursor"
              />
            ) : (
              originalData && (
                <ReleaseTreeTable
                  type="output"
                  testingData={{ current: originalData }}
                />
              )
            )}
            <div
              className="convert-json btn-group-toggle"
              data-toggle="buttons"
            >
              <label className="btn outline border-radius-left-4 active">
                <input
                  type="radio"
                  value="0"
                  checked={!isInputJsonView}
                  onChange={() => setIsInputJsonView(false)}
                />
                {t("tree")}
              </label>
              <label className="btn outline border-radius-right-4">
                <input
                  type="radio"
                  value="1"
                  checked={isInputJsonView}
                  onChange={() => setIsInputJsonView(true)}
                />
                JSON
              </label>
            </div>
          </div>
          {/* Delta Data */}
          <div className="col-md-6">
            <h5>Result data</h5>

            {resultData ? (
              outputViewMode === "deltaJson" ? (
                <CodeMirror
                  key={`delta-${JSON.stringify(deltaData)}`}
                  value={JSON.stringify(deltaData, null, 2)}
                  extensions={[json(), EditorView.lineWrapping]}
                  cursor={{ line: 1, ch: 1 }}
                  height="600px"
                  readOnly="nocursor"
                />
              ) : outputViewMode === "json" ? (
                <CodeMirror
                  key={`output-${JSON.stringify(resultData)}`}
                  value={JSON.stringify(resultData, null, 2)}
                  extensions={[json(), EditorView.lineWrapping]}
                  cursor={{ line: 1, ch: 1 }}
                  height="600px"
                  readOnly="nocursor"
                />
              ) : outputViewMode === "deltaTree" ? (
                <ReleaseTreeTable
                  testingData={{ current: deltaData }}
                  type="output"
                />
              ) : (
                <ReleaseTreeTable
                  testingData={{ current: resultData }}
                  type="output"
                />
              )
            ) : (
              <div style={{ margin: "auto" }}>{t("nothing")}</div>
            )}

            <div
              className="convert-json btn-group-toggle"
              data-toggle="buttons"
            >
              <label className="btn outline border-radius-left-4 active">
                <input
                  type="radio"
                  value="0"
                  checked={outputViewMode === "tree"}
                  onChange={() => setOutputViewMode("tree")}
                />
                {t("tree")}
              </label>
              <label className="btn outline">
                <input
                  type="radio"
                  value="1"
                  checked={outputViewMode === "deltaTree"}
                  onChange={() => setOutputViewMode("deltaTree")}
                />
                Δ Tree
              </label>
              <label className="btn outline">
                <input
                  type="radio"
                  value="1"
                  checked={outputViewMode === "json"}
                  onChange={() => setOutputViewMode("json")}
                />
                JSON
              </label>

              <label className="btn outline border-radius-right-4">
                <input
                  type="radio"
                  value="1"
                  checked={outputViewMode === "deltaJson"}
                  onChange={() => setOutputViewMode("deltaJson")}
                />
                Δ JSON
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant=""
          onClick={handleClose}
          className="my-0 outline d-inline-block"
        >
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ComparisonModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  originalData: PropTypes.object.isRequired,
  resultData: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default ComparisonModal;
