// src/components/BatchTestProgress.jsx
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BatchTestProgress = () => {
  const { batchTest } = useSelector((state) => state.releases);
  const { t } = useTranslation();

  if (batchTest.total === 0) return null;

  // Ensure completed never exceeds total for display purposes
  const displayCompleted = Math.min(batchTest.completed, batchTest.total);

  // Cap progress percentage at 100%
  const progressPercentage = Math.min(
    ((displayCompleted / batchTest.total) * 100).toFixed(2),
    100
  );

  return (
    <div className="card bg-light mb-4">
      <div className="card-body">
        <h4 className="h6 mb-3">{t("batch_testing_progress")}</h4>
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${progressPercentage}%` }}
            aria-valuenow={displayCompleted}
            aria-valuemin="0"
            aria-valuemax={batchTest.total}
          >
            {progressPercentage}%
          </div>
        </div>
        <p className="mt-2 small">
          {t("completed")} {displayCompleted} / {batchTest.total}
        </p>
      </div>
    </div>
  );
};

export default BatchTestProgress;
