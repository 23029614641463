import React from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { useTranslation } from "react-i18next";
import { types } from "../utils";

/**
 * Diff-aware input field.
 */
const DiffInput = ({ originalValue, newValue, className, ...props }) => {
  const changed = String(originalValue || "") !== String(newValue || "");
  return (
    <input
      {...props}
      value={newValue || ""}
      disabled
      className={`${className || ""} ${changed ? "changed-field" : ""}`}
    />
  );
};

DiffInput.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Diff-aware dropdown (select).
 */
const DiffSelect = ({
  originalValue,
  newValue,
  options,
  className,
  ...props
}) => {
  const changed = String(originalValue || "") !== String(newValue || "");
  return (
    <select
      {...props}
      value={newValue || ""}
      disabled
      className={`${className || ""} ${changed ? "changed-field" : ""}`}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

DiffSelect.propTypes = {
  originalValue: PropTypes.string,
  newValue: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

/**
 * ConditionCompared renders a single condition in diff mode.
 */
const ConditionCompared = ({
  originalCondition = {},
  newCondition,
  vectorsData,
  conditionTypesData,
  revisionMode = true,
}) => {
  const { t } = useTranslation();

  /** Prepare condition type (operator) options */
  const conditionTypeOptions = (conditionTypesData || []).map((ct) => ({
    value: ct.name,
    label: ct.title,
  }));

  /** Lookup condition type labels */
  const originalConditionType = conditionTypeOptions.find(
    (ct) => ct.value === originalCondition.condition_type_name
  ) || { label: "Unknown", value: "" };

  const newConditionType = conditionTypeOptions.find(
    (ct) => ct.value === newCondition.condition_type_name
  ) || { label: "Unknown", value: "" };

  /** Lookup type labels for attribute1 and attribute2, with safe fallbacks */
  const originalAttribute1Type = types.find(
    (type) => type.value == (originalCondition.attribute1_vector || "0")
  ) || { label: "Unknown" };

  const newAttribute1Type = types.find(
    (type) => type.value == (newCondition.attribute1_vector || "0")
  ) || { label: "Unknown" };

  const originalAttribute2Type = types.find(
    (type) => type.value == (originalCondition.attribute2_vector || "0")
  ) || { label: "Unknown" };

  const newAttribute2Type = types.find(
    (type) => type.value == (newCondition.attribute2_vector || "0")
  ) || { label: "Unknown" };

  // If this is a non-group condition, render it
  if (newCondition.condition_type_name !== "group") {
    return (
      <div
        className="condition-compared border-md ruleset-row"
        id={`condition-${newCondition.rule_condition_id}`}
      >
        {/* Attribute 1 (Left Side) */}
        <div className="attribute1 large-item">
          <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
            <div className="input-group-prepend">
              {/* Type Selection Button for Attribute 1 */}
              <button className="btn outline font-size-14 dropdown-toggle text-left border-radius-left-4 bg-white rule-set-btn">
                {newAttribute1Type.label}:
              </button>
            </div>
            {newCondition.attribute1_vector == "1" ? (
              <AutoSuggestInput
                vectorsData={vectorsData}
                defaultValue={newCondition.attribute1_path || ""}
                inputName={`condition[${newCondition.rule_condition_id}][attribute1_path]`}
                revisionMode={true}
              />
            ) : (
              <DiffInput
                originalValue={originalCondition.attribute1_value}
                newValue={newCondition.attribute1_value}
                className="form-control w-100 border-radius-right-4"
              />
            )}
          </div>
        </div>

        {/* Condition Type (Operator) */}
        <div className="condition-type">
          <button className="btn outline font-size-14 text-center bg-white rule-set-btn">
            {originalConditionType.label !== newConditionType.label ? (
              <>
                <span className="diff-old">{originalConditionType.label}</span>
                <span className="diff-new">{newConditionType.label}</span>
              </>
            ) : (
              newConditionType.label
            )}
          </button>
        </div>

        {/* Attribute 2 (Right Side) */}
        <div
          className="attribute2 large-item"
          style={{
            visibility:
              newCondition.condition_type_name === "is_null" ||
              newCondition.condition_type_name === "is_not_null"
                ? "hidden"
                : "visible",
          }}
        >
          <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
            <div className="input-group-prepend">
              {/* Type Selection Button for Attribute 2 */}
              <button className="btn outline font-size-14 dropdown-toggle text-left border-radius-left-4 bg-white rule-set-btn">
                {newAttribute2Type.label}:
              </button>
            </div>
            {newCondition.attribute2_vector == "1" ? (
              <AutoSuggestInput
                vectorsData={vectorsData}
                defaultValue={newCondition.attribute2_path || ""}
                inputName={`condition[${newCondition.rule_condition_id}][attribute2_path]`}
                revisionMode={true}
              />
            ) : (
              <DiffInput
                originalValue={originalCondition.attribute2_value}
                newValue={newCondition.attribute2_value}
                className="form-control w-100 border-radius-right-4"
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    // If it's a group condition, render it recursively
    return (
      <div className="condition-compared-group">
        <div className="ruleset-row group-rule-set-row">
          <div className="d-flex">
            <span className="condition-label">{t("group_condition")}</span>
            <DiffSelect
              originalValue={originalCondition.logical_operator}
              newValue={newCondition.logical_operator}
              options={[
                { value: "all", label: t("all") },
                { value: "any", label: t("any") },
              ]}
            />
          </div>
        </div>
        {(newCondition.children || []).map((child, idx) => (
          <ConditionCompared
            key={child.rule_condition_id}
            originalCondition={
              ((originalCondition || {}).children || [])[idx] || {}
            }
            newCondition={child}
            vectorsData={vectorsData}
            conditionTypesData={conditionTypesData}
            revisionMode={true}
          />
        ))}
      </div>
    );
  }
};

ConditionCompared.propTypes = {
  originalCondition: PropTypes.object,
  newCondition: PropTypes.object.isRequired,
  vectorsData: PropTypes.array,
  conditionTypesData: PropTypes.arrayOf(
    PropTypes.shape({
      rule_condition_type_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  revisionMode: PropTypes.bool,
};

export default ConditionCompared;
