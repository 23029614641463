import { call, put, takeLatest, all, fork, delay } from "redux-saga/effects";
import axios from "api/axios";
import {
  createDeployReleaseFailure,
  createDeployReleaseRequest,
  createDeployReleaseSuccess,
  deleteReleaseFailure,
  deleteReleaseRequest,
  deleteReleaseSuccess,
  getReleaseApiEndPointsFailure,
  getReleaseApiEndPointsRequest,
  getReleaseApiEndPointsSuccess,
  getReleaseFailure,
  getReleaseRequest,
  getReleasesFailure,
  getReleasesRequest,
  getReleasesSuccess,
  getReleaseSuccess,
  getTestReleaseFailure,
  getTestReleaseRequest,
  getTestReleaseSuccess,
  getWorkflowReleasesFailure,
  getWorkflowReleasesRequest,
  getWorkflowReleasesSuccess,
  updateReleaseNotesFailure,
  updateReleaseNotesRequest,
  updateReleaseNotesSuccess,
  downloadBlueprintRequest,
  downloadBlueprintSuccess,
  downloadBlueprintFailure,
  createValidationSetRequest,
  createValidationSetSuccess,
  createValidationSetFailure,
  notifyValidationSetUploadRequest,
  notifyValidationSetUploadSuccess,
  notifyValidationSetUploadFailure,
  getValidationSetsFailure,
  getValidationSetsRequest,
  getValidationSetsSuccess,
  getValidationSetFailure,
  getValidationSetRequest,
  getValidationSetSuccess,
  deleteValidationSetFailure,
  deleteValidationSetRequest,
  deleteValidationSetSuccess,
  startBatchTestRequest,
  startBatchTestSuccess,
  startBatchTestFailure,
  addBatchTestResult,
  loadBatchTestResultsRequest,
  loadBatchTestResultsSuccess,
  loadBatchTestResultsFailure,
  clearBatchTestResultsRequest,
  clearBatchTestResultsSuccess,
  clearBatchTestResultsFailure,
} from "redux/releases/action";
import {
  addReleaseResultToDB,
  getReleaseResultsFromDB,
  clearReleaseResultsFromDB,
  clearPendingReleaseTests,
  getPendingReleaseTests,
  markReleaseTestAsProcessed,
  markReleaseTestAsQueued,
} from "utility/BatchTestDB";
import { toast } from "react-toastify";

function* getReleases({ payload }) {
  try {
    let url = "/releases";
    if (payload) {
      url = `releases?page=${payload}`;
    }
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleasesSuccess(response.data));
    }
  } catch (e) {
    yield put(getReleasesFailure("e.message"));
  }
}

function* getRelease({ payload }) {
  try {
    const url = `releases/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleaseSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getReleaseFailure("e.message"));
  }
}

function* getWorkflowReleases({ payload }) {
  try {
    const url = `workflows/${payload}/releases`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowReleasesSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowReleasesFailure("e.message"));
  }
}

function* deleteRelease({ payload }) {
  try {
    const url = `releases/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteReleaseSuccess());
    }
  } catch (e) {
    yield put(deleteReleaseFailure("e.message"));
  }
}

function* getTestRelease({ payload }) {
  try {
    const url = `releases/${payload.id}/execute?call_ext_data=${payload.callExtData}&record_steps=${payload.recordSteps}&record_lineage=${payload.recordLineage}`;
    const response = yield call(axios.post, url, payload.testingInputData);
    if (response.status === 200) {
      yield put(getTestReleaseSuccess(response.data));
    }
  } catch (e) {
    yield put(getTestReleaseFailure("e.message"));
  }
}

function* getReleaseApiEndPoints({ payload }) {
  try {
    const url = `api-endpoints?workflow_release_id=${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleaseApiEndPointsSuccess(response.data));
    }
  } catch (e) {
    yield put(getReleaseApiEndPointsFailure("e.message"));
  }
}

function* createDeployRelease({ payload }) {
  try {
    const response = yield call(axios.post, "deployments", payload);
    if (response.status === 201) {
      yield put(createDeployReleaseSuccess());
    }
  } catch (e) {
    yield put(createDeployReleaseFailure(e.response.status));
  }
}

function* updateReleaseNotes({ payload }) {
  try {
    const url = `releases/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateReleaseNotesSuccess());
    }
  } catch (e) {
    yield put(updateReleaseNotesFailure("e.message"));
  }
}

function* downloadBlueprint({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `releases/${payload.id}/blueprints`,
      {
        responseType: "blob",
      }
    );
    if (response.status === 200) {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", payload.filename || "blueprint.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      yield put(downloadBlueprintSuccess());
    } else {
      yield put(downloadBlueprintFailure("Failed to download blueprint"));
    }
  } catch (e) {
    yield put(downloadBlueprintFailure(e.message));
  }
}

function* createValidationSet({ payload }) {
  try {
    const response = yield call(axios.post, "/validation-sets", payload);
    if (response.status === 201) {
      yield put(
        createValidationSetSuccess({
          data: response.data,
          eTag: response.headers.etag,
        })
      );
    }
  } catch (e) {
    yield put(createValidationSetFailure(e.message));
  }
}

function* notifyValidationSetUpload({ payload }) {
  try {
    const response = yield call(
      axios.post,
      "/validation-sets/" +
        payload.release_validation_set_id +
        "/notifications",
      payload
    );
    if (response.status === 201) {
      yield put(notifyValidationSetUploadSuccess());
    } else {
      yield put(
        notifyValidationSetUploadFailure(
          "Failed to notify validation set upload"
        )
      );
    }
  } catch (e) {
    yield put(notifyValidationSetUploadFailure(e.message));
  }
}

//get set of validation sets
function* getValidationSets({ payload }) {
  try {
    const response = yield call(axios.get, "/validation-sets");
    if (response.status === 200) {
      yield put(getValidationSetsSuccess(response.data));
    }
  } catch (e) {
    yield put(getValidationSetsFailure(e.message));
  }
}

//get validation set
function* getValidationSet({ payload }) {
  try {
    const response = yield call(
      axios.get,
      "/validation-sets/" + payload.validationSetId
    );
    if (response.status === 200) {
      yield put(getValidationSetSuccess(response.data));
    } else {
      yield put(getValidationSetFailure("Failed to get validation set"));
    }
  } catch (e) {
    yield put(getValidationSetFailure(e.message));
  }
}

//delete validation set
function* deleteValidationSet({ payload }) {
  try {
    const response = yield call(
      axios.delete,
      "/validation-sets/" + payload.validationSetId
    );
    if (response.status === 204) {
      yield put(deleteValidationSetSuccess());
    } else {
      yield put(deleteValidationSetFailure("Failed to delete validation set"));
    }
  } catch (e) {
    yield put(deleteValidationSetFailure(e.message));
  }
}
// Worker Saga: Handle individual test
function* handleIndividualTest(test, releaseId) {
  try {
    yield call(markReleaseTestAsQueued, test.id);
    // Use test.input if available; otherwise, use test as the input.
    const inputData = test.input || test;
    const url = `releases/${releaseId}/execute`;
    const response = yield call(axios.post, url, inputData);
    const status = response.status === 200 ? "success" : "failure";
    const result = {
      input: inputData,
      output: response.data,
      status,
    };
    yield put(addBatchTestResult(result));
    yield call(
      addReleaseResultToDB,
      releaseId,
      result.datasetId || null,
      result
    );
    yield call(markReleaseTestAsProcessed, test.id);
  } catch (error) {
    const inputData = test.input || test;
    const result = {
      input: inputData,
      output: error.message,
      status: "failure",
    };
    yield put(addBatchTestResult(result));
    yield call(
      addReleaseResultToDB,
      releaseId,
      result.datasetId || null,
      result
    );
    yield call(markReleaseTestAsProcessed, test.id);
  }
}

// Saga: Start Batch Test
function* startBatchTestSaga({ payload }) {
  try {
    const totalCount = payload.totalCount;
    const batchSize = 100;
    let processed = 0;
    let hasMoreTests = true;

    while (hasMoreTests) {
      // Get only pending (unqueued) tests
      const pendingTests = yield call(
        getPendingReleaseTests,
        payload.releaseId,
        0,
        batchSize
      );

      if (pendingTests.length === 0) {
        hasMoreTests = false;
        break;
      }

      const concurrency = 5;
      for (let i = 0; i < pendingTests.length; i += concurrency) {
        const batch = pendingTests.slice(i, i + concurrency);
        yield all(
          batch.map((test) =>
            fork(handleIndividualTest, test, payload.releaseId)
          )
        );

        processed += batch.length;
        yield put({
          type: "UPDATE_BATCH_PROGRESS",
          payload: {
            completed: Math.min(processed, totalCount),
            total: totalCount,
          },
        });

        yield delay(100); // Prevent server overload
      }
    }

    // Verify all tests were processed and retry any missing ones
    yield delay(1000); // Allow pending operations to complete
    const { totalCount: savedCount } = yield call(
      getReleaseResultsFromDB,
      payload.releaseId
    );

    if (savedCount < totalCount) {
      console.log(
        `Found discrepancy: ${
          totalCount - savedCount
        } tests missing. Processing remaining tests...`
      );

      // Process any remaining tests - use call instead of fork to ensure completion
      const remainingTests = yield call(
        getPendingReleaseTests,
        payload.releaseId,
        0,
        totalCount - savedCount
      );

      if (remainingTests.length > 0) {
        // Process remaining tests synchronously to ensure completion
        for (let i = 0; i < remainingTests.length; i++) {
          yield call(
            handleIndividualTest,
            remainingTests[i],
            payload.releaseId
          );

          // Update progress
          yield put({
            type: "UPDATE_BATCH_PROGRESS",
            payload: {
              completed: Math.min(savedCount + i + 1, totalCount),
              total: totalCount,
            },
          });
        }
      }

      // Final verification
      const { totalCount: finalCount } = yield call(
        getReleaseResultsFromDB,
        payload.releaseId
      );
      if (finalCount < totalCount) {
        console.warn(
          `After retries, still missing ${totalCount - finalCount} test results`
        );
      }
    }

    yield put(startBatchTestSuccess());
  } catch (error) {
    yield put(startBatchTestFailure(error.message));
  }
}

// Saga: Load Batch Test Results from IndexedDB
function* loadBatchTestResultsSaga({ payload }) {
  const { releaseId } = payload;
  try {
    const response = yield call(getReleaseResultsFromDB, releaseId);
    yield put(
      loadBatchTestResultsSuccess({
        results: response.results,
        totalCount: response.totalCount,
      })
    );
  } catch (error) {
    yield put(loadBatchTestResultsFailure(error.message));
    toast.error("Failed to load batch test results.");
  }
}

// Worker Saga: Handle Clearing Batch Test Results
function* clearBatchTestResultsSaga({ payload }) {
  const { releaseId } = payload;
  try {
    yield call(clearReleaseResultsFromDB, releaseId);
    yield call(clearPendingReleaseTests, releaseId);

    // Dispatch success action
    yield put(clearBatchTestResultsSuccess());
  } catch (error) {
    // Dispatch failure action with error message
    yield put(clearBatchTestResultsFailure(error.message));
  }
}

// Watcher Sagas
function* watchStartBatchTest() {
  yield takeLatest(startBatchTestRequest, startBatchTestSaga);
}

function* watchLoadBatchTestResults() {
  yield takeLatest(loadBatchTestResultsRequest, loadBatchTestResultsSaga);
}

function* watchClearBatchTestResults() {
  yield takeLatest(clearBatchTestResultsRequest, clearBatchTestResultsSaga);
}

export default function* saga() {
  yield takeLatest(getReleasesRequest, getReleases);
  yield takeLatest(getReleaseRequest, getRelease);
  yield takeLatest(getWorkflowReleasesRequest, getWorkflowReleases);
  yield takeLatest(deleteReleaseRequest, deleteRelease);
  yield takeLatest(getTestReleaseRequest, getTestRelease);
  yield takeLatest(getReleaseApiEndPointsRequest, getReleaseApiEndPoints);
  yield takeLatest(createDeployReleaseRequest, createDeployRelease);
  yield takeLatest(updateReleaseNotesRequest, updateReleaseNotes);
  yield takeLatest(downloadBlueprintRequest, downloadBlueprint);
  yield takeLatest(createValidationSetRequest, createValidationSet);
  yield takeLatest(notifyValidationSetUploadRequest, notifyValidationSetUpload);
  yield takeLatest(getValidationSetsRequest, getValidationSets);
  yield takeLatest(getValidationSetRequest, getValidationSet);
  yield takeLatest(deleteValidationSetRequest, deleteValidationSet);
  // Batch Testing Watchers
  yield all([
    fork(watchStartBatchTest),
    fork(watchLoadBatchTestResults),
    fork(watchClearBatchTestResults),
  ]);
}
