import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StepMainContent = ({ title, name, type }) => {
  const { revisionId } = useParams();
  const { t } = useTranslation();

  const stepType = ["external_data_call", "fork"].includes(type);

  return (
    <div className="d-flex mt-3">
      <div
        className={`${
          stepType ? "col-4 mr-1" : "col"
        } d-flex flex-column form-group`}
      >
        <label>{t("table_title")}</label>
        <input
          disabled={!!revisionId}
          type="text"
          defaultValue={title}
          name="title"
          className={`workflow-modal-input ${stepType ? "w-400" : ""}`}
          required
        />
      </div>
      <div
        className={`${
          stepType ? "col-4 mr-1" : "col"
        } d-flex flex-column form-group`}
      >
        <label>{t("identifier")}</label>
        <input
          disabled={!!revisionId}
          type="text"
          defaultValue={name}
          name="name"
          className={`workflow-modal-input ${stepType ? "w-400" : ""}`}
          required
          pattern="[a-zA-Z0-9_\-]+"
          title={t("only_letters_numbers_underscore_hyphen")}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9_-]/g, "");
          }}
        />
      </div>
    </div>
  );
};

StepMainContent.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default StepMainContent;
