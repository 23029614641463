import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ruleSetTypes, types } from "../utils";
import { isJson } from "utility/utility";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../CustomSelect";

import { ReactComponent as JSONIcon } from "assets/icons/json.svg";
import { ReactComponent as VectorsIcon } from "assets/icons/vectors.svg";
import { ReactComponent as AbcIcon } from "assets/icons/abc.svg";

const Condition = ({
  ruleId,
  condition,
  vectorsData,
  conditionTypesData,
  groupConditionLabel,
  deleteModal,
  revisionMode = false,
  addCondition,
  index,
  rule,
  meetLabel,
  childLength,
}) => {
  const { t } = useTranslation();
  const [attribute1, setAttribute1] = useState(
    condition?.attribute1_vector?.toString() || "1"
  );
  const [attribute2, setAttribute2] = useState(
    condition?.attribute2_vector?.toString() || "0"
  );
  const [conditionValue1, setConditionValue1] = useState(
    condition?.attribute1_value || ""
  );
  const [conditionValue2, setConditionValue2] = useState(
    condition?.attribute2_value || ""
  );
  const [conditionType, setConditionType] = useState(
    condition?.condition_type_name || ""
  );
  const [isInvalid1, setIsInvalid1] = useState("");
  const [isInvalid2, setIsInvalid2] = useState("");
  const [conditionLabel, setConditionLabel] = useState(
    groupConditionLabel || "all"
  );

  useEffect(() => {
    setConditionLabel(groupConditionLabel);
  }, []);

  const changeAttribute = (
    value,
    setAttribute,
    conditionValue,
    setIsInvalid
  ) => {
    if (value === "2" && !isJson(conditionValue) && conditionValue) {
      setIsInvalid("is-invalid");
    } else {
      setIsInvalid("");
    }

    setAttribute(value);
  };

  const handleChangeInputValue = (
    e,
    attribute,
    setConditionValue,
    setIsInvalid
  ) => {
    const val = e.target.value;
    setConditionValue(val);
    if (attribute === "2") {
      if (isJson(val) || val === "false" || !val) {
        setIsInvalid("");
      } else {
        setIsInvalid("is-invalid");
      }
    }
  };

  const handleChangeCond = (e) => {
    setConditionLabel(e.label);
  };

  return (
    <>
      {condition.condition_type_name !== "group" ? (
        <div
          className="border-md ruleset-row"
          id={`condition-${condition.rule_condition_id}`}
        >
          <input
            type="hidden"
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][parent_condition_id]`}
            defaultValue={condition.parent_condition_id}
          />
          <div className="attribute1 large-item">
            <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
              <div className="input-group-prepend">
                <label className="condition-label">
                  {index !== 0 ? (
                    condition.parent_condition_id ? (
                      <span>
                        {groupConditionLabel === "any" ? t("or") : t("and")}
                      </span>
                    ) : (
                      <span>{meetLabel === "any" ? t("or") : t("and")}</span>
                    )
                  ) : null}
                </label>
                <input
                  type="hidden"
                  name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute1_vector]`}
                  value={types.find((type) => type.value === attribute1).value}
                />
                <button
                  disabled={revisionMode ? "disabled" : ""}
                  className="btn outline font-size-14 dropdown-toggle text-left border-radius-left-4 bg-white rule-set-btn border-radius-none-right-top-bottom"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="icon">
                    {(() => {
                      const typeLabel = types.find(
                        (type) => type.value === attribute1
                      ).label;
                      switch (typeLabel) {
                        case "JSON":
                          return <JSONIcon />;
                        case "Attribute":
                          return (
                            <VectorsIcon
                              style={{
                                width: "16px",
                                height: "16px",
                              }}
                            />
                          );
                        case "Value":
                          return <AbcIcon />;
                        default:
                          return null;
                      }
                    })()}
                  </span>
                  <span className="text">
                    {types.find((type) => type.value === attribute1).label}
                  </span>
                </button>
                <div className="dropdown-menu ml-3">
                  {types.map((type) => {
                    return (
                      <button
                        key={type.value}
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          changeAttribute(
                            type.value,
                            setAttribute1,
                            conditionValue1,
                            setIsInvalid1
                          )
                        }
                      >
                        {type.label}
                      </button>
                    );
                  })}
                </div>
              </div>
              {attribute1 === "1" ? (
                <AutoSuggestInput
                  vectorsData={vectorsData}
                  defaultValue={condition.attribute1_path || ""}
                  inputName={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute1_path]`}
                  revisionMode={revisionMode}
                  isRuleSet={true}
                />
              ) : (
                <input
                  type="text"
                  onChange={(e) =>
                    handleChangeInputValue(
                      e,
                      attribute1,
                      setConditionValue1,
                      setIsInvalid1
                    )
                  }
                  value={conditionValue1}
                  className={`form-control w-100 border-radius-right-4 ${isInvalid1}`}
                  name={`rule[${ruleId}][condition][${
                    condition.rule_condition_id
                  }][attribute1_${attribute1 === 1 ? "path" : "value"}]`}
                  disabled={revisionMode ? "disabled" : ""}
                />
              )}
            </div>
          </div>
          <select
            className="form-control outline font-size-14 condition-operation math-symbols text-center mx-2"
            id={`condition-operator-${condition.rule_condition_id}`}
            value={conditionType}
            onChange={(e) => setConditionType(e.target.value)}
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][operator]`}
            disabled={revisionMode ? "disabled" : ""}
          >
            {conditionTypesData &&
              conditionTypesData.length > 0 &&
              conditionTypesData.map((conditionType) => {
                return (
                  <option
                    key={conditionType.rule_condition_type_id}
                    value={conditionType.name}
                  >
                    {conditionType.title}
                  </option>
                );
              })}
          </select>

          <div
            className="attribute2 large-item"
            style={{
              visibility:
                conditionType === "is_null" || conditionType === "is_not_null"
                  ? "hidden"
                  : "visible",
            }}
          >
            <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
              <div className="input-group-prepend">
                <input
                  type="hidden"
                  name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute2_vector]`}
                  value={types.find((type) => type.value === attribute2).value}
                />
                <button
                  disabled={revisionMode ? "disabled" : ""}
                  className="btn outline font-size-14 dropdown-toggle text-left border-radius-left-4 bg-white rule-set-btn border-radius-none-right-top-bottom"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="icon">
                    {(() => {
                      const typeLabel = types.find(
                        (type) => type.value === attribute2
                      ).label;
                      switch (typeLabel) {
                        case "JSON":
                          return <JSONIcon />;
                        case "Attribute":
                          return (
                            <VectorsIcon
                              style={{
                                width: "16px",
                                height: "16px",
                              }}
                            />
                          );
                        case "Value":
                          return <AbcIcon />;
                        default:
                          return null;
                      }
                    })()}
                  </span>
                  <span className="text">
                    {types.find((type) => type.value === attribute2).label}:
                  </span>
                </button>
                <div className="dropdown-menu">
                  {types.map((type) => {
                    return (
                      <button
                        key={type.value}
                        className="dropdown-item"
                        type="button"
                        onClick={() =>
                          changeAttribute(
                            type.value,
                            setAttribute2,
                            conditionValue2,
                            setIsInvalid2
                          )
                        }
                      >
                        {type.label}
                      </button>
                    );
                  })}
                </div>
              </div>
              {attribute2 === "1" ? (
                <AutoSuggestInput
                  vectorsData={vectorsData}
                  defaultValue={condition.attribute2_path || ""}
                  inputName={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute2_path]`}
                  revisionMode={revisionMode}
                  isRuleSet={true}
                />
              ) : (
                <input
                  type="text"
                  onChange={(e) =>
                    handleChangeInputValue(
                      e,
                      attribute2,
                      setConditionValue2,
                      setIsInvalid2
                    )
                  }
                  value={conditionValue2}
                  className={`form-control w-100 border-radius-right-4 ${isInvalid2}`}
                  name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute2_value]`}
                  disabled={revisionMode ? "disabled" : ""}
                />
              )}
            </div>
          </div>
          {!revisionMode ? (
            !condition.parent_condition_id || index || childLength > 1 ? (
              <button
                className="btn outline font-size-16 ml-1"
                type="button"
                onClick={() => deleteModal(ruleId, condition.rule_condition_id)}
              >
                <DeleteIcon />
              </button>
            ) : null
          ) : null}
          <p style={{ width: "78px" }} />
        </div>
      ) : null}
      {condition.condition_type_name === "group" ? (
        <>
          <input
            type="hidden"
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute1_vector]`}
            defaultValue={"1"}
            value={types.find((type) => type.value === attribute1).value}
          />
          <input
            type="hidden"
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute1_path]`}
            defaultValue={""}
          />
          <input
            type="hidden"
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][operator]`}
            defaultValue={"group"}
          />
          <input
            type="hidden"
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute2_vector]`}
            defaultValue={"1"}
          />
          <input
            type="hidden"
            name={`rule[${ruleId}][condition][${condition.rule_condition_id}][attribute2_path]`}
            defaultValue={""}
          />
          <div className="condition-container condition-container-group">
            <div className="ruleset-row group-rule-set-row">
              <div className="d-flex">
                <span className="condition-label">
                  {meetLabel === "any" ? t("or") : t("and")}
                </span>
                <CustomSelect
                  name={`rule[${ruleId}][condition][${condition.rule_condition_id}][logical_operator]`}
                  defaultValue={ruleSetTypes.find(
                    (type) => conditionLabel === type.label
                  )}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  selectStyle={{
                    dropdownIndicator: (styles) => ({
                      ...styles,
                      marginRight: "0",
                    }),
                  }}
                  handleChange={handleChangeCond}
                  isDisabled={revisionMode ? "disabled" : ""}
                  options={ruleSetTypes}
                  getOptionLabel={(option) => t(option.label)}
                  key={ruleSetTypes.length}
                  isSearchable={false}
                  selectWidth="w-95"
                />
                <span className="pt-2 ml-3">{t("following_conditions")}</span>
              </div>
              {!revisionMode && (
                <button
                  className="btn outline cond-group-btn"
                  type="button"
                  onClick={() =>
                    deleteModal(ruleId, condition.rule_condition_id)
                  }
                >
                  <DeleteIcon />
                </button>
              )}
            </div>
            <div className="condition-group">
              {condition.children?.map((child, index) => {
                return (
                  <Condition
                    childLength={condition.children.length}
                    rule={rule}
                    groupConditionLabel={conditionLabel}
                    index={index}
                    addCondition={addCondition}
                    key={child.rule_condition_id}
                    ruleId={ruleId}
                    condition={child}
                    vectorsData={vectorsData}
                    conditionTypesData={conditionTypesData}
                    deleteModal={deleteModal}
                    revisionMode={revisionMode}
                  />
                );
              })}
              <div className="ruleset-row">
                {!revisionMode && (
                  <div className="d-flex mb-2 align-items-center">
                    <div className="condition-label-group">
                      {conditionLabel === "any" ? t("or") : t("and")}
                    </div>
                    <button
                      className="btn outline"
                      type="button"
                      onClick={() =>
                        addCondition({
                          rule_id: ruleId,
                          type: "group",
                          condition,
                        })
                      }
                    >
                      <PlusIcon
                        style={{
                          filter: "brightness(0.5)",
                        }}
                      />
                      <span className="ml-2">{t("add_condition")}</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

Condition.propTypes = {
  condition: PropTypes.object,
  ruleId: PropTypes.any,
  conditionTypesData: PropTypes.any,
  vectorsData: PropTypes.array,
  deleteModal: PropTypes.func,
  addCondition: PropTypes.func,
  groupConditionLabel: PropTypes.any,
  revisionMode: PropTypes.bool,
  index: PropTypes.number,
  rule: PropTypes.any,
  meetLabel: PropTypes.string,
  childLength: PropTypes.number,
};

export default Condition;
