import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import Autosuggest from "react-autosuggest";

import "assets/css/autosuggest/theme.scss";
import { Tab } from "react-bootstrap";

const LimitedAutoSuggestInput = ({
  suggestionsData,
  defaultValue = "",
  inputName = "autosuggest",
  onSelect,
  onInputChange, // Add this prop
  placeholder = "Type to search...",
  debounceTime = 300,
  maxSuggestions = 10,
  className = "",
  disabled = false,
  tableMode = false,
  revisionMode = false,
  isLast = false,
  isFunc = false,
}) => {
  const [value, setValue] = useState(defaultValue);

  // Add effect to sync with external value changes
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const [suggestions, setSuggestions] = useState([]);

  const getSuggestions = (inputValue) => {
    const inputValueLower = inputValue.trim().toLowerCase();
    return suggestionsData
      .filter((suggestion) =>
        suggestion.toLowerCase().includes(inputValueLower)
      )
      .slice(0, maxSuggestions);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (onInputChange) {
      onInputChange(newValue);
    }
  };

  const getInputClassName = () => {
    const baseClass = "form-control";
    const classes = [baseClass];

    if (tableMode) {
      classes.push("table-mode");
    }

    if (revisionMode) {
      classes.push("revision-mode");
    }

    if (disabled) {
      classes.push("disabled");
    }

    return classes.join(" ");
  };

  const inputProps = {
    name: inputName,
    placeholder,
    value,
    onChange,
    disabled: disabled || revisionMode,
    className: getInputClassName(),
    style: {
      fontSize: "1rem",
      backgroundColor: isLast ? "#F8F9FA" : "transparent",
      borderTop: isFunc ? "none" : undefined,
      border: tableMode ? "none" : undefined,
      color: "#0f3356",
      borderRadius: "4px",
      paddingLeft: "0.1rem",
    },
  };

  return (
    <div className={`autosuggest-container ${className}`}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        theme={{
          container: "react-autosuggest__container",
          input: "react-autosuggest__input",
          suggestionsContainer: "react-autosuggest__suggestions-container",
          suggestionsContainerOpen:
            "react-autosuggest__suggestions-container--open",
          suggestionsList: "react-autosuggest__suggestions-list",
          suggestion: "react-autosuggest__suggestion",
          suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
        }}
      />
    </div>
  );
};

LimitedAutoSuggestInput.propTypes = {
  suggestionsData: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.string,
  inputName: PropTypes.string,
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  debounceTime: PropTypes.number,
  maxSuggestions: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  tableMode: PropTypes.bool,
  revisionMode: PropTypes.bool,
  isLast: PropTypes.bool,
  isFunc: PropTypes.bool,
};

export default React.memo(LimitedAutoSuggestInput);
