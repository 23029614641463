import { createAction } from "redux-actions";

export const getFunctionsRequest = createAction("GET_FUNCTIONS_REQUEST");
export const getFunctionsSuccess = createAction("GET_FUNCTIONS_SUCCESS");
export const getFunctionsFailure = createAction("GET_FUNCTIONS_FAILURE");

export const getFunctionRequest = createAction("GET_FUNCTION_REQUEST");
export const getFunctionSuccess = createAction("GET_FUNCTION_SUCCESS");
export const getFunctionFailure = createAction("GET_FUNCTION_FAILURE");

export const getFunctionRevisionsRequest = createAction(
  "GET_FUNCTION_REVISIONS_REQUEST"
);
export const getFunctionRevisionsSuccess = createAction(
  "GET_FUNCTION_REVISIONS_SUCCESS"
);
export const getFunctionRevisionsFailure = createAction(
  "GET_FUNCTION_REVISIONS_FAILURE"
);

export const getFunctionRevisionRequest = createAction(
  "GET_FUNCTION_REVISION_REQUEST"
);
export const getFunctionRevisionSuccess = createAction(
  "GET_FUNCTION_REVISION_SUCCESS"
);
export const getFunctionRevisionFailure = createAction(
  "GET_FUNCTION_REVISION_FAILURE"
);

export const getFunctionRevision2Request = createAction(
  "GET_FUNCTION_REVISION2_REQUEST"
);
export const getFunctionRevision2Success = createAction(
  "GET_FUNCTION_REVISION2_SUCCESS"
);
export const getFunctionRevision2Failure = createAction(
  "GET_FUNCTION_REVISION2_FAILURE"
);

export const getFunctionTestRequest = createAction("GET_FUNCTION_TEST_REQUEST");
export const getFunctionTestSuccess = createAction("GET_FUNCTION_TEST_SUCCESS");
export const getFunctionTestFailure = createAction("GET_FUNCTION_TEST_FAILURE");

export const getRunTestResultDetailsFunctionRequest = createAction(
  "GET_RUN_TEST_RESULT_DETAILS_FUNCTION_REQUEST"
);
export const getRunTestResultDetailsFunctionSuccess = createAction(
  "GET_RUN_TEST_RESULT_DETAILS_FUNCTION_SUCCESS"
);
export const getRunTestResultDetailsFunctionFailure = createAction(
  "GET_RUN_TEST_RESULT_DETAILS_FUNCTION_FAILURE"
);

export const getRunTestResultStatusFunctionRequest = createAction(
  "GET_RUN_TEST_RESULT_STATUS_FUNCTION_REQUEST"
);
export const getRunTestResultStatusFunctionSuccess = createAction(
  "GET_RUN_TEST_RESULT_STATUS_FUNCTION_SUCCESS"
);
export const getRunTestResultStatusFunctionFailure = createAction(
  "GET_RUN_TEST_RESULT_STATUS_FUNCTION_FAILURE"
);

export const updateFunctionRequest = createAction("UPDATE_FUNCTION_REQUEST");
export const updateFunctionSuccess = createAction("UPDATE_FUNCTION_SUCCESS");
export const updateFunctionFailure = createAction("UPDATE_FUNCTION_FAILURE");

export const createFunctionRequest = createAction("CREATE_FUNCTION_REQUEST");
export const createFunctionSuccess = createAction("CREATE_FUNCTION_SUCCESS");
export const createFunctionFailure = createAction("CREATE_FUNCTION_FAILURE");

export const deleteFunctionRequest = createAction("DELETE_FUNCTION_REQUEST");
export const deleteFunctionSuccess = createAction("DELETE_FUNCTION_SUCCESS");
export const deleteFunctionFailure = createAction("DELETE_FUNCTION_FAILURE");
export const getFunctionExplanationRequest = createAction(
  "GET_FUNCTION_EXPLANATION_REQUEST"
);

export const getFunctionExplanationSuccess = createAction(
  "GET_FUNCTION_EXPLANATION_SUCCESS"
);

export const getFunctionExplanationFailure = createAction(
  "GET_FUNCTION_EXPLANATION_FAILURE"
);
//workflows
export const getFunctionWorkflowsRequest = createAction(
  "GET_FUNCTION_WORKFLOWS_REQUEST"
);
export const getFunctionWorkflowsSuccess = createAction(
  "GET_FUNCTION_WORKFLOWS_SUCCESS"
);
export const getFunctionWorkflowsFailure = createAction(
  "GET_FUNCTION_WORKFLOWS_FAILURE"
);
