import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import ConstraintManager from "./ConstraintManager"; // reuse the previously created component
import { useTranslation } from "react-i18next";

const VariableFormModal = ({
  open,
  handleClose,
  handleConfirm,
  initialData = {},
}) => {
  // Form fields
  const [name, setName] = useState("");
  const [mapping, setMapping] = useState("");
  const [dataType, setDataType] = useState("STRING");
  const [dummyValue, setDummyValue] = useState("");
  const [constraints, setConstraints] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      setName(initialData.name || "");
      setMapping(initialData.mapping || "");
      setDataType(initialData.dataType || "STRING");
      setDummyValue(initialData.dummy_value || "");
      setConstraints(initialData.constraints || []);
    } else {
      // Reset form if no data is provided.
      setName("");
      setMapping("");
      setDataType("STRING");
      setDummyValue("");
      setConstraints([]);
    }
  }, [initialData, open]);

  const onSubmit = (e) => {
    e.preventDefault();
    // Prepare the updated variable object.
    const formData = {
      name,
      mapping,
      dataType,
      dummy_value: dummyValue,
      constraints, // this is an array of constraint objects
    };
    handleConfirm(formData);
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title">Edit</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <form onSubmit={onSubmit}>
        <Modal.Body>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>{t("attribute_mapping")}</label>
            <input
              type="text"
              className="form-control"
              value={mapping}
              onChange={(e) => setMapping(e.target.value)}
              placeholder="$.path.to.value"
              required
            />
          </div>
          <div className="form-group">
            <label>{t("data_type")}</label>
            <select
              className="form-control"
              value={dataType}
              onChange={(e) => setDataType(e.target.value)}
              required
            >
              <option value="STRING">String</option>
              <option value="INTEGER">Integer</option>
              <option value="FLOAT">Float</option>
              <option value="BOOLEAN">Boolean</option>
              <option value="DATE">Date</option>
            </select>
          </div>
          <div className="form-group">
            <label>{t("dummy_value")}</label>
            <input
              type="text"
              className="form-control"
              value={dummyValue}
              onChange={(e) => setDummyValue(e.target.value)}
            />
          </div>
          <div className="form-group">
            <ConstraintManager
              constraints={constraints}
              onChange={setConstraints}
              t={t}
              dataType={dataType}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="" onClick={handleClose} className="outline my-0">
            {t("cancel")}
          </Button>
          <Button variant="" type="submit" className="primary my-0 w-70">
            {t("save")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

VariableFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  initialData: PropTypes.object,
};

export default VariableFormModal;
