import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spinner, Alert, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import {
  getReleaseResultsFromDB,
  getJsonPathSuggestions,
} from "utility/BatchTestDB";
import {
  getAnalysisRecipesRequest,
  getAnalysisRecipeRequest,
} from "redux/analysisRecipes/actions";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import AnalysisRecipeForm from "./AnalysisRecipeForm";
import "./AnalyticsModal.css";
import { useTranslation } from "react-i18next";
import usePrevious from "utility/hooks/usePrevious";
// Import utility functions
import {
  extractJSONPathValues,
  aggregateValues,
  groupDataByDimension,
  generateChartOptions,
} from "utility/AnalyticsUtils";

const AnalyticsModal = ({
  show,
  handleClose,
  releaseId,
  decisionFlowTitle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Form Data
  const [formData, setFormData] = useState({ title: "", name: "", slices: [] });
  const [chartOptions, setChartOptions] = useState([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState(null);
  const [jsonPathSuggestions, setJsonPathSuggestions] = useState([]);

  // Recipe Selection
  const [showRecipeSection, setShowRecipeSection] = useState(false);
  const [selectedRecipeId, setSelectedRecipeId] = useState("");

  // Fetch Recipes from Redux Store
  const { analysisRecipes, analysisRecipe, isGetAnalysisRecipeSuccess } =
    useSelector((state) => state.analysisRecipes);

  const prevIsGetAnalysisRecipeSuccess = usePrevious(
    isGetAnalysisRecipeSuccess
  );

  useEffect(() => {
    if (show) {
      dispatch(getAnalysisRecipesRequest()); // Load available recipes
      if (releaseId) {
        getJsonPathSuggestions(releaseId, "release").then(
          setJsonPathSuggestions
        );
      }
    }
  }, [show, releaseId, dispatch]);

  // When a recipe is selected, fetch its details
  useEffect(() => {
    if (selectedRecipeId) {
      dispatch(getAnalysisRecipeRequest(selectedRecipeId));
    }
  }, [selectedRecipeId, dispatch]);

  // When the recipe is loaded, update the form
  useEffect(() => {
    if (
      analysisRecipe &&
      analysisRecipe.release_analysis_recipe_id == selectedRecipeId &&
      isGetAnalysisRecipeSuccess &&
      prevIsGetAnalysisRecipeSuccess === false
    ) {
      // Ensure slices have all the required properties
      const formattedSlices = (analysisRecipe.slices || []).map((slice) => ({
        release_recipe_slice_id: slice.release_recipe_slice_id,
        name: slice.name || "",
        dimension: slice.dimension || "",
        metric: slice.metric || "",
        type: slice.type || "number",
        aggregation: slice.aggregation || "sum",
        sequence: slice.sequence || 0,
      }));

      setFormData({
        title: analysisRecipe.title || "",
        name: analysisRecipe.name || "",
        slices: formattedSlices,
      });
    }
  }, [
    isGetAnalysisRecipeSuccess,
    analysisRecipe,
    selectedRecipeId,
    prevIsGetAnalysisRecipeSuccess,
  ]);

  const handleRecipeSelect = (e) => {
    setSelectedRecipeId(e.target.value);
  };

  const handleAnalyze = async () => {
    setIsAnalyzing(true);
    setChartOptions([]);
    setError(null);

    try {
      if (formData.slices.length === 0) {
        toast.warn(t("please_add_at_least_one_slice"), ToastOptions);
        setIsAnalyzing(false);
        return;
      }

      // Validate input
      for (const slice of formData.slices) {
        if (!slice.metric) {
          toast.warn(t("please_add_a_metric_json_path"), ToastOptions);
          setIsAnalyzing(false);
          return;
        }

        // Skip validation against jsonPathSuggestions if this came from a recipe
        if (!selectedRecipeId && !jsonPathSuggestions.includes(slice.metric)) {
          toast.warn(t("please_add_at_least_one_metric"), ToastOptions);
          setIsAnalyzing(false);
          return;
        }

        // Only validate dimensions if they exist but are not from a loaded recipe
        if (
          slice.dimension &&
          !selectedRecipeId &&
          !jsonPathSuggestions.includes(slice.dimension)
        ) {
          toast.warn(t("please_add_a_valid_dimension_json_path"), ToastOptions);
          setIsAnalyzing(false);
          return;
        }
      }

      // First get the total count
      const { totalCount } = await getReleaseResultsFromDB(releaseId, 0, 1);

      // Then fetch all results using the total count as the page size
      const { results } = await getReleaseResultsFromDB(
        releaseId,
        0,
        totalCount
      );

      if (!results || results.length === 0) {
        toast.error(t("no_batch_test_results_found"), ToastOptions);
        setIsAnalyzing(false);
        return;
      }

      const aggregatedCharts = [];

      // Process each slice
      for (const slice of formData.slices) {
        const { metric: path, dimension, aggregation, name } = slice;

        // Use utility functions for data extraction
        const extractedData = extractJSONPathValues(
          results.map((result) => result.output),
          path
        );

        if (extractedData.length === 0) {
          toast.warn(
            t("no_data_found_for_jsonpath", { jsonPath: path }),
            ToastOptions
          );
          continue;
        }

        // Generate chart options using utility function
        const option = generateChartOptions(
          { metricPath: path, dimensionPath: dimension, aggregation },
          results.map((result) => result.output),
          `${formData.title || t("analytics")} - ${capitalize(
            aggregation
          )}: ${name}`,
          "#003057" // Primary blue
        );

        if (option) {
          // Add any additional enhancements specific to AnalyticsModal
          if (aggregation === "decile") {
            // Add area style and mark points for decile charts
            option.series.forEach((series) => {
              series.areaStyle = { opacity: 0.1 };
              series.markPoint = {
                data: [
                  { type: "max", name: "Maximum" },
                  { type: "min", name: "Minimum" },
                ],
              };
            });
          }

          // Add any additional color palette settings
          option.color = option.color || [
            "#003057", // Primary blue
            "#005C42", // Secondary green
            "#455A64", // Dark gray
            "#8FD4F2", // Light blue
            "#014A80", // Secondary blue
            "#0F3356", // Navy blue
            "#6C757D", // Gray
            "#F8F9FA", // Off-white
            "#DEE2E6", // Light gray
          ];

          aggregatedCharts.push(option);
        }
      }

      if (aggregatedCharts.length === 0) {
        toast.warn(t("no_valid_aggregations_performed"), ToastOptions);
      } else {
        setChartOptions(aggregatedCharts);
        toast.success(t("analysis_completed_successfully"), ToastOptions);
      }
    } catch (err) {
      console.error("Analysis error:", err);
      toast.error(t("error_occured_during_analysis"), ToastOptions);
    } finally {
      setIsAnalyzing(false);
    }
  };

  // Simple utility function (kept because it's small and specific to this component)
  const capitalize = (str) => {
    if (typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        <h5 className="modal-title">{t("analyse_results")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h6>{t("analysis_configuration")}</h6>
          <Button
            variant="link"
            className="text-decoration-none p-0"
            onClick={() => setShowRecipeSection(!showRecipeSection)}
          >
            <small>
              {showRecipeSection
                ? t("hide_recipe_selection")
                : t("from_recipe")}
            </small>
          </Button>
        </div>

        {/* Load Recipe Section - conditionally rendered */}
        {showRecipeSection && (
          <div className="mb-3 recipe-selection-container p-3 border rounded bg-light">
            <Form.Label>{t("load_analytics_recipe")}</Form.Label>
            <Form.Control
              as="select"
              value={selectedRecipeId}
              onChange={handleRecipeSelect}
            >
              <option value="">{t("select_analytics_recipe")}</option>
              {analysisRecipes.map((recipe) => (
                <option
                  key={recipe.release_analysis_recipe_id}
                  value={recipe.release_analysis_recipe_id}
                >
                  {recipe.title}
                </option>
              ))}
            </Form.Control>
          </div>
        )}

        <AnalysisRecipeForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleAnalyze}
          vectorsData={jsonPathSuggestions}
          decisionFlowTitle={decisionFlowTitle}
        />

        <div className="d-flex justify-content-center my-3">
          <Button
            variant=""
            type="submit"
            className="btn primary deploy-btn w-auto"
            onClick={handleAnalyze}
            disabled={isAnalyzing || formData.slices.length === 0}
          >
            {isAnalyzing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                {t("analyzing")}
              </>
            ) : (
              t("analyze")
            )}
          </Button>
        </div>

        {error && <Alert variant="danger">{error}</Alert>}

        <div className="analytics-results">
          {chartOptions.length > 0
            ? chartOptions.map((option, idx) => (
                <ReactECharts
                  key={idx}
                  option={option}
                  style={{ height: "400px", width: "100%", marginTop: "20px" }}
                />
              ))
            : !isAnalyzing && <p>{t("no_results")}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" className="outline deploy-btn" onClick={handleClose}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AnalyticsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  releaseId: PropTypes.string.isRequired,
  decisionFlowTitle: PropTypes.string,
};

export default AnalyticsModal;
