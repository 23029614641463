import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { useCtrlSHandler } from "utility/hooks/useCtrlSHandler";

const ResponseModal = ({
  show,
  onClose,
  onSave,
  response = null,
  isTestResponse = false,
  testResponseData = null,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    response_name: "",
    json_response: "",
    attributes: [],
  });
  const [isJsonValid, setIsJsonValid] = useState(true);

  // Add Ctrl+S handler for saving
  useCtrlSHandler(() => handleSubmit(), show);

  useEffect(() => {
    if (response) {
      const jsonResponse = response.json_response || "";
      setFormData({
        response_name: response.response_name || "",
        json_response: jsonResponse,
        attributes: response.response_attributes || [],
      });

      // Validate initial JSON
      validateJson(jsonResponse);
    } else if (isTestResponse && testResponseData) {
      // Handle test response data
      const jsonResponse = JSON.stringify(testResponseData, null, 2);
      setFormData({
        response_name: `Response ${new Date().toLocaleDateString()}`,
        json_response: jsonResponse,
        attributes: [],
      });
      setIsJsonValid(true); // Should be valid since we're stringifying an object
    }
  }, [response, isTestResponse, testResponseData]);

  const validateJson = (jsonString) => {
    if (!jsonString.trim()) {
      setIsJsonValid(true); // Empty is considered valid
      return true;
    }

    try {
      JSON.parse(jsonString);
      setIsJsonValid(true);
      return true;
    } catch (e) {
      setIsJsonValid(false);
      return false;
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleJsonChange = (value) => {
    setFormData({ ...formData, json_response: value });
    validateJson(value);
  };

  const handleSubmit = () => {
    // Validate response name
    if (!formData.response_name.trim()) {
      toast.error(t("please_fill_out_all_fields"), ToastOptions);
      return;
    }

    // Validate JSON
    if (!isJsonValid) {
      toast.error(t("invalid_json"), ToastOptions);
      return;
    }

    onSave(formData);
  };

  const handleCopyJson = () => {
    navigator.clipboard.writeText(formData.json_response);
    toast.success(t("copied"), ToastOptions);
  };

  // Format JSON if possible
  const formatJson = () => {
    try {
      const parsedJson = JSON.parse(formData.json_response);
      const formatted = JSON.stringify(parsedJson, null, 2);
      setFormData({ ...formData, json_response: formatted });
      setIsJsonValid(true);
      toast.success(t("json_formatted"), ToastOptions);
    } catch (e) {
      setIsJsonValid(false);
      toast.error(t("invalid_json"), ToastOptions);
    }
  };

  return (
    <Modal show={show} onHide={onClose} size="xl">
      <Modal.Header>
        <h5 className="modal-title">
          {response ? t("edit_response") : t("add_response")}
        </h5>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="response_name">{t("response_name")}*</label>
          <input
            type="text"
            className="form-control"
            id="response_name"
            name="response_name"
            value={formData.response_name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <label htmlFor="json_response">
              {t("json_response")}
              {!isJsonValid && (
                <span className="text-danger ml-2">({t("invalid_json")})</span>
              )}
            </label>
            <div>
              <button
                className="btn btn-sm btn-outline-secondary mr-2"
                onClick={formatJson}
              >
                {t("format_json")}
              </button>
              <button
                className="btn btn-transparent"
                data-tip={true}
                data-for="copyJson"
                onClick={handleCopyJson}
              >
                <CopyIcon />
              </button>
              <ReactTooltip
                type="dark"
                place="top"
                effect="solid"
                id="copyJson"
              >
                {t("copy_clipboard")}
              </ReactTooltip>
            </div>
          </div>
          <CodeMirror
            value={formData.json_response}
            extensions={[json(), EditorView.lineWrapping]}
            onChange={handleJsonChange}
            height="300px"
            className={!isJsonValid ? "invalid-json-editor" : ""}
          />
        </div>

        {formData.attributes && formData.attributes.length > 0 && (
          <div className="form-group mt-4">
            <label>{t("response_attributes")}</label>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("path")}</th>
                    <th>{t("value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.attributes.map((attr, index) => (
                    <tr key={index}>
                      <td>{attr.mapping || attr.path || ""}</td>
                      <td>{attr.value || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button variant="" onClick={onClose} className="outline my-0">
          {t("cancel")}
        </Button>
        <Button
          variant=""
          onClick={handleSubmit}
          className="primary my-0"
          disabled={!isJsonValid}
        >
          <SaveIcon className="mr-1" /> {response ? t("update") : t("save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ResponseModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  response: PropTypes.object,
  isTestResponse: PropTypes.bool,
  testResponseData: PropTypes.object,
};

export default ResponseModal;
