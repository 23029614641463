import { createAction } from "redux-actions";

export const getRuleSetsRequest = createAction("GET_RULE_SETS_REQUEST");
export const getRuleSetsSuccess = createAction("GET_RULE_SETS_SUCCESS");
export const getRuleSetsFailure = createAction("GET_RULE_SETS_FAILURE");

export const getRuleSetRequest = createAction("GET_RULE_SET_REQUEST");
export const getRuleSetForCopySuccess = createAction(
  "GET_RULE_SET_FOR_COPY_SUCCESS"
);
export const getRuleSetSuccess = createAction("GET_RULE_SET_SUCCESS");
export const getRuleSetFailure = createAction("GET_RULE_SET_FAILURE");

export const getConditionTypesRequest = createAction(
  "GET_CONDITION_TYPES_REQUEST"
);
export const getConditionTypesSuccess = createAction(
  "GET_CONDITION_TYPES_SUCCESS"
);
export const getConditionTypesFailure = createAction(
  "GET_CONDITION_TYPES_FAILURE"
);

export const createRuleSetRequest = createAction("CREATE_RULE_SET_REQUEST");
export const createRuleSetSuccess = createAction("CREATE_RULE_SET_SUCCESS");
export const createRuleSetFailure = createAction("CREATE_RULE_SET_FAILURE");

export const updateRuleSetRequest = createAction("SAVE_RULE_SET_REQUEST");
export const updateRuleSetSuccess = createAction("SAVE_RULE_SET_SUCCESS");
export const updateRuleSetFailure = createAction("SAVE_RULE_SET_FAILURE");

export const deleteRuleSetRequest = createAction("DELETE_RULE_SET_REQUEST");
export const deleteRuleSetSuccess = createAction("DELETE_RULE_SET_SUCCESS");
export const deleteRuleSetFailure = createAction("DELETE_RULE_SET_FAILURE");

export const deleteRuleRequest = createAction("DELETE_RULE_REQUEST");
export const deleteRuleSuccess = createAction("DELETE_RULE_SUCCESS");
export const deleteRuleFailure = createAction("DELETE_RULE_FAILURE");

export const deleteRuleConditionRequest = createAction(
  "DELETE_RULE_CONDITION_REQUEST"
);
export const deleteRuleConditionSuccess = createAction(
  "DELETE_RULE_CONDITION_SUCCESS"
);
export const deleteRuleConditionFailure = createAction(
  "DELETE_RULE_CONDITION_FAILURE"
);

export const deleteRuleActionRequest = createAction(
  "DELETE_RULE_ACTION_REQUEST"
);
export const deleteRuleActionSuccess = createAction(
  "DELETE_RULE_ACTION_SUCCESS"
);
export const deleteRuleActionFailure = createAction(
  "DELETE_RULE_ACTION_FAILURE"
);

export const getRuleRevisionsRequest = createAction(
  "GET_RULE_REVISIONS_REQUEST"
);
export const getRuleRevisionsSuccess = createAction(
  "GET_RULE_REVISIONS_SUCCESS"
);
export const getRuleRevisionsFailure = createAction(
  "GET_RULE_REVISIONS_FAILURE"
);

export const getTestRuleSetRequest = createAction("GET_TEST_RULE_SET_REQUEST");
export const getTestRuleSetSuccess = createAction("GET_TEST_RULE_SET_SUCCESS");
export const getTestRuleSetFailure = createAction("GET_TEST_RULE_SET_FAILURE");

export const getRunTestResultDetailsRequest = createAction(
  "GET_RUN_TEST_RESULT_DETAILS_REQUEST"
);
export const getRunTestResultDetailsSuccess = createAction(
  "GET_RUN_TEST_RESULT_DETAILS_SUCCESS"
);
export const getRunTestResultDetailsFailure = createAction(
  "GET_RUN_TEST_RESULT_DETAILS_FAILURE"
);

export const getRunTestResultStatusRequest = createAction(
  "GET_RUN_TEST_RESULT_STATUS_REQUEST"
);
export const getRunTestResultStatusSuccess = createAction(
  "GET_RUN_TEST_RESULT_STATUS_SUCCESS"
);
export const getRunTestResultStatusFailure = createAction(
  "GET_RUN_TEST_RESULT_STATUS_FAILURE"
);

export const getRuleRevisionRequest = createAction("GET_RULE_REVISION_REQUEST");
export const getRuleRevisionSuccess = createAction("GET_RULE_REVISION_SUCCESS");
export const getRuleRevisionFailure = createAction("GET_RULE_REVISION_FAILURE");

export const getRuleRevision2Request = createAction(
  "GET_RULE_REVISION2_REQUEST"
);
export const getRuleRevision2Success = createAction(
  "GET_RULE_REVISION2_SUCCESS"
);
export const getRuleRevision2Failure = createAction(
  "GET_RULE_REVISION2_FAILURE"
);

//Explanations

export const getRuleExplanationRequest = createAction(
  "GET_RULE_EXPLANATION_REQUEST"
);
export const getRuleExplanationSuccess = createAction(
  "GET_RULE_EXPLANATION_SUCCESS"
);
export const getRuleExplanationFailure = createAction(
  "GET_RULE_EXPLANATION_FAILURE"
);

//chat
export const getRuleSetChatRequest = createAction("GET_RULE_SET_CHAT_REQUEST");
export const getRuleSetChatSuccess = createAction("GET_RULE_SET_CHAT_SUCCESS");
export const getRuleSetChatFailure = createAction("GET_RULE_SET_CHAT_FAILURE");

//workflows
export const getRuleSetWorkflowsRequest = createAction(
  "GET_RULE_SET_WORKFLOWS_REQUEST"
);
export const getRuleSetWorkflowsSuccess = createAction(
  "GET_RULE_SET_WORKFLOWS_SUCCESS"
);
export const getRuleSetWorkflowsFailure = createAction(
  "GET_RULE_SET_WORKFLOWS_FAILURE"
);
