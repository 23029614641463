import React, { useState } from "react";
import PropTypes from "prop-types";
import AutoSuggestInput from "components/autosuggest";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { types } from "../utils";
import { isJson } from "utility/utility";
import { useTranslation } from "react-i18next";

import { ReactComponent as JSONIcon } from "assets/icons/json.svg";
import { ReactComponent as VectorsIcon } from "assets/icons/vectors.svg";
import { ReactComponent as AbcIcon } from "assets/icons/abc.svg";

const Action = ({
  ruleId,
  action,
  deleteModal,
  revisionMode = false,
  vectorsData,
}) => {
  const { t } = useTranslation();

  const [actionVector, setActionVector] = useState(
    action.action_vector.toString()
  );
  const [isInvalid, setIsInvalid] = useState("");
  const [actionValue, setActionValue] = useState(action.action_value || "");

  const changeType = (value) => {
    if (value === "2" && actionValue && !isJson(actionValue)) {
      setIsInvalid("is-invalid");
    } else {
      setIsInvalid("");
    }

    setActionVector(value);
  };

  const handleChangeInputValue = (e) => {
    setActionValue(e.target.value);
    if (actionVector === "2") {
      if (isJson(e.target.value) || !e.target.value) {
        setIsInvalid("");
      } else {
        setIsInvalid("is-invalid");
      }
    }
  };

  return (
    <>
      <input
        type="hidden"
        name={`rule[${ruleId}][action][${action.rule_action_id}][rule_action_id]`}
        defaultValue={
          action.rule_action_id
            ? action.rule_action_id
            : `new_${Math.round(Math.random() * 10000)}`
        }
        key={action.rule_action_id}
      />
      <div
        className="border-md ruleset-row pl-0"
        id={`action-${action.rule_action_id}`}
      >
        <div className="attribute1 large-item">
          <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
            <div className="input-group-prepend">
              <button
                className="btn outline font-size-14 w-100 border-radius-left-4 bg-white data-attribute-btn border-radius-none-right-top-bottom"
                type="button"
              >
                <span className="icon">
                  <VectorsIcon
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </span>
                <span className="text">{t("attribute")}:</span>
              </button>
            </div>
            <AutoSuggestInput
              vectorsData={vectorsData}
              defaultValue={action.attribute_path}
              inputName={`rule[${ruleId}][action][${action.rule_action_id}][attribute_path]`}
              revisionMode={revisionMode}
              isAction={true}
            />
          </div>
        </div>
        <select
          className="form-control outline font-size-16 condition-operation math-symbols text-center"
          id={`action-operator-${action.rule_action_id}`}
          defaultValue={action.action_type}
          name={`rule[${ruleId}][action][${action.rule_action_id}][action_type]`}
          disabled={revisionMode ? "disabled" : ""}
        >
          <option value="s">=</option>
          <option value="p">+</option>
          <option value="m">-</option>
          <option value="x">*</option>
          <option value="h">max([array])</option>
          <option value="l">min([array])</option>
          <option value="c">count([array])</option>
          <option value="g">sum([array])</option>
          <option value="v">avg([array])</option>
          <option value="a">add to array</option>
          <option value="t">add text</option>
        </select>
        <div className="attribute2 large-item">
          <div className="flex-column flex-sm-nowrap flex-sm-row input-group">
            <div className="input-group-prepend">
              <input
                type="hidden"
                name={`rule[${ruleId}][action][${action.rule_action_id}][action_vector]`}
                value={types.find((type) => type.value === actionVector).value}
              />
              <button
                disabled={revisionMode ? "disabled" : ""}
                className="btn outline font-size-14 dropdown-toggle text-left border-radius-left-4 bg-white rule-set-btn border-radius-none-right-top-bottom"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon">
                  {(() => {
                    const typeLabel = types.find(
                      (type) => type.value === actionVector
                    ).label;
                    switch (typeLabel) {
                      case "JSON":
                        return <JSONIcon />;
                      case "Attribute":
                        return (
                          <VectorsIcon
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                          />
                        );
                      case "Value":
                        return <AbcIcon />;
                      default:
                        return null;
                    }
                  })()}
                </span>
                <span className="text">
                  {types.find((type) => type.value === actionVector).label}
                </span>
              </button>
              <div className="dropdown-menu">
                {types.map((type) => {
                  return (
                    <button
                      key={type.value}
                      className="dropdown-item"
                      type="button"
                      onClick={() => changeType(type.value)}
                    >
                      {type.label}
                    </button>
                  );
                })}
              </div>
            </div>
            {actionVector === "1" ? (
              <AutoSuggestInput
                vectorsData={vectorsData}
                defaultValue={action.action_path || ""}
                inputName={`rule[${ruleId}][action][${action.rule_action_id}][action_path]`}
                revisionMode={revisionMode}
              />
            ) : (
              <input
                type="text"
                onChange={handleChangeInputValue}
                value={actionValue}
                className={`form-control w-100 border-radius-right-4 ${isInvalid}`}
                name={`rule[${ruleId}][action][${action.rule_action_id}][action_value]`}
                disabled={revisionMode ? "disabled" : ""}
              />
            )}
          </div>
        </div>
        {!revisionMode && (
          <button
            className="btn outline font-size-16"
            type="button"
            onClick={() => deleteModal(ruleId, action.rule_action_id)}
          >
            <DeleteIcon />
          </button>
        )}
      </div>
    </>
  );
};

Action.propTypes = {
  action: PropTypes.object,
  ruleId: PropTypes.any,
  deleteModal: PropTypes.func,
  vectorsData: PropTypes.array,
  revisionMode: PropTypes.bool,
};

export default Action;
