import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Card, Button, Form, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getScenarioResultsFromDB } from "utility/BatchTestDB"; // Updated import
import ReactECharts from "echarts-for-react";
import {
  getAnalysisRecipesRequest,
  getAnalysisRecipeRequest,
} from "redux/analysisRecipes/actions";
import AnalysisRecipeForm from "components/modals/release/AnalyticsModal/AnalysisRecipeForm";
import usePrevious from "utility/hooks/usePrevious";
import { generateComparisonCharts } from "utility/AnalyticsUtils";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import VirtualImpactRow from "./VirtualImpactRow";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import "./ImpactAnalyzer.scss";
import ImpactAnalyzerProgress from "./ImpactAnalyzerProgress";

const ImpactAnalyzerResults = ({ scenarioId, initialRecipeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [viewMode, setViewMode] = useState("analytics"); // Default to analytics view
  const [jsonPathSuggestions, setJsonPathSuggestions] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [resultsLoaded, setResultsLoaded] = useState(false); // Track if results are loaded
  const initialAnalyticsGenerated = useRef(false);
  const [filter, setFilter] = useState("all"); // 'all', 'different', 'identical'

  // Analysis recipes
  const { analysisRecipes } = useSelector((state) => state.analysisRecipes);
  const [isConfigExpanded, setIsConfigExpanded] = useState(
    analysisRecipes.length === 0
  );
  const [selectedRecipeId, setSelectedRecipeId] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    slices: [],
  });

  const { isExecutingImpactScenario, impactScenarioResults } = useSelector(
    (state) => state.impactAnalyzer
  );

  const { analysisRecipe, isGetAnalysisRecipeSuccess } = useSelector(
    (state) => state.analysisRecipes
  );

  const prevIsGetAnalysisRecipeSuccess = usePrevious(
    isGetAnalysisRecipeSuccess
  );

  useEffect(() => {
    dispatch(getAnalysisRecipesRequest());

    if (initialRecipeId) {
      setSelectedRecipeId(initialRecipeId);
    }

    if (analysisRecipes.length === 0) {
      setIsConfigExpanded(true);
    }
  }, [dispatch, initialRecipeId, analysisRecipes.length]);

  useEffect(() => {
    if (selectedRecipeId) {
      dispatch(getAnalysisRecipeRequest(selectedRecipeId));
    }
  }, [selectedRecipeId, dispatch]);

  useEffect(() => {
    if (
      analysisRecipe &&
      analysisRecipe.release_analysis_recipe_id == selectedRecipeId &&
      isGetAnalysisRecipeSuccess &&
      prevIsGetAnalysisRecipeSuccess === false
    ) {
      const formattedSlices = (analysisRecipe.slices || []).map((slice) => ({
        release_recipe_slice_id: slice.release_recipe_slice_id,
        name: slice.name || "",
        dimension: slice.dimension || "",
        metric: slice.metric || "",
        type: slice.type || "number",
        aggregation: slice.aggregation || "sum",
        sequence: slice.sequence || 0,
      }));

      setFormData({
        title: analysisRecipe.title || "",
        name: analysisRecipe.name || "",
        slices: formattedSlices,
      });

      if (resultsLoaded && !initialAnalyticsGenerated.current) {
        setTimeout(() => {
          generateAnalytics(formattedSlices);
          initialAnalyticsGenerated.current = true;
        }, 100);
      }
    }
  }, [
    isGetAnalysisRecipeSuccess,
    analysisRecipe,
    selectedRecipeId,
    prevIsGetAnalysisRecipeSuccess,
    resultsLoaded,
    initialRecipeId,
  ]);

  useEffect(() => {
    const loadResults = async () => {
      try {
        setLoading(true);
        // Use new helper to fetch scenario results from the dedicated table
        const data = await getScenarioResultsFromDB(scenarioId);
        setResults(data);

        if (data.length > 0) {
          const sampleBaseline = data[0].baselineOutput;
          const suggestions = extractJsonPaths(sampleBaseline);
          setJsonPathSuggestions(suggestions);

          if (
            initialRecipeId &&
            formData.slices.length > 0 &&
            !chartOptions.length
          ) {
            generateAnalytics();
          }
        }
      } catch (error) {
        console.error("Error loading impact results:", error);
      } finally {
        setLoading(false);
        setResultsLoaded(true);
      }
    };

    if (!isExecutingImpactScenario) {
      loadResults();
    }
  }, [scenarioId, isExecutingImpactScenario]);

  useEffect(() => {
    if (
      resultsLoaded &&
      formData.slices.length > 0 &&
      !initialAnalyticsGenerated.current &&
      !chartOptions.length
    ) {
      generateAnalytics(formData.slices);
      initialAnalyticsGenerated.current = true;
    }
  }, [resultsLoaded, formData.slices, chartOptions.length]);

  const extractJsonPaths = (obj, currentPath = "$", paths = []) => {
    // Add synthetic "Differences" dimension at the beginning
    if (currentPath === "$") {
      paths.push("$.__synthetic.differences");
    }

    if (!obj || typeof obj !== "object") return paths;
    if (Array.isArray(obj)) {
      if (obj.length > 0) {
        extractJsonPaths(obj[0], `${currentPath}[0]`, paths);
      }
    } else {
      Object.keys(obj).forEach((key) => {
        const newPath = `${currentPath}.${key}`;
        paths.push(newPath);
        if (obj[key] && typeof obj[key] === "object") {
          extractJsonPaths(obj[key], newPath, paths);
        }
      });
    }
    return paths;
  };

  const handleRecipeSelect = (e) => {
    setSelectedRecipeId(e.target.value);
  };

  const generateAnalytics = (slicesToUse = formData.slices) => {
    setIsAnalyzing(true);

    try {
      if (slicesToUse.length === 0 || results.length === 0) {
        setIsAnalyzing(false);
        return;
      }

      const aggregatedCharts = [];

      for (const slice of slicesToUse) {
        // Special handling for the synthetic differences dimension
        if (slice.dimension === "$.__synthetic.differences") {
          // Create enriched baseline outputs with synthetic difference property
          const enrichedBaselineOutputs = results.map((r) => {
            const hasDifferences =
              JSON.stringify(r.baselineOutput) !==
              JSON.stringify(r.comparisonOutput);

            // Clone baseline output and add synthetic property
            const enriched = {
              ...r.baselineOutput,
              __synthetic: {
                differences: hasDifferences ? t("different") : t("identical"),
              },
            };
            return enriched;
          });

          // Do the same for comparison outputs
          const enrichedComparisonOutputs = results.map((r) => {
            const hasDifferences =
              JSON.stringify(r.baselineOutput) !==
              JSON.stringify(r.comparisonOutput);

            const enriched = {
              ...r.comparisonOutput,
              __synthetic: {
                differences: hasDifferences ? t("different") : t("identical"),
              },
            };
            return enriched;
          });

          const chartSet = generateComparisonCharts(
            slice,
            enrichedBaselineOutputs,
            enrichedComparisonOutputs,
            t
          );

          if (chartSet) {
            aggregatedCharts.push(chartSet);
          }
        } else {
          // Normal handling for regular dimensions
          const chartSet = generateComparisonCharts(
            slice,
            results.map((r) => r.baselineOutput),
            results.map((r) => r.comparisonOutput),
            t
          );

          if (chartSet) {
            aggregatedCharts.push(chartSet);
          }
        }
      }

      // Only update if we have charts to show
      if (aggregatedCharts.length > 0) {
        setChartOptions(aggregatedCharts);
      }
    } catch (error) {
      console.error("Error generating charts:", error);
      toast.error(t("error_generating_charts"), ToastOptions);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleDownloadBaseline = () => {
    if (results.length === 0) {
      toast.error(t("no_results_to_download"), ToastOptions);
      return;
    }
    const jsonLines = results
      .map((result) => JSON.stringify(result.baselineOutput))
      .join("\n");
    const timestamp = new Date()
      .toISOString()
      .replace(/T/, "_")
      .replace(/\..+/, "")
      .replace(/:/g, "-");
    const filename = `baseline_${scenarioId}_${timestamp}.jsonl`;
    downloadFile(jsonLines, filename);
    toast.success(t("download_started"), ToastOptions);
  };

  const handleDownloadComparison = () => {
    if (results.length === 0) {
      toast.error(t("no_results_to_download"), ToastOptions);
      return;
    }
    const jsonLines = results
      .map((result) => JSON.stringify(result.comparisonOutput))
      .join("\n");
    const timestamp = new Date()
      .toISOString()
      .replace(/T/, "_")
      .replace(/\..+/, "")
      .replace(/:/g, "-");
    const filename = `comparison_${scenarioId}_${timestamp}.jsonl`;
    downloadFile(jsonLines, filename);
    toast.success(t("download_started"), ToastOptions);
  };

  const downloadFile = (content, filename) => {
    const blob = new Blob([content], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getFilteredResults = useCallback(() => {
    if (filter === "all") return results;

    return results.filter((result) => {
      const hasDifferences =
        JSON.stringify(result.baselineOutput) !==
        JSON.stringify(result.comparisonOutput);
      return filter === "different" ? hasDifferences : !hasDifferences;
    });
  }, [results, filter]);

  const totalPages = Math.ceil(results.length / pageSize);
  const currentResults = results.slice((page - 1) * pageSize, page * pageSize);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const getItemData = useCallback(
    () => ({
      results: getFilteredResults(),
      t,
    }),
    [getFilteredResults, t]
  );

  if (loading || isExecutingImpactScenario) {
    return <ImpactAnalyzerProgress />;
  }

  return (
    <div className="impact-analyzer-results">
      <div className="mb-4">
        <Card bg="light">
          <Card.Body>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-0">
                  {t("total_items_processed")}: {results.length}
                </p>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      {results.length === 0 ? (
        <div className="alert alert-info">{t("no_results_available")}</div>
      ) : (
        <>
          <div className="d-flex justify-content-center mb-3">
            <div className="btn-group btn-group-toggle" role="group">
              <div
                className={`btn outline border-radius-left-4 ${
                  viewMode === "table" ? "active" : ""
                }`}
                onClick={() => setViewMode("table")}
              >
                {t("table_view")}
              </div>
              <div
                className={`btn outline border-radius-right-4 ${
                  viewMode === "analytics" ? "active" : ""
                }`}
                onClick={() => setViewMode("analytics")}
              >
                {t("analytics_view")}
              </div>
            </div>
          </div>

          {viewMode === "analytics" ? (
            <div className="analytics-section">
              <Card className="mb-4">
                <Card.Header
                  className="d-flex justify-content-between align-items-center"
                  onClick={() => setIsConfigExpanded(!isConfigExpanded)}
                >
                  <h5 className="mb-0">{t("analysis_configuration")}</h5>
                  <Button
                    variant="link"
                    className="p-0"
                    aria-expanded={isConfigExpanded}
                    aria-controls="analysis-config-content"
                  >
                    {isConfigExpanded ? "Hide" : "Show"}
                  </Button>
                </Card.Header>
                <Card.Body
                  id="analysis-config-content"
                  className={isConfigExpanded ? "" : "d-none"}
                >
                  {analysisRecipes.length > 0 && (
                    <div className="mb-4">
                      <Form.Group>
                        <Form.Label>{t("load_analytics_recipe")}</Form.Label>
                        <Form.Control
                          as="select"
                          value={selectedRecipeId}
                          onChange={handleRecipeSelect}
                        >
                          <option value="">
                            {t("select_analytics_recipe")}
                          </option>
                          {analysisRecipes.map((recipe) => (
                            <option
                              key={recipe.release_analysis_recipe_id}
                              value={recipe.release_analysis_recipe_id}
                            >
                              {recipe.title}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  )}

                  <AnalysisRecipeForm
                    formData={formData}
                    setFormData={setFormData}
                    onSubmit={() => generateAnalytics()}
                    vectorsData={jsonPathSuggestions}
                  />

                  <div className="d-flex justify-content-center my-3">
                    <Button
                      variant=""
                      type="button"
                      className="btn primary deploy-btn w-auto"
                      onClick={() => generateAnalytics()}
                      disabled={isAnalyzing || formData.slices.length === 0}
                    >
                      {isAnalyzing ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          {t("analyzing")}
                        </>
                      ) : (
                        t("analyze")
                      )}
                    </Button>
                  </div>
                </Card.Body>
              </Card>

              {chartOptions.length > 0 ? (
                chartOptions.map((chartSet, idx) => (
                  <Card key={idx} className="mb-4">
                    <Card.Header>
                      <h6 className="mb-0">{chartSet.name}</h6>
                    </Card.Header>
                    <Card.Body>
                      {chartSet.isFullWidth ? (
                        // Full width chart for distribution shift
                        <div className="row">
                          <div className="col-12">
                            <ReactECharts
                              option={chartSet.chart}
                              style={{ height: "500px", width: "100%" }}
                              notMerge={true}
                              lazyUpdate={false}
                            />
                          </div>
                        </div>
                      ) : (
                        // Regular side-by-side comparison charts
                        <div className="row">
                          <div className="col-md-6">
                            <ReactECharts
                              option={chartSet.baseline}
                              style={{ height: "400px", width: "100%" }}
                              notMerge={true}
                              lazyUpdate={false}
                            />
                          </div>
                          <div className="col-md-6">
                            <ReactECharts
                              option={chartSet.comparison}
                              style={{ height: "400px", width: "100%" }}
                              notMerge={true}
                              lazyUpdate={false}
                            />
                          </div>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <div className="text-center p-5 border rounded">
                  <p className="text-muted">
                    {isAnalyzing
                      ? t("generating_charts")
                      : t("no_charts_generated")}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between mb-3">
                <div className="filter-controls">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      id="filter-dropdown"
                      className="btn-sm outline border-radius-left-4"
                    >
                      <i className="bi bi-funnel"></i> {t("filter")}:{" "}
                      {t(filter)}
                      <span className="ml-2 badge bg-light text-dark">
                        {getFilteredResults().length} / {results.length}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        active={filter === "all"}
                        onClick={() => setFilter("all")}
                      >
                        {t("all")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={filter === "different"}
                        onClick={() => setFilter("different")}
                      >
                        {t("different")}
                      </Dropdown.Item>
                      <Dropdown.Item
                        active={filter === "identical"}
                        onClick={() => setFilter("identical")}
                      >
                        {t("identical")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <button
                    className="btn outline border-radius-right-4"
                    onClick={handleDownloadBaseline}
                    disabled={results.length === 0}
                  >
                    <i className="bi bi-download mr-1"></i>{" "}
                    {t("download_baseline")}
                  </button>
                  <button
                    className="ml-2 btn outline border-radius-right-4"
                    onClick={handleDownloadComparison}
                    disabled={results.length === 0}
                  >
                    <i className="bi bi-download mr-1"></i>{" "}
                    {t("download_comparison")}
                  </button>
                </div>
              </div>
              <div
                className="virtual-table-container"
                style={{ height: "600px" }}
              >
                <div className="table-header">
                  <div className="table-col col-number">#</div>
                  <div className="table-col col-content">{t("input")}</div>
                  <div className="table-col col-content">
                    {t("baseline_output")}
                  </div>
                  <div className="table-col col-content">
                    {t("comparison_output")}
                  </div>
                  <div className="table-col col-status">{t("differences")}</div>
                </div>

                {loading ? (
                  <div className="loading-indicator">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        height={height - 40}
                        itemCount={getFilteredResults().length}
                        itemSize={50}
                        width={width}
                        itemData={getItemData()}
                        overscanCount={5}
                        className="impact-analyzer-list"
                      >
                        {VirtualImpactRow}
                      </List>
                    )}
                  </AutoSizer>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ImpactAnalyzerResults.propTypes = {
  scenarioId: PropTypes.string.isRequired,
  initialRecipeId: PropTypes.string,
};

export default ImpactAnalyzerResults;
