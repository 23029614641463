import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createDataSourceFailure,
  createDataSourceRequest,
  createDataSourceSuccess,
  createOAuth2TokenFailure,
  createOAuth2TokenRequest,
  createOAuth2TokenSuccess,
  deleteDataSourceFailure,
  deleteDataSourceHeaderFailure,
  deleteDataSourceHeaderRequest,
  deleteDataSourceHeaderSuccess,
  deleteDataSourceParamFailure,
  deleteDataSourceParamRequest,
  deleteDataSourceParamSuccess,
  deleteDataSourceRequest,
  deleteDataSourceSuccess,
  deleteDataSourceConflict,
  deleteOAuth2TokenFailure,
  deleteOAuth2TokenRequest,
  deleteOAuth2TokenSuccess,
  getDataSourceFailure,
  getDataSourceRequest,
  getDataSourcesFailure,
  getDataSourcesRequest,
  getDataSourcesSuccess,
  getDataSourceSuccess,
  getExternalDataTestingFailure,
  getExternalDataTestingRequest,
  getExternalDataTestingSuccess,
  getOAuth2TokenFailure,
  getOAuth2TokenRequest,
  getOAuth2TokensFailure,
  getOAuth2TokensRequest,
  getOAuth2TokensSuccess,
  getOAuth2TokenSuccess,
  getRunTestResultFailure,
  getRunTestResultRequest,
  getRunTestResultSuccess,
  updateDataSourceFailure,
  updateDataSourceRequest,
  updateDataSourceSuccess,
  updateOAuth2TokenFailure,
  updateOAuth2TokenRequest,
  updateOAuth2TokenSuccess,
  //response
  createExternalDataResponseFailure,
  createExternalDataResponseRequest,
  createExternalDataResponseSuccess,
  updateExternalDataResponseFailure,
  updateExternalDataResponseRequest,
  updateExternalDataResponseSuccess,
  deleteExternalDataResponseFailure,
  deleteExternalDataResponseRequest,
  deleteExternalDataResponseSuccess,
  getExternalDataResponseRequest,
  getExternalDataResponseSuccess,
  getExternalDataResponseFailure,
  getExternalDataResponsesRequest,
  getExternalDataResponsesSuccess,
  getExternalDataResponsesFailure,
} from "redux/dataSources/action";
import { useCaseWorkflowRequest } from "../workflows/action";

const useCaseWorkflowSelector = (state) => state.workflows.useCaseWorkflow;

function* getDataSources({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/external-data?page=${payload}` : "/external-data"
    );
    if (response.status === 200) {
      yield put(getDataSourcesSuccess(response.data));
    }
  } catch (e) {
    yield put(getDataSourcesFailure("e.message"));
  }
}

function* getDataSource({ payload }) {
  try {
    const url = `external-data/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getDataSourceSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getDataSourceFailure("e.message"));
  }
}

function* getExternalDataTesting({ payload }) {
  try {
    const url = `external-data/${payload}/test`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getExternalDataTestingSuccess(response.data));
    }
  } catch (e) {
    yield put(getExternalDataTestingFailure("e.message"));
  }
}

function* getRunTestResult({ payload }) {
  try {
    const url = `external-data/${payload.id}/test`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultFailure("e.message"));
  }
}

function* updateDataSource({ payload }) {
  try {
    const url = `external-data/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateDataSourceSuccess());
    }
  } catch (e) {
    yield put(updateDataSourceFailure("e.message"));
  }
}

function* deleteDataSource({ payload }) {
  try {
    const url = `external-data/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteDataSourceSuccess());
    }
  } catch (e) {
    if (e.response?.status === 409) {
      yield put(deleteDataSourceConflict(e.response.data));
    } else {
      yield put(deleteDataSourceFailure(e.message));
    }
  }
}

function* deleteDataSourceParam({ payload }) {
  try {
    const url = `external-data/${payload.id}/params/${payload.paramId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteDataSourceParamSuccess());
    }
  } catch (e) {
    yield put(deleteDataSourceParamFailure("e.message"));
  }
}

function* deleteDataSourceHeader({ payload }) {
  try {
    const url = `external-data/${payload.id}/headers/${payload.headerId}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteDataSourceHeaderSuccess());
    }
  } catch (e) {
    yield put(deleteDataSourceHeaderFailure("e.message"));
  }
}

function* createDataSource({ payload }) {
  try {
    const response = yield call(axios.post, "/external-data", payload.data);
    if (response.status === 201) {
      yield put(createDataSourceSuccess(response.headers.etag));
      if (payload.external_data_id) {
        const workflow = yield select(useCaseWorkflowSelector);
        workflow.workflow_step.map((step) => {
          step.options?.map((option) => {
            if (option.external_data_id === payload.external_data_id) {
              option.external_data_id = response.headers.etag;
            }
          });
        });
        yield put(useCaseWorkflowRequest(workflow));
      }
    }
  } catch (e) {
    yield put(createDataSourceFailure("e.message"));
  }
}

function* getOAuth2Tokens() {
  try {
    const response = yield call(axios.get, "oauth2-tokens");
    if (response.status === 200) {
      yield put(getOAuth2TokensSuccess(response.data));
    }
  } catch (e) {
    yield put(getOAuth2TokensFailure("e.message"));
  }
}

function* getOAuth2Token({ payload }) {
  try {
    const response = yield call(axios.get, `oauth2-tokens/${payload}`);
    if (response.status === 200) {
      yield put(getOAuth2TokenSuccess(response.data));
    }
  } catch (e) {
    yield put(getOAuth2TokenFailure("e.message"));
  }
}

function* createOAuth2Token({ payload }) {
  try {
    const response = yield call(axios.post, "oauth2-tokens", payload.data);
    if (response.status === 201) {
      yield put(createOAuth2TokenSuccess(response.data));
    }
  } catch (e) {
    yield put(createOAuth2TokenFailure("e.message"));
  }
}

function* updateOAuth2Token({ payload }) {
  try {
    const response = yield call(
      axios.put,
      `oauth2-tokens/${payload.id}`,
      payload.data
    );
    if (response.status === 200) {
      yield put(updateOAuth2TokenSuccess(response.data));
    }
  } catch (e) {
    yield put(updateOAuth2TokenFailure("e.message"));
  }
}

function* deleteOAuth2Token({ payload }) {
  try {
    const response = yield call(axios.delete, `oauth2-tokens/${payload}`);
    if (response.status === 204) {
      yield put(
        deleteOAuth2TokenSuccess({ ...response.data, deleted_id: payload })
      );
    }
  } catch (e) {
    yield put(deleteOAuth2TokenFailure("e.message"));
  }
}

//data responses
function* createExternalDataResponse({ payload }) {
  try {
    const response = yield call(
      axios.post,
      "external-data/" + payload.external_data_id + "/responses",
      payload
    );
    if (response.status === 201) {
      yield put(createExternalDataResponseSuccess(response.data));
    }
  } catch (e) {
    yield put(createExternalDataResponseFailure("e.message"));
  }
}

function* updateExternalDataResponse({ payload }) {
  try {
    const response = yield call(
      axios.put,
      "external-data/" + payload.external_data_id + "/responses/" + payload.id,
      payload
    );
    if (response.status === 200) {
      yield put(updateExternalDataResponseSuccess(response.data));
    }
  } catch (e) {
    yield put(updateExternalDataResponseFailure("e.message"));
  }
}

function* deleteExternalDataResponse({ payload }) {
  try {
    const response = yield call(
      axios.delete,
      "external-data/" +
        payload.external_data_id +
        "/responses/" +
        payload.ext_data_response_id
    );
    if (response.status === 204) {
      yield put(deleteExternalDataResponseSuccess(response.data));
    }
  } catch (e) {
    yield put(deleteExternalDataResponseFailure("e.message"));
  }
}

function* getExternalDataResponse({ payload }) {
  try {
    const response = yield call(
      axios.get,
      "external-data/" +
        payload.external_data_id +
        "/responses/" +
        payload.ext_data_response_id
    );
    if (response.status === 200) {
      yield put(getExternalDataResponseSuccess(response.data));
    }
  } catch (e) {
    yield put(getExternalDataResponseFailure("e.message"));
  }
}

function* getExternalDataResponses({ payload }) {
  try {
    const response = yield call(
      axios.get,
      "external-data/" + payload.external_data_id + "/responses"
    );
    if (response.status === 200) {
      yield put(getExternalDataResponsesSuccess(response.data));
    }
  } catch (e) {
    yield put(getExternalDataResponsesFailure("e.message"));
  }
}

export default function* saga() {
  yield takeLatest(getDataSourcesRequest, getDataSources);
  yield takeLatest(getDataSourceRequest, getDataSource);
  yield takeLatest(updateDataSourceRequest, updateDataSource);
  yield takeLatest(deleteDataSourceRequest, deleteDataSource);
  yield takeLatest(deleteDataSourceParamRequest, deleteDataSourceParam);
  yield takeLatest(deleteDataSourceHeaderRequest, deleteDataSourceHeader);
  yield takeEvery(createDataSourceRequest, createDataSource);
  yield takeLatest(getRunTestResultRequest, getRunTestResult);
  yield takeLatest(getExternalDataTestingRequest, getExternalDataTesting);
  yield takeLatest(getOAuth2TokensRequest, getOAuth2Tokens);
  yield takeLatest(getOAuth2TokenRequest, getOAuth2Token);
  yield takeLatest(createOAuth2TokenRequest, createOAuth2Token);
  yield takeLatest(updateOAuth2TokenRequest, updateOAuth2Token);
  yield takeLatest(deleteOAuth2TokenRequest, deleteOAuth2Token);
  yield takeLatest(
    createExternalDataResponseRequest,
    createExternalDataResponse
  );
  yield takeLatest(
    updateExternalDataResponseRequest,
    updateExternalDataResponse
  );
  yield takeLatest(
    deleteExternalDataResponseRequest,
    deleteExternalDataResponse
  );
  yield takeLatest(getExternalDataResponseRequest, getExternalDataResponse);
  yield takeLatest(getExternalDataResponsesRequest, getExternalDataResponses);
}
