import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserFromAccountsRequest,
  getUserAccountsRequest,
  lockUserRequest,
  unlockUserRequest,
} from "redux/user/action";
import { MainContext } from "context/contexts";
import { ToastOptions } from "components/toastify";
import usePrevious from "utility/hooks/usePrevious";
import SubHeader from "components/SubHeader";
import ChangePasswordModal from "components/modals/userAccounts/ChangePassword";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as UnlockIcon } from "assets/icons/unlock.svg";
import { ReactComponent as KeyIcon } from "assets/icons/key.svg";
import { ReactComponent as UsersIcon } from "assets/icons/users.svg";
import { ReactComponent as UserPlusIcon } from "assets/icons/user-plus.svg";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { TableNoItems } from "utility/utility";
import { useTranslation } from "react-i18next";

const Accounts = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    userAccounts,
    isGetUserAccountsSuccess,
    removeUserFromAccountsSuccess,
    isChangeAccountPasswordSuccess,
    isChangeAccountPasswordFailure,
    removeUserFromAccountsFailure,
    isGetUserAccountsError,
    isLockUserSuccess,
    isLockUserFailure,
    isUnlockUserSuccess,
    isUnlockUserFailure,
  } = useSelector((state) => {
    return state.users;
  });

  const { user: currentUser } = useSelector((state) => state.auth);

  const prevIsGetUserAccountsSuccess = usePrevious(isGetUserAccountsSuccess);
  const prevIsGetUserAccountsError = usePrevious(isGetUserAccountsError);
  const prevRemoveUserFromAccountsFailure = usePrevious(
    removeUserFromAccountsFailure
  );
  const prevIsChangeAccountPasswordSuccess = usePrevious(
    isChangeAccountPasswordSuccess
  );
  const prevIsChangeAccountPasswordFailure = usePrevious(
    isChangeAccountPasswordFailure
  );
  const prevRemoveUserFromAccountsSuccess = usePrevious(
    removeUserFromAccountsSuccess
  );

  const prevIsLockUserSuccess = usePrevious(isLockUserSuccess);
  const prevIsLockUserFailure = usePrevious(isLockUserFailure);

  const prevIsUnlockUserSuccess = usePrevious(isUnlockUserSuccess);
  const prevIsUnlockUserFailure = usePrevious(isUnlockUserFailure);

  const [userAccountsData, setUserAccountsData] = useState([]);
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState(false);
  const [isUserChangePasswordModalOpen, setIsUserChangePasswordModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    document.title = t("users_decisimo");
    setIsLoading(true);
    dispatch(getUserAccountsRequest());
  }, []);

  useEffect(() => {
    if (
      removeUserFromAccountsSuccess &&
      prevRemoveUserFromAccountsSuccess === false
    ) {
      setIsLoading(false);
      setUserAccountsData(
        userAccountsData.filter(
          (account) => account.user_id !== selectedUser.user_id
        )
      );
      handleClose();
      toast.warning(t("user_account_deleted"), ToastOptions);
    }
  }, [removeUserFromAccountsSuccess]);

  useEffect(() => {
    if (
      (removeUserFromAccountsFailure &&
        prevRemoveUserFromAccountsFailure === false) ||
      (isChangeAccountPasswordFailure &&
        prevIsChangeAccountPasswordFailure === false) ||
      (isGetUserAccountsError && prevIsGetUserAccountsError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    removeUserFromAccountsFailure,
    isChangeAccountPasswordFailure,
    isGetUserAccountsError,
  ]);

  useEffect(() => {
    if (
      isChangeAccountPasswordSuccess &&
      prevIsChangeAccountPasswordSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("password_changed"), ToastOptions);
    }
  }, [isChangeAccountPasswordSuccess]);

  useEffect(() => {
    if (
      isChangeAccountPasswordFailure &&
      prevIsChangeAccountPasswordFailure === false
    ) {
      setIsLoading(false);
      //clean toasts
      toast.dismiss();
      toast.error(t("password_not_changed"), ToastOptions);
    }
  }, [isChangeAccountPasswordFailure]);

  useEffect(() => {
    if (isGetUserAccountsSuccess && prevIsGetUserAccountsSuccess === false) {
      setIsLoading(false);
      setUserAccountsData(userAccounts);
    }
  }, [isGetUserAccountsSuccess]);

  useEffect(() => {
    if (
      (isLockUserSuccess && prevIsLockUserSuccess === false) ||
      (isUnlockUserSuccess && prevIsUnlockUserSuccess === false)
    ) {
      setIsLoading(false);
      toast.success(
        isLockUserSuccess
          ? t("user_locked_success")
          : t("user_unlocked_success"),
        ToastOptions
      );
    }
  }, [isLockUserSuccess, isUnlockUserSuccess]);

  useEffect(() => {
    if (
      (isLockUserFailure && prevIsLockUserFailure === false) ||
      (isUnlockUserFailure && prevIsUnlockUserFailure === false)
    ) {
      setIsLoading(false);
      toast.error(
        isLockUserFailure ? t("user_lock_failed") : t("user_unlock_failed"),
        ToastOptions
      );
    }
  }, [isLockUserFailure, isUnlockUserFailure]);

  const handleShowDeleteModal = (selectedUserInfo) => {
    setSelectedUser(selectedUserInfo);
    setIsUserDeleteModalOpen(true);
  };

  const handleShowChangePasswordModal = (selectedUserInfo) => {
    setSelectedUser(selectedUserInfo);
    setIsUserChangePasswordModalOpen(true);
  };

  const handleLockUser = (userId) => {
    setIsLoading(true);
    dispatch(lockUserRequest(userId));
  };

  const handleUnlockUser = (userId) => {
    setIsLoading(true);
    dispatch(unlockUserRequest(userId));
  };

  const handleClose = () => {
    setIsUserDeleteModalOpen(false);
    setIsUserChangePasswordModalOpen(false);
    setTimeout(() => {
      setSelectedUser({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(deleteUserFromAccountsRequest(selectedUser.user_id));
  };

  return (
    <>
      <SubHeader
        title={t("user_accounts")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/user-account"
              className="btn primary-header border-radius-left-4"
            >
              <UserPlusIcon /> <span className="ml-2">{t("add_user")}</span>
            </Link>
            <div className="btn-group">
              <button
                className="btn dropdown-toggle dropdown-toggle-split primary border-radius-right-4"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="sr-only">{t("toggle_dropdown")}</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
                <Link to="/user-roles">
                  <button className="dropdown-item" type="button">
                    <UsersIcon />{" "}
                    <span className="ml-2">{t("role_management")}</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
      />
      <div className="mb-3">
        <table className="table table-hover table-fixed border-top">
          <thead className="border-top-0">
            <tr>
              <th className="th-flow col-lg-3">{t("full_name")}</th>
              <th className="th-flow col-lg-3">{t("email")}</th>
              <th className="th-flow function-col text-right" />
            </tr>
          </thead>
          <tbody>
            {userAccountsData?.length > 0
              ? userAccountsData.map((account) => {
                  return (
                    <tr
                      className={`td-flow ${
                        account.locked ? "text-danger" : ""
                      }`}
                      key={account.user_id}
                    >
                      <td>
                        <Link
                          to={`/user-accounts/${account.user_id}`}
                          title={
                            account.full_name +
                            " (" +
                            (account.locked ? t("locked") : "") +
                            ")"
                          }
                          className={`text-truncate ${
                            account.locked ? "text-danger" : ""
                          }`}
                        >
                          {account.locked ? (
                            <LockIcon
                              className="pb-2 pt-1"
                              title={t("locked")}
                            />
                          ) : null}
                          {account.full_name}
                        </Link>
                      </td>
                      <td>{account.email}</td>
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <div>
                          <button
                            type="button"
                            className="btn primary-text border-0 p-0 pb-1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <MoreVertical
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </button>

                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() =>
                                handleShowChangePasswordModal(account)
                              }
                            >
                              <KeyIcon /> {t("change_password")}
                            </button>
                            {currentUser.user_id !== account.user_id && (
                              <>
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() =>
                                    account.locked
                                      ? handleUnlockUser(account.user_id)
                                      : handleLockUser(account.user_id)
                                  }
                                >
                                  {account.locked ? (
                                    <UnlockIcon />
                                  ) : (
                                    <LockIcon />
                                  )}{" "}
                                  {account.locked
                                    ? t("unlock_user")
                                    : t("lock_user")}
                                </button>
                                <div className="dropdown-divider" />
                                <button
                                  className="dropdown-item"
                                  type="button"
                                  onClick={() => handleShowDeleteModal(account)}
                                >
                                  <TrashIcon /> {t("delete")}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetUserAccountsSuccess &&
                userAccounts?.length === 0 && <TableNoItems colspan={3} />}
          </tbody>
        </table>
      </div>

      <ChangePasswordModal
        userId={parseInt(selectedUser.user_id)}
        handleClose={handleClose}
        open={isUserChangePasswordModalOpen}
      />
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_user")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedUser.full_name}</b>{" "}
            {t("user")} ?
          </span>
        }
        open={isUserDeleteModalOpen}
      />
    </>
  );
};

export default Accounts;
