import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startBatchTestRequest } from "redux/releases/action";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  addPendingReleaseTests,
  clearReleaseResultsFromDB,
  clearPendingReleaseTests,
  getAllDatasets,
  startReleaseTestWithDataset,
} from "utility/BatchTestDB";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import DatasetManager from "./DatasetManager";
import DatasetDropdown from "./DatasetDropdown";
import "./BatchTest.css";

const BatchTestUploader = ({ releaseId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isBatchTestSuccess, isBatchTestError } = useSelector(
    (state) => state.releases
  );

  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showDatasetCreator, setShowDatasetCreator] = useState(false);
  const [showDatasetManager, setShowDatasetManager] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [showDatasetsList, setShowDatasetsList] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });
  const dropdownButtonRef = useRef(null);

  const prevIsBatchTestSuccess = usePrevious(isBatchTestSuccess);
  const prevIsBatchTestError = usePrevious(isBatchTestError);

  // Load available datasets on mount
  useEffect(() => {
    loadDatasets();
  }, []);

  const loadDatasets = async () => {
    try {
      const data = await getAllDatasets();
      setDatasets(data);
    } catch (error) {
      console.error("Error loading datasets:", error);
    }
  };

  // Parse a file line; show error toast if invalid
  const processLine = (line, index) => {
    try {
      return JSON.parse(line);
    } catch (err) {
      toast.error(
        t("invalid_json_on_line_x", { line: index + 1 }),
        ToastOptions
      );
      return null;
    }
  };

  // File upload: read file, process valid lines, clear previous tests,
  // add tests into pendingReleaseTests, then dispatch a startBatchTestRequest.
  const handleUpload = async () => {
    if (!file) {
      toast.error(t("please_select_file"), ToastOptions);
      return;
    }
    setIsProcessing(true);
    try {
      const content = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsText(file);
      });
      const lines = content.split("\n").filter((line) => line.trim() !== "");
      const validTests = lines
        .map((line, index) => processLine(line, index))
        .filter((item) => item !== null);
      const totalTests = validTests.length;
      if (totalTests === 0) {
        toast.error(t("no_valid_tests_found"), ToastOptions);
        setIsProcessing(false);
        return;
      }
      // Clear previous tests/results before starting a new test batch
      await clearReleaseResultsFromDB(releaseId);
      await clearPendingReleaseTests(releaseId);
      await addPendingReleaseTests(releaseId, null, validTests);
      toast.success(
        t("successfully_queued_for_processing", { totalTests }),
        ToastOptions
      );
      dispatch(startBatchTestRequest({ releaseId, totalCount: totalTests }));
    } catch (error) {
      toast.error(t("error_processing_file"), ToastOptions);
    } finally {
      setIsProcessing(false);
    }
  };

  // Use a dataset: clear previous tests/results, then queue tests from dataset items.
  const handleUseDataset = async (dataset) => {
    setShowDatasetsList(false);
    setIsProcessing(true);
    try {
      await clearReleaseResultsFromDB(releaseId);
      await clearPendingReleaseTests(releaseId);
      const totalTests = await startReleaseTestWithDataset(
        releaseId,
        dataset.id
      );
      toast.success(
        t("dataset_queued_for_processing", {
          name: dataset.name,
          count: totalTests,
        }),
        ToastOptions
      );
      dispatch(startBatchTestRequest({ releaseId, totalCount: totalTests }));
    } catch (error) {
      console.error("Error starting batch test with dataset:", error);
      toast.error(t("error_processing_dataset"), ToastOptions);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDatasetCreated = async () => {
    await loadDatasets();
    setShowDatasetCreator(false);
    toast.success(t("dataset_created"), ToastOptions);
  };

  const handleManageDatasets = () => {
    setShowDatasetManager(true);
  };

  useEffect(() => {
    if (prevIsBatchTestSuccess === false && isBatchTestSuccess) {
      toast.success(t("batch_test_completed_successfully"), ToastOptions);
    }
  }, [isBatchTestSuccess, prevIsBatchTestSuccess, t]);

  useEffect(() => {
    if (prevIsBatchTestError === false && isBatchTestError) {
      toast.error(t("batch_test_failed"), ToastOptions);
    }
  }, [isBatchTestError, prevIsBatchTestError, t]);

  const handleToggleDatasetList = () => {
    if (dropdownButtonRef.current) {
      const rect = dropdownButtonRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom,
        left: rect.left,
        width: rect.width,
      });
    }
    setShowDatasetsList(!showDatasetsList);
  };

  // If the user is managing datasets, show the DatasetManager view.
  if (showDatasetManager) {
    return (
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5 className="h6">{t("manage_datasets")}</h5>
          <div className="header-action-btn">
            <button
              className="btn outline"
              onClick={() => {
                setShowDatasetManager(false);
                loadDatasets();
              }}
            >
              {t("back_to_data_selection")}
            </button>
          </div>
        </div>
        <div className="input-card-body">
          <DatasetManager
            onSelectDataset={handleUseDataset}
            onDatasetCreated={handleDatasetCreated}
            hideList={false}
            autoShowForm={false}
          />
        </div>
      </div>
    );
  }

  // If the user is creating a new dataset, show the creation view.
  if (showDatasetCreator) {
    return (
      <div className="batch-test-container">
        <div className="dataset-creator">
          <div className="dataset-creator-header">
            <h5>{t("create_new_dataset")}</h5>
            <button
              className="btn outline"
              onClick={() => setShowDatasetCreator(false)}
            >
              {t("back_to_options")}
            </button>
          </div>
          <DatasetManager
            onSelectDataset={handleUseDataset}
            onDatasetCreated={handleDatasetCreated}
            hideList={true}
            autoShowForm={true}
          />
        </div>
      </div>
    );
  }

  // Main upload view: file upload and dataset selection.
  return (
    <div className="card mb-4">
      <div className="card-header">
        <h4 className="mb-0 h6">{t("batch_test_input")}</h4>
      </div>
      <div className="card-body">
        <div className="row">
          {/* File Upload Card */}
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-header bg-light d-flex align-items-center">
                <h5 className="mb-0 ms-2 h6">{t("upload_file")}</h5>
              </div>
              <div className="card-body">
                <p className="text-muted small mb-3">
                  {t("upload_file_description")}
                </p>
                <input
                  type="file"
                  accept=".json,.jsonl,.txt"
                  onChange={(e) => setFile(e.target.files[0])}
                  disabled={isProcessing}
                  className="form-control mb-3"
                />
                <button
                  onClick={handleUpload}
                  className="btn primary w-100"
                  disabled={isProcessing || !file}
                >
                  {isProcessing ? t("processing") : t("start_batch_test")}
                </button>
              </div>
            </div>
          </div>

          {/* Dataset Card */}
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-header bg-light d-flex align-items-center">
                <h5 className="mb-0 ms-2 h6">{t("use_dataset")}</h5>
              </div>
              <div className="card-body">
                <p className="text-muted small mb-3">
                  {t("use_dataset_description")}
                </p>
                <DatasetDropdown
                  datasets={datasets}
                  onSelect={handleUseDataset}
                  onManageDatasets={handleManageDatasets}
                  disabled={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BatchTestUploader.propTypes = {
  releaseId: PropTypes.string.isRequired,
};

export default BatchTestUploader;
