import AutoSuggestInput from "../autosuggest";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as GripVertical } from "assets/icons/grip-vertical.svg";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import CustomSelect from "../CustomSelect";

const column = {
  attribute_path: "",
  title: "",
};

const TableColumn = ({
  col,
  vectorsData,
  handleShowDeleteColumnModal,
  setCols,
  isLast,
}) => {
  const { t } = useTranslation();

  const [role, setRole] = useState(col.role);
  const [title, setTitle] = useState(col?.title || "");

  const handleChangeRole = (e) => {
    setRole(e.value);
  };

  const roles = [
    {
      value: "condition",
      label: t("condition"),
    },
    {
      value: "result",
      label: t("action_result"),
    },
  ];

  const getSelectValue = () => {
    return roles.find((data) => data.value == role);
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: col.decision_table_column_id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleChange = (e, setData, id, isAutoSuggest = false) => {
    if (isLast) {
      setCols((prevState) => {
        const prevStateParam = prevState.find(
          (param) => param.decision_table_column_id === id
        );
        prevStateParam.isDefault = false;

        const columnCopy = structuredClone(column);
        columnCopy.decision_table_column_id = `new_${Math.round(
          Math.random() * 10000
        )}`;
        columnCopy.is_new = true;
        columnCopy.role = "condition";

        return [...prevState, columnCopy];
      });
    }

    !isAutoSuggest && setData(e.target.value);
  };

  return (
    <tr
      className={isLast ? "bg-light" : "bg-white"}
      ref={setNodeRef}
      style={style}
      {...attributes}
      id={`column-${col.decision_table_column_id}`}
    >
      {isLast ? (
        <td />
      ) : (
        <td {...listeners}>
          <GripVertical className="cursor-move" />
        </td>
      )}
      <td className={isLast ? "bg-light" : "bg-white"}>
        <input
          className="form-control form-control-sm form-control-plaintext min-column-width-200 w-100"
          type="text"
          name={
            isLast ? null : `column[${col.decision_table_column_id}][title]`
          }
          value={title || ""} // Ensure value is never undefined
          onChange={(e) =>
            handleChange(e, setTitle, col.decision_table_column_id)
          }
          placeholder={t("some_column_title")}
        />
      </td>
      <td className={isLast ? "bg-light" : "bg-white"}>
        <AutoSuggestInput
          vectorsData={vectorsData}
          defaultValue={col.attribute_path || ""}
          tableMode={true}
          onInputChange={(e) => {
            handleChange(e, () => {}, col.decision_table_column_id, true);
          }}
          isColumn={true}
          inputName={
            !isLast
              ? `column[${col.decision_table_column_id}][attribute_path]`
              : null
          }
        />
      </td>
      <td className={isLast ? "bg-light" : "bg-white"}>
        <CustomSelect
          defaultValue={getSelectValue}
          options={roles}
          key={role}
          handleChange={handleChangeRole}
          isSearchable={false}
        />
        <input
          type="hidden"
          name={
            !isLast ? `column[${col.decision_table_column_id}][role]` : null
          }
          value={role}
        />
      </td>
      {!isLast ? (
        <td
          title="Delete column"
          className="clickable text-center vertical-align-middle"
          onClick={() => handleShowDeleteColumnModal(col)}
        >
          <DeleteIcon />
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
};

TableColumn.propTypes = {
  col: PropTypes.object,
  vectorsData: PropTypes.array,
  handleShowDeleteColumnModal: PropTypes.func,
  setCols: PropTypes.func,
  isLast: PropTypes.bool,
};

export default TableColumn;
