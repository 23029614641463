import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import Rule from "../../rulesets/rule";
//css
import "./ChatModal.css";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { getRuleSetChatRequest } from "redux/rulesets/action";
import { useDispatch, useSelector } from "react-redux";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import showdown from "showdown";

import { useTranslation } from "react-i18next";

const ChatModal = ({
  open,
  handleClose,
  conditionTypesData,
  onAddRule,
  ruleSetId,
  ruleSet,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const chatContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [conversationId, setConversationId] = useState(null);

  //toast

  const {
    isGetRuleSetChatSuccess,
    chatResponse,
    isGetRuleSetChatError,
    chatConversationId,
  } = useSelector((state) => state.ruleSets);

  const prevIsGetRuleSetChatSuccess = usePrevious(isGetRuleSetChatSuccess);
  const prevIsGetRuleSetChatError = usePrevious(isGetRuleSetChatError);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!inputText.trim()) return;

    const userMsg = { sender: "user", text: inputText.trim() };
    setMessages([...messages, userMsg]);
    setInputText("");
    setIsChatLoading(true);
    dispatch(
      getRuleSetChatRequest({
        rule_set_id: ruleSetId,
        message: inputText.trim(),
        conversation_id: conversationId,
      })
    );
  };

  const handleAddRule = (rule) => {
    if (onAddRule) {
      onAddRule(rule);
    } else {
      console.log("Add rule to ruleset:", rule);
    }
  };

  useEffect(() => {
    if (chatConversationId) {
      setConversationId(chatConversationId);
    }
  }, [chatConversationId]);

  useEffect(() => {
    if (isGetRuleSetChatSuccess && prevIsGetRuleSetChatSuccess == false) {
      let answer = chatResponse.answer;

      if (answer) {
        let aiMsg = {
          sender: "ai",
          explanation: answer.explanation,
          rules: answer.rules,
          closing_remarks: answer?.closing_remarks,
        };

        setMessages([...messages, aiMsg]);
      }

      setIsChatLoading(false);
    }
  }, [isGetRuleSetChatSuccess]);

  //toasting errors
  useEffect(() => {
    if (isGetRuleSetChatError && prevIsGetRuleSetChatError == false) {
      toast.error(t("failed_to_get_chat_response"), ToastOptions);
      setIsChatLoading(false);
    }
  }, [isGetRuleSetChatError]);

  const handleTextAreaInput = (e) => {
    setInputText(e.target.value);
    setTextAreaHeight("auto");
    setTextAreaHeight(`${e.target.scrollHeight}px`);
  };

  const handleKeyPress = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter" && !isChatLoading) {
      handleSubmit(e);
    }
    // pop up the toast if the user tries to send an empty message
    if (
      (e.ctrlKey || e.metaKey) &&
      e.key === "Enter" &&
      !inputText.trim() &&
      !isChatLoading
    ) {
      toast.error(t("please_enter_a_message"), ToastOptions);
    }
    // pop up the toast if the user tries to send a message while the chat is loading
    if ((e.ctrlKey || e.metaKey) && e.key === "Enter" && isChatLoading) {
      toast.error(t("chat_is_loading"), ToastOptions);
    }
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isChatLoading]);

  const renderMarkdown = (markdownText) => {
    const converter = new showdown.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      literalMidWordUnderscores: true,
      tasklists: true,
      breaks: true,
      simpleLineBreaks: true,
    });

    const html = converter.makeHtml(markdownText);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  return (
    <Modal show={open} onHide={handleClose} size="xl">
      <Modal.Header className="border-bottom-0">
        <h5 className="modal-title">AI Assistant</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body className="p-0">
        {/* Chat container */}
        <div
          ref={chatContainerRef}
          className="chat-container bg-light p-3"
          style={{ height: "60vh", overflowY: "auto" }}
        >
          {messages.map((msg, idx) =>
            msg.sender === "user" ? (
              <div
                key={`message-${idx}`}
                className="d-flex justify-content-end mb-3"
              >
                <div className="message user-message">
                  <div className="message-content p-3 shadow-sm">
                    {renderMarkdown(msg.text)}
                  </div>
                  <small className="text-muted mt-1 d-block text-end">
                    {new Date().toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </small>
                </div>
              </div>
            ) : (
              <div key={`message-${idx}`} className="d-flex mb-3">
                <div className="message ai-message">
                  <div className="message-content p-3 shadow-sm">
                    <div className="mb-2">
                      {renderMarkdown(msg.explanation)}
                    </div>
                    {msg.rules &&
                      msg.rules.map((rule, ruleIdx) => {
                        // Process nested conditions before rendering
                        let processedRule = { ...rule };
                        if (processedRule.condition) {
                          // Find all group conditions
                          const groupConditions =
                            processedRule.condition.filter(
                              (c) => c.condition_type_name === "group"
                            );

                          // For each group condition, find its children and nest them
                          groupConditions.forEach((groupCond) => {
                            // Find all conditions that have this group as parent
                            const children = processedRule.condition.filter(
                              (c) =>
                                c.parent_condition_id ===
                                groupCond.rule_condition_id
                            );

                            // Add children to the group condition
                            groupCond.children = children;
                          });

                          // Filter out the child conditions from the top level
                          processedRule.condition =
                            processedRule.condition.filter(
                              (c) => !c.parent_condition_id
                            );
                        }

                        const isExistingRule = ruleSet?.rules?.some(
                          (existingRule) => existingRule.rule_id == rule.rule_id
                        );

                        return (
                          <div
                            key={rule.rule_id}
                            className="border-top pt-3 mt-3"
                          >
                            <div
                              className="accordion"
                              id={`rules-${rule.rule_id}`}
                            >
                              <div className="cursor-auto">
                                <Rule
                                  key={rule.rule_id}
                                  id={rule.rule_id}
                                  rule={{
                                    ...processedRule,
                                    defaultExpanded: true,
                                  }}
                                  setRuleSet={handleAddRule}
                                  index={ruleIdx}
                                  conditionTypesData={conditionTypesData}
                                  importMode={true}
                                  revisionMode={true}
                                />
                              </div>
                            </div>
                            <button
                              className="mt-2 outline btn "
                              onClick={() => handleAddRule(processedRule)}
                            >
                              <PlusIcon className="mr-2" />
                              {isExistingRule
                                ? t("update_rule")
                                : t("add_rule")}
                            </button>
                          </div>
                        );
                      })}
                    {msg.closing_remarks && (
                      <div className="mt-3">{msg.closing_remarks}</div>
                    )}
                  </div>
                  <small className="text-muted mt-1 d-block">
                    {new Date().toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </small>
                </div>
              </div>
            )
          )}
          {isChatLoading && (
            <div className="d-flex mb-3">
              <div className="message ai-message">
                <div className="message-content bg-white p-3 rounded-3 shadow-sm">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0 bg-light">
        <Form onSubmit={handleSubmit} className="d-flex w-100">
          <Form.Control
            as="textarea"
            rows={1}
            placeholder={t("type_your_message_ctrl_enter_to_send")}
            value={inputText}
            onChange={handleTextAreaInput}
            onKeyDown={handleKeyPress}
            className="me-2 p-1"
            style={{
              minHeight: "40px",
              maxHeight: "120px",
              height: textAreaHeight,
              resize: "none",
              padding: "8px 16px",
              overflowY: "auto",
            }}
          />
          <Button
            type="submit"
            variant=""
            className="btn outline ml-2"
            disabled={isChatLoading}
            style={{
              width: "40px",
              height: "40px",
              padding: "0",
              verticalAlign: "middle",
            }}
          >
            <SendIcon />
          </Button>
        </Form>
      </Modal.Footer>
    </Modal>
  );
};

ChatModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  conditionTypesData: PropTypes.array,
  onAddRule: PropTypes.func,
  ruleSetId: PropTypes.any,
  ruleSet: PropTypes.object,
};

export default ChatModal;
