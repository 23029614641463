import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

const ImpactAnalyzerProgress = () => {
  const { isExecutingImpactScenario, impactScenarioResults } = useSelector(
    (state) => state.impactAnalyzer
  );
  const { t } = useTranslation();

  if (!isExecutingImpactScenario) return null;

  // Ensure completed never exceeds total for display purposes
  const displayCompleted = Math.min(
    impactScenarioResults.completed,
    impactScenarioResults.total
  );

  // Cap progress percentage at 100%
  const progressPercentage = Math.min(
    ((displayCompleted / impactScenarioResults.total) * 100).toFixed(2),
    100
  );

  return (
    <div className="text-center py-5">
      <Spinner animation="border" />
      <p className="mt-3">
        {t("processing")} {displayCompleted} / {impactScenarioResults.total}
      </p>
      <div className="progress mt-2">
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${progressPercentage}%`,
          }}
          aria-valuenow={displayCompleted}
          aria-valuemin="0"
          aria-valuemax={impactScenarioResults.total}
        >
          {progressPercentage}%
        </div>
      </div>
    </div>
  );
};

export default ImpactAnalyzerProgress;
