import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { useTranslation } from "react-i18next";

const BinInput = ({
  scorecardPredictorId,
  bin,
  handleBinChange,
  isRevision = false,
}) => {
  const [valueA, setValueA] = useState(bin.value_a);
  const [valueB, setValueB] = useState(bin.value_b);

  const { t } = useTranslation();

  useEffect(() => {
    setValueA(bin.value_a);
    setValueB(bin.value_b);
  }, [bin]);

  const handleValueChange = (newValue, key) => {
    const isNumberRequired = [
      "greater_than",
      "greater_than_or_equal_to",
      "less_than",
      "less_than_or_equal_to",
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(bin.operator);

    if (isNumberRequired) {
      const reg = /^-?\d*(\.\d*)?$/;
      if (!reg.test(newValue) && newValue !== "") {
        return; // Prevent non-numeric input
      }
    }

    if (key === "value_a") {
      setValueA(newValue);
    } else if (key === "value_b") {
      setValueB(newValue);
    }

    handleBinChange(scorecardPredictorId, bin.scorecard_bin_id, {
      ...bin,
      [key]: newValue,
    });
  };

  const handleBlur = (e, key) => {
    const value = parseFloat(e.target.value);
    const isNumberRequired = [
      "greater_than",
      "greater_than_or_equal_to",
      "less_than",
      "less_than_or_equal_to",
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(bin.operator);

    if (!isNaN(value) || !isNumberRequired) {
      handleValueChange(isNaN(value) ? e.target.value : value.toString(), key);
    } else {
      handleValueChange("", key);
    }
  };

  const handleOperatorChange = (newOperator) => {
    handleBinChange(scorecardPredictorId, bin.scorecard_bin_id, {
      ...bin,
      operator: newOperator,
    });
  };

  const operators = [
    "greater_than",
    "greater_than_or_equal_to",
    "less_than",
    "less_than_or_equal_to",
    "equal_to",
    "not_equal_to",
    "closed_interval",
    "open_interval",
    "left_closed_right_open",
    "left_open_right_closed",
    "any_value",
    "is_null",
    "starts_with",
    "ends_with",
  ];

  const renderCondition = (text) => (
    <ContextMenuTrigger id={`bin-${bin.scorecard_bin_id}`} mouseButton={2}>
      <span className="input-group-text border-0 rounded-0 m-0">{text}</span>
    </ContextMenuTrigger>
  );

  const operatorSymbols = {
    greater_than: ">",
    greater_than_or_equal_to: "≥",
    less_than: "<",
    less_than_or_equal_to: "≤",
    equal_to: "=",
    not_equal_to: "≠",
    closed_interval: "[",
    open_interval: "(",
    left_closed_right_open: "[",
    left_open_right_closed: "(",
    is_null: "∅",
    starts_with: "^",
    ends_with: "$",
  };
  const renderBinInput = () => {
    const isInterval = [
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(bin.operator);

    const isNumberInput = [
      "greater_than",
      "greater_than_or_equal_to",
      "less_than",
      "less_than_or_equal_to",
      "closed_interval",
      "open_interval",
      "left_closed_right_open",
      "left_open_right_closed",
    ].includes(bin.operator);

    const renderInputField = (value, key) => (
      <input
        type="text"
        className="form-control border-0 m-0 border-0 rounded-0 pl-1 pr-0"
        value={value || ""}
        disabled={isRevision ? "disabled" : ""}
        onChange={(e) => handleValueChange(e.target.value, key)}
        onBlur={(e) => handleBlur(e, key)}
        inputMode={isNumberInput ? "numeric" : "text"}
      />
    );

    return bin.operator === "any_value" || bin.operator === "is_null" ? (
      <div className="text-center align-middle any_value w-100 outline-none border-0 rounded-0">
        {t(bin.operator)}
      </div>
    ) : bin.operator === "ends_with" ? (
      <div className="input-group d-flex justify-content-between border-0 rounded-0 scorecard-bin">
        {renderInputField(valueA, "value_a")}
        {renderCondition(operatorSymbols[bin.operator] || "")}
      </div>
    ) : (
      <div className="input-group d-flex justify-content-between border-0 rounded-0 scorecard-bin">
        {renderCondition(operatorSymbols[bin.operator] || "")}
        {renderInputField(valueA, "value_a")}
        {isInterval && (
          <>
            <span className="input-group-text border-0 rounded-0">,</span>
            {renderInputField(valueB, "value_b")}
            <span className="input-group-text border-0 rounded-0">
              {bin.operator === "open_interval" ||
              bin.operator === "left_closed_right_open"
                ? ")"
                : "]"}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderContextMenu = () =>
    !isRevision && (
      <ContextMenu id={`bin-${bin.scorecard_bin_id}`}>
        {operators.map((op) => (
          <MenuItem key={op} onClick={() => handleOperatorChange(op)}>
            {op === "starts_with"
              ? t("starts_with")
              : op === "ends_with"
              ? t("ends_with")
              : t(op)}
          </MenuItem>
        ))}
      </ContextMenu>
    );

  return (
    <div>
      <ContextMenuTrigger id={`bin-${bin.scorecard_bin_id}`} holdToDisplay={-1}>
        {renderBinInput()}
      </ContextMenuTrigger>
      {renderContextMenu()}
    </div>
  );
};

BinInput.propTypes = {
  bin: PropTypes.shape({
    scorecard_bin_id: PropTypes.string,
    value_a: PropTypes.string,
    value_b: PropTypes.string,
    operator: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  scorecardPredictorId: PropTypes.string,
  handleBinChange: PropTypes.func.isRequired,
  isRevision: PropTypes.bool,
};

export default BinInput;
