import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { MainContext } from "context/contexts";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import SubHeader from "components/SubHeader";
import Revisions from "components/modals/decisionTables/Revisions";
import { useTranslation } from "react-i18next";
import { compareRulesets } from "./compareRulesets";
import "./RulesetRevisionComparison.css";

import {
  getRuleRevisionRequest,
  getRuleRevision2Request,
  getRuleRevisionsRequest,
  getConditionTypesRequest,
} from "redux/rulesets/action";

// Import the new diff-aware rule component
import RuleCompared from "./RuleCompared";

/**
 * Helper to render a top‑level diff field.
 * If the old value differs from the new, the old value appears in red
 * (using the "deleted" class) and the new in green (using the "added" class).
 */
function renderDiffField(oldVal, newVal) {
  if (String(oldVal) === String(newVal)) return <span>{newVal}</span>;
  return (
    <>
      <span className="deleted">{oldVal || "''"}</span>
      <span className="added">{newVal || "''"}</span>
    </>
  );
}

const RulesetRevisionComparison = () => {
  const { setIsLoading } = useContext(MainContext);
  const { id, revisionId, revisionId2 } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    ruleRevision,
    ruleRevision2,
    isGetRuleRevisionSuccess,
    isGetRuleRevision2Success,
    isGetRuleRevisionError,
    isGetRuleRevision2Error,
    isGetConditionTypesSuccess,
    isGetConditionTypesError,
    revisions,
    conditionTypes,
  } = useSelector((state) => state.ruleSets);

  const [comparison, setComparison] = useState(null);
  const [isRevisionsModalOpen, setIsRevisionsModalOpen] = useState(false);
  const [conditionTypesData, setConditionTypesData] = useState([]);

  useEffect(() => {
    document.title = t("compare_ruleset_revisions");
  }, [t]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getRuleRevisionRequest([id, revisionId]));
    dispatch(getRuleRevision2Request([id, revisionId2]));
    dispatch(getRuleRevisionsRequest(parseInt(id)));
    dispatch(getConditionTypesRequest(id));
  }, [dispatch, id, revisionId, revisionId2, setIsLoading]);

  useEffect(() => {
    if (
      (isGetRuleRevisionError || isGetRuleRevision2Error) &&
      !(isGetRuleRevisionSuccess && isGetRuleRevision2Success)
    ) {
      setIsLoading(false);
    }
    if (
      isGetRuleRevisionSuccess &&
      isGetRuleRevision2Success &&
      ruleRevision &&
      ruleRevision2
    ) {
      setIsLoading(false);
      const result = compareRulesets(ruleRevision, ruleRevision2);
      setComparison(result);
    }
  }, [
    isGetRuleRevisionSuccess,
    isGetRuleRevision2Success,
    isGetRuleRevisionError,
    isGetRuleRevision2Error,
    ruleRevision,
    ruleRevision2,
    setIsLoading,
  ]);

  const handleShowRevisionsModal = () => {
    setIsLoading(true);
    dispatch(getRuleRevisionsRequest(parseInt(id)));
    setIsRevisionsModalOpen(true);
  };

  const handleCloseRevisions = () => {
    setIsRevisionsModalOpen(false);
  };

  useEffect(() => {
    if (isGetConditionTypesSuccess) {
      setConditionTypesData(conditionTypes);

      // If the condition types are loaded, we can now show the page
      setIsLoading(false);
    }
  }, [isGetConditionTypesSuccess, conditionTypes, setIsLoading]);

  if (!isGetRuleRevisionSuccess || !isGetRuleRevision2Success) {
    return (
      <div className="align-items-center col d-flex justify-content-center">
        <span>{t("loading")}...</span>
      </div>
    );
  }
  if (!comparison) return null;

  const { topChanges, rules } = comparison;

  return (
    <>
      <SubHeader
        title={t("compare_ruleset_revisions")}
        actions={
          <Link to={`/rule-sets/${id}`} className="mr-2">
            <button className="btn outline" title={t("back_to_ruleset")}>
              <BackIcon />
            </button>
          </Link>
        }
      />

      <div className="comparison-table-container p-4">
        {topChanges.length > 0 && (
          <div className="mb-4">
            <h3>{t("top_level_changes")}</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>{t("property")}</th>
                  <th>{t("from")}</th>
                  <th>{t("to")}</th>
                </tr>
              </thead>
              <tbody>
                {topChanges.map((change, i) => (
                  <tr key={i}>
                    <td>{change.field}</td>
                    <td>{renderDiffField(change.oldValue, change.newValue)}</td>
                    <td>{renderDiffField(change.oldValue, change.newValue)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <h3>{t("rule_changes")}</h3>
        <div className="rule-changes-container">
          {rules.map((r, index) => {
            // Determine the rule ID based on status: use newRule.rule_id for modified/added;
            // for deleted, use oldRule.rule_id.
            const ruleId =
              r.ruleStatus === "deleted"
                ? r.oldRule.rule_id
                : r.newRule.rule_id;

            let ruleContent = null;
            if (r.ruleStatus === "modified") {
              ruleContent = (
                <RuleCompared
                  originalRule={r.oldRule}
                  newRule={r.newRule}
                  conditionTypesData={conditionTypesData}
                  revisionMode={true}
                  id={r.newRule.rule_id}
                  ruleSetId={id}
                />
              );
            } else if (r.ruleStatus === "added") {
              ruleContent = (
                <>
                  <div className="status-badge added-badge">[ADDED]</div>
                  <RuleCompared
                    originalRule={{}}
                    newRule={r.newRule}
                    conditionTypesData={conditionTypesData}
                    revisionMode={true}
                    id={r.newRule.rule_id}
                    ruleSetId={id}
                  />
                </>
              );
            } else if (r.ruleStatus === "deleted") {
              ruleContent = (
                <>
                  <div className="status-badge deleted-badge">[DELETED]</div>
                  <RuleCompared
                    originalRule={r.oldRule}
                    newRule={{}}
                    conditionTypesData={conditionTypesData}
                    revisionMode={true}
                    id={r.oldRule.rule_id}
                    ruleSetId={id}
                  />
                </>
              );
            } else {
              ruleContent = (
                <RuleCompared
                  originalRule={r.oldRule}
                  newRule={r.newRule}
                  conditionTypesData={conditionTypesData}
                  revisionMode={true}
                  id={r.newRule.rule_id}
                  ruleSetId={id}
                />
              );
            }

            // Wrap each rule change inside the "rule envelope"
            return (
              <div
                key={ruleId}
                className={`accordion ${!id ? "pt-5" : ""} ui-sortable`}
                id={`rules-${ruleId}`}
              >
                <div className="cursor-auto">{ruleContent}</div>
              </div>
            );
          })}
        </div>
      </div>

      <Revisions
        handleClose={handleCloseRevisions}
        revisionsData={revisions}
        linkTo="rule-sets"
        open={isRevisionsModalOpen}
        elemId={parseInt(id)}
        leftRevisionId={parseInt(revisionId)}
        rightRevisionId={parseInt(revisionId2)}
      />
    </>
  );
};

export default RulesetRevisionComparison;
