import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDeploymentRequest,
  getDeploymentsRequest,
} from "redux/deployments/action";
import { ToastOptions } from "components/toastify";
import { toast } from "react-toastify";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "components/modals/DeleteConfirm";
import usePrevious from "utility/hooks/usePrevious";
import { MainContext } from "context/contexts";
import { sortByTimeDesc, TableNoItems } from "utility/utility";
import Pagination from "utility/Pagination";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";

const Deployments = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetDeploymentsSuccess,
    deployments,
    deploymentsCount,
    isDeletedDeploymentSuccess,
    isGetDeploymentsError,
    isDeletedDeploymentError,
  } = useSelector((state) => state.deployments);

  const prevIsDeletedDeploymentSuccess = usePrevious(
    isDeletedDeploymentSuccess
  );
  const prevIsGetDeploymentsSuccess = usePrevious(isGetDeploymentsSuccess);
  const prevIsGetDeploymentsError = usePrevious(isGetDeploymentsError);
  const prevIsDeletedDeploymentError = usePrevious(isDeletedDeploymentError);

  const [deploymentsClone, setDeploymentsClone] = useState([]);
  const [selectedDeployment, setSelectedDeployment] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [isDeploymentDeleteModalOpen, setIsDeploymentDeleteModalOpen] =
    useState(false);

  useEffect(() => {
    document.title = `${t("nav_bar_deployments")} - Decisimo`;
  }, []);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(getDeploymentsRequest(currentPage.toString()));
    }
  }, [currentPage]);

  useEffect(() => {
    if (isGetDeploymentsSuccess && prevIsGetDeploymentsSuccess === false) {
      setIsLoading(false);
      const deploymentsClone = structuredClone(deployments);
      deploymentsClone?.sort(sortByTimeDesc);
      setDeploymentsClone(deploymentsClone);
    }
  }, [isGetDeploymentsSuccess]);

  useEffect(() => {
    if (
      (isGetDeploymentsError && prevIsGetDeploymentsError === false) ||
      (isDeletedDeploymentError && prevIsDeletedDeploymentError === false)
    ) {
      setIsLoading(false);
    }
  }, [isGetDeploymentsError, isDeletedDeploymentError]);

  useEffect(() => {
    if (
      isDeletedDeploymentSuccess &&
      prevIsDeletedDeploymentSuccess === false
    ) {
      setIsLoading(false);
      setDeploymentsClone(
        deploymentsClone.filter(
          (deployment) =>
            deployment.wf_release_deployment_id !==
            selectedDeployment.wf_release_deployment_id
        )
      );
      handleClose();
      toast.warning(t("deployment_deleted"), ToastOptions);
    }
  }, [isDeletedDeploymentSuccess]);

  const handleShowDeleteModal = (deployment) => {
    setSelectedDeployment(deployment);
    setIsDeploymentDeleteModalOpen(true);
  };

  const handleClose = () => {
    setIsDeploymentDeleteModalOpen(false);
    setTimeout(() => {
      setSelectedDeployment({});
    }, 100);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    dispatch(
      deleteDeploymentRequest(selectedDeployment.wf_release_deployment_id)
    );
    setIsDeploymentDeleteModalOpen(false);
  };

  const handleChangePage = (isPrev = false) => {
    setCurrentPage(isPrev ? currentPage - 1 : currentPage + 1);
  };

  return (
    <>
      <SubHeader title={t("deployment_log")} actions="" />
      <div className=" mb-3">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0 ">
            <tr>
              <th scope="col" className="th-flow col-lg-3 py-3 ">
                {t("deployment")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 ">
                {t("decision_flow")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 ">
                {t("user_deployed")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 ">
                {t("deployment_finished")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 ">
                {t("nav_bar_endpoint")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 " />
            </tr>
          </thead>
          <tbody className="py-4">
            {deploymentsClone?.length > 0
              ? deploymentsClone.map((deployment) => {
                  return (
                    <tr
                      key={deployment.wf_release_deployment_id}
                      className="td-flow "
                    >
                      <td>
                        <Link
                          title={deployment.deployed_endpoint_name}
                          to={`/deployments/${deployment.wf_release_deployment_id}`}
                        >
                          {deployment.name}
                        </Link>
                      </td>
                      <td className="">
                        {!deployment.workflow_title ? (
                          <em>deleted</em>
                        ) : (
                          <Link
                            to={`/workflows/${deployment.workflow_id}`}
                            className="function-item"
                          >
                            {deployment.workflow_title}
                          </Link>
                        )}
                      </td>
                      <td className={`${!deployment.full_name ? "pl-4" : ""}`}>
                        {deployment.full_name ? (
                          deployment.full_name
                        ) : (
                          <em>deleted</em>
                        )}
                      </td>
                      <td className="">
                        {!deployment.dtime_deployment_finished
                          ? "null"
                          : deployment.dtime_deployment_finished}
                      </td>
                      <td className="">
                        {!deployment.endpoint_name ? (
                          deployment.deployed_endpoint_name
                        ) : (
                          <Link
                            to={`/api-endpoints/${deployment.endpoint_placeholder_id}`}
                            className="function-item"
                          >
                            {deployment.endpoint_name}
                          </Link>
                        )}
                      </td>
                      <td className="d-flex align-items-center justify-content-end border-0">
                        <button
                          title={t("delete_deployment")}
                          type="button"
                          className="btn primary-text border-0 p-0 pb-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <MoreVertical
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </button>
                        <div
                          className="dropdown-menu "
                          aria-labelledby="dropdownMenuLink"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => handleShowDeleteModal(deployment)}
                          >
                            <TrashIcon /> {t("delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              : isGetDeploymentsSuccess &&
                deployments?.length === 0 && <TableNoItems colspan={6} />}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        completeItemsCount={deploymentsCount}
        itemsPerPage={20}
        setCurrentPage={setCurrentPage}
      />
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("deployment_delete")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{selectedDeployment.name}</b>{" "}
            {t("deployment_small")}?
          </span>
        }
        open={isDeploymentDeleteModalOpen}
      />
    </>
  );
};

export default Deployments;
