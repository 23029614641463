import { handleActions } from "redux-actions";
import {
  createRuleSetFailure,
  createRuleSetRequest,
  createRuleSetSuccess,
  deleteRuleActionFailure,
  deleteRuleActionRequest,
  deleteRuleActionSuccess,
  deleteRuleConditionFailure,
  deleteRuleConditionRequest,
  deleteRuleConditionSuccess,
  deleteRuleFailure,
  deleteRuleRequest,
  deleteRuleSetFailure,
  deleteRuleSetRequest,
  deleteRuleSetSuccess,
  deleteRuleSuccess,
  getConditionTypesFailure,
  getConditionTypesRequest,
  getConditionTypesSuccess,
  getRuleRevisionFailure,
  getRuleRevisionRequest,
  getRuleRevisionsFailure,
  getRuleRevisionsRequest,
  getRuleRevisionsSuccess,
  getRuleRevisionSuccess,
  getRuleRevision2Failure,
  getRuleRevision2Request,
  getRuleRevision2Success,
  getRuleSetFailure,
  getRuleSetForCopySuccess,
  getRuleSetRequest,
  getRuleSetsFailure,
  getRuleSetsRequest,
  getRuleSetsSuccess,
  getRuleSetSuccess,
  getRunTestResultDetailsFailure,
  getRunTestResultDetailsRequest,
  getRunTestResultDetailsSuccess,
  getRunTestResultStatusFailure,
  getRunTestResultStatusRequest,
  getRunTestResultStatusSuccess,
  getTestRuleSetFailure,
  getTestRuleSetRequest,
  getTestRuleSetSuccess,
  updateRuleSetFailure,
  updateRuleSetRequest,
  updateRuleSetSuccess,
  //explanations
  getRuleExplanationRequest,
  getRuleExplanationSuccess,
  getRuleExplanationFailure,
  //
  getRuleSetChatFailure,
  getRuleSetChatRequest,
  getRuleSetChatSuccess,
  getRuleSetWorkflowsFailure,
  getRuleSetWorkflowsRequest,
  getRuleSetWorkflowsSuccess,
} from "redux/rulesets/action";

const initialState = {
  ruleSets: [],
  ruleSetsCount: 0,
  ruleSet: {},
  newRuleSetId: "",
  conditionTypes: {},
  isGetRuleSetsSuccess: false,
  isGetRuleSetsError: false,
  isCreatedRuleSetSuccess: false,
  isCreatedRuleSetError: false,
  isRemovedRuleSetSuccess: false,
  isRemovedRuleSetError: false,
  isGetConditionTypesSuccess: false,
  isGetConditionTypesError: false,
  isUpdateRuleSetSuccess: false,
  isUpdateRuleSetError: false,
  isRemovedRuleSuccess: false,
  isRemovedRuleError: false,
  isRemovedRuleConditionSuccess: false,
  isRemovedRuleConditionError: false,
  isRemovedRuleActionSuccess: false,
  isRemovedRuleActionError: false,
  isGetRuleRevisionsSuccess: false,
  isGetRuleRevisionsError: false,
  ruleRevisions: [],
  isGetRuleRevisionSuccess: false,
  isGetRuleRevisionError: false,
  isGetRuleRevision2Success: false,
  isGetRuleRevision2Error: false,
  isGetTestRuleSetSuccess: false,
  isGetTestRuleSetError: false,
  isGetRunTestResultDetailsSuccess: false,
  isGetRunTestResultDetailsError: false,
  isGetRunTestResultStatusSuccess: false,
  isGetRunTestResultStatusError: false,
  runTestResultDetails: [],
  runTestResultStatus: {},
  ruleRevision: {},
  ruleRevision2: {},
  isGetRuleSetRequest: false,
  isGetRuleSetSuccess: false,
  isGetRuleSetError: false,
  isGetRuleSetForCopySuccess: false,
  ruleSetForCopy: {},
  //explanations
  isGetRuleExplanationSuccess: false,
  isGetRuleExplanationError: false,
  ruleExplanation: "",
  //
  isGetRuleSetChatSuccess: false,
  isGetRuleSetChatError: false,
  chatResponse: [],
  chatConversationId: "",
  //workflows
  isGetRuleSetWorkflowsSuccess: false,
  isGetRuleSetWorkflowsError: false,
  ruleSetWorkflows: [],
};

const reducer = handleActions(
  {
    [getRuleSetsRequest]: (state) => ({
      ...state,
      isGetRuleSetsRequest: true,
      isGetRuleSetsSuccess: false,
      isGetRuleSetsError: false,
    }),
    [getRuleSetsSuccess]: (state, { payload }) => ({
      ...state,
      ruleSets: payload.rule_sets || [],
      ruleSetsCount: payload.total_count,
      isGetRuleSetsRequest: false,
      isGetRuleSetsSuccess: true,
      isGetRuleSetsError: false,
    }),
    [getRuleSetsFailure]: (state) => ({
      ...state,
      isGetRuleSetsRequest: false,
      isGetRuleSetsSuccess: false,
      isGetRuleSetsError: true,
    }),
    // create rule set
    [createRuleSetRequest]: (state) => ({
      ...state,
      isCreatedRuleSetSuccess: false,
      isCreatedRuleSetError: false,
    }),
    [createRuleSetSuccess]: (state, { payload }) => ({
      ...state,
      newRuleSetId: payload?.rule_set_id ?? "",
      isCreatedRuleSetSuccess: true,
      isCreatedRuleSetError: false,
    }),
    [createRuleSetFailure]: (state) => ({
      ...state,
      isCreatedRuleSetSuccess: false,
      isCreatedRuleSetError: true,
    }),
    // remove rule set
    [deleteRuleSetRequest]: (state) => ({
      ...state,
      isRemovedRuleSetSuccess: false,
      isRemovedRuleSetError: false,
    }),
    [deleteRuleSetSuccess]: (state, { payload }) => ({
      ...state,
      ruleSets: payload,
      isRemovedRuleSetSuccess: true,
      isRemovedRuleSetError: false,
    }),
    [deleteRuleSetFailure]: (state) => ({
      ...state,
      isRemovedRuleSetSuccess: false,
      isRemovedRuleSetError: true,
    }),
    // get rule set
    [getRuleSetRequest]: (state) => ({
      ...state,
      isGetRuleSetRequest: true,
      isGetRuleSetSuccess: false,
      isGetRuleSetError: false,
      isGetRuleSetForCopySuccess: false,
    }),
    [getRuleSetSuccess]: (state, { payload }) => ({
      ...state,
      ruleSet: payload,
      isGetRuleSetRequest: false,
      isGetRuleSetSuccess: true,
      isGetRuleSetError: false,
      isGetRuleSetForCopySuccess: false,
    }),
    [getRuleSetForCopySuccess]: (state, { payload }) => ({
      ...state,
      ruleSetForCopy: payload,
      isGetRuleSetRequest: false,
      isGetRuleSetForCopySuccess: true,
    }),
    [getRuleSetFailure]: (state) => ({
      ...state,
      isGetRuleSetSuccess: false,
      isGetRuleSetError: true,
      isGetRuleSetForCopySuccess: false,
    }),
    // update rule set
    [updateRuleSetRequest]: (state) => ({
      ...state,
      isUpdateRuleSetSuccess: false,
      isUpdateRuleSetError: false,
    }),
    [updateRuleSetSuccess]: (state, { payload }) => ({
      ...state,
      ruleSet: payload,
      isUpdateRuleSetSuccess: true,
      isUpdateRuleSetError: false,
    }),
    [updateRuleSetFailure]: (state) => ({
      ...state,
      isUpdateRuleSetSuccess: false,
      isUpdateRuleSetError: true,
    }),
    // remove rule from rule sets
    [deleteRuleRequest]: (state) => ({
      ...state,
      isRemovedRuleSuccess: false,
      isRemovedRuleError: false,
    }),
    [deleteRuleSuccess]: (state, { payload }) => ({
      ...state,
      ruleSets: payload,
      isRemovedRuleSuccess: true,
      isRemovedRuleError: false,
    }),
    [deleteRuleFailure]: (state) => ({
      ...state,
      isRemovedRuleSuccess: false,
      isRemovedRuleError: true,
    }),
    // get condition types
    [getConditionTypesRequest]: (state) => ({
      ...state,
      isGetConditionTypesSuccess: false,
      isGetConditionTypesError: false,
    }),
    [getConditionTypesSuccess]: (state, { payload }) => ({
      ...state,
      conditionTypes: payload ?? {},
      isGetConditionTypesSuccess: true,
      isGetConditionTypesError: false,
    }),
    [getConditionTypesFailure]: (state) => ({
      ...state,
      isGetConditionTypesSuccess: false,
      isGetConditionTypesError: true,
    }),
    // remove rule condition
    [deleteRuleConditionRequest]: (state) => ({
      ...state,
      isRemovedRuleConditionSuccess: false,
      isRemovedRuleConditionError: false,
    }),
    [deleteRuleConditionSuccess]: (state) => ({
      ...state,
      isRemovedRuleConditionSuccess: true,
      isRemovedRuleConditionError: false,
    }),
    [deleteRuleConditionFailure]: (state) => ({
      ...state,
      isRemovedRuleConditionSuccess: false,
      isRemovedRuleConditionError: true,
    }),
    // remove rule action
    [deleteRuleActionRequest]: (state) => ({
      ...state,
      isRemovedRuleActionSuccess: false,
      isRemovedRuleActionError: false,
    }),
    [deleteRuleActionSuccess]: (state) => ({
      ...state,
      isRemovedRuleActionSuccess: true,
      isRemovedRuleActionError: false,
    }),
    [deleteRuleActionFailure]: (state) => ({
      ...state,
      isRemovedRuleActionSuccess: false,
      isRemovedRuleActionError: true,
    }),
    // get test rule set
    [getTestRuleSetRequest]: (state) => ({
      ...state,
      isGetTestRuleSetSuccess: false,
      isGetTestRuleSetError: false,
    }),
    [getTestRuleSetSuccess]: (state, { payload }) => ({
      ...state,
      testRuleSet: payload,
      isGetTestRuleSetSuccess: true,
      isGetTestRuleSetError: false,
    }),
    [getTestRuleSetFailure]: (state) => ({
      ...state,
      isGetTestRuleSetSuccess: false,
      isGetTestRuleSetError: true,
    }),
    // get run test result details
    [getRunTestResultDetailsRequest]: (state) => ({
      ...state,
      isGetRunTestResultDetailsSuccess: false,
      isGetRunTestResultDetailsError: false,
    }),
    [getRunTestResultDetailsSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultDetails: payload ?? [],
      isGetRunTestResultDetailsSuccess: true,
      isGetRunTestResultDetailsError: false,
    }),
    [getRunTestResultDetailsFailure]: (state) => ({
      ...state,
      isGetRunTestResultDetailsSuccess: false,
      isGetRunTestResultDetailsError: true,
    }),
    // get run test result status
    [getRunTestResultStatusRequest]: (state) => ({
      ...state,
      isGetRunTestResultStatusSuccess: false,
      isGetRunTestResultStatusError: false,
    }),
    [getRunTestResultStatusSuccess]: (state, { payload }) => ({
      ...state,
      runTestResultStatus: payload,
      isGetRunTestResultStatusSuccess: true,
      isGetRunTestResultStatusError: false,
    }),
    [getRunTestResultStatusFailure]: (state) => ({
      ...state,
      isGetRunTestResultStatusSuccess: false,
      isGetRunTestResultStatusError: true,
    }),
    // get rule revisions
    [getRuleRevisionsRequest]: (state) => ({
      ...state,
      isGetRuleRevisionsSuccess: false,
      isGetRuleRevisionsError: false,
    }),
    [getRuleRevisionsSuccess]: (state, { payload }) => ({
      ...state,
      ruleRevisions: payload,
      isGetRuleRevisionsSuccess: true,
      isGetRuleRevisionsError: false,
    }),
    [getRuleRevisionsFailure]: (state) => ({
      ...state,
      isGetRuleRevisionsSuccess: false,
      isGetRuleRevisionsError: true,
    }),
    // get rule revision
    [getRuleRevisionRequest]: (state) => ({
      ...state,
      isGetRuleRevisionSuccess: false,
      isGetRuleRevisionError: false,
    }),
    [getRuleRevisionSuccess]: (state, { payload }) => ({
      ...state,
      ruleRevision: payload,
      isGetRuleRevisionSuccess: true,
      isGetRuleRevisionError: false,
    }),
    [getRuleRevisionFailure]: (state) => ({
      ...state,
      isGetRuleRevisionSuccess: false,
      isGetRuleRevisionError: true,
    }),
    // get rule revision 2
    [getRuleRevision2Request]: (state) => ({
      ...state,
      isGetRuleRevision2Success: false,
      isGetRuleRevision2Error: false,
    }),
    [getRuleRevision2Success]: (state, { payload }) => ({
      ...state,
      ruleRevision2: payload,
      isGetRuleRevision2Success: true,
      isGetRuleRevision2Error: false,
    }),
    [getRuleRevision2Failure]: (state) => ({
      ...state,
      isGetRuleRevision2Success: false,
      isGetRuleRevision2Error: true,
    }),
    // get rule explanation
    [getRuleExplanationRequest]: (state) => ({
      ...state,
      isGetRuleExplanationSuccess: false,
      isGetRuleExplanationError: false,
    }),
    [getRuleExplanationSuccess]: (state, { payload }) => ({
      ...state,
      ruleExplanation: payload.explanation,
      isGetRuleExplanationSuccess: true,
      isGetRuleExplanationError: false,
    }),
    [getRuleExplanationFailure]: (state) => ({
      ...state,
      isGetRuleExplanationSuccess: false,
      isGetRuleExplanationError: true,
    }),
    // get rule set chat
    [getRuleSetChatRequest]: (state) => ({
      ...state,
      isGetRuleSetChatSuccess: false,
      isGetRuleSetChatError: false,
    }),
    [getRuleSetChatSuccess]: (state, { payload }) => ({
      ...state,
      chatResponse: payload,
      chatConversationId: payload.conversation_id,
      isGetRuleSetChatSuccess: true,
      isGetRuleSetChatError: false,
    }),
    [getRuleSetChatFailure]: (state) => ({
      ...state,
      isGetRuleSetChatSuccess: false,
      isGetRuleSetChatError: true,
    }),
    // get rule set workflows
    [getRuleSetWorkflowsRequest]: (state) => ({
      ...state,
      isGetRuleSetWorkflowsSuccess: false,
      isGetRuleSetWorkflowsError: false,
    }),
    [getRuleSetWorkflowsSuccess]: (state, { payload }) => ({
      ...state,
      ruleSetWorkflows: payload,
      isGetRuleSetWorkflowsSuccess: true,
      isGetRuleSetWorkflowsError: false,
    }),
    [getRuleSetWorkflowsFailure]: (state) => ({
      ...state,
      isGetRuleSetWorkflowsSuccess: false,
      isGetRuleSetWorkflowsError: true,
    }),
  },
  initialState
);

export default reducer;
