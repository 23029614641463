import { handleActions } from "redux-actions";
import {
  createVectorAttributeFailure,
  createVectorAttributeRequest,
  createVectorAttributeSuccess,
  createVectorFailure,
  createVectorRequest,
  createVectorSuccess,
  deleteVectorAttributeFailure,
  deleteVectorAttributeRequest,
  deleteVectorAttributeSuccess,
  deleteVectorFailure,
  deleteVectorRequest,
  deleteVectorSuccess,
  getVectorAttributesFailure,
  getVectorAttributesRequest,
  getVectorAttributesSuccess,
  getVectorFailure,
  getVectorRequest,
  getVectorsFailure,
  getVectorsRequest,
  getVectorsSuccess,
  getVectorSuccess,
  getVectorsExtraRequest,
  getVectorsExtraFailure,
  getVectorsExtraSuccess,
  updateVectorAttributeFailure,
  updateVectorAttributeRequest,
  updateVectorAttributeSuccess,
  updateVectorFailure,
  updateVectorRequest,
  updateVectorSuccess,
} from "redux/vectors/action";

const initialState = {
  newVectorId: "",
  vectors: [],
  vectorsCount: 0,
  vectorsExtra: [],
  vector: {},
  vectorAttributes: {},
  isGetRuleSetsSuccess: false,
  isGetRuleSetsError: false,
  isGetVectorSuccess: false,
  isGetVectorError: false,
  isUpdatedVectorRequest: false,
  isUpdatedVectorSuccess: false,
  isUpdatedVectorError: false,
  isGetVectorAttributesSuccess: false,
  isGetVectorAttributesError: false,
  isUpdatedVectorAttributeSuccess: false,
  isUpdatedVectorAttributeError: false,
  isDeletedVectorAttributeSuccess: false,
  isDeletedVectorAttributeError: false,
  isDeletedVectorSuccess: false,
  isDeletedVectorError: false,
  isCreatedVectorAttributeSuccess: false,
  isCreatedVectorAttributeError: false,
  isCreatedVectorRequest: false,
  isCreatedVectorSuccess: false,
  isCreatedVectorError: false,
  isGetVectorsExtraSuccess: false,
  isGetVectorsExtraFailure: false,
};

const reducer = handleActions(
  {
    [getVectorsRequest]: (state) => ({
      ...state,
      isGetVectorsSuccess: false,
      isGetVectorsError: false,
    }),
    [getVectorsSuccess]: (state, { payload }) => ({
      ...state,
      vectors: payload.vectors ?? [],
      vectorsCount: payload.total_count ?? 0,
      isGetVectorsSuccess: true,
      isGetVectorsError: false,
    }),
    [getVectorsFailure]: (state) => ({
      ...state,
      isGetVectorsSuccess: false,
      isGetVectorsError: true,
    }),
    // get vector
    [getVectorRequest]: (state) => ({
      ...state,
      isGetVectorSuccess: false,
      isGetVectorError: false,
    }),
    [getVectorSuccess]: (state, { payload }) => ({
      ...state,
      vector: payload,
      isGetVectorSuccess: true,
      isGetVectorError: false,
    }),
    [getVectorFailure]: (state) => ({
      ...state,
      isGetVectorSuccess: false,
      isGetVectorError: true,
    }),
    // get vectors extra
    [getVectorsExtraRequest]: (state) => ({
      ...state,
      isGetVectorsExtraSuccess: false,
      isGetVectorsExtraError: false,
    }),
    [getVectorsExtraSuccess]: (state, { payload }) => ({
      ...state,
      vectorsExtra: payload ?? [],
      isGetVectorsExtraSuccess: true,
      isGetVectorsExtraError: false,
    }),
    [getVectorsExtraFailure]: (state) => ({
      ...state,
      isGetVectorsExtraSuccess: false,
      isGetVectorsExtraError: true,
    }),

    // vector attributes
    [getVectorAttributesRequest]: (state) => ({
      ...state,
      isGetVectorAttributesSuccess: false,
      isGetVectorAttributesError: false,
    }),
    [getVectorAttributesSuccess]: (state, { payload }) => ({
      ...state,
      vectorAttributes: payload,
      isGetVectorAttributesSuccess: true,
      isGetVectorAttributesError: false,
    }),
    [getVectorAttributesFailure]: (state) => ({
      ...state,
      vectorAttributes: {},
      isGetVectorAttributesSuccess: false,
      isGetVectorAttributesError: true,
    }),
    // create vector
    [createVectorRequest]: (state) => ({
      ...state,
      isCreatedVectorRequest: true,
      isCreatedVectorSuccess: false,
      isCreatedVectorError: false,
    }),
    [createVectorSuccess]: (state, { payload }) => ({
      ...state,
      newVectorId: payload ?? "",
      isCreatedVectorSuccess: true,
      isCreatedVectorRequest: false,
      isCreatedVectorError: false,
    }),
    [createVectorFailure]: (state) => ({
      ...state,
      isCreatedVectorSuccess: false,
      isCreatedVectorRequest: false,
      isCreatedVectorError: true,
    }),
    // create vector attr
    [createVectorAttributeRequest]: (state) => ({
      ...state,
      isCreatedVectorAttributeSuccess: false,
      isCreatedVectorAttributeError: false,
    }),
    [createVectorAttributeSuccess]: (state) => ({
      ...state,
      isCreatedVectorAttributeSuccess: true,
      isCreatedVectorAttributeError: false,
    }),
    [createVectorAttributeFailure]: (state) => ({
      ...state,
      isCreatedVectorAttributeSuccess: false,
      isCreatedVectorAttributeError: true,
    }),
    // update vector
    [updateVectorRequest]: (state) => ({
      ...state,
      isUpdatedVectorRequest: true,
      isUpdatedVectorSuccess: false,
      isUpdatedVectorError: false,
    }),
    [updateVectorSuccess]: (state) => ({
      ...state,
      isUpdatedVectorRequest: false,
      isUpdatedVectorSuccess: true,
      isUpdatedVectorError: false,
    }),
    [updateVectorFailure]: (state) => ({
      ...state,
      isUpdatedVectorRequest: false,
      isUpdatedVectorSuccess: false,
      isUpdatedVectorError: true,
    }),
    // update vector attr
    [updateVectorAttributeRequest]: (state) => ({
      ...state,
      isUpdatedVectorAttributeSuccess: false,
      isUpdatedVectorAttributeError: false,
    }),
    [updateVectorAttributeSuccess]: (state) => ({
      ...state,
      isUpdatedVectorAttributeSuccess: true,
      isUpdatedVectorAttributeError: false,
    }),
    [updateVectorAttributeFailure]: (state) => ({
      ...state,
      isUpdatedVectorAttributeSuccess: false,
      isUpdatedVectorAttributeError: true,
    }),
    // delete vector attr
    [deleteVectorAttributeRequest]: (state) => ({
      ...state,
      isDeletedVectorAttributeSuccess: false,
      isDeletedVectorAttributeError: false,
    }),
    [deleteVectorAttributeSuccess]: (state) => ({
      ...state,
      isDeletedVectorAttributeSuccess: true,
      isDeletedVectorAttributeError: false,
    }),
    [deleteVectorAttributeFailure]: (state) => ({
      ...state,
      isDeletedVectorAttributeSuccess: false,
      isDeletedVectorAttributeError: true,
    }),
    // delete vector
    [deleteVectorRequest]: (state) => ({
      ...state,
      isDeletedVectorSuccess: false,
      isDeletedVectorError: false,
    }),
    [deleteVectorSuccess]: (state) => ({
      ...state,
      isDeletedVectorSuccess: true,
      isDeletedVectorError: false,
    }),
    [deleteVectorFailure]: (state) => ({
      ...state,
      isDeletedVectorSuccess: false,
      isDeletedVectorError: true,
    }),
  },
  initialState
);

export default reducer;
