import { call, put, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createUserAccountFailure,
  createUserAccountRequest,
  createUserAccountSuccess,
  createUserAccountConflict,
  deleteUserFromAccountsFailure,
  deleteUserFromAccountsRequest,
  deleteUserFromAccountsSuccess,
  getUserAccountFailure,
  getUserAccountRequest,
  getUserAccountsFailure,
  getUserAccountsRequest,
  getUserAccountsSuccess,
  getUserAccountSuccess,
  updateUserAccountFailure,
  updateUserAccountPasswordFailure,
  updateUserAccountPasswordRequest,
  updateUserAccountPasswordSuccess,
  updateUserAccountRequest,
  updateUserAccountSuccess,
  checkUserSessionStatusRequest,
  checkUserSessionStatusSuccess,
  checkUserSessionStatusFailure,
  lockUserRequest,
  lockUserSuccess,
  lockUserFailure,
  unlockUserRequest,
  unlockUserSuccess,
  unlockUserFailure,
} from "redux/user/action";

function* getUserAccounts({ payload }) {
  try {
    const response = yield call(
      axios.get,
      payload ? `/user-accounts?page=${payload}` : "/user-accounts"
    );
    if (response.status === 200) {
      yield put(getUserAccountsSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserAccountsFailure("e.message"));
  }
}

function* getUserAccount({ payload }) {
  try {
    const url = `/user-accounts/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getUserAccountSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getUserAccountFailure("e.message"));
  }
}

function* createUserAccount({ payload }) {
  try {
    const response = yield call(axios.post, "user-accounts", payload);
    if (response.status === 201) {
      yield put(createUserAccountSuccess(response.headers.etag));
    }
  } catch (e) {
    if (e.response.status === 409) {
      yield put(createUserAccountConflict());
    } else {
      yield put(createUserAccountFailure("e.message"));
    }
  }
}

function* updateUserAccount({ payload }) {
  try {
    const url = `/user-accounts/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateUserAccountSuccess(response.data));
    }
  } catch (e) {
    yield put(updateUserAccountFailure("e.message"));
  }
}

function* updateAccountPassword({ payload }) {
  try {
    const url = `/user-passwords/${payload.get("user_account_id")}`;
    const response = yield call(axios.put, url, payload);
    if (response.status === 200) {
      yield put(updateUserAccountPasswordSuccess());
    }
  } catch (e) {
    yield put(updateUserAccountPasswordFailure("e.message"));
  }
}

function* deleteUserFromAccounts({ payload }) {
  try {
    const url = `/user-accounts/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteUserFromAccountsSuccess());
    }
  } catch (e) {
    yield put(deleteUserFromAccountsFailure("e.message"));
  }
}

function* checkUserSessionStatus() {
  try {
    const url = `/user/session-status`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(checkUserSessionStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(checkUserSessionStatusFailure("e.message"));
  }
}

function* lockUser({ payload }) {
  try {
    const url = `/user-accounts/${payload}/lock`;
    const response = yield call(axios.post, url);
    if (response.status === 201) {
      yield put(lockUserSuccess());
      // Refresh user accounts list
      yield put(getUserAccountsRequest());
    }
  } catch (e) {
    yield put(lockUserFailure(e.message));
  }
}

function* unlockUser({ payload }) {
  try {
    const url = `/user-accounts/${payload}/lock/1`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(unlockUserSuccess());
      // Refresh user accounts list
      yield put(getUserAccountsRequest());
    }
  } catch (e) {
    yield put(unlockUserFailure(e.message));
  }
}

export default function* saga() {
  yield takeLatest(getUserAccountsRequest, getUserAccounts);
  yield takeLatest(getUserAccountRequest, getUserAccount);
  yield takeLatest(createUserAccountRequest, createUserAccount);
  yield takeLatest(updateUserAccountRequest, updateUserAccount);
  yield takeLatest(updateUserAccountPasswordRequest, updateAccountPassword);
  yield takeLatest(deleteUserFromAccountsRequest, deleteUserFromAccounts);
  yield takeLatest(checkUserSessionStatusRequest, checkUserSessionStatus);
  yield takeLatest(lockUserRequest, lockUser);
  yield takeLatest(unlockUserRequest, unlockUser);
}
