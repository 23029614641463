import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ReactComponent as EditIcon } from "assets/icons/templateIcons/edit_small.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";

const ResponseCard = ({ response, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const statusAttr = response.response_attributes?.find((attr) =>
    attr.mapping?.includes("__http_code")
  );
  const contentTypeAttr = response.response_attributes?.find((attr) =>
    attr.mapping?.includes("__content_type")
  );
  const timeAttr = response.response_attributes?.find((attr) =>
    attr.mapping?.includes("__total_time")
  );

  const getStatusEmoji = (statusCode) => {
    statusCode = parseInt(statusCode, 10);

    if (statusCode >= 200 && statusCode < 300) {
      return "🟢"; // Green for success (2XX)
    } else if (statusCode >= 300 && statusCode < 400) {
      return "🔵"; // Blue for redirection (3XX)
    } else if (statusCode >= 400 && statusCode < 500) {
      return "🟠"; // Orange for client errors (4XX)
    } else if (statusCode >= 500) {
      return "🔴"; // Red for server errors (5XX)
    }
    return "⚪"; // Default/unknown
  };

  return (
    <div className="card mb-3">
      <div
        className="card-header d-flex justify-content-between align-items-center cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: "pointer", userSelect: "none" }}
      >
        <div>
          <h5 className="mb-1">
            {response.response_name || t("unnamed_response")}
          </h5>
          <div className="text-muted small d-flex flex-wrap">
            {statusAttr && (
              <span className="mr-3">
                {getStatusEmoji(statusAttr.dummy_value)} {t("status")}:{" "}
                {statusAttr.dummy_value}
              </span>
            )}
            {timeAttr && (
              <span className="mr-3">
                ⏱ {t("time")}: {parseFloat(timeAttr.dummy_value).toFixed(2)}s
              </span>
            )}
          </div>
        </div>
        <div
          className="d-flex align-items-center"
          onClick={(e) => e.stopPropagation()} // prevents header click
        >
          <button
            title={t("edit_response")}
            className="border-radius-4 border-0 btn btn-outline-light mr-2"
            type="button"
            onClick={onEdit}
          >
            <EditIcon />
          </button>
          <button
            title={t("delete_response")}
            className="border-radius-4 border-0 btn btn-outline-light option-delete"
            type="button"
            onClick={onDelete}
          >
            <DeleteIcon style={{ color: "#6C757D" }} />
          </button>
        </div>
      </div>

      {isExpanded && (
        <div className="card-body">
          {response.response_attributes?.length > 0 ? (
            <div className="table-responsive">
              <table className="table data-source-table">
                <thead className="bg-transparent border-0">
                  <tr>
                    <th style={{ width: "60%" }}>{t("attribute_path")}</th>
                    <th>{t("value")}</th>
                  </tr>
                </thead>
                <tbody>
                  {response.response_attributes.map((attribute, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          fontFamily: "monospace",
                          wordBreak: "break-all",
                        }}
                      >
                        {attribute.mapping || attribute.path || ""}
                      </td>
                      <td>{attribute.dummy_value || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-muted">{t("no_response_attributes")}</p>
          )}
        </div>
      )}
    </div>
  );
};

ResponseCard.propTypes = {
  response: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ResponseCard;
