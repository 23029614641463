import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import { Card, CardsBottomSide } from "components/auth/Card";
import "react-toastify/dist/ReactToastify.css";
import "components/auth/login.scss";
import { emailRegex } from "utility/utility";
import { getLoginRequest, resetOTPRequirement } from "../../redux/auth/action";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { useNavigate } from "react-router-dom";

//max password length
const passwordMaxLength = 64;
const passwordMinLength = 8;

const Login = () => {
  const dispatch = useDispatch();
  const { isGetLoginFailure } = useSelector((state) => state.auth);
  const { isRequireOTP } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const prevIsGetLoginFailure = usePrevious(isGetLoginFailure);
  const prevIsRequireOTP = usePrevious(isRequireOTP);
  const { t } = useTranslation();
  //whether waiting for the response from the server - button login was hit - default false. when response from server is received, it will be set to false
  const [isWaiting, setIsWaiting] = useState(false);
  const [secret, setSecret] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const onHandleLogin = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (email.length > 0 && email.match(emailRegex)) {
      if (password.length > 4 && password.length <= passwordMaxLength) {
        setIsWaiting(true);
        dispatch(getLoginRequest(data));
      } else if (password.length < passwordMinLength) {
        toast.error(
          `Password must be at least ${passwordMinLength} characters!`,
          ToastOptions
        );
      } else {
        toast.error(
          `Password must be less than ${passwordMaxLength} characters!`,
          ToastOptions
        );
      }
    } else {
      return toast.error(t("invalid_email_address"), ToastOptions);
    }
  };

  useEffect(() => {
    document.title = "Sing in - Decisimo";
  }, []);

  useEffect(() => {
    if (isGetLoginFailure && prevIsGetLoginFailure === false && !isRequireOTP) {
      toast.error(t("unable_login"), ToastOptions);
      setIsWaiting(false);
    }
  }, [isGetLoginFailure]);

  //OTP required
  useEffect(() => {
    if (isRequireOTP) {
      toast.success(t("please_check_your_email_for_otp"), ToastOptions);
      setIsWaiting(false);
      //reset OTP requirement
      dispatch(resetOTPRequirement());
      navigate("/otp-login");
    }
  }, [isRequireOTP]);

  function togglePassword() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setSecret(true);
    } else {
      x.type = "password";
      setSecret(false);
    }
  }

  const handleCapsLock = (e) => {
    setIsCapsLockOn(e.getModifierState("CapsLock"));
  };

  return (
    <Card>
      <form className="form-pretty" onSubmit={onHandleLogin}>
        <h1 className="form-pretty__title my-4">{t("please_sign_in")}</h1>
        <label className="sr-only mt-2">{t("email")}</label>
        <input
          type="email"
          name="email"
          className="form-control mb-2"
          placeholder={t("email")}
          required
          autoFocus
        />
        <label className="sr-only">{t("password")}</label>
        <div className="input-group">
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            placeholder={t("password")}
            required
            onKeyUp={handleCapsLock}
            onKeyDown={handleCapsLock}
          />
          <div className="input-group-append">
            <button
              title={!secret ? t("show_password") : t("hide_password")}
              className="form-control"
              type="button"
              onClick={togglePassword}
            >
              {secret ? <EyeOffIcon /> : <EyeIcon />}
            </button>
          </div>
        </div>
        {isCapsLockOn && <div className="small mt-1">{t("caps_lock_on")}</div>}
        <button className="form-pretty__btn" type="submit">
          {isWaiting ? (
            <div
              className="spinner-border"
              style={{
                width: "1rem",
                height: "1rem",
              }}
              role="status"
            >
              <span className="sr-only">{t("loading")}</span>
            </div>
          ) : (
            t("log_in")
          )}
        </button>
        <hr />
        <CardsBottomSide isForgotten={true} />
      </form>
    </Card>
  );
};

export default Login;
