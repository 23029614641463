import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteDecisionTreeRequest,
  fetchDecisionTreesRequest,
} from "redux/decisionTrees/action";
import { MainContext } from "context/contexts";
import DeleteConfirm from "components/modals/DeleteConfirm";
import SubHeader from "components/SubHeader";
import { ToastOptions } from "components/toastify";
import { ReactComponent as PlusIcon } from "assets/icons/plus-white.svg";
import { ReactComponent as MoreVertical } from "assets/icons/more-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { useTranslation } from "react-i18next";
import { sortByTitle, TableNoItems } from "utility/utility";
import Pagination from "utility/Pagination";
import usePrevious from "utility/hooks/usePrevious";

const DecisionTrees = () => {
  const { setIsLoading } = useContext(MainContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    decisionTrees,
    decisionTreesCount,
    isLoading,
    error,
    isDeleteTreeSuccess,
    isDeleteTreeError,
  } = useSelector((state) => state.decisionTrees);

  const prevIsDeleteTreeSuccess = usePrevious(isDeleteTreeSuccess);
  const prevIsDeleteTreeError = usePrevious(isDeleteTreeError);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    document.title = `${t("nav_bar_decision_trees")} - Decisimo`;
    dispatch(fetchDecisionTreesRequest());
  }, [dispatch, t]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    if (error) {
      toast.error(error, ToastOptions);
    }
  }, [error]);

  useEffect(() => {
    if (currentPage >= 0) {
      setIsLoading(true);
      dispatch(fetchDecisionTreesRequest(currentPage.toString()));
    }
  }, [currentPage]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTree, setSelectedTree] = useState(null);

  const handleShowDeleteModal = (tree) => {
    setSelectedTree(tree);
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteDecisionTreeRequest(selectedTree.decision_tree_id));
    setIsDeleteModalOpen(false);
  };

  //if successfully deleted, show success message
  useEffect(() => {
    if (isDeleteTreeSuccess && prevIsDeleteTreeSuccess === false) {
      toast.success(t("decision_tree_deleted_successfully"), ToastOptions);
      dispatch(fetchDecisionTreesRequest());
    }
  }, [isDeleteTreeSuccess, dispatch, t]);

  //if delete failed, show error message
  useEffect(() => {
    if (isDeleteTreeError && prevIsDeleteTreeError === false) {
      toast.error(t("error_deleting_decision_tree"), ToastOptions);
    }
  }, [isDeleteTreeError, t]);

  return (
    <>
      <SubHeader
        alt={t("nav_bar_decision_trees")}
        title={t("nav_bar_decision_trees")}
        actions={
          <div className="d-flex flex-wrap my-3 text-right white-space-pre btn-group">
            <Link
              to="/decision-tree"
              title={t("create_decision_tree")}
              className="btn primary-header border-radius-left-4"
            >
              <PlusIcon />{" "}
              <span className="ml-2">{t("create_decision_tree")}</span>
            </Link>
          </div>
        }
      />
      <div className="mb-4">
        <table className="table table-hover table-fixed border-top-0">
          <thead className="border-top-0">
            <tr>
              <th scope="col" className="th-flow col-lg-8 py-3">
                {t("title_capitalized")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3">
                {t("last_modified")}
              </th>
              <th scope="col" className="th-flow col-lg-2 py-3 text-right"></th>
            </tr>
          </thead>
          <tbody>
            {decisionTrees.length > 0 ? (
              decisionTrees.map((tree) => (
                <tr key={tree.decision_tree_id} className="td-flow">
                  <td>
                    <Link to={`/decision-trees/${tree.decision_tree_id}`}>
                      {tree.title}
                    </Link>
                  </td>
                  <td>{tree.dtime_modified}</td>
                  <td className="d-flex align-items-center justify-content-end border-0">
                    <div className="dropdown">
                      <button
                        className="btn primary-text border-0 p-0"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <MoreVertical />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <button
                          className="dropdown-item"
                          onClick={() => handleShowDeleteModal(tree)}
                        >
                          <TrashIcon /> {t("delete")}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <TableNoItems />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        completeItemsCount={decisionTreesCount}
        itemsPerPage={20}
        setCurrentPage={setCurrentPage}
      />

      <DeleteConfirm
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={handleConfirmDelete}
        title={t("delete_decision_tree")}
        message={t("are_you_sure_delete_decision_tree", {
          title: selectedTree?.title,
        })}
      />
    </>
  );
};

export default DecisionTrees;
