import { handleActions } from "redux-actions";
import {
  createTemplateDecisionFailure,
  createTemplateDecisionRequest,
  createTemplateDecisionSuccess,
  getAllTemplatesSuccess,
  getTemplateDecisionRequest,
  getTemplateDecisionsFailure,
  getTemplateDecisionsRequest,
  getTemplateDecisionsSuccess,
  getTemplateDecisionSuccess,
  getTemplateFailure,
  getTemplateRequest,
  getTemplatesFailure,
  getTemplatesRequest,
  getTemplatesSuccess,
  getTemplateSuccess,
  deleteTemplateDecisionFailure,
  deleteTemplateDecisionRequest,
  deleteTemplateDecisionSuccess,
} from "redux/templates/action";

const initialState = {
  templates: [],
  allTemplates: [],
  useCaseTemplate: [],
  template: {},
  isGetTemplateSuccess: false,
  isGetTemplateError: false,
  isGetTemplatesSuccess: false,
  isGetTemplatesError: false,
  isCreateTemplateDecisionSuccess: false,
  isCreateTemplateDecisionRequest: false,
  isCreateTemplateDecisionError: false,
  isGetTemplateDecisionsSuccess: false,
  isGetTemplateDecisionsError: false,
  isGetTemplateDecisionSuccess: false,
  isGetTemplateDecisionError: false,
  isDeleteTemplateDecisionSuccess: false,
  isDeleteTemplateDecisionError: false,
};

const reducer = handleActions(
  {
    // get templates
    [getTemplatesRequest]: (state) => ({
      ...state,
      isGetTemplatesSuccess: false,
      isGetTemplatesError: false,
    }),
    [getTemplatesSuccess]: (state, { payload }) => ({
      ...state,
      templates: payload || [],
      isGetTemplatesSuccess: true,
      isGetTemplatesError: false,
    }),
    [getAllTemplatesSuccess]: (state, { payload }) => ({
      ...state,
      allTemplates: payload || [],
      isGetTemplatesSuccess: true,
      isGetTemplatesError: false,
    }),
    [getTemplatesFailure]: (state) => ({
      ...state,
      isGetTemplatesSuccess: false,
      isGetTemplatesError: true,
    }),
    // get template
    [getTemplateRequest]: (state) => ({
      ...state,
      isGetTemplateSuccess: false,
      isGetTemplateError: false,
      useCaseTemplate: [],
    }),
    [getTemplateSuccess]: (state, { payload }) => ({
      ...state,
      template: payload.data || {},
      isGetTemplateSuccess: true,
      isGetTemplateError: false,
      useCaseTemplate: payload.useCaseTemplate,
    }),
    [getTemplateFailure]: (state) => ({
      ...state,
      isGetTemplateSuccess: false,
      isGetTemplateError: true,
      useCaseTemplate: [],
    }),
    // create Decision designer
    [createTemplateDecisionRequest]: (state) => ({
      ...state,
      isCreateTemplateDecisionRequest: true,
      isCreateTemplateDecisionSuccess: false,
      isCreateTemplateDecisionError: false,
    }),
    [createTemplateDecisionSuccess]: (state) => ({
      ...state,
      isCreateTemplateDecisionRequest: false,
      isCreateTemplateDecisionSuccess: true,
      isCreateTemplateDecisionError: false,
    }),
    [createTemplateDecisionFailure]: (state, { payload }) => ({
      ...state,
      isCreateTemplateDecisionRequest: false,
      isCreateTemplateDecisionSuccess: false,
      isCreateTemplateDecisionError: true,
      errorStatus: payload,
    }),
    // get Decisions Design
    [getTemplateDecisionsRequest]: (state) => ({
      ...state,
      isGetTemplateDecisionsSuccess: false,
      isGetTemplateDecisionsError: false,
    }),
    [getTemplateDecisionsSuccess]: (state, { payload }) => ({
      ...state,
      templatesDecisions: payload || [],
      isGetTemplateDecisionsSuccess: true,
      isGetTemplateDecisionsError: false,
    }),
    [getTemplateDecisionsFailure]: (state, { payload }) => ({
      ...state,
      allTemplates: payload || [],
      isGetTemplateDecisionsSuccess: true,
      isGetTemplateDecisionsError: false,
    }),
    // get Decision Design
    [getTemplateDecisionRequest]: (state) => ({
      ...state,
      isGetTemplateDecisionSuccess: false,
      isGetTemplateDecisionError: false,
    }),
    [getTemplateDecisionSuccess]: (state, { payload }) => ({
      ...state,
      decision: payload ?? {},
      isGetTemplateDecisionSuccess: true,
      isGetTemplateDecisionError: false,
    }),
    [getTemplateDecisionsFailure]: (state) => ({
      ...state,
      isGetTemplateDecisionSuccess: false,
      isGetTemplateDecisionError: true,
    }),
    // delete Decision Design
    [deleteTemplateDecisionRequest]: (state) => ({
      ...state,
      isDeleteTemplateDecisionSuccess: false,
      isDeleteTemplateDecisionError: false,
    }),
    [deleteTemplateDecisionSuccess]: (state) => ({
      ...state,
      isDeleteTemplateDecisionSuccess: true,
      isDeleteTemplateDecisionError: false,
    }),
    [deleteTemplateDecisionFailure]: (state) => ({
      ...state,
      isDeleteTemplateDecisionSuccess: false,
      isDeleteTemplateDecisionError: true,
    }),
  },
  initialState
);

export default reducer;
