import React, { useState } from "react";
import PropTypes from "prop-types";
import ResultContentModal from "components/modals/release/ResultContentModal";
import { Badge } from "react-bootstrap";

const VirtualImpactRow = React.memo(({ index, style, data }) => {
  const { results, t } = data;
  const result = results[index];

  // Early return if result is undefined
  if (!result) return null;

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const handleRowClick = (content, type) => {
    setModalContent(content);
    setModalTitle(`${type} #${index + 1}`);
    setShowModal(true);
  };

  // Check for differences between baseline and comparison
  const hasDifferences =
    JSON.stringify(result.baselineOutput) !==
    JSON.stringify(result.comparisonOutput);

  return (
    <>
      <div
        style={{
          ...style,
          display: "flex",
          width: "100%",
          boxSizing: "border-box",
        }}
        className="virtual-row"
      >
        <div className="table-col col-number">
          <span>{index + 1}</span>
        </div>
        <div
          className="table-col col-content"
          onClick={() => handleRowClick(result.input, "Input")}
        >
          <pre className="result-pre">
            {JSON.stringify(result.input, null, 2).substring(0, 50)}
            {JSON.stringify(result.input, null, 2).length > 50 ? "..." : ""}
          </pre>
        </div>
        <div
          className="table-col col-content"
          onClick={() =>
            handleRowClick(result.baselineOutput, "Baseline Output")
          }
        >
          <pre className="result-pre">
            {JSON.stringify(result.baselineOutput, null, 2).substring(0, 50)}
            {JSON.stringify(result.baselineOutput, null, 2).length > 50
              ? "..."
              : ""}
          </pre>
        </div>
        <div
          className="table-col col-content"
          onClick={() =>
            handleRowClick(result.comparisonOutput, "Comparison Output")
          }
        >
          <pre className="result-pre">
            {JSON.stringify(result.comparisonOutput, null, 2).substring(0, 50)}
            {JSON.stringify(result.comparisonOutput, null, 2).length > 50
              ? "..."
              : ""}
          </pre>
        </div>
        <div className="table-col col-status">
          {hasDifferences ? (
            <Badge bg="warning">{t("different")}</Badge>
          ) : (
            <Badge bg="success">{t("identical")}</Badge>
          )}
        </div>
      </div>

      <ResultContentModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={modalContent}
        title={modalTitle}
      />
    </>
  );
});

VirtualImpactRow.displayName = "VirtualImpactRow";

VirtualImpactRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.shape({
    results: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
  }).isRequired,
};

export default VirtualImpactRow;
